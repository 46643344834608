import React from 'react';
import Profile from './Profile';
import { Dialog } from '@mui/material';

/**
 * Wrap profile app seting
 */
export default function ProfileDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="profile-dialog">
      <Profile appId="PROFILE"
          selectedIndex={props.selectedIndex}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.closeCallback} />
    </Dialog>
   
  );
}


