import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { useInterval } from '../../ckcore/core/CkUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';

import PartnerSimList from '../sim/PartnerSimList';
import CKAppSmsList from '../sim/CKAppSmsList';

/**
 * Partner CKApp and SMS
 * @param {*} props
 */
export default function PartnerCKApp(props) {
  
  const userObj = props.userObj;
  const action = (props.action) ? props.action : "";

  const [delay, setDelay] = useState(100);
  const [loading, setLoading] = useState(false);

  const [smsData, setSmsData] = useState({
    sims: [],
    smsTx: {},
  });

  // load sms data
  const loadSmsData = async () => {
    try {

      if(loading) {
        return;
      }

      // load sms list
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_LOAD_SMS_LIST,
        partnerId: userObj.partnerId,
        index: 0,
        count: 111,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "partner=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setSmsData(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load sms status, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get sms data.. " + error);
    }

    setLoading(false);

  };


  // Use interval to refresh bank data
  useInterval(() => {

    loadSmsData();

    setDelay(5000);
  }, delay);


  useEffect(() => {
    // reload on refresh
    loadSmsData();

    
  }, []);


  return (

    <Grid container spacing={1}>

      <Grid item xs={12} style={{ paddingTop: 8 }}>
        <PartnerSimList           
          simList={smsData.sims} 
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
                
      </Grid>

      <Grid item xs={12} style={{ paddingTop: 16 }}>
      <CKAppSmsList 
          smsList={smsData.smsList} 
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      </Grid>

    </Grid>

  );
}
