import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Divider, FormControl, FormControlLabel, FormLabel, IconButton, MenuItem, Radio, RadioGroup, Tab, Tabs, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

import { useInterval } from '../../ckcore/core/CkUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';

import PaymentTxList from '../order/PaymentTxList';
import Colors from '../../ckcore/core/Colors';
import PurchaseRequestDialog from '../order/PurchaseRequestDialog';
import { getDateRange, TIME_RANGES } from '../../ckcore/core/TimeRanges';
import ServiceTypes from '../../ckcore/core/ServiceTypes';
import ExportGoogleSheets from '../export/ExportGoogleSheets';

/**
 * Partner Payment Transactions
 * @param {*} props
 */
export default function PartnerOrder(props) {

  const userObj = props.userObj;
  const action = (props.action) ? props.action : "";
  // console.log("Request action: " + action);

  const [delay, setDelay] = useState(100);
  const [loading, setLoading] = useState(false);

  const [showPurchaseRequestDialog, setShowPurchaseRequestDialog] = useState(false);
  const closePurchaseRequestDialog = () => {
    setShowPurchaseRequestDialog(false);
    props.refreshCallback('ORDERS', '');
    loadOrderData();
  }

  const [showSyncGoogleSheetsDialog, setShowSyncGoogleSheetsDialog] = useState(false);
  const closeSyncGoogleSheetsDialog = () => {
    setShowSyncGoogleSheetsDialog(false);
  }

  const [orderData, setOrderData] = useState({
    bankConfig: {},
    txList: [],
  });

  const [filterObj, setFilterObj] = useState({
    serviceId: 0,
    index: 0,
    count: 99,
    status: 'latest',
    timeRange: 'today',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    keyword: ''
  });

  /**
   * Change filter status
   */
  const handleChangeStatus = (event) => {
    var status = event.target.value;
    setFilterObj({ ...filterObj, 'status': status });
  }


  /**
   * Change time range
   */
  const handleChangeTimeRange = (event) => {

    let timeRange = event.target.value;
    let dateRange = getDateRange(timeRange);

    let fromDate = (dateRange.fromDate) ? dateRange.fromDate : filterObj.fromDate;
    let toDate = (dateRange.toDate) ? dateRange.toDate : filterObj.toDate;
    setFilterObj({ ...filterObj, timeRange: timeRange, fromDate: fromDate, toDate: toDate });
  }

  /**
   * Change date to filter bank tx
   */
  const handleChangeDate = name => event => {
    var val = event.target.value;
    setFilterObj({ ...filterObj, [name]: val });
  };

  /**
   * Change search keyword
   */
  const handleChangeKeyword = (event) => {
    var keyword = event.target.value.toLowerCase();
    setFilterObj({ ...filterObj, 'keyword': keyword });
  }

  // change service ID by order tab
  const [orderTab, setOrderTab] = useState('all');
  const handleChangeTab = (event, newTab) => {

    var serviceId = 0; // all
    if (newTab === 'sale_orders') {
      serviceId = ServiceTypes.SALE_ORDER;
    } else if (newTab === 'payment_requests') {
      serviceId = ServiceTypes.PAYMENT_REQUEST;
    } else if (newTab === 'purchase_orders') {
      serviceId = ServiceTypes.PURCHASE_ORDER;
    } else if (newTab === 'purchase_requests') {
      serviceId = ServiceTypes.PURCHASE_REQUEST;
    } else {
      serviceId = 0;
    }

    setOrderTab(newTab);
    setFilterObj({ ...filterObj, serviceId: serviceId });

  };

  // load order data
  const loadOrderData = async () => {
    try {

      if (loading) {
        return;
      }

      // load data
      var fromTime = filterObj.status === 'latest' ? 0 : moment(filterObj.fromDate, 'YYYY-MM-DD').toDate().getTime();
      var toTime = filterObj.status === 'latest' ? 0 : moment(filterObj.toDate, 'YYYY-MM-DD').add(24, 'hours').toDate().getTime();

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_LIST,
        partnerId: userObj.partnerId,
        serviceId: filterObj.serviceId,
        index: filterObj.index,
        count: filterObj.count,
        dateStart: fromTime,
        dateEnd: toTime,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setOrderData(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order data, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get order data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Get bank account array from bank config
   */
  const getBankAccounts = (bankConfig) => {

    var bankAccounts = [];

    if (!(bankConfig.banks)) {
      return bankAccounts;
    }

    bankConfig.banks.map((accountName) => {
      bankAccounts.push(bankConfig[accountName]);
    })
    return bankAccounts;
  }


  // Use interval to refresh order data
  useInterval(() => {

    loadOrderData();

    setDelay(11000);
  }, delay);

  useEffect(() => {

    // reload on refresh
    loadOrderData();
  }, [userObj, filterObj.status, filterObj.fromDate, filterObj.toDate]);

  useEffect(() => {
    if (action === 'createSaleOrder') {

    } else if (action === 'paymentRequest') {

    } else if (action === 'createPurchaseOrder') {

    } else if (action === 'purchaseRequest') {
      handleChangeTab(null, 'purchase_requests');
      setShowPurchaseRequestDialog(true);
    }


  }, [action]);

  return (

    <Grid container spacing={1}>

      <Grid item xs={9.5} style={{ paddingTop: 8 }}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>DANH SÁCH ĐƠN HÀNG: </FormLabel>
          <RadioGroup size="small" row aria-label="ckStatus" name="ckStatus" defaultValue={'latest'} onChange={handleChangeStatus}>
            <FormControlLabel value="latest" control={<Radio size="small" />} label="Mới nhất" />
            <FormControlLabel value="all" control={<Radio size="small" />} label="Theo thời gian" />
          </RadioGroup>
        </FormControl>
        <TextField id="ck-time-range" variant="standard" margin="dense" size="small"
          label={"Chọn thời gian"}
          select
          value={filterObj.timeRange}
          style={{ marginLeft: 20, maxWidth: 138, minWidth: 111 }}
          InputProps={{ readOnly: false }}
          disabled={filterObj.status === 'latest'}
          onChange={(e) => handleChangeTimeRange(e)}>
          {TIME_RANGES.map((rangeItem, index) => (
            <MenuItem key={rangeItem.value} value={rangeItem.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{rangeItem.name}</Box>
            </MenuItem>
          ))}

        </TextField>
        <TextField
          label="Từ ngày: "
          type="date"
          size="small"
          variant="standard" margin="dense"
          style={{ marginLeft: 12, marginTop: 12, maxWidth: 138 }}
          value={filterObj.fromDate}
          onChange={handleChangeDate('fromDate')}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={filterObj.status === 'latest'}
        />
        <TextField
          label="Đến ngày: "
          type="date"
          size="small"
          variant="standard" margin="dense"
          style={{ marginLeft: 12, marginTop: 12, maxWidth: 138 }}
          value={filterObj.toDate}
          onChange={handleChangeDate('toDate')}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={filterObj.status === 'latest'}
        />
        <Tooltip title="Đưa danh sách đơn hàng lên GoogleSheets" aria-label="export-googlesheets">
          <IconButton aria-label="export" size="large"
            style={{ marginLeft: 12, marginTop: 12, color: Colors.DARK_BLUE }}
            onClick={() => {setShowSyncGoogleSheetsDialog(true)}}>
            <CloudUploadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={2.5} style={{ paddingTop: 20, textAlign: 'right' }}>
        <Paper component="form" sx={{ p: '1px 3px', display: 'flex', alignItems: 'right', width: '100%' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Lọc giao dịch"
            inputProps={{ 'aria-label': 'tim noi dung' }}
            onChange={handleChangeKeyword}
          />
          <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: 3 }}>

        <Tabs
          value={orderTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="Orders"
        >
          <Tab value="all" label="Tất cả" />
          <Tab value="sale_orders" label="Đơn hàng bán" />
          <Tab value="payment_requests" label="Yêu cầu thanh toán" />
          <Tab value="purchase_orders" label="Đơn mua hàng" />
          <Tab value="purchase_requests" label="Yêu cầu chi phí" />
        </Tabs>

        <Divider />

        <PaymentTxList
          bankAccounts={getBankAccounts(orderData.bankConfig)}
          txList={orderData.txList}
          filterObj={filterObj}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />

        {showPurchaseRequestDialog &&
          <PurchaseRequestDialog open={showPurchaseRequestDialog} closeCallback={closePurchaseRequestDialog}
            bankAccounts={getBankAccounts(orderData.bankConfig)}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
        {showSyncGoogleSheetsDialog &&
          <ExportGoogleSheets open={showSyncGoogleSheetsDialog} closeCallback={closeSyncGoogleSheetsDialog}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            txTypeId={TxTypes.PARTNER_ADMIN_ORDER_LIST_EXPORT}
            filterObj={filterObj} />
        }
      </Grid>

    </Grid>

  );
}
