
/**
 * Define payment statuses
 */
const PaymentStatus = {
    // PAYMENT STATUS from 10 to 99
    PAYMENT_WAITING                                 : 10,
    PAYMENT_SUCCESS                                 : 11,
    PAYMENT_DOUBLE_PAY                              : 12,
    PAYMENT_OVER_FUND                               : 13,
    PAYMENT_INSUFFICIENT_FUND                       : 14,
    PAYMENT_DEBIT                                   : 15,
    PAYMENT_ORDER_NOT_FOUND                         : 16,
    PAYMENT_BANK_ACCOUNT_NOT_FOUND                  : 17,
    PAYMENT_DUPLICATED                              : 18,
    PAYMENT_TIMEOUT                                 : 19,
    PAYMENT_CANCEL                                  : 20,
    PAYMENT_IGNORE                                  : 21,
    PAYMENT_MANUAL_REVIEW                           : 22,
    PAYMENT_INTERNAL                                : 23,
    
    PAYMENT_MANUAL_CONFIRM                          : 25,

    // SYSTEM ERROR STATUS
    PAYMENT_SIM_ERROR                               : 30,
    PAYMENT_PARTNER_ERROR                           : 31,
    PAYMENT_DASHBOARD_ERROR                         : 32,
    PAYMENT_UNKNOWN_ERROR                           : 33,
    PAYMENT_SERVER_MAINTENANCE                      : 34,
    
}
export default PaymentStatus;

export const isCreditSuccess = (paymentStatus) => {
    if(
        paymentStatus === PaymentStatus.PAYMENT_SUCCESS 
          || paymentStatus === PaymentStatus.PAYMENT_MANUAL_CONFIRM
          || paymentStatus === PaymentStatus.PAYMENT_OVER_FUND
    ) {
        return true;
    }
}

export const isCreditError = (paymentStatus) => {
    if(
        paymentStatus === PaymentStatus.PAYMENT_ORDER_NOT_FOUND 
        || paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND
        || paymentStatus === PaymentStatus.PAYMENT_DOUBLE_PAY
    ) {
        return true;
    }
}

export const isDebitSuccess = (paymentStatus) => {
    if(
        paymentStatus === PaymentStatus.PAYMENT_SUCCESS
    ) {
        return true;
    }
}

export const isDebitError = (paymentStatus) => {
    if(
        paymentStatus === PaymentStatus.PAYMENT_ORDER_NOT_FOUND 
        || paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND
    ) {
        return true;
    }
}

export const isInternalTx = (paymentStatus) => {
    if(
        paymentStatus === PaymentStatus.PAYMENT_INTERNAL
    ) {
        return true;
    }
}

export const isOtherTx = (paymentStatus) => {
    if(
        paymentStatus === PaymentStatus.PAYMENT_IGNORE 
        || paymentStatus === PaymentStatus.PAYMENT_DEBIT
        || paymentStatus === PaymentStatus.PAYMENT_MANUAL_REVIEW
    ) {
        return true;
    }
}

