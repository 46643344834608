import React from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';

import ConfirmApiConfig from './ConfirmApiConfig';
import ForwardConfig from './ForwardConfig';
import SmsHttpConfig from './SmsHttpConfig';
import SendSmsOtpConfig from './SendSmsOtpConfig';
import CkqrConfig from './CkqrConfig';
import NotificationConfig from './NotificationConfig';
import Config from '../../ckcore/core/Config';
import WebhookConfig from './WebhookConfig';

export default function CkApiTabs(props) {

  const [value, setValue] = React.useState((props.selectedIndex) ? props.selectedIndex : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSelectedIndex = () => {
    
    if(Config.isStandalone()) {
      return value + 1;
    }

    return value;
  }

  return (
    <Paper square>

      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="CK API"
      >
        <Tab label="Tích hợp hệ thống" />
        {
          !Config.isStandalone() &&
            <Tab label="Chuyển Khoản QR" />
        }
        <Tab label="Xác nhận thanh toán" />
        <Tab label="Chuyển tiếp BĐSD" />
        <Tab label="Cấu hình thông báo" />
        {/*
        <Tab label="Chuyển tiếp SMS" />
        <Tab label="Gửi SMS OTP" />
        */}
      </Tabs>

      <Divider />

      {
        (getSelectedIndex() === 0) &&
        <WebhookConfig appId="WEBHOOK"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }

      {
        (getSelectedIndex() === 1) &&
        <CkqrConfig appId="CKQR"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }

      {
        (getSelectedIndex() === 2) &&
        <ConfirmApiConfig appId="CONFIRM"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }

      {
        (getSelectedIndex() === 3) &&
        <ForwardConfig appId="FORWARD"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }
      {
        (getSelectedIndex() === 4) &&
        <NotificationConfig appId="NOTIFICATION"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }
      {/*
        (getSelectedIndex() === 4) &&
        <SmsHttpConfig appId="SMSHTTP"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>        
    */}
      {/*
        (getSelectedIndex() === 5) &&
        <SendSmsOtpConfig appId="SMSOTP"
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
    */}
    </Paper>
  );
}