import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Box from "@mui/material/Box";

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import PaymentTxList from './PaymentTxList';
import PaymentStatus from '../../payment/components/PaymentStatus';
import { parseTxBean } from '../../ckcore/core/TxUtils';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import OrderMatchingTable from './ui/OrderMatchingTable';
import { ckTheme } from '../../ckcore/ui/CkStyles';
import MultipleOrdersMatchingTable from './ui/MultipleOrdersMatchingTable';

export default function MatchingMultipleOrdersDialog(props) {

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const bankTxObj = props.bankTxObj;

  const [messages, setMessages] = useState({error: '', success: '', info: '', done: false});
  const [loading, setLoading] = useState(false);

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState({
    bankConfig: {},
    txList: [],
  });

  // load order data
  const loadOrderData = async () => {
    try {

      if (loading) {
        return;
      }

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_LIST,
        partnerId: userObj.partnerId,

        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setOrderData(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order data, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get order data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Get bank account array from bank config
   */
  const getBankAccounts = (bankConfig) => {

    var bankAccounts = [];

    if (!(bankConfig.banks)) {
      return bankAccounts;
    }

    bankConfig.banks.map((accountName) => {
      bankAccounts.push(bankConfig[accountName]);
    })
    return bankAccounts;
  }

  /**
   * Filter order to get error status only
   */
  const filterOrders = (orderTxList) => {
    const txList = [];
    var txObj;
    orderTxList.map((txLine) => {
      txObj = parseTxBean(txLine);
      if (txObj.paymentStatus === PaymentStatus.TIMEOUT
        || txObj.paymentStatus === PaymentStatus.PAYMENT_CANCEL
        || txObj.paymentStatus === PaymentStatus.PAYMENT_WAITING
        || txObj.paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
        txList.push(txLine);
      }
    });
    return txList;
  }

  /**
   * Order select multiple callback
   */
  const onSelectMultipleOrders = (txList) => {
    setOrderList(txList);
  }

  // process matching order
  const doMatchingOrder = async () => {

    try {

      if (loading) {
        return;
      }
      setLoading(true);
      setMessages({...messages, error: '', success: ''});

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_MATCHING,
        partnerId: userObj.partnerId,
        txObj: bankTxObj,
        orders: orderList,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };


      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        const partnerResponseStatusId = response.order.txStatusId;
        if (partnerResponseStatusId === TxStatus.HTTP_SUCCESS) {
          setMessages({...messages, error: '', success: 'Đơn hàng đã khớp và xác nhận thành công', done: true});
        } else {
          setMessages({...messages, error: 'Đơn hàng đã khớp nhưng xác nhận giao dịch lỗi: ' + partnerResponseStatusId, success: '', done: true});
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order data, msg: " + response.msg);
        setMessages({...messages, error: 'Lỗi khớp đơn hàng, vui lòng thử lại sau', success: ''});
      }
    } catch (error) {
      console.log("Error get order data.. " + error);
      setMessages({...messages, error: 'Lỗi khớp đơn hàng, vui lòng thử lại sau', success: ''});
    }

    setLoading(false);

  };


  useEffect(() => {
    // reload on refresh
    loadOrderData();
  }, []);


  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>
        <Dialog open={props.open} fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Khớp đơn hàng</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>
              {getMessageBox(messages.error, messages.success, "Mời bạn chọn đơn hàng cần khớp")}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MultipleOrdersMatchingTable orderList={orderList} bankTxObj={bankTxObj} />
              </Grid>

              <Grid item xs={12}>
                {
                  !messages.done &&
                  <PaymentTxList
                    bankAccounts={getBankAccounts(orderData.bankConfig)}
                    txList={filterOrders(orderData.txList)}
                    rowsPerPage={5}
                    userObj={props.userObj}
                    partnerObj={props.partnerObj}
                    selectMultipleCallback={onSelectMultipleOrders}
                    logoutCallback={props.logoutCallback}
                    refreshCallback={props.refreshCallback} />
                }
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button variant="outlined" color="warning" onClick={() => doMatchingOrder()}
              disabled={loading || orderList.length < 1 || messages.done} >
              Khớp đơn hàng
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
