import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import { useInterval } from '../../ckcore/core/CkUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';

import BankAccountList from '../bank/BankAccountList';
import PartnerSimList from '../sim/PartnerSimList';
import PaymentTxList from '../order/PaymentTxList';
import { Typography } from '@mui/material';
import BankTxList from '../bank/BankTxList';

/**
 * Partner main page
 * @param {*} props
 */
export default function PartnerHome(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const refreshTime = props.refreshTime;

  // console.log("PartnerId in HOME refreshTime: " + refreshTime);

  const [delay, setDelay] = useState(100);
  const [loading, setLoading] = useState(false);

  const [partnerData, setPartnerData] = useState({
    sims: [],
    bankConfig: {},
    txList: [],
    bankTx: [],
    loaded: false,
  });
  const [guideStep, setGuideStep] = useState(-1);

  // load statuses response: sim status, bank status, and trans status
  const loadPartnerData = async () => {
    try {

      if (loading) {
        return;
      }

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_DASHBOARD_STATUS,
        partnerId: userObj.partnerId,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "home=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // setPartnerData(response);
        const newData = response;
        newData.loaded = true;
        setPartnerData(newData);

        if ((response.bankConfig.banks) && response.bankConfig.banks.length < 1) {
          // check data to set guide step
          if ((response.sims) && response.sims.length < 1) {
            setGuideStep(1);
          } else {
            setGuideStep(2);
          }
        } else if (response.txList.length < 1) {
          setGuideStep(3);
        } else {
          // check more txt to set step = 4
          setGuideStep(-1);
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load ckbox home status, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get ckbox home data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Get bank account array from bank config
   */
  const getBankAccounts = (bankConfig) => {

    var bankAccounts = [];

    if (!(bankConfig.banks)) {
      return bankAccounts;
    }

    bankConfig.banks.map((accountName) => {
      bankAccounts.push(bankConfig[accountName]);
    })
    return bankAccounts;
  }


  // Use interval to refresh data
  useInterval(() => {

    loadPartnerData();

    setDelay(5000);
  }, delay);

  useEffect(() => {
    // reload on refresh
    loadPartnerData();
  }, [userObj, refreshTime]);

  return (

    <Grid container spacing={1}>

      {
        !(partnerData.loaded) &&
        <Grid item xs={12}>
          <Typography variant="body1">Đang tải..</Typography>
        </Grid>
      }

      <Grid item xs={12} style={{ paddingTop: 8 }}>
        <BankAccountList
          loaded={partnerData.loaded}
          bankAccounts={getBankAccounts(partnerData.bankConfig)}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
      </Grid>

      {!(partnerObj.transparency) &&
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          <BankTxList
            showHeader={true}
            loaded={partnerData.loaded}
            bankAccounts={getBankAccounts(partnerData.bankConfig)}
            bankTx={partnerData.bankTx}
            rowsPerPage={5}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            refreshTime={props.refreshTime}
            logoutCallback={props.logoutCallback}
            refreshCallback={props.refreshCallback} />
        </Grid>
      }

      <Grid item xs={12} style={{ paddingTop: 20 }}>
        {!(partnerObj.transparency) ? (
          <PaymentTxList
            loaded={partnerData.loaded}
            bankAccounts={getBankAccounts(partnerData.bankConfig)}
            txList={partnerData.txList}
            rowsPerPage={5}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            refreshTime={props.refreshTime}
            logoutCallback={props.logoutCallback}
            refreshCallback={props.refreshCallback} />
        ) : (
          <BankTxList
            showHeader={true}
            loaded={partnerData.loaded}
            bankAccounts={getBankAccounts(partnerData.bankConfig)}
            bankTx={partnerData.bankTx}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            refreshTime={props.refreshTime} ß
            logoutCallback={props.logoutCallback}
            refreshCallback={props.refreshCallback} />
        )
        }

      </Grid>

    </Grid>

  );
}
