import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { isValidUrl, isValidNameUTF8, isValidEmail, isValidDomain, isValidAddress, isValidTin, isValidPhone } from '../../ckcore/core//Validator';
import { getMessageBox } from '../../ckcore/core//TxUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core//Colors';
import Config from '../../ckcore/core//Config';
import TxStatus from '../../ckcore/core//TxStatuses';
import User from '../../ckcore/core//User';
import { MenuItem } from '@mui/material';
import { getProvinces } from '../../ckcore/core/LocationUtils';

/**
 * Setting partner information
 */
export default function Setting(props) {
  const classes = useStyle();

  const userObj = props.userObj;
  // const partnerObj = props.partnerObj;

  const appId = props.appId;

  const [settingConfig, setSettingConfig] = useState({ transparency: false });
  const [configChanged, setConfigChanged] = useState(false);
  const [changedValue, setChangedValue] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleChangeValue = (field) => event => {
    settingConfig[field]["value"] = event.target.value;
    setConfigChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  // Validate all key
  const validateKeys = () => {

    var allValid = true;

    settingConfig.fields.map((field) => {

      var fieldObj = settingConfig[field];

      // set to valid first
      settingConfig[field]["error"] = false;

      // Validate email, website, address
      if (field === 'email') {

        if (!isValidEmail(fieldObj["value"])) {
          settingConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid Email = " + fieldObj["value"]);
        }
      } else if (field === 'tin') {
        if ((fieldObj["value"]) && !isValidTin(fieldObj["value"])) {
          settingConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid TIN = " + fieldObj["value"]);
        }
      } else if (field === 'tel') {
        if ((fieldObj["value"]) && !isValidPhone(fieldObj["value"])) {
          settingConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid hotline = " + fieldObj["value"]);
        }
      } else if (field === 'website' || field === 'logo') {

        if ((fieldObj["value"]) && !isValidUrl(fieldObj["value"]) && !isValidDomain(fieldObj["value"])) {
          settingConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid Wesite = " + fieldObj["value"]);
        }
      } else if (field === 'address') {

        if (!isValidAddress(fieldObj["value"])) {
          settingConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid Address = " + fieldObj["value"]);
        }
      } else if (!Array.isArray(fieldObj["value"]) && !isValidNameUTF8(fieldObj["value"])) {

        settingConfig[field]["error"] = true;
        console.log("Invalid " + field + " = " + fieldObj["value"]);
        allValid = false;

      } else {
        settingConfig[field]["error"] = false;
      }

    });

    setChangedValue(!changedValue);

    return allValid;
  }


  const submitForm = (event) => {

    event.preventDefault();
    event.stopPropagation();

    setSuccessMsg("");
    setErrorMsg("");

    if (validateKeys()) {
      // do update forward config
      processUpateSettingConfig();
    } else {
      setErrorMsg("Giá trị không hợp lệ, vui lòng kiểm tra lại");
    }

  }


  // load forward config
  const loadAppConfig = async () => {

    setSuccessMsg("");
    setErrorMsg("");

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setSettingConfig(response.appConfig);
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // Update Forward API config
  const processUpateSettingConfig = async () => {

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      appId: appId,
      partnerId: userObj.partnerId,
      appConfig: settingConfig,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {

        setConfigChanged(false);
        setSuccessMsg("Cập nhật thông tin thành công");

        // request to refresh after update profile
        // props.refreshCallback();

      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request update error with statuses: " + response.statusId);
        setErrorMsg("Cập nhật thông tin bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMsg("Cập nhật thông tin bị lỗi, vui lòng thử lại sau.")
      console.log("Error update Profile config " + error);
    }
  }

  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);

  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = settingConfig[field];
    const isError = (settingConfig[field]["error"]);

    if (field === 'province') {
      return (
        <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
          <Box className={classes.fieldNameStyle}>
            {fieldObj["name"]}:
          </Box>
          <Box className={classes.fieldValueStyle}>
            <TextField id="ck-province-list" variant="outlined" size="small" margin="dense" fullWidth
              label={"Tỉnh / thành phố"}
              select
              value={fieldObj["value"]}
              onChange={handleChangeValue(field)}>
              {getProvinces().map((provinceObj, index) => (
                <MenuItem key={provinceObj.pn} value={provinceObj.pi}>
                  <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{provinceObj.pn}</Box>
                </MenuItem>
              ))}
            </TextField>
          </Box>

        </Box>
      );
    }


    return (
      <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
        <Box className={classes.fieldNameStyle}>
          {fieldObj["name"]}:
        </Box>
        <Box className={classes.fieldValueStyle}>
          <TextField
            error={isError}
            type="text"
            className={classes.textFieldValue}
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            fullWidth
            size="small"
            variant="outlined"
            value={fieldObj["value"]}
            margin="dense"
            label={fieldObj["label"]}
            disabled={(fieldObj["disabled"]) ? fieldObj["disabled"] : false}
            onChange={handleChangeValue(field)}
          />
        </Box>

      </Box>
    );

  }

  return (
    <Paper className={classes.paper}>
      <h3 className={classes.title}>CẬP NHẬT THÔNG TIN TỔ CHỨC</h3>

      <Box p={1} m={1}>
        {
          getMessageBox(errorMsg, successMsg, "")
        }
        <Box className={classes.guide}>
        </Box>
      </Box>

      <form className={classes.box_form} onSubmit={submitForm}>

        {
          (settingConfig.fields) &&
          settingConfig.fields.map((field, index) => (
            getFieldConfig(field)
          ))
        }

        <div className={classes.grow}></div>

        <Divider />

        <Box >

          <Button className={classes.ckButton} variant="contained" color="primary" onClick={() => props.refreshCallback("MEMBERS")}>
            Trở lại
          </Button>

          <Button className={classes.ckButton} variant="contained" color="primary" type="submit" disabled={!configChanged}>
            Cập nhật
          </Button>
        </Box>
      </form>
    </Paper>
  );
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: Colors.DARK_BLUE,
    marginTop: 20,
  },

  fieldNameStyle: {
    width: 111,
    textAlign: "right",
  },

  fieldValueStyle: {
    width: 333,
    marginLeft: 8,
    marginRight: 8,
    textAlign: "left",
  },

  textFieldKey: {
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldOption: {
  },

  grow: {
    minHeight: "15px",
  },

  ckButton: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));
