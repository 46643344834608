import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import { MenuItem, TextField } from '@mui/material';

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import { getBankSvgIcon } from '../../../ckcore/core/CkBank'
import ValUtils from '../../../ckcore/core/ValUtils';
import Colors from '../../../ckcore/core/Colors';
import TxStatus from '../../../ckcore/core/TxStatuses';
import ServiceTypes from '../../../ckcore/core/ServiceTypes';

export default function OrderPaymentInfo(props) {
  const classes = useStyles();
  const orderObj = props.orderObj;
  const bankAccounts = props.bankAccounts;

  const canChangeBankAccount = () => {

    let statusId = orderObj.statusId;
    if (statusId === TxStatus.WAITING || statusId === TxStatus.PROCESSING || statusId === TxStatus.CANCEL) {
      return true;
    } else {
      return false;
    }

  }
  // console.log("Bank accounts: " + bankAccounts.length);
  const handleChange = (event) => {

    const accountNumber = event.target.value;
    const bankAccount = bankAccounts.find((account) => account.accountNumber === accountNumber);

    props.changeBankAccount(bankAccount);
  };


  const isPurchase = () => {
    return (orderObj.serviceId === ServiceTypes.PURCHASE_ORDER || orderObj.serviceId === ServiceTypes.PURCHASE_REQUEST);
  }

  return (
    <Box>

      <Box style={{ textAlign: 'center', fontSize: 14 }}>
        {
          isPurchase() ? "Thông tin chuyển khoản đi" : "Thông tin thanh toán"
        }        
      </Box>
      <Box style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: isPurchase() ? Colors.ORANGE : Colors.DARK_BLUE }}>
        {orderObj.order.account_name}
      </Box>

      <Box style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20, marginTop: 8 }}>

        {
          (orderObj.serviceId === ServiceTypes.SALE_ORDER || orderObj.serviceId === ServiceTypes.PAYMENT_REQUEST) ? (
            <TextField id="ck-bank-account" variant="outlined" margin="normal" size="small" fullWidth
              label={"Số tài khoản " + orderObj.order.bank_name}
              select
              value={orderObj.order.account_number}
              InputLabelProps={{ style: { fontSize: 16 } }}
              InputProps={{
                readOnly: !canChangeBankAccount(),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small" >
                      <SvgIcon style={{ fontSize: 24 }} component={getBankSvgIcon(orderObj.order.bank_name)} viewBox="0 0 48 48" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={handleChange}>
              {bankAccounts.map((bankAccount, index) => (
                <MenuItem key={bankAccount.accountNumber} value={bankAccount.accountNumber}>
                  <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3, fontSize: 16, fontWeight: 'bold' }}>{ValUtils.formatBankAccountNumber(bankAccount.accountNumber)}</Box>
                </MenuItem>
              ))}

            </TextField>
          ) : (
            <TextField id="ck-bank-account" variant="outlined" margin="normal" size="small" fullWidth
              label={"Số tài khoản " + orderObj.order.bank_name}
              value={orderObj.order.account_number}
              InputLabelProps={{ style: { fontSize: 16 } }}
              InputProps={{
                readOnly: true,
                inputProps: {
                  style: { textAlign: "center", fontWeight: '600', marginRight: 24 },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small" >
                      <SvgIcon style={{ fontSize: 24 }} component={getBankSvgIcon(orderObj.order.bank_name)} viewBox="0 0 48 48" />
                    </IconButton>
                  </InputAdornment>
                )
              }}/>
          )
        }

      </Box>

      <Box style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>
        <TextField id="ck-order-info" variant="outlined" margin="normal" size="small" fullWidth
          label="Nội dung chuyển khoản"
          value={orderObj.order.ckqr_content}
          inputProps={{
            min: 0, style: {
              textAlign: 'center', fontSize: 16, fontWeight: 'bold', color: Colors.RED, height: 25,
            }
          }}
          InputLabelProps={{ style: { fontSize: 16 } }}
          InputProps={{
            readOnly: true,
            className: classes.inputTxContent
          }}
        />
      </Box>
    </Box>

  );
}


const useStyles = makeStyles(theme => ({
  root: {

  },

  actionButton: {
    marginLeft: 8,
  },
}));
