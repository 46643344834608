import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, useTheme, adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Hidden from "@mui/material/Hidden";
import Colors from '../core/Colors';

const useStylesPaging = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(1),
    },
  }));


const theme = createTheme(adaptV4Theme({
    // spacing: 3,
    overrides: {
      MuiTableCell: {
        root: {  //This can be referred from Material UI API documentation.
          padding: '2px 6px',
        },
        sizeSmall: {
          padding: '2px 3px 2px 3px',
        },
      },
      MuiSvgIcon: {
        root: {
          width: 18,
          height: 18,
        }
      },
    },
  }));


export default function TablePaginationActions(props) {

  const classes = useStylesPaging();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>

      <Hidden smDown>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="Trang đầu"
          style={{backgroundColor: '#FFFFFF', border: 0, color: Colors.GRAY}}
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </Hidden>

      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Trang trước"
        style={{backgroundColor: '#FFFFFF', border: 0, color: Colors.GRAY}}
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Trang tiếp theo"
        style={{backgroundColor: '#FFFFFF', border: 0, color: Colors.GRAY}}
        size="large">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>

      <Hidden smDown>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Trang cuối"
          style={{backgroundColor: '#FFFFFF', border: 0, color: Colors.GRAY}}
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Hidden>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


