import { Box } from "@mui/material";
import Colors from "../../../ckcore/core/Colors";
import { getDisplayPhone } from "../../../ckcore/core/Telcos";
import { getTxTimeVN } from "../../../ckcore/core/TxUtils";

export default function OrderChangeHistory(props) {
  const orderObj = props.orderObj;

  return (

    <Box sx={{padding: '12px', paddingTop: '4px', marginRight: '20px', borderRadius: 3, backgroundColor: Colors.BG_WHITE}}>
      {
        orderObj.changeList.slice(0).reverse().map((changeObj, index) => (
          <Box key={index} sx={{ marginTop: '8px', padding: '6px', paddingLeft: '12px', border: 1, borderRadius: 3, borderColor: 'grey.300', backgroundColor: 'white'}}>
            <Box sx={{ display: 'flex', fontSize: 14 }}>
              <strong>{changeObj.name} ({getDisplayPhone(changeObj.staffPhone)})</strong>
              <Box sx={{display: 'inline', marginLeft: '16px', color: 'grey.500'}}>{getTxTimeVN(changeObj.created)}</Box> 
            </Box>
            <Box sx={{ display: 'flex' }}><i>{changeObj.desc}</i></Box>
          </Box>
        ))
      }
    </Box>

  );
}