
import { ckTableStyles } from '../../ckcore/ui/CkStyles';
import { Box, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { getSubscriptionPackageName, pricingData } from './PricingTable';
import Colors from '../../ckcore/core/Colors';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { formatAmount, formatAmountVND, formatCount } from '../../ckcore/core/TxUtils';

export default function CKCoinTable(props) {
  const tableStyles = ckTableStyles();
  const partnerObj = props.partnerObj;
  const requestCKCoins = props.requestCKCoins;
  
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  const handleSelectCKCoins = (value) => {
    let selectedCKCoins = pricingData.ckcoins.find((ckcoins) => ckcoins.value === value);
    props.onSelectCKCoins(selectedCKCoins);
  }

  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>

          <TableRow className={tableStyles.row} style={{ height: 40 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>
              Số CKCoin hiện tại:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <strong style={{ color: Colors.DARK_BLUE }}>{formatCount(partnerObj.ckCoin)}</strong>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
             Số CKCoin mua thêm:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <TextField id="ck-subscription" variant="standard" margin="dense" size="small"
                select
                value={requestCKCoins.value}
                style={{ marginLeft: 20, maxWidth: 200 }}
                InputProps={{ readOnly: false }}
                onChange={(e) => handleSelectCKCoins(parseInt(e.target.value))}>
                {pricingData.ckcoins.map((ckcoinObj, index) => (
                  <MenuItem key={ckcoinObj.value} value={ckcoinObj.value}>
                    <Box style={{ color: Colors.DARK_BLUE}}>{ckcoinObj.name}</Box>
                  </MenuItem>
                ))}

              </TextField>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              Giá {formatAmount(requestCKCoins.price)}/CKCoin cho gói <strong>{getSubscriptionPackageName(partnerObj.subscription.package)}</strong>
            </TableCell>
          </TableRow>

          <TableRow className={tableStyles.row} style={{ height: 40 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>
              Ưu đãi:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <Box style={{ color: Colors.DARK_GREEN, fontWeight: '500' }}>Tặng {formatCount(requestCKCoins.bonus)} CKCoin</Box>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              Số tiền thanh toán:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <strong style={{ color: Colors.RED, marginLeft: 16 }}>{formatAmountVND(requestCKCoins.amount)}</strong>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              Tổng số CKCoin sau khi mua: <strong>{formatCount(partnerObj.ckCoin + requestCKCoins.value + requestCKCoins.bonus)}</strong>
            </TableCell>
          </TableRow>

          <TableRow className={tableStyles.row} style={{ height: 56 }}>
            <TableCell colSpan={5} align="left" className={tableStyles.colHeader} sx={cellBorder}>
              <Box><i>Mỗi thông báo biến động số dư của tài khoản ngân hàng được tính là một lượt chuyển khoản. CKApp sẽ dừng xử lý giao dịch nếu tổ chức của bạn hết CKCoin.</i></Box>
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>

  );
}