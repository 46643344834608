import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';


import Tooltip from '@mui/material/Tooltip';
import QRCode from 'qrcode.react';
import Colors from '../../ckcore/core/Colors';
import PaymentStatus from './PaymentStatus';
import { copyTextToClipboard } from '../../ckcore/core/CkUtils';

import ServiceTypes from '../../ckcore/core/ServiceTypes';
import { getCKBankLogo, getBankSvgIcon } from '../../ckcore/core/CkBank'
import { Button, Typography } from '@mui/material';
import ValUtils from '../../ckcore/core/ValUtils';
import styled from 'styled-components';
import './ckpayment.css';
import { withStyles } from '@mui/styles';

const StyledTextField = styled(TextField)`
  /* Add your custom styles here */
  width: 100%;
  height: 'auto';
  & .MuiInputBase-input {
    /* Override input field styles */
    height: '1.4375em';
    margin: 0;
    border: 0;
    padding: '8.5px 14px';
    padding-left: 0;
    padding-right: 0;
  }
  /*
  & .MuiOutlinedInput-root: {
    & fieldset: {
      border-color: 'yellow',
    },
    &:hover fieldset: {
      border-color: 'yellow',
    },
    &.Mui-focused fieldset: {
      border-color: 'yellow',
    },
  }, */
`;


export default function CheckoutPayment(props) {

  const classes = useStyles();

  const [supportBankStartIndex, setSupportBankStartIndex] = useState(0);

  const order = props.order;
  const bankInfo = props.bankInfo;
  const paymentStatus = props.paymentStatus;
  const countDown = props.countDown;
  const bankName = order.bank_name;

  // show full content (not just CK ORDERID_CHECKSUM)
  const showFullContent = (bankInfo.ckQRContent.length > 11);
  const notRequiredContent = parseInt(order.service_type) === ServiceTypes.CHECKOUT_DONATE; // Donate

  const getContentStyle = () => {
    if (notRequiredContent) {
      return classes.inputNormalContent;
    } else {
      return classes.inputTxContent;
    }
  }
  /**
   * Get ben bank icon and button
   */
  const getBenBankButton = (bankAccount, index) => {

    // Support only 5 ben banks
    if (index >= 5) {
      return <div key={index}></div>
    }

    return (
      <div key={index} style={{ marginTop: 3 }}>
        <Tooltip title={"Chuyển khoản tới " + bankAccount.bankName + ": " + bankAccount.accountNumber} aria-label="Bank"><span>
          <IconButton size="small" className={bankAccount.accountNumber === order.account_number ? classes.bankIconSelected : classes.bankIconButton}
            onClick={() => props.changeBenBankAccount(bankAccount.accountNumber)}>
            <SvgIcon component={getBankSvgIcon(bankAccount.bankName)} viewBox="0 0 48 48" />
          </IconButton></span>
        </Tooltip>
      </div>
    );
  }

  const showTransferWarning = () => {
    props.requestShowWarning();
  }

  const showMoreBanks = () => {
    const totalBank = props.supportBanks.length;
    if (supportBankStartIndex + 10 < totalBank) {
      setSupportBankStartIndex(supportBankStartIndex + 10);
    } else {
      setSupportBankStartIndex(0);
    }
  }

  const getSupportBankButton = (bank, index) => {

    if (index < supportBankStartIndex) {
      return <div key={index}></div>
    }

    return (
      <span key={index}>
        <Tooltip title={"Hướng dẫn chuyển khoản từ " + bank.name} aria-label="Bank"><span>
          <IconButton size="small" className={classes.bankIconButton} onClick={() => props.bankTransferGuide(bank)}>
            <SvgIcon component={getBankSvgIcon(bank.name)} viewBox="0 0 48 48" />
          </IconButton></span>
        </Tooltip>
      </span>
    );
  }

  // for copying
  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipboard = (text, message) => {

    if (!(text)) {
      // setShowError(true);
    } else {
      copyTextToClipboard(text);
      // show copy success message
      setCopySuccess(message);
    }


    // clear on timeout
    setTimeout(function () {
      setCopySuccess('');
    }, 5000);
  };


  // Get button text by status
  const getButtonTextByPaymentStatus = () => {

    if (paymentStatus === PaymentStatus.PAYMENT_SUCCESS || paymentStatus === PaymentStatus.PAYMENT_OVER_FUND) {
      return "GD thành công";
    } else if (paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
      return "Chuyển thiếu tiền";
    } else if (paymentStatus === PaymentStatus.PAYMENT_WAITING & countDown !== '00:00') {
      return "Xem hướng dẫn";
    } else if (countDown === '00:00') {
      return "Quá thời gian";
    } else {
      return "GD bị huỷ";
    }
  }

  const getButtonColorByPaymentStatus = () => {
    if (paymentStatus === PaymentStatus.PAYMENT_SUCCESS || paymentStatus === PaymentStatus.PAYMENT_OVER_FUND) {
      return "success";
    } else if (paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
      return "warning";
    } else if (paymentStatus === PaymentStatus.PAYMENT_WAITING & countDown !== '00:00') {
      return "warning";
    } else if (countDown === '00:00') {
      return "Quá thời gian";
    } else {
      return "GD bị huỷ";
    }
  }

  // Download QRCode PNG
  const downloadQRCodePng = () => {
    props.downloadCallback('png');
  }

  return (
    <div className="CheckoutPaymentForm">

      <div className="PaymentRequestOrHeader" style={{ height: '96px', minHeight: '44px' }}>
        <div className="ButtonAndDividerContainer" style={{ opacity: 1, display: 'block' }}>
          <div className="PaymentRequestButtonContainer">
            <div style={{
              margin: '0px !important', padding: '0px !important', border: 'none !important', display: 'block !important',
              background: 'transparent !important', position: 'relative !important', opacity: '1 !important'
            }}>
              <h1 style={{ textAlign: "center", color: Colors.DARK_BLUE, marginTop: 0, marginBottom: 8 }}>Chuyển Khoản QR</h1>
            </div>
          </div>
          <div className="Divider">
            <hr />
            <p className="Divider-Text Text Text-color--gray700 Text-fontSize--14 Text-fontWeight--400">
              Dùng app ngân hàng quét mã <span style={{ color: Colors.RED, fontWeight: 'bold' }}>VIET</span>
              <span style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>QR</span>: <span style={{display: "inline-block", width: 39}}>{countDown}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="App-Global-Fields flex-container spacing-12 direction-row wrap-wrap">
        <div className="flex-item width-12" style={{ margin: 'auto', textAlign: 'center' }}>
          <Grid container>
            <Grid item xs={2}>
              <div className={classes.benSelectText}><Typography variant="caption">TK NHẬN</Typography></div>
              {
                bankInfo.bankAccounts.map((bankAccount, index) => (
                  getBenBankButton(bankAccount, index)
                ))
              }

            </Grid>
            <Grid item xs={8}>
              <QRCode size={180} value={bankInfo.ckQR} />
            </Grid>
            <Grid item xs={2}>
              <div key="guide" className={classes.benSelectText}>
                <Typography variant="caption" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>HƯỚNG DẪN</Typography>
              </div>
              <div key="support-bank" style={{ maxHeight: 150, height: 150, overflow: 'hidden' }}>
                {
                  props.supportBanks.map((bank, index) => (
                    getSupportBankButton(bank, index)
                  ))
                }
              </div>
              <div key="show-more" style={{ marginTop: -8 }}>
                <Tooltip title={"Xem thêm"} aria-label="Bank"><span>
                  <IconButton size="small" className={classes.showmoreButton} onClick={() => showMoreBanks()}>
                    <ExpandMoreOutlinedIcon />
                  </IconButton></span>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="PaymentForm-paymentMethodFormContainer flex-container spacing-12 direction-row wrap-wrap">

        <div className="flex-item width-12" style={{ padding: 0 }}>

          <div className="FormFieldGroup">

            <div className="FormFieldGroup-container" id="cardNumber-fieldset">

              <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childLeft FormFieldGroup-childBottom">
                <img style={{ height: 64 }} src={getCKBankLogo('napas247')} alt="Napas 247" />
              </div>

              <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childRight FormFieldGroup-childBottom">
                <img style={{ height: 64 }} src={getCKBankLogo(bankName)} alt={bankName} />
              </div>

            </div>
          </div>

          <div className="Divider" style={{ marginTop: 6 }}>
            <hr />
            {
              (copySuccess) ? (
                <p className="Divider-Text Text Text-fontSize--14 Text-fontWeight--400" style={{ color: Colors.ORANGE }}>
                  {copySuccess}
                </p>

              ) : (
                <p className="Divider-Text Text Text-color--gray700 Text-fontSize--14 Text-fontWeight--500">
                  THÔNG TIN TÀI KHOẢN NHẬN {bankName.toUpperCase()}
                </p>
              )
            }
          </div>
        </div>

        <div className="flex-item width-12" style={{ color: Colors.DARK_BLUE, margin: 'auto', marginTop: 6, textAlign: 'center', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {
            (order.account_name.length <= 38) ? (
              <Typography variant="subtitle1"><strong>{order.account_name}</strong></Typography>
            ) : (
              <Typography variant="subtitle2"><strong>{order.account_name}</strong></Typography>
            )
          }
        </div>

        <div className="flex-item width-12" style={{ margin: 'auto', paddingBottom: 0, textAlign: 'center' }}>

          <StyledTextField id="ck-bank-account" variant="outlined" fullWidth margin="normal" size="small"
            label={"SỐ TÀI KHOẢN"}
            onClick={() => copyToClipboard(order.account_number, 'Đã copy số tài khoản')}
            value={ValUtils.formatBankAccountNumber(order.account_number)}
            style={{ color: bankInfo.verified ? Colors.DARK_GREEN : Colors.ORANGE }}
            className={classes.textField}
            inputProps={{ min: 0, style: { textAlign: 'center', color: bankInfo.verified ? Colors.DARK_GREEN : Colors.ORANGE } }}
            InputProps={{
              readOnly: true,
              className: classes.inputAccountNumber,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" className={classes.iconCheck} size="large">
                    <FileCopyOutlinedIcon style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.55)' }} />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton size="small" >
                    <SvgIcon component={getBankSvgIcon(order.bank_name)} viewBox="0 0 48 48" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

        </div>

        <div className="flex-item width-12" style={{ margin: 'auto', paddingBottom: 0, textAlign: 'center' }}>

          <div className="FormFieldGroup">

            <div className="FormFieldGroup-container" id="cardNumber-fieldset">

              <div style={{ marginTop: 3 }} className={showFullContent ? "FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childBottom" : "FormFieldGroup-child FormFieldGroup-child--width-4 FormFieldGroup-childLeft FormFieldGroup-childBottom"}>
                <StyledTextField id="ck-order-amount" variant="outlined" fullWidth margin="normal" size="small"
                  label="SỐ TIỀN"
                  onClick={() => copyToClipboard(order.order_total, 'Đã copy số tiền')}
                  defaultValue={ValUtils.formatMoney(order.order_total) + 'đ'}
                  className={classes.textField}
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  InputProps={{
                    readOnly: true,
                    className: classes.inputTxValue,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton color="primary" className={classes.iconCheck} size="large">
                          <FileCopyOutlinedIcon style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.55)' }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div style={{ marginTop: 3 }} className={showFullContent ? "FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childBottom" : "FormFieldGroup-child FormFieldGroup-child--width-8 FormFieldGroup-childLeft FormFieldGroup-childBottom"}>
                <StyledTextField id="ck-order-info" variant="outlined" fullWidth margin="normal" size="small"
                  label={notRequiredContent ? "NỘI DUNG CHUYỂN KHOẢN" : "NỘI DUNG XIN GHI LÀ"}
                  onClick={() => copyToClipboard(bankInfo.ckQRContent, 'Đã copy nội dung chuyển')}
                  value={bankInfo.ckQRContent}
                  className={classes.textField}
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  InputProps={{
                    readOnly: true,
                    className: getContentStyle(),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton color="primary" className={classes.iconCheck} size="large">
                          <FileCopyOutlinedIcon style={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.55)' }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div style={{ opacity: 0, height: 0 }}><span
                className="FieldError Text Text-color--red Text-fontSize--13"><span
                  aria-hidden="true"></span></span></div>
            </div>
          </div>
        </div>

        <div className="flex-item width-12" style={{ margin: 'auto', paddingBottom: 0, textAlign: 'center', marginTop: 8 }}>

          <div className="FormFieldGroup">

            <div className="FormFieldGroup-container" id="cardNumber-fieldset">

              <div style={{ marginTop: 3 }} className={"FormFieldGroup-child FormFieldGroup-child--width-4 FormFieldGroup-childLeft FormFieldGroup-childBottom"}>
                <Button variant='outlined' size='large' color='primary' fullWidth
                  disabled={props.loading === true}
                  onClick={() => downloadQRCodePng()}>Tải mã QR</Button>
              </div>

              <div style={{ marginTop: 3 }} className={"FormFieldGroup-child FormFieldGroup-child--width-8 FormFieldGroup-childLeft FormFieldGroup-childBottom"}>
                <Button variant='outlined' size='large' color='warning' fullWidth
                  onClick={() => showTransferWarning()}>
                  {
                    getButtonTextByPaymentStatus()
                  }
                </Button>
              </div>

              <div style={{ opacity: 0, height: 0 }}><span
                className="FieldError Text Text-color--red Text-fontSize--13"><span
                  aria-hidden="true"></span></span></div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );

}


const useStyles = makeStyles(theme => ({

  textField: {
    marginTop: 8,
    marginLeft: 0,
    marginRight: 0,
    background: 'white',
    color: '#36507D',
    width: '100%',
    padingRight: 3,
    cursor: 'pointer',
  },

  inputAccountNumber: {
    color: Colors.DARK_GREEN,
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    paddingTop: 3,
    cursor: 'pointer'
  },

  inputTxValue: {
    color: Colors.DARK_BLUE,
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    paddingTop: 3,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  inputTxContent: {
    color: Colors.RED,
    width: '100%',
    fontSize: 16,
    paddingTop: 3,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  inputNormalContent: {
    color: Colors.DARK_BLUE,
    width: '100%',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

  iconCheck: {
    margin: -10,
    width: 24,
    height: 24,
    padding: 0,
    marginRight: -12,
  },

  benSelectText: {
    margin: 0,
    padding: 0,
  },

  bankIconButton: {
  },

  bankIconSelected: {
    borderColor: Colors.DARK_GREEN,
    borderStyle: 'dashed',
    borderWidth: 1,
  },

}));
