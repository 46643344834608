import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Colors from '../../ckcore/core/Colors';
import { Paper } from '@mui/material';
import { Link } from '@mui/material';


export default function StepThree(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="subtitle1">
        <strong>Bước 3: </strong>
        <span>Đăng ký biến động số dư (BĐSD)</span>
      </Typography>
      <p>
        <span>Khi thêm tài khoản ngân hàng, Chuyenkhoan.com cấp cho bạn một số điện thoại để đăng ký BĐSD. </span>
        <span>Một số ngân hàng cho phép bạn đăng ký BĐSD ngay trên ứng dụng của ngân hàng (như MBBank), các ngân hàng khác yêu cầu bạn phải tới quầy giao dịch.</span>
      </p>
      <p style={{color: Colors.RED}}>
        <span>ĐẶC BIỆT LƯU Ý: Chuyenkhoan.com chỉ yêu cầu bạn đăng ký Biến động số dư tài khoản ngân hàng, chúng tôi KHÔNG YÊU CẦU bạn đăng ký SMS Banking hay mật khẩu OTP của tài khoản về SĐT ở trên.</span>
      </p>
    </div>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFBE6',
    marginBottom: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guide: {
    paddingLeft: 40,
    paddingBottom: 20,
  },
  link: {
    cursor: 'pointer',
    color: Colors.DARK_BLUE,
    fontWeight: 'bold'
  }
}));
