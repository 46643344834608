import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { getRoleName } from '../../ckcore/core/Roles';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';


export default function AcceptRoleDialog(props) {

  const userObj = props.userObj;
  const role = props.role;
  const partner = props.partner;
  let partnerName = (partner) ? partner.name : "";

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };


  /**
   * User accept role
   */
  const acceptRole = async (role) => {
    if (loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const ckc = {
      txTypeId: TxTypes.TX_USER_ACCEPT_ROLE,
      role: role,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        // request refresh all page
        // window.location.reload();
        setSuccess(true);

        // call active role
        props.activeRole(role);

        // props.refreshCallback("HOME");      
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Accept role error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error accept role: " + error);
    }

    setLoading(false);
  }

  // Add bank dialog
  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Mời tham gia tổ chức</DialogTitle>
      <DialogContent dividers>

        {
          !success ? (
            <Box p={1}>
              {partnerName} mời bạn tham gia tổ chức với vai trò: {getRoleName(role.roleId)}
            </Box>

          ) :
            (
              <Box p={1}>
                Bạn đã trở thành thành viên của {partnerName}. Vui lòng tải lại trang để bắt đầu sử dụng dịch vụ Chuyenkhoan.com.
              </Box>
            )
        }

      </DialogContent>
      <DialogActions>
        {!success &&
          <Button onClick={handleClose} color="primary">
            Trở lại
          </Button>
        }
        {!success &&
          <Button disabled={loading} onClick={() => acceptRole(role)} color="primary">
            Đồng ý
          </Button>
        }
        {success &&
          <Button onClick={() => window.location.reload()} color="primary">
            Tải lại trang
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}
