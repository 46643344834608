
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';


import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Authorization from './Authorization';
import ChangePassword from './ChangePassword';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Actions from './Actions'
import ck_bg_image from '../images/chuyenkhoan_bg.svg'
import ck_logo_white from '../images/chuyenkhoan_logo_white.png'
import Config from '../core/Config';

export default function UserAccount(props) {

  // set contact phone by hours
  const currentHour = (new Date()).getHours();
  const contactPhone = (currentHour >= 4 && currentHour <= 16) ? "0906246196" : "0969781318";
  const requestRegister = (window.location.href.indexOf("register") > -1) ? true : false;
  const [action, setAction] = useState(requestRegister ? Actions.SIGN_UP : Actions.SIGN_IN);
  const [data, setData] = useState({ isLogin: true, token: "" });

  // action callback
  const actionCallback = (action, data) => {

    // still in login page
    setData(data);
    setAction(action);
  }

  // Check state to return
  const getComponents = () => {

    switch (action) {
      case Actions.SIGN_IN:
        return <SignIn actionCallback={actionCallback} />
      case Actions.SIGN_UP:
        return <SignUp actionCallback={actionCallback} />
      case Actions.AUTHORIZE:
        return <Authorization actionCallback={actionCallback} isLogin={data.isLogin} token={data.token} password={data.password} smsc={data.smsc} />
      case Actions.CHANGE_PASSWORD:
        return <ChangePassword actionCallback={actionCallback} message={data.message} password={data.password} />
      case Actions.SIGN_OUT:
        return <SignUp actionCallback={actionCallback} />
      default:
        return <SignIn actionCallback={actionCallback} />
    }
  }

  useEffect(() => {

    // login success
    if (action === Actions.LOGGED_IN) {
      // do callback
      props.loginCallback(true, 'Signin success');
      return;
    }

  }, [data, action]);

  const classes = useStyles();
  return (
    <Box className={classes.root}>

      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ minHeight: 48 }}>
          <Container >
            {
              Config.isStandalone() &&
              <Typography variant='h5'>{((window.ckDashboardTitle) ? window.ckDashboardTitle : "CK Dashboard")}</Typography>
            }
            {
              !Config.isStandalone() &&
              <a href="https://chuyenkhoan.com">
                <img className={classes.logo} src={ck_logo_white} alt="Chuyển khoản" />
              </a>
            }
          </Container>
        </Toolbar>
      </AppBar>

      <Grid container className={classes.userAccount} direction="column" justifyContent="space-between" alignItems="center">

        <Grid item xs={12}>
          {
            getComponents()
          }
        </Grid>

        <Grid item xs={12}>
          {
            !Config.isStandalone() &&
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} style={{ paddingTop: 20, paddingBottom: 8 }}>

              <Grid item xs={12} sm={4} container justifyContent="center">
                <Button color="primary" className={classes.textButton} href='mailto:cskh@chuyenkhoan.com' title='Email' whitespace="nowrap" startIcon={<EmailIcon />}>
                  <Box whiteSpace="nowrap">cskh@chuyenkhoan.com</Box>

                </Button>
              </Grid>

              <Grid item xs={12} sm={4} container justifyContent="center">
                <Button color="primary" className={classes.textButton} href={'tel:' + contactPhone} title={contactPhone} startIcon={<PhoneIcon />}>
                  <Box whiteSpace="nowrap">Hotline: {contactPhone}</Box>
                </Button>
              </Grid>

              <Grid item xs={12} sm={4} container justifyContent="center">
                <Button color="primary" className={classes.textButton} href='https://www.facebook.com/chuyenkhoan.com' target="_blank" title='Chuyen Khoan on Facebook' startIcon={<FacebookIcon />}>
                  /chuyenkhoan.com</Button>
              </Grid>

            </Grid>
          }

          {
            Config.isStandalone() ? (
              <Typography variant="body2" color="textSecondary" align="center">
                {'Powered by Chuyenkhoan.com'}
              </Typography>

            ) :
              (
                <Typography variant="body2" color="textSecondary" align="center">
                  {'©CKPay 2009-'}{new Date().getFullYear()}
                </Typography>
              )
          }
        </Grid>

      </Grid>


    </Box>
  );


}

const useStyles = makeStyles((homeTheme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    margin: 'auto',
  },

  logo: {
    height: 40,
  },


  appBar: {
    zIndex: homeTheme.zIndex.drawer + 1,
    transition: homeTheme.transitions.create(["width", "margin"], {
      easing: homeTheme.transitions.easing.sharp,
      duration: homeTheme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#37517f",
    backgroundImage: `url(${ck_bg_image})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundOrigin: "center",
  },

  userAccount: {
    paddingTop: 112,
    margin: 'auto',
    minHeight: '100vh',
    paddingBottom: 8,
  },

  textButton: {
    textTransform: "none",
  },



}));