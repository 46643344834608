import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CustomerList from '../customer/CustomerList';
import moment from 'moment';
import { Divider, FormControl, FormControlLabel, FormLabel, IconButton, InputBase, Paper, Radio, RadioGroup, Snackbar, SnackbarContent, Tab, Tabs, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import AddIcon from '@mui/icons-material/Add';

import ServiceTypes from '../../ckcore/core/ServiceTypes';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import { parsePopaBean } from '../../ckcore/core/CustomerUtils';
import Config from '../../ckcore/core/Config';
import CustomerDetailsDialog from '../customer/CustomerDetailsDialog';

export default function Customer(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState({ error: '', success: '', info: '', showStatus: false });
  const [customers, setCustomers] = useState([]);

  const [filterObj, setFilterObj] = useState({
    typeId: 0,
    index: 0,
    count: 99,
    status: 'latest',
    timeRange: 'today',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    keyword: ''
  });

  /**
   * Change filter status
   */
  const handleChangeStatus = (event) => {
    var status = event.target.value;
    setFilterObj({ ...filterObj, 'status': status });
  }

  /**
     * Change search keyword
     */
  const handleChangeKeyword = (event) => {
    var keyword = event.target.value.toLowerCase();
    setFilterObj({ ...filterObj, 'keyword': keyword });
  }

  // change service ID by order tab
  const [popaTab, setPopaTab] = useState('all');
  const handleChangeTab = (event, newTab) => {

    var typeId = 0; // all
    if (newTab === 'sale_orders') {
      typeId = ServiceTypes.SALE_ORDER;
    } else if (newTab === 'payment_requests') {
      typeId = ServiceTypes.PAYMENT_REQUEST;
    } else if (newTab === 'purchase_orders') {
      typeId = ServiceTypes.PURCHASE_ORDER;
    } else if (newTab === 'purchase_requests') {
      typeId = ServiceTypes.PURCHASE_REQUEST;
    } else {
      typeId = 0;
    }

    setPopaTab(newTab);
    setFilterObj({ ...filterObj, typeId: typeId });

  };

  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);
  const addCustomer = () => {
    setShowAddCustomerDialog(true);
  }
  const closeAddCustomerDialog = () => {
    setShowAddCustomerDialog(false);
    loadCustomers();
  }


  /**
   * Load all customers of partner
   */
  const loadCustomers = async () => {
    try {

      if (loading) { return; }

      // build request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_LOAD_CUSTOMERS,
        partnerId: userObj.partnerId,
        index: 0,
        count: 99,
        dateStart: 0,
        dateEnd: 0,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "customer=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        const customerList = [];
        var popaObj;
        response.customers.map((popaLine) => {
          popaObj = parsePopaBean(popaLine);
          customerList.push(popaObj);
        });

        setCustomers(customerList);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load customers, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get customers.. " + error);
    }

    setLoading(false);
  };


  /**
   * Sync customers from orders
   */
  const syncCustomers = async () => {
    try {

      if (loading) { return; }

      // build request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_SYNC_CUSTOMERS,
        partnerId: userObj.partnerId,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "customer=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // setCustomers(response.customers);
        setMessages({ ...messages, success: 'Đồng bộ danh bạ khách hàng thành công', showStatus: true });

        setTimeout(() => {
          loadCustomers();
        }, 1000);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load customers, msg: " + response.msg);
        setMessages({ ...messages, error: 'Đồng bộ danh bạ khách bị lỗi', showStatus: true });
      }
    } catch (error) {
      console.log("Error get customers.. " + error);
    }

    setLoading(false);

  };

  useEffect(() => {
    // reload on refresh
    loadCustomers();
  }, [userObj]);


  return (
    <Grid container spacing={1}>

      <Grid item xs={9.5} style={{ paddindTop: 8 }} >
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>DANH BẠ TÀI KHOẢN NGÂN HÀNG</FormLabel>
          <RadioGroup size="small" row aria-label="ckStatus" name="ckStatus" defaultValue={'latest'} onChange={handleChangeStatus}>
            <FormControlLabel value="latest" control={<Radio size="small" />} label="Mới nhất" />
            <FormControlLabel value="all" control={<Radio size="small" />} label="Theo doanh thu" />
          </RadioGroup>
        </FormControl>
        <Tooltip title="Thêm khách hàng" aria-label="add contact">
          <IconButton aria-label="add-contact" size="large"
            style={{ marginLeft: 12, marginTop: 12, color: Colors.DARK_BLUE }}
            onClick={() => { addCustomer() }}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Đồng bộ khách hàng từ đơn hàng" aria-label="sync-contact-from-orders">
          <IconButton aria-label="sync-contact" size="large"
            style={{ marginLeft: 12, marginTop: 12, color: Colors.DARK_BLUE }}
            onClick={() => { syncCustomers() }}>
            <SyncOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Đưa danh sách khách hàng lên GoogleSheets" aria-label="export-googlesheets">
          <IconButton aria-label="export" size="large"
            style={{ marginLeft: 12, marginTop: 12, color: Colors.DARK_BLUE }}
            onClick={() => { }}>
            <CloudUploadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item xs={2.5} style={{ paddingTop: 20, textAlign: 'right' }}>
        <Paper component="form" sx={{ p: '1px 3px', display: 'flex', alignItems: 'right', width: '100%' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Tìm khách hàng"
            inputProps={{ 'aria-label': 'tim khach hang' }}
            onChange={handleChangeKeyword}
          />
          <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: 3 }}>

        <Tabs
          value={popaTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChangeTab}
          aria-label="Orders"
        >
          <Tab value="all" label="Tất cả" />
          <Tab value="purchase_requests" label="Khách lẻ" />
          <Tab value="purchase_orders" label="Đại lý / NPP" />
          <Tab value="payment_requests" label="Nhà cung cấp" />
          <Tab value="sale_orders" label="Nhân viên" />
        </Tabs>

        <Divider />

        <CustomerList
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          customers={customers}
          reloadCallback={loadCustomers}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />

        <Snackbar key="customer-status" open={messages.showStatus} autoHideDuration={2200}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setMessages({ ...messages, showStatus: false })}>
          <SnackbarContent style={{ backgroundColor: Colors.CK_BG_WARNING, color: (messages.error) ? Colors.RED : Colors.DARK_GREEN }}
            message={(messages.error) ? messages.error : messages.success} />
        </Snackbar>
      </Grid>

      {
          showAddCustomerDialog &&
          <CustomerDetailsDialog open={showAddCustomerDialog} closeCallback={closeAddCustomerDialog}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

    </Grid>
  );
} 
