// import { makeStyles } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';


export default function PartnerSendSms(props) {

  const userObj = props.userObj;

  const sim = props.sim;
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [done, setDone] = useState(false);

  const [values, setValues] = useState({
      ussd: '*101#',
      destPhone: '',
      message: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    setDone(false);
  };


  // Call send message
  const sendMessage = (msgValues) => {

    setDone(true);
    console.log("SendSmsDialog start send sms..")
    var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_SEND_SMS_MT,
        partnerId: userObj.partnerId,
        port: sim.port,
        imsi: sim.imsi,
        src: sim.phone,
        dest: msgValues.destPhone,
        msg: encodeURIComponent(msgValues.message),
        time: (new Date()).getTime(),
        identity: User.getIdentity()
    };

    (async () => {

      // request data
      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();
      if(response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        console.log("Send message done.");

        setSuccessMessage('Đã yêu cầu gửi SMS');

        // handle close
         setTimeout(() => {
            handleClose();
        }, 1800);

      } else {

        console.log("Send message error: " + JSON.stringify(response));

        // show error message
        setErrorMessage("Gửi SMS bị lỗi, vui lòng kiểm tra SIM");
      }

    })();

  }


  // User click agree
  const handleSendSms = () => {
    sendMessage(values);
  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Send SMS dialog
  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Gửi tin tin nhắn</DialogTitle>
      <DialogContent>

        <DialogContentText component="div" style={{color: Colors.DARK_BLUE, paddingBottom: 16}}>
          {getMessageBox(errorMessage, successMessage, "SMS từ SĐT " + getDisplayPhone(sim.phone))}
        </DialogContentText>

        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <TextField autoFocus size="small" margin="dense" id="destPhone" label="SĐT nhận"
              style = {{width: 121}}
              onChange={handleChange('destPhone')}
            />
          </Grid>
          <Grid item>
            <TextField size="small" margin="dense" id="message" label="Tin nhắn"
              style = {{width: 333}}
              onChange={handleChange('message')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Trở lại
        </Button>
        <Button disabled={done} onClick={() => handleSendSms()} color="primary">
          Gửi tin
        </Button>
      </DialogActions>
    </Dialog>
  );

}
