import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//  change local color
import TextField from '@mui/material/TextField';
import { isValidEmail, isValidName, isValidPhone } from '../../ckcore/core/Validator';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import User from '../../ckcore/core/User';

import { green } from '@mui/material/colors';
import Colors from '../../ckcore/core/Colors';
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

/**
 * SMS for automatic confirm order
 */
export default function NotificationConfig(props) {
  const classes = useStyle();

  const userObj = props.userObj;
  // const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const appId = props.appId;

  const [forwardConfig, setForwardConfig] = useState({});
  const [configChanged, setConfigChanged] = useState(false);
  const [changedValue, setChangedValue] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleChangeValue = (field) => event =>  {
    forwardConfig[field]["value"] = event.target.value;
    setConfigChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }


  const handleCheck = (field, itemName, checked) =>  {

    const valueArray = forwardConfig[field]["value"];
    // do update
    valueArray.map((item, index) => {
      if(item.name === itemName) {
        valueArray[index].active = checked;
        console.log("Change checked item: " + itemName);
      }
    });

    // set config changed
    setConfigChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  // Validate all key
  const validateKeys = () => {

    var allValid = true;

    forwardConfig.fields.map((field) => {
      var fieldObj = forwardConfig[field];

      // set to valid first
      forwardConfig[field]["error"] = false;

      // Validate email
      if(field === 'smsRecipients') {
        var phones = fieldObj["value"].split(",");
        phones.map((phone) => {
          if((phone.trim()) && !isValidPhone(phone.trim())) {
            forwardConfig[field]["error"] = true;
            allValid = false;
            console.log("Invalid phone recepient: " + fieldObj["value"]);
          }
        });
      } else if(field === 'emailOrders' || field === 'emailRecipients' || field === 'emailAdmins') {
        var emails = fieldObj["value"].split(",");
        emails.map((email) => {
          if((email.trim()) && !isValidEmail(email.trim())) {
            forwardConfig[field]["error"] = true;
            allValid = false;
            console.log("Invalid email recepient: " + fieldObj["value"]);
          }
        });      
      } else if(field === 'prefix') {
        const prefix = fieldObj["value"];
        if(prefix.length !== 0 && (prefix.length < 3 || prefix.length > 11)) {
          forwardConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid prefix: " + prefix);
        }
      } else if(!Array.isArray(fieldObj["value"]) && !isValidName(fieldObj["value"])) {
        forwardConfig[field]["error"] = true;
        console.log("Invalid " + fieldObj["name"] + " = " + fieldObj["value"]);
        allValid = false;
      } else {
        forwardConfig[field]["error"] = false;
      }

    });

    setChangedValue(!changedValue);

    return allValid;
  }


  const submitForm = (event) => {

    event.preventDefault();
    event.stopPropagation();
    
    setSuccessMsg("");
    setErrorMsg("");

    if(validateKeys()) {
      // do update forward config
      processUpateForwardConfig();
    } else {
      setErrorMsg("Giá trị không hợp lệ, vui lòng kiểm tra lại");
    }

  }


  // load forward config
  const loadAppConfig = async () => {

    setSuccessMsg("");
    setErrorMsg("");

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setForwardConfig(response.appConfig);
        // console.log("Loaded API config: " + JSON.stringify(response.appConfig));
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }


  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);


  // Update Forward API config
  const processUpateForwardConfig = async () => {

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      timestamp: new Date().getTime(),
      appId: appId,
      appConfig: forwardConfig,      
      partnerId: activePartnerId,
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        console.log("update done");
        
        setConfigChanged(false);
        setSuccessMsg("Cập nhật cấu hình thông báo thành công");
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMsg("Cập nhật cấu hình thông báo bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMsg("Cập nhật cấu hình thông báo bị lỗi, vui lòng thử lại sau.")
      console.log("Error update API config " + error);
    }
  }

  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = forwardConfig[field];

    const isValueArray = Array.isArray(fieldObj.value); //(fieldObj.value) && (fieldObj.value.length > 1);

    if(isValueArray) {
      return (
        <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="start" alignContent="stretch"  style={{paddingTop: 20}}>
          <Box className={classes.fieldNameArrayStyle}>
            {fieldObj["name"]}:
          </Box>
          <Box className={classes.fieldValueStyle}>
            {
              getFieldConfigValueArray(field)
            }
          </Box>
        </Box>
      );
    } else {
      return (
        <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
          <Box className={classes.fieldNameStyle}>
            {fieldObj["name"]}:
          </Box>
          <Box className={classes.fieldValueStyle}>
            {
              getFieldConfigValue(field)
            }
          </Box>
              
        </Box>
      );
    }

    
  }

  /**
   * Get field config value
   */
  const getFieldConfigValue = (field) => {

    const fieldObj = forwardConfig[field];
    const isError = (forwardConfig[field]["error"]);
    
    // Receipients multiple value
    if(field === "emailOrders" || field === "emailRecipients" || field === "emailAdmins") {
      return <TextField
              error={isError}
              type="text"
              className={classes.textFieldValue}
              inputProps={{min: 0, style: { textAlign: 'left' }}}
              fullWidth
              multiline
              rows={2}
              size="small"
              variant="outlined"
              value={fieldObj["value"]}
              margin="dense"
              label={fieldObj["label"]}
              onChange={handleChangeValue(field)}
            />
    } else {

      return <TextField
              error={isError}
              type="text"
              className={classes.textFieldValue}
              inputProps={{min: 0, style: { textAlign: 'left' }}}
              fullWidth
              size="small"
              variant="outlined"
              value={fieldObj["value"]}
              margin="dense"
              label={fieldObj["label"]}
              onChange={handleChangeValue(field)}
            />
    }    
  }

  /**
   * Get field config value array
   */
  const getFieldConfigValueArray = (field) => {
    const fieldObj = forwardConfig[field];
    const isRegexError = (forwardConfig[field]["regexError"]);
    const valueArray = fieldObj.value;

    return <div>
      {
        valueArray.map((item, index) => (
          <FormControlLabel key={item.name} label={(item.label) ? item.label : item.name}
            control={<GreenCheckbox name={item.name} checked={item.active} onChange={() => handleCheck(field, item.name, !item.active)} />}
            disabled={true}
          />            
        ))
      }
          
    </div>

  }

  return (
    <Container className={classes.paper}>

          <h3 className={classes.title}>CÀI ĐẶT THÔNG BÁO HỆ THỐNG</h3>

          <Box p={1} m={1}>
            {
              getMessageBox(errorMsg, successMsg, "Mời bạn nhập email nhận thông báo")
            }
          </Box>

          <form className={classes.box_form} onSubmit={submitForm}>
            {
              (forwardConfig.fields) && 
              forwardConfig.fields.map((field, index) => (
                getFieldConfig(field)
              ))
            }
            <div className={classes.grow}></div>

            <Divider />

            <Button className={classes.btnAdd} variant="contained" color="primary" type="submit" disabled={!configChanged}>
              Cập nhật
            </Button>

          </form>

    </Container>
  );
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },

  guide: {
    padding: 8,
  },

  fieldsBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },

  fieldNameStyle: {
    width: 150,
    textAlign: "right",
  },

  fieldNameArrayStyle: {
    width: 150,
    textAlign: "right",
    paddingTop: 8,
  },

  fieldValueStyle: {
    width: 480,
    marginLeft: 8,
    marginRight: 8,
    textAlign: "left",
  },

  
  textFieldKey: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },  

  textFieldOption: {
  },

  grow: {
    flexFlow: 1,
    minHeight: "15px",
  },

  btnAdd: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));
