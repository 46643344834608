import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Colors from '../../ckcore/core/Colors';
import ValUtils from '../../ckcore/core/ValUtils';
import CkCheckout from '../../checkout/components/CkCheckout';
import CKPayment from '../../payment/components/CKPayment';
import { pricingData } from './PricingTable';
import CKCoinTable from './CKCoinTable';
import { ckTheme } from '../../ckcore/ui/CkStyles';

export default function TopupCkCoin(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const ckpk = pricingData.packages.find((ckpk) => ckpk.id === partnerObj.subscription.package);
  const coinPrice = (partnerObj.subscription.fee) ? partnerObj.subscription.fee : ckpk.fee;
  const defaultCKCoins = {...pricingData.ckcoins[2], price: coinPrice, amount: pricingData.ckcoins[2].value * coinPrice};

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState({});


  const [requestCKCoins, setRequestCKCoin] = useState(defaultCKCoins);
  const onSelectCKCoins = (selectedCKCoins) => {


    // calculate amount
    let amount = selectedCKCoins.value * coinPrice;
    let ckcoins = {...selectedCKCoins, amount: amount};

    console.log("Amount: " + amount + " package: " + JSON.stringify(partnerObj.subscription) + " ckpk: " + JSON.stringify(ckpk));

    setRequestCKCoin(ckcoins);
  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Request Topup CKCoin to get order id and CKPay payment info
  const requestTopupCkCoin = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_TOPUP_CKCOIN,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setSuccessMessage("");
      setErrorMessage("");
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setOrder(response.order);

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request topup CKCoin error with statuses: " + response.statusId);
        setErrorMessage("Yêu cầu mua CKCoin bị lỗi, vui lòng thử lại sau");
      }

    } catch (error) {
      console.log("Error... " + error);
      setErrorMessage("Yêu cầu mua CKCoin bị lỗi, vui lòng thử lại sau");
    }

    setLoading(false);
  };


  /**
   * Process do payment by CkCheckout
   */
  const processPayment = () => {

    CkCheckout.init(order);

    // update topup order
    const ckCoinOrder = order;
    ckCoinOrder.payment_key = '';// reset payment key
    ckCoinOrder.order_total = requestCKCoins.amount;
    ckCoinOrder.order_info = User.getDisplayPhone() +  ' mua CKCoin';
    ckCoinOrder.customer_phone = userObj.phone;
    ckCoinOrder.customer_id = userObj.partnerId;
    ckCoinOrder.product = {
      name: "Mua lượt chuyển khoản " + requestCKCoins.value + " CKCoin",
      code: "CKCOIN",
      price: coinPrice,
      quantity: requestCKCoins.value,
      bonus: requestCKCoins.bonus,
      discount: 0,
      tax: 0,
      image: "https://chuyenkhoan.com/images/chuyenkhoan_banner_1200x620.png",
      description: "Mua lượt chuyển khoản CKCoin"
    };

    console.log("Process pay order: " + JSON.stringify(ckCoinOrder));

    // request pay
    CkCheckout.payNow(ckCoinOrder, errorCallback, paymentCallback);

    console.log("Process payment done.");
  }

  /**
   * Error create order callback
   */
  const errorCallback = (error) => {
    console.log("Eror callback: " + JSON.stringify(error));
  }

  /**
   * Payment callback with order token
   */
  const paymentCallback = (token) => {
    console.log("Request order success. Order token: " + token);
    setOrder({ ...order, token: token });
  }

  /**
   * CKPayment return callback
   */
  const returnCallback = (returnObj) => {
    console.log("Return callback: " + JSON.stringify(returnObj));
    handleClose();
  }

  /**
   * Process on change
   */
  useEffect(() => {
    // request create order
    if (!(order.order_id)) {
      requestTopupCkCoin();
    }
  }, []);

  useEffect(() => {

  }, [requestCKCoins]);

  if ((order.token)) {

    // show payment page
    return (
      <Dialog fullScreen maxWidth="md" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-payment">
        <DialogContent>
          <CKPayment token={order.token} returnCallback={returnCallback} />
        </DialogContent>
      </Dialog>
    );
  } else {

    // Topup CKCoin dialog
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ckTheme}>


          <Dialog fullWidth={true} maxWidth="lg" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-topup">
            <DialogTitle id="form-dialog-title">Mua Lượt Chuyển Khoản (CKCoin)</DialogTitle>
            <DialogContent dividers>

              <Box p={1}>
                {getMessageBox(errorMessage, successMessage, "Mua thêm CKCoin cho " + partnerObj.name)}
              </Box>

              <CKCoinTable 
                  userObj={props.userObj}
                  partnerObj={props.partnerObj}
                  requestCKCoins={requestCKCoins} 
                  onSelectCKCoins={onSelectCKCoins} />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Trở lại
              </Button>

              <Button disabled={loading || !(order.order_id)} onClick={() => processPayment()} variant="outlined" color="warning">
                Đồng ý
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}