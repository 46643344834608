import React, { useState, useEffect } from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Box from "@mui/material/Box";
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from '@mui/material';

import Header from './Header';
import Footer from './Footer';
import ProductSummary from './ProductSummary';
import CheckoutPayment from './CheckoutPayment';
import PaymentStatus from './PaymentStatus';

import { buildOrderObj } from './OrderUtils';
import TxStatus from '../../ckcore/core/TxStatuses';
import './ckpayment.css';
import PaymentCancelDialog from './PaymentCancel';
import PaymentWarningDialog from './PaymentWarning';
import PaymentGuideDialog from './PaymentGuide';
import { useInterval } from '../../ckcore/core/CkUtils';
import ProductListSummary from './ProductListSummary';
import PaymentErrorDialog from './PaymentError';
import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import { downloadBase64Png, dataURItoBlob, downloadLinkPng, dataURItoBlobPng } from '../../ckcore/core/ExportUtils';

const COUNT_DOWN_TIME = 10 * 60 * 1000;
const TRANSITION_TIME = 1800;

export default function CKPayment(props) {

  const [orderObj, setOrderObj] = useState({});
  const [bankInfo, setBankInfo] = useState({
    txId: 0,
    bankAccounts: [],
    ckQR: '',
    ckQRContent: '',
    verified: false,
    upToDate: false
  });

  const [supportBanks, setSupportBanks] = useState([]);
  const [partnerObj, setPartnerObj] = useState({});

  const [token, setToken] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.PAYMENT_WAITING);
  const [finishTime, setFinishTime] = useState(0);
  const [delay, setDelay] = useState(5000);
  const [refreshTime, setRefreshTime] = useState(new Date().getTime());
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const isLargeScreen = useMediaQuery('(min-width:960px)');
  const [showAutoConfirmStatus, setShowAutoConfirmStatus] = useState(false);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const handleCloseCancel = () => {
    setShowCancelDialog(false);
  }
  
  const [showWarninglDialog, setShowWarningDialog] = useState(false);
  const closeWarningDialog = () => {
    setShowWarningDialog(false);
  }

  const [showErrorOrder, setShowErrorOrder] = useState({ show: false, errors: {}, statusId: 0 });
  const requestShowError = () => {
    setShowErrorOrder({ ...showErrorOrder, show: true });
  }
  const handleCloseError = () => {
    setShowErrorOrder({ ...showErrorOrder, show: false });
  }

  const [selectedGuideBank, setSelectedGuideBank] = useState({});
  const [showPaymentGuide, setShowPaymentGuide] = useState(false);
  const bankTransferGuide = (bankObj) => {
    setSelectedGuideBank(bankObj);
    setShowPaymentGuide(true);
  }

  const closeBankTransferGuide = () => {
    setShowPaymentGuide(false);
  }


  var requestOrder = {};
  var requestToken = (props.token) ? props.token : "";
  var errorParseMsg = "";
  try {

    if (!(requestToken)) {
      // get request params
      const requestParams = window.location.search.substring(1);


      if (requestParams.includes("token")) {
        requestOrder = buildOrderObj(requestParams);
        requestToken = requestOrder.token;
        requestOrder = {};
        // console.log("Found request token: " + requestToken);
      } else {
        const spIndex = requestParams.indexOf('=');
        const orderEncoded = requestParams.substring(spIndex + 1);

        // Plus sign (+) must be encoded to %2b
        requestOrder["order"] = JSON.parse(decodeURIComponent(orderEncoded.replace(/\+/g, ' ')));
        requestOrder["txType"] = "pay";
        requestToken = "";
        // console.log(JSON.stringify(requestOrder));
      }
    }

  } catch (error) {
    // console.log("Error parse order from location search: " + window.location + " error: " + error);
    // setShowErrorOrder(true);
    errorParseMsg = error;
  }

  /**
   * Request checkout order
   */
  const requestCheckout = async () => {

    try {

      if (!(requestOrder.order)) {
        console.log("Request order is empty");
        return;
      }

      if (loading) { return; } else { setLoading(true); };

      /*
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: requestOrder
      };
      */
      // console.log("Request order: " + JSON.stringify(requestOrder));

      // const rawRespone = await fetch("https://chuyenkhoan.com/ckpay/pay", requestOptions);
      const requestURL = Config.getPaymentServerURL() + "pay=" + encodeURIComponent(JSON.stringify(requestOrder));
      // const requestURL = Config.getPaymentServerURL() + "pay=" + JSON.stringify(requestOrder);

      // console.log("RequestURL: " + requestURL);
      const rawRespone = await fetch(requestURL);
      const response = await rawRespone.json();
      if (response.token) {

        // goto new location
        redirectSession(response.token);

        // console.log("Found order token: " + response.token);
      } else {

        // TODO: show error message
        setShowErrorOrder({ ...showErrorOrder, show: true, errors: response.errors, statusId: response.statusId });

        // set error
        console.log("Error generate CK QR: " + JSON.stringify(response));
      }

    } catch (error) {
      console.log("Error send checkout request to ckserver: " + error);
      setShowErrorOrder({ ...showErrorOrder, show: true, errors: {}, statusId: TxStatus.HTTP_SERVICE_UNAVAILABLE });
    }

    setLoading(false);
  };

  /**
   * Load order by token
   */
  const loadOrder = async () => {

    // has no token
    if (!(requestToken)) {
      return;
    }

    if (loading) { return; } else { setLoading(true); };


    try {

      // build message request
      const messageRequest = {
        txType: "load",
        token: requestToken,
        created: (orderObj.timestamp) ? orderObj.timestamp : 0,
        timestamp: (new Date()).getTime()
      }

      // request query order
      const rawRespone = await fetch(Config.getPaymentServerURL() + "pay=" + JSON.stringify(messageRequest));
      const response = await rawRespone.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {

        setBankInfo({
          txId: response.txId,
          bankAccounts: response.bankAccounts,
          ckQR: response.ckqr,
          ckQRContent: response.ckqr_content,
          verified: response.verified,
          upToDate: response.upToDate
        });

        setOrderObj(response.order);
        setPaymentStatus(response.paymentStatus);
        setToken(requestToken);

        // get support bank
        setSupportBanks(response.supportBanks);
        setPartnerObj(response.partner);

        // console.log("Loaded account number: " + response.order.account_number);
      } else if (response.statusId === TxStatus.HTTP_NOT_FOUND) {
        console.log("Order not found. Goto chuyenkhoan.com");
        // window.location.href = NOT_FOUND_URL;
        // requestShowError();
        setShowErrorOrder({ ...showErrorOrder, show: true, errors: response.errors, statusId: response.statusId });

      } else {
        // set error
        console.log("Error load order by token");
      }

    } catch (error) {
      console.log("Error send load request to ckserver: " + error);
    }

    setLoading(false);
  }


  /**
   * Request change ben bank account number
   */
  const requestChangeBenBankAccount = async (benAccountNumber) => {

    try {

      if (loading) { return; } else { setLoading(true); };


      // build message request
      const messageRequest = {
        txType: "change",
        token: requestToken,
        accountNumber: benAccountNumber,
        created: (orderObj.timestamp) ? orderObj.timestamp : 0,
        partner_id: partnerObj.partner_id,
        timestamp: (new Date()).getTime()
      }

      // request query order
      const rawRespone = await fetch(Config.getPaymentServerURL() + "pay=" + JSON.stringify(messageRequest));
      const response = await rawRespone.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {

        // console.log("Change account number to: " + response.order.account_number + " with new token: " + response.token);

        // redirect to new order token
        if (requestToken !== response.token) {
          redirectSession(response.token);
        } else {
          setBankInfo({
            bankAccounts: response.bankAccounts,
            ckQR: response.ckqr,
            ckQRContent: response.ckqr_content,
            verified: response.verified,
            upToDate: response.upToDate
          });

          setOrderObj(response.order);
          setPaymentStatus(response.paymentStatus);
          setToken(requestToken);
        }
      } else if (response.statusId === TxStatus.HTTP_NOT_FOUND) {
        // console.log("Order not found. Goto chuyenkhoan.com");
        // window.location.href = NOT_FOUND_URL;
        requestShowError();
      } else {
        // set error
        console.log("Error load order by token");
      }

    } catch (error) {
      console.log("Error send load request to ckserver: " + error);
    }

    setLoading(false);
  }

  /**
   * Query order status by token
   */
  const queryOrder = async () => {

    // has no token
    if (!(token)) {
      return;
    }

    // not in waiting status
    if (paymentStatus !== PaymentStatus.PAYMENT_WAITING) {
      if (finishTime === 0) {
        setFinishTime(new Date().getTime());
      }

      return;
    }

    // timeout
    if (getRemainTime() <= 0 && (orderObj.return_fields)) {
      return;
    }

    var action = (getRemainTime() <= 0 && !(orderObj.return_fields)) ? "timeout" : "";

    try {

      if (loading) { return; } else { setLoading(true); };

      // build message request
      const messageRequest = {
        txType: "query",
        token: token,
        action: action,
        created: (orderObj.timestamp) ? orderObj.timestamp : 0,
        timestamp: (new Date()).getTime()
      }

      // request query order
      const rawRespone = await fetch(Config.getPaymentServerURL() + "pay=" + JSON.stringify(messageRequest));
      const response = await rawRespone.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {


        // order is finished
        if (response.paymentStatus !== PaymentStatus.PAYMENT_WAITING) {

          // set order paid amount
          setOrderObj({ ...orderObj, order_paid: response.order_paid, return_fields: response.return_fields });
          setFinishTime(new Date().getTime());

          setPaymentStatus(response.paymentStatus);
        } else if (response.return_fields) {

          setOrderObj({ ...orderObj, return_fields: response.return_fields });
        }

        // console.log("Current order status: " + response.paymentStatus + " amount: " + response.amount);
      } else {
        // set error
        console.log("Error query order by token");
      }

    } catch (error) {
      console.log("Error send query request to ckserver: " + error);
    }

    setLoading(false);

  }

  const requestShowWarning = () => {
    setShowWarningDialog(true);
  }

  // Request cancel order
  const requestCancelOrder = () => {

    if (getRemainTime() > 0 && paymentStatus === PaymentStatus.PAYMENT_WAITING) {
      // show confirm dialog
      setShowCancelDialog(true);
    } else {
      // just return
      returnMerchantURL();
    }

  }

  /**
   * User cancel order
   */
  const cancelOrder = async () => {

    try {

      if (loading) { return; } else { setLoading(true); };

      // build message request
      const messageRequest = {
        txType: "cancel",
        token: token,
        created: (orderObj.timestamp) ? orderObj.timestamp : 0,
        timestamp: (new Date()).getTime()
      }

      // request cancel order
      const rawRespone = await fetch(Config.getPaymentServerURL() + "pay=" + JSON.stringify(messageRequest));
      const response = await rawRespone.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setPaymentStatus(response.paymentStatus);

        if (response.return_fields) {
          setOrderObj({ ...orderObj, return_fields: response.return_fields });
        }

        if (response.paymentStatus === PaymentStatus.PAYMENT_CANCEL || response.paymentStatus === PaymentStatus.PAYMENT_TIMEOUT) {
          returnMerchantURL(response.return_fields);
        }

      } else {
        // set error
        console.log("Error cancel order by token");
      }

    } catch (error) {
      console.log("Error send cancel request to ckserver: " + error);
    }

    setLoading(false);

  }


  /**
   * Download order PNG
   */
  const downloadOrderInvoice = async (fileType) => {

    try {

      if (downloading) {
        return;
      } else {
        setDownloading(true);
      }

      // build message request
      const messageRequest = {
        fileType: fileType,
        orderId: orderObj.order_id,
        txId: bankInfo.txId,
        token: token,
        partner: partnerObj,
        timestamp: (new Date()).getTime()
      }
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(messageRequest)
      };

      // request query order
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/export/download?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        console.log("Print pdf / png image success to file: " + response.fileName);
        if (fileType === 'png') {


          if (isMobile) {
            downloadLinkPng(response.downloadLink, response.fileName);
          } else {
            // const blob = dataURItoBlobPng(response.blob);
            // const fileURL = URL.createObjectURL(blob); //Open the URL on new Window
            // window.open(fileURL);
            downloadBase64Png(response.blob, response.fileName);
          }
        } else {

          const blob = dataURItoBlob(response.blob);
          const fileURL = URL.createObjectURL(blob); //Open the URL on new Window
          window.open(fileURL);
        }
      } else if (response.statusId === TxStatus.HTTP_NOT_FOUND) {
        requestShowError();
      } else {
        // set error
        console.log("Error download order invoince: " + response.statusId);
      }

    } catch (error) {
      console.log("Error send download request to ckexport: " + error);
    }

    setDownloading(false);

  }

  /**
   * Get countdown time
   * @returns Countdown time in format HH:mm
   */
  const getCountDown = () => {

    const currentTime = refreshTime;
    var remainTime = orderObj.timestamp - currentTime + COUNT_DOWN_TIME;
    if (remainTime > 0) {
      let minute = Math.floor(remainTime / 60000);
      let second = Math.floor((remainTime % 60000) / 1000);
      return (minute < 10 ? "0" + minute : minute) + ':' + (second < 10 ? "0" + second : second);
    } else {
      return "00:00";
    }
  }

  /**
   * Get remain time
   * @returns Remain time for waiting order
   */
  const getRemainTime = () => {
    return (orderObj.timestamp - refreshTime + COUNT_DOWN_TIME);
  }

  /**
   * Return to merchant URL
   */
  const returnMerchantURL = (return_fields) => {

    const rfields = (return_fields) ? return_fields : orderObj.return_fields;

    // console.log("Retur fields: " + JSON.stringify(rfields));

    // just callback to close payment dialog
    if (props.returnCallback) {
      props.returnCallback(rfields);
      return;
    }

    // build return url
    var return_url = orderObj.return_url;
    if (return_url.indexOf('?') < 0) {
      return_url = return_url + '?';
    }

    // get return fields
    if (rfields) {
      // add return fields
      return_url = return_url + 'txn_ref=' + rfields.txn_ref;
      return_url = return_url + '&status_code=' + rfields.status_code;
      return_url = return_url + '&amount=' + rfields.amount;
      return_url = return_url + '&order_id=' + rfields.order_id;
      return_url = return_url + '&timestamp=' + rfields.timestamp;
      return_url = return_url + '&checksum=' + rfields.checksum;
    }

    window.location.href = return_url;
  }

  /**
   * Redirect to CKQR session URL
   */
  const redirectSession = (token) => {
    var href = window.location.href;

    // use current URL but change params
    href = href.substring(0, href.indexOf('?') + 1);
    href = href + "token=" + token;

    window.location.href = href;

  }

  /**
   * Process return callback
   */
  const returnCallback = (action) => {
    if (action === 'repay' && !(props.returnCallback)) {
      // just request change bank account to repaid
      requestChangeBenBankAccount(orderObj.account_number);
    } else {
      returnMerchantURL();
    }
  }

  // Request checkout on load
  useEffect(() => {
    if (requestToken) {
      loadOrder();
    } else {
      requestCheckout();
    }

    if (errorParseMsg) {
      requestShowError();
    }

  }, []);

  // Change auto confirm status
  useEffect(() => {
    setShowAutoConfirmStatus(isLargeScreen);  
    console.log("Show auto confirm status: " + isLargeScreen); 
}, [isLargeScreen]);

  // Use interval to refresh order status
  useInterval(() => {

    // Fetch order status
    queryOrder();

    if (window.location.search.length < 11) {
      // setDelay(11000);
    }

  }, delay);

  // Use interval to refresh countdown
  useInterval(() => {

    setRefreshTime(new Date().getTime());

    if (getRemainTime() < -TRANSITION_TIME) {
      // show timeout message
      requestShowWarning();
    } else if (finishTime > 0 && refreshTime - finishTime > TRANSITION_TIME) {
      // show success message
      requestShowWarning();
    } else if (paymentStatus !== PaymentStatus.PAYMENT_WAITING) {
      requestShowWarning();
    }

  }, 1000);

  return (

    <div className="App-Container is-noBackground flex-container justify-content-center">
      {
        (orderObj.account_number) &&
        <div className="App App--singleItem">
          <div className="App-Overview">

            <Header order={orderObj} bankInfo={bankInfo} cancelOrder={requestCancelOrder} />

            <div className="">
              {
                ((orderObj.product) || ((orderObj.products) && orderObj.products.length === 1)) ? (
                  <ProductSummary order={orderObj} bankInfo={bankInfo} />
                ) : (
                  <ProductListSummary order={orderObj} bankInfo={bankInfo} />
                )
              }

            </div>
            <div style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}>
              {
                !(bankInfo.upToDate) &&
                <Box style={{ marginTop: 16 }}>
                  <Alert severity="warning" style={{paddingBottom: 0, cursor: "pointer"}}>
                    <AlertTitle onClick={() => setShowAutoConfirmStatus(!showAutoConfirmStatus)}>
                      <span style={{marginTop: 6}}>GIAO DỊCH KHÔNG TỰ ĐỘNG</span> 
                      <span style={{paddingLeft: 16, paddingBottom: 3}}>{showAutoConfirmStatus ? " <<" : " >>"}</span> 
                    </AlertTitle>                    
                  </Alert>
                  {showAutoConfirmStatus &&
                    <Typography style={{ padding: 16, paddingTop: 0, paddingBottom: 5, marginTop: -12, textAlign: 'left', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#FFF4E6', lineHeight: 1.5 }}>
                      Giao dịch thanh toán của bạn sẽ không được xác nhận tự động do gián đoạn kết nối giữa tài khoản <strong>{orderObj.account_number}</strong> của <strong>{partnerObj.name} </strong>
                      và Chuyenkhoan.com
                    </Typography>
                  }
                  
                </Box>
              }
              {
                (bankInfo.upToDate) &&
                <Box style={{ marginTop: 16 }}>
                  <Alert severity="success" style={{paddingBottom: 0, cursor: "pointer"}}>
                    <AlertTitle onClick={() => setShowAutoConfirmStatus(!showAutoConfirmStatus)}>
                    <span style={{marginTop: 6}}>GIAO DỊCH TỰ ĐỘNG</span>
                    <span style={{paddingLeft: 16}}>{showAutoConfirmStatus ? " <<" : " >>"}</span> 
                    </AlertTitle>
                  </Alert>
                  {showAutoConfirmStatus &&
                    <Typography style={{ padding: 16, paddingTop: 0, paddingBottom: 5, marginTop: -12, textAlign: 'left', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#EDF7EE', lineHeight: 1.5 }}>
                      Giao dịch thanh toán của bạn sẽ ĐƯỢC XÁC NHẬN TỰ ĐỘNG. Nếu bạn không quét được mã VietQR, vui lòng <strong>NHẬP ĐÚNG SỐ TIỀN và NỘI DUNG</strong> chuyển khoản.
                    </Typography>
                  }
                </Box>
              }
            </div>
          </div>

          <div className="App-Payment">
            <CheckoutPayment
              order={orderObj}
              bankInfo={bankInfo}
              paymentStatus={paymentStatus}
              supportBanks={supportBanks}
              partnerObj={partnerObj}
              requestShowWarning={requestShowWarning}
              bankTransferGuide={bankTransferGuide}
              changeBenBankAccount={requestChangeBenBankAccount}
              countDown={getCountDown()}
              remainTime={getRemainTime()}
              downloadCallback={downloadOrderInvoice}
              loading={downloading} />

          </div>

          <Footer order={orderObj} bankInfo={bankInfo} />
        </div>

      }
      {
        showCancelDialog &&
        <PaymentCancelDialog open={showCancelDialog} closeCallback={handleCloseCancel} returnCallback={cancelOrder}
          order={orderObj} bankInfo={bankInfo}
        />
      }

      {
        showWarninglDialog &&
        <PaymentWarningDialog open={showWarninglDialog} closeCallback={closeWarningDialog} returnCallback={returnCallback} bankTransferGuide={bankTransferGuide}
          paymentStatus={paymentStatus}
          remainTime={getRemainTime()}
          order={orderObj}
          bankInfo={bankInfo}
          supportBanks={supportBanks}
          partnerObj={partnerObj}
          downloadCallback={downloadOrderInvoice}
          loading={downloading}
        />
      }
      {
        showErrorOrder.show &&
        <PaymentErrorDialog open={showErrorOrder.show} closeCallback={handleCloseError} returnCallback={() => { window.history.back() }}
          order={orderObj}
          bankInfo={bankInfo}
          errorOrder={showErrorOrder}
        />
      }
      {
        showPaymentGuide &&
        <PaymentGuideDialog open={showPaymentGuide} closeCallback={closeBankTransferGuide}
          bankObj={selectedGuideBank}
          order={orderObj}
          bankInfo={bankInfo}
          supportBanks={supportBanks}
        />
      }
    </div>
  );
}
