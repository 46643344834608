import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import Autocomplete from '@mui/material/Autocomplete';

import { sha256 } from 'js-sha256';
import { ckTheme } from '../../ckcore/ui/CkStyles';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import { isValidNameUTF8, isValidUrl, isValidAccountNumber, isValidAddress, isValidPhone, isValidEmail, isValidCustomerCode, isValidDomain, isValidTin } from '../../ckcore/core/Validator';
import Colors from '../../ckcore/core/Colors';
import ValUtils from '../../ckcore/core/ValUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { getBankInfo, getBankSvgIcon } from '../../ckcore/core/CkBank'
import { MenuItem } from '@mui/material';
import User from '../../ckcore/core/User';
import TxTypes from '../../ckcore/core/TxTypes';
import TxStatus from '../../ckcore/core/TxStatuses';
import Config from '../../ckcore/core/Config';
import ServiceTypes from '../../ckcore/core/ServiceTypes';
import CustomerTable from './ui/CustomerTable';
import CustomerFields from './ui/CustomerFields';


export default function CustomerDetailsDialog(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const [messages, setMessages] = useState({ success: '', error: '', info: '', editing: false });
  const [loading, setLoading] = useState(false);

  const [popaObj, setPopaObj] = useState((props.popaObj) ? props.popaObj : {
    popaId: 0,
    statusId: 0,
    typeId: 0,
    industry: 0,
    optional: 0,

    birthday: 0,
    gender: 0,
    provinceId: 0,
    districtId: 0,
    communeId: 0,
    lat: 0.0,
    lng: 0.0,

    credits: 0,
    credit: 0,
    debits: 0,
    debit: 0,
    created: 0,
    updated: 0,

    code: '',
    phone: '',
    email: '',
    website: '',
    bankName: '',
    accountNumber: '',
    accountName: '',
    name: '',
    address: '',
    info: {},
    contacts: [],
    accounts: []
  });

  const [changedPopaObj, setChangedPopaObj] = useState({});
  const onUpdatePopa = (newPopaObj) => {
    setChangedPopaObj(newPopaObj);

    if(JSON.stringify(newPopaObj) !== JSON.stringify(popaObj)) {
      setMessages({ ...messages, editing: true });
    }
  }

  /*
  * Load potential partner details (customer details)
  */
  const loadPopaObj = async () => {

    // request get user info
    const ckc = {
      txTypeId: TxTypes.PARTNER_ADMIN_LOAD_CUSTOMER_DETAILS,
      partnerId: userObj.partnerId,
      popaId: popaObj.popaId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "customer=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.HTTP_SUCCESS) {

        if(response.popaObj.popaId) {
          setPopaObj(response.popaObj);
        }                
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request load error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error... " + error);
    }

    setLoading(false);
  }

  /*
  * Update customer details
  */
  const updateCustomer = async () => {

    // code or name
    if (!(changedPopaObj.code) || !(changedPopaObj.name) || !isValidCustomerCode(changedPopaObj.code) || !isValidNameUTF8(changedPopaObj.name)) {
      console.log("Customer code or name invalid: " + changedPopaObj.code);
      setMessages({ ...messages, error: 'Vui lòng nhập đúng, đủ mã và tên khách hàng', success: '' });
      return;
    }

    // address
    if ((changedPopaObj.address) && !isValidAddress(changedPopaObj.address)) {
      setMessages({ ...messages, error: 'Địa chỉ không hợp lệ: ' + changedPopaObj.address, success: '' });
      return;
    }

    // tin
    if ((changedPopaObj.info.tin) && !isValidTin(changedPopaObj.info.tin)) {
      setMessages({ ...messages, error: 'Mã số thuế không hợp lệ: ' + changedPopaObj.info.tin, success: '' });
      return;
    }

    // name
    if ((changedPopaObj.info.contact) && !isValidNameUTF8(changedPopaObj.info.contact)) {
      setMessages({ ...messages, error: 'Sai tên người liên hệ: ' + changedPopaObj.info.contact, success: '' });
      return;
    }

    // phone
    if ((changedPopaObj.phone) && !isValidPhone(changedPopaObj.phone)) {
      setMessages({ ...messages, error: 'Số điện thoại không hợp lệ: ' + changedPopaObj.phone, success: '' });
      return;
    }

    // email
    if ((changedPopaObj.email) && !isValidEmail(changedPopaObj.email)) {
      setMessages({ ...messages, error: 'Địa chỉ email không hợp lệ: ' + changedPopaObj.email, success: '' });
      return;
    }

    // website
    if ((changedPopaObj.website) && !(isValidDomain(changedPopaObj.website) || isValidUrl(changedPopaObj.website))) {
      setMessages({ ...messages, error: 'Website không hợp lệ: ' + changedPopaObj.website, success: '' });
      return;
    }

    // get bank info
    if ((changedPopaObj.bankName)) {
      const bankInfo = getBankInfo(Config.getAvailableBanks(), changedPopaObj.bankName);
      if (!(bankInfo)) {
        setMessages({ ...messages, error: "Tên ngân hàng không hợp lệ: " + changedPopaObj.bankName });
        return;
      }

      // validate account
      if (!isValidAccountNumber(changedPopaObj.accountNumber, bankInfo.accountRegex)) {
        setMessages({ ...messages, error: "Số tài khoản không hợp lệ: " + changedPopaObj.accountNumber });
        return;
      }
      if (!isValidNameUTF8(changedPopaObj.accountName)) {
        setMessages({ ...messages, error: "Tên tài khoản không hợp lệ: " + changedPopaObj.accountName });
        return;
      }
    }

    // create order request
    try {

      if (loading) { return; }
      setLoading(true);
      setMessages({ ...messages, error: '', success: '' });

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_UPDATE_CUSTOMER,
        partnerId: userObj.partnerId,
        popaObj: changedPopaObj,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ckmsg)
      };


      // fetch response
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/dashboard/customer?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        setMessages({ ...messages, editing: false, error: '', success: 'Cập nhật khách hàng ' + changedPopaObj.code + ' thành công' });
      } else if (response.statusId === TxStatus.DUPLICATED) {
        setMessages({ ...messages, error: 'Mã khách hàng đã tồn tại: ' + changedPopaObj.code, success: ''});
      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
        handleClose();
      } else {
        console.log("ERROR update customer, msg: " + response.msg);
        setMessages({ ...messages, error: 'Lỗi cập nhật khách hàng, vui lòng thử lại sau', success: '' });
      }
    } catch (error) {
      console.log("Error create order .. " + error);
      setMessages({ ...messages, error: 'Lỗi cập nhật khách hàng, vui lòng thử lại sau', success: '' });
    }

    setLoading(false);

  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  useEffect(() => {
    
    // add new customer
    if(popaObj.popaId === 0) {
      setMessages({ ...messages, editing: true });
    }

    loadPopaObj();
  }, []);

  // Add bank dialog
  return (
    <ThemeProvider theme={ckTheme}>
      <Dialog open={props.open} fullWidth maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item>
              {messages.editing ? "Cập nhật khách hàng / đối tác" : "Thông tin khách hàng / đối tác"}
            </Grid>
            <Grid item>
              {/*
              <span>
                <Button disabled={loading} variant="outlined" color="primary" onClick={() => setMessages({ ...messages, editing: !messages.editing })}>
                  {messages.editing ? "XONG" : "SỬA"}
                </Button>
              </span>
              */}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>

          <Box p={2}>
            {getMessageBox(messages.error, messages.success, messages.info)}
          </Box>

          <CustomerFields popaObj={popaObj} updateCallback={onUpdatePopa} />

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Trở lại
          </Button>

          <Button disabled={loading || !messages.editing} onClick={() => updateCustomer()} variant="outlined" size="small" color="primary">
            Cập nhật
          </Button>

        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
