
export const dataURItoBlob = (dataURI) => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/pdf' });
  return blob;
}

export const dataURItoBlobPng = (base64Png) => {
  const byteString = window.atob(base64Png);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'image/png' });
  return blob;
}


// Parameters:
// contentType: The content type of your file. 
//              its like application/pdf or application/msword or image/jpeg or
//              image/png and so on
// base64Data: Its your actual base64 data
// fileName: Its the file name of the file which will be downloaded. 
// image/octet-stream
export const downloadBase64Png = (base64Data, fileName) => {
  // const linkSource = `data:image/png;base64,${base64Data}`;
  const linkSource = `data:image/octet-stream;base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

/**
 * Click to download image
 */
export const downloadLinkPng = (link, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = link;
  downloadLink.download = fileName;
  // downloadLink.target = '_blank';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}