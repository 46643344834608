import React from 'react';
import NumberFormat from 'react-number-format';
import './ckpayment.css';

export default function ProductListSummary(props) {

  const order = props.order;
  const products = order.products;
  const firstProduct = products[0];
  const productCount = products.length;

  return (
    <div className="App-productSummaryContainer">

      <div className="ProductSummary">
        <div className="ProductSummary-info is-clickable">
          <span className="Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500">
            Đơn hàng #{order.order_id}: {firstProduct.name} và {productCount - 1} sản phẩm nữa
          </span>
          <span className="ProductSummary-totalAmount Text Text-color--default Text-fontWeight--600 Text--tabularNumbers" id="ProductSummary-totalAmount">
            <NumberFormat value={order.order_total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
          </span>
        </div>
      </div>

      <section className="OrderDetails App-Overview-OrderDetails my6">
        <ul className="OrderDetails-items">
          {
            products.map((product, index) => (
              <div key={"item_" + index} className="OrderDetails-item" style={{opacity: 1}}>
                <div className="LineItem flex-container align-items-flex-start">
                  <div className="LineItem-imageContainer mr4 flex-item width-fixed">
                    <img src={product.image} alt="" className="LineItem-image" />
                  </div>
                  <div className="flex-item width-grow">
                    <div className="flex-container justify-content-space-between align-items-baseline wrap-wrap">
                      <div className="flex-column-break" style={{order: 2}}>
                      </div>
                      <div className="flex-column-break" style={{order: 6}}>
                      </div>
                      <div className="flex-item width-12 flex-container justify-content-space-between width-12">
                        <div className="LineItem-productName flex-item width-auto" style={{order: 0}}>
                          <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">
                          {product.name}
                          </span>
                        </div>
                        <div className="ml2 flex-item width-fixed" style={{order: 1}}>
                          <span className="Text Text-color--default Text-fontSize--14 Text-fontWeight--500 Text--tabularNumbers">
                            <NumberFormat value={product.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
                          </span>
                        </div>
                      </div>
                      <div className="flex-item width-auto flex-item-align-left" style={{order: 4}}>
                        <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                          {product.description}
                        </span>
                      </div>
                      <div className="flex-item width-auto flex-item-align-right" style={{order: 5}}>
                        <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                          {product.discount > 0 ? (
                            <NumberFormat value={product.discount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'-'} suffix={'đ'} />
                          ):(
                            ""
                          )}
                        </span>
                      </div>
                      <div className="LineItem-tierBreakdown flex-item width-auto flex-item-align-left" style={{order: 7}}>
                        <div className="flex-container">
                        </div>
                      </div>
                      <div className="flex-item width-auto" style={{order: 3}}>
                        <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </ul>
        <div className="OrderDetails-footer">
        </div>
      </section>

    </div>

  );

}