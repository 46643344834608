
import React, { useEffect, useState } from 'react';
import { Hidden, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';

import {
  getStatusColor,
  getTxStatusText,
  getTxMillisText,
  parseTxBean,
  getTxName
} from '../../ckcore/core/TxUtils';
import { useInterval } from '../../ckcore/core/CkUtils';

import Config from '../../ckcore/core/Config';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';

/**
 * System log all transactions
 */
export default function SystemLogList(props) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [systemData, setSystemData] = useState({
    systemInfo: {},
    systemTxList: [],
  });  

  const [selectedTxObj, setSelectedObj] = useState({});

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(33);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // load system data
  const loadSystemData = async () => {

    try {

      if(loading) {
        return;
      }

      // request message
      var ckmsg = {
        txTypeId: TxTypes.ORIGIN_ADMIN_SYSTEM_REPORT,
        index: 0,
        count: 121,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "admin=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // parse system transaction
        const systemTxList = [];    
        var txObj;
        if(response.txList) {
          response.txList.map((txLine) => {
            txObj = parseTxBean(txLine);
            txObj.time = getTxMillisText(txObj.created);
            systemTxList.push(txObj);
          });
        }

        setSystemData({...systemData, systemTxList: systemTxList});

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load bank status, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get bank data.. " + error);
    }

    setLoading(false);

  };

  // Get tx actions
  const getActions = (txObj, index) => {
    return (
      <div>
       
      </div>
    );
  }

  /**
   * User request search
   */
  const handleSearch = (searchTxt) => {

  }

  const getTxColor = (txObj) => {
    if(txObj.statusId === TxStatus.WAITING) {
      return ''; // default
    } else {
      return getStatusColor(txObj.statusId);
    }
    
  }

  // Use interval to refresh bank data
  useInterval(() => {
    loadSystemData();
  }, 5000);

  // reload on refresh
  useEffect(() => {
    loadSystemData();
  }, []);



  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        
        <TableContainer className={classes.tableContainer} component={Paper} >

          <Table className={classes.table} size="small" aria-label="trans list table">

            <TableHead>
                <TableRow>
                    <TableCell className={classes.colIndex} align="center">TxId</TableCell>
                    <TableCell className={classes.colIndex} align="left">TxType</TableCell>
                    <TableCell className={classes.colAddress} align="left">Src</TableCell>
                    <TableCell className={classes.colAddress} align="left">Dest</TableCell>
                    <TableCell className={classes.colTxStatus} align="left">Amount</TableCell>
                    <TableCell className={classes.colTxStatus} align="left">Balance</TableCell>
                    <TableCell className={classes.colTime} align="left">Time</TableCell>
                    <TableCell className={classes.colTxBody} align="left">Content</TableCell>
                  </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? systemData.systemTxList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : systemData.systemTxList
              ).map((txObj, index) => (
                <TableRow key={index} style={{height: 40, overflow: 'hidden'}}>
                      <TableCell className={classes.colIndex} component="th" scope="row">{txObj.txId}</TableCell>
                      <TableCell className={classes.colIndex} align="left" style={{ color: getTxColor(txObj) }}>{getTxName(txObj.txTypeId)}</TableCell>
                      <TableCell className={classes.colAddress} align="left">{getDisplayPhone((txObj.src) ? txObj.src : txObj.srcPhone)}</TableCell>
                      <TableCell className={classes.colAddress} align="left">{getDisplayPhone((txObj.ben) ? txObj.ben : txObj.destPhone)}</TableCell>
                      <TableCell className={classes.colAmount} align="right" >{ValUtils.formatMoney(txObj.amount)}</TableCell>
                      <TableCell className={classes.colAmount} align="right" >{ValUtils.formatMoney(txObj.balance)}</TableCell>
                      <TableCell className={classes.colTime} align="left">{txObj.time}</TableCell>
                      <TableCell className={classes.colTxBody} align="left">{txObj.content}</TableCell>
                    </TableRow>
              ))}
            </TableBody>
          
            <TableFooter >
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[11, 22, 33]}
                  colSpan={9}
                  count={systemData.systemTxList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Số GĐ' },
                    native: true,
                  }}
                  labelRowsPerPage="Xem:"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        
      </ThemeProvider>
    </StyledEngineProvider>
  );
}



const theme = createTheme(adaptV4Theme({
  // spacing: 3,
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation.
        padding: '2px 6px',
      },
      sizeSmall: {
        padding: '2px 3px 2px 3px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
      padding: 8,
  },

  alert: {
    marginBottom: 12,
  },

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
  },

  transHead: {
    minHeight: 22,
    padding: '3px 3px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
    width: 200,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

  tableContainer: {
    marginTop: 8,
  },

  table: {
    marginTop: 5,
    marginBottom: 0,    
  },

  row: {
    overflow: 'hidden',
    height: 40,
    maxHeight: 48,
    minHeight: 40,
    fontSize: 11,
  },


  colIndex: {
    minWidth: 80,
    maxWidth: 120,
    textAlign: 'center',
    padding: '2px 3px',
    fontSize: 12,
  },

  colTxStatus: {
    minWidth: 80,
    maxWidth: 80,
    textAlign: 'center',
    padding: '2px 3px',
    fontSize: 12,
  },

  colAmount: {
    minWidth: 80,
    fontSize: 12,
    padding: '2px 12px',
    textAlign: 'right',
    fontWeight: 'bold',
  },

  colAddress: {
    minWidth: 80,
    maxWidth: 12,
    padding: '2px 3px',
    fontSize: 12,
  },

  colTime: {
    minWidth: 120,
    maxWidth: 120,
    padding: '2px 3px',
    fontSize: 12,
  },

  colTxBody: {
    minWidth: 333,
    maxWidth: 360,
    padding: '2px 3px',
    fontSize: 12,
  },
}));

