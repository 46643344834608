import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import Box from "@mui/material/Box";

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import PaymentTxList from './PaymentTxList';
import PaymentStatus from '../../payment/components/PaymentStatus';
import { parseTxBean } from '../../ckcore/core/TxUtils';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import OrderMatchingTable from './ui/OrderMatchingTable';

export default function MatchingOrderDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const bankTxObj = props.bankTxObj;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const [orderObj, setOrderObj] = useState({});
  const [orderData, setOrderData] = useState({
    bankConfig: {},
    txList: [],
  });

  // load order data
  const loadOrderData = async () => {
    try {

      if (loading) {
        return;
      }

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_LIST,
        partnerId: userObj.partnerId,

        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setOrderData(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order data, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get order data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Get bank account array from bank config
   */
  const getBankAccounts = (bankConfig) => {

    var bankAccounts = [];

    if (!(bankConfig.banks)) {
      return bankAccounts;
    }

    bankConfig.banks.map((accountName) => {
      bankAccounts.push(bankConfig[accountName]);
    })
    return bankAccounts;
  }

  /**
   * Filter order to get error status only
   */
  const filterOrders = (orderTxList) => {
    const txList = [];
    var txObj;
    orderTxList.map((txLine) => {
      txObj = parseTxBean(txLine);
      if (txObj.paymentStatus === PaymentStatus.TIMEOUT
        || txObj.paymentStatus === PaymentStatus.PAYMENT_CANCEL
        || txObj.paymentStatus === PaymentStatus.PAYMENT_WAITING
        || txObj.paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
        txList.push(txLine);
      }
    });
    return txList;
  }

  /**
   * Order select callback
   */
  const onSelectOrder = (txObj) => {
    setOrderObj(txObj);
  }

  // process matching order
  const doMatchingOrder = async () => {

    try {

      if (loading) {
        return;
      }

      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_MATCHING,
        partnerId: userObj.partnerId,
        txObj: bankTxObj,
        order: orderObj,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        const partnerResponseStatusId = response.order.txStatusId;
        if (partnerResponseStatusId === TxStatus.HTTP_SUCCESS) {
          setSuccessMessage("Đơn hàng đã khớp và xác nhận thành công");
        } else {
          setErrorMessage("Đơn hàng đã khớp nhưng xác nhận giao dịch lỗi: " + partnerResponseStatusId);
        }

        setOrderObj({ ...orderObj, paymentStatus: PaymentStatus.PAYMENT_SUCCESS });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order data, msg: " + response.msg);
        setErrorMessage("Lỗi khớp đơn hàng, vui lòng thử lại sau");
      }
    } catch (error) {
      console.log("Error get order data.. " + error);
      setErrorMessage("Lỗi khớp đơn hàng, vui lòng thử lại sau");
    }

    setLoading(false);

  };


  useEffect(() => {
    // reload on refresh
    loadOrderData();
  }, []);


  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Khớp đơn hàng</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>
              {getMessageBox(errorMessage, successMessage, "Mời bạn chọn đơn hàng cần khớp")}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OrderMatchingTable orderObj={orderObj} bankTxObj={bankTxObj} />
              </Grid>

              <Grid item xs={12}>
                {
                  (orderObj.paymentStatus !== PaymentStatus.PAYMENT_SUCCESS) &&
                  <PaymentTxList
                    bankAccounts={getBankAccounts(orderData.bankConfig)}
                    txList={filterOrders(orderData.txList)}
                    rowsPerPage={5}
                    userObj={props.userObj}
                    partnerObj={props.partnerObj}
                    selectCallback={onSelectOrder}
                    logoutCallback={props.logoutCallback}
                    refreshCallback={props.refreshCallback} />
                }
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button variant="outlined" color="warning" onClick={() => doMatchingOrder()}
              disabled={loading || !(orderObj.txId) || orderObj.paymentStatus === PaymentStatus.PAYMENT_SUCCESS} >
              Khớp đơn hàng
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});

const useStyles = makeStyles(theme => ({
  root: {

  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

}));
