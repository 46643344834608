import React from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';

import PartnerListAll from './PartnerListAll';
import SystemLogList from './SystemLogList';
import CKBoxList from './CKBoxList';
import UserListAll from './UserListAll';
import SimListAll from './SimListAll';
import BankAccountListAll from './BankAccountListAll';

export default function RootTabs(props) {

  const [value, setValue] = React.useState((props.selectedIndex) ? props.selectedIndex : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square>

      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Root Functions"
      >
        <Tab label="System Log" />
        <Tab label="Partner Manager" />
        <Tab label="User Manager" />
        <Tab label="CKBox Monitor" />
        <Tab label="CK Sim List" />
        <Tab label="Bank Accounts" />
      </Tabs>

      <Divider />
      {
        (value === 0) &&
        <SystemLogList
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }

      {
        (value === 1) &&
        <PartnerListAll
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }

      {
        (value === 2) &&
        <UserListAll 
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }

      {
        (value === 3) &&
        <CKBoxList
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }
      {
        (value === 4) &&
        <SimListAll
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }
      {
        (value === 5) &&
        <BankAccountListAll
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }
      
    </Paper>
  );
}