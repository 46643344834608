
import { ckTableStyles } from '../../../ckcore/ui/CkStyles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatAmount } from '../../../ckcore/core/TxUtils';
import ServiceTypes from '../../../ckcore/core/ServiceTypes';

export default function OrderProductTable(props) {
  const tableStyles = ckTableStyles();
  const orderObj = props.orderObj;
  const products = props.products;

  // fix previous tag name
  const orderPaid = (orderObj.order.order_paid) ? orderObj.order.order_paid : ((orderObj.order.amount) ? orderObj.order.amount : 0);
  const orderRemain = orderObj.amount - orderPaid;

  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>

        <TableHead>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="center" className={tableStyles.colNo} sx={cellBorder}><strong>STT</strong></TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>Tên sản phẩm</TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>Số lượng</TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>Giá (VNĐ)</TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>Chiết khấu</TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>Chiết khấu (%)</TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>VAT (%)</TableCell>
            <TableCell align="center" className={tableStyles.colHeaderStrong} sx={cellBorder}>Thành tiền</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            products.map((product, index) => (
              <TableRow key={index} style={{ height: 32 }}>
                <TableCell className={tableStyles.colNo} sx={cellBorder}>{index + 1}</TableCell>
                <TableCell className={tableStyles.colName} sx={cellBorder}>{product.code} - {product.name}</TableCell>
                <TableCell className={tableStyles.colQuantity} sx={cellBorder}>{product.quantity}</TableCell>
                <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(product.price)}</TableCell>
                <TableCell className={tableStyles.colQuantity} sx={cellBorder}>{product.discount_amount}</TableCell>
                <TableCell className={tableStyles.colQuantity} sx={cellBorder}>{product.discount}</TableCell>
                <TableCell className={tableStyles.colQuantity} sx={cellBorder}>{product.tax}</TableCell>
                <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(product.price * product.quantity)}</TableCell>
              </TableRow>
            ))
          }

          {(orderObj.serviceId === ServiceTypes.PURCHASE_REQUEST) ? (
            <></>
          ) : (
            <>
            {
              /*
              <TableRow style={{ height: 32 }}>
                <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Tổng</TableCell>
                <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderObj.amount)}</TableCell>
              </TableRow>
              <TableRow style={{ height: 32 }}>
                <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Phí vận chuyển</TableCell>
                <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderObj.fee)}</TableCell>
              </TableRow>
              <TableRow style={{ height: 32 }}>
                <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Chiết khấu</TableCell>
                <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderObj.discount)}</TableCell>
              </TableRow>
              <TableRow style={{ height: 32 }}>
                <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>VAT</TableCell>
                <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderObj.tax)}</TableCell>
              </TableRow>
              */
            }              
            </>
          )}

          <TableRow style={{ height: 32 }}>
            <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Tổng cộng</TableCell>
            <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderObj.amount)}</TableCell>
          </TableRow>
          <TableRow style={{ height: 32 }}>
            <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Đã thanh toán</TableCell>
            <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderPaid)}</TableCell>
          </TableRow>
          <TableRow style={{ height: 32 }}>
            <TableCell colSpan={7} align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Còn lại</TableCell>
            <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(orderRemain)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}