import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Colors from '../../ckcore/core/Colors';
import { Container, Paper } from '@mui/material';
import { Box } from '@mui/material';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';


export default function Guideline(props) {
  const classes = useStyles();

  // check completed steps
  const currentStep = (props.currentStep) ? props.currentStep : 0;
  const completed = new Set();
  for (var i = 0; i < currentStep; i++) {
    completed.add(i);
  }

  const [activeStep, setActiveStep] = React.useState(currentStep);

  const getSteps = () => {
    return ['Tạo tổ chức', 'Thêm tài khoản ngân hàng', 'Đăng ký biến động số dư', 'Tạo VietQR chuyển khoản', 'Xác nhận đơn tự động'];
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <StepOne refreshCallback={props.refreshCallback}/>;
      case 1:
        return <StepTwo refreshCallback={props.refreshCallback}/>;
      case 2:
        return <StepThree refreshCallback={props.refreshCallback}/>;
      case 3:
        return <StepFour refreshCallback={props.refreshCallback}/>;
      case 4:
        return <StepFive refreshCallback={props.refreshCallback}/>;
      default:
        return '';
    }
  }


  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  // refresh steps
  useEffect(() => {
    setActiveStep(currentStep);

  }, [currentStep]);

  return (
    <Container >

    <Paper className={classes.root}>

      <Box style={{textAlign: "center", paddingBottom: 20}}><h3>Hướng dẫn kết nối thanh toán chuyển khoản</h3></Box>

      <Stepper alternativeLabel nonLinear activeStep={activeStep} style={{ backgroundColor: "transparent" }}>
        {getSteps().map((label, index) => {
          const stepProps = {};
          const buttonProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div className={classes.guide}>
        {getStepContent(activeStep)}
      </div>
    </Paper>
    </Container>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guide: {
    paddingTop: 40,
    paddingBottom: 20
  },

  link: {
    cursor: 'pointer',
    color: Colors.DARK_BLUE,
    fontWeight: 'bold'
  }
}));
