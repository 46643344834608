import React from 'react';
import { Dialog } from '@mui/material';
import ChangePassword from '../../ckcore/account/ChangePassword';

/**
 * Wrap change password app seting
 */
export default function PasswordDialog(props) {
  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="password-dialog">
      <ChangePassword appId="PASSWORD"
          selectedIndex={props.selectedIndex}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.closeCallback} />
    </Dialog>
   
  );
}


