import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { isValidPassword } from '../core/Validator';
import Colors from '../core/Colors';
import Config from '../core/Config';
import TxStatus from '../core/TxStatuses';
import TxTypes from '../core/TxTypes';
import User from '../core/User';
import Actions from './Actions'
import ck_logo_round from '../images/ck_lgrb_256.png'
import { getMessageBox } from '../core/TxUtils';
import { Box } from '@mui/material';

export default function ChangePassword(props) {

    const classes = useStyles();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const message = (props.message) ? props.message : "";

    const [password, setPassword] = useState((props.password) ? props.password : "");
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState({ error: '', success: '', info: message });

    // input passwords
    const inputPassword = (text) => {
        setPassword(text);
        setMessages({ ...messages, error: '', success: '' });
    }

    const inputNewPassword = (text) => {
        setNewPassword(text);
        setMessages({ ...messages, error: '', success: '' });
    }

    const inputRetypePassword = (text) => {
        setRetypePassword(text);
        setMessages({ ...messages, error: '', success: '' });
    }

    /**
     * Process change password
     */
    const doChangePassword = async () => {

        // Check new password retype
        if (!isValidPassword(newPassword)) {
            setMessages({ ...messages, error: 'Mật khẩu mới không hợp lệ!', success: '' });
            return;
        } else if (retypePassword !== newPassword) {
            setMessages({ ...messages, error: 'Mật khẩu nhập lại không khớp!', success: '' });
            return;
        } else {
            setMessages({ ...messages, error: '', success: '' });
        }

        if (loading) {
            return;
        }

        setLoading(true);

        // request data
        var date = new Date();

        // account message
        const accountMsg = {
            txTypeId: TxTypes.TX_USER_CHANGE_PASSWORD,
            timestamp: date.getTime(),
            password: password,
            newPassword: newPassword,
            identity: User.getIdentity()
        };

        try {

            // Call server
            const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(accountMsg));
            const response = await rawResponse.json();
            if (response.statusId === TxStatus.SUCCESS) {

                setMessages({ ...messages, error: '', success: 'Đổi mật khẩu thành công' });

                setTimeout(() => {
                    // go back to login
                    if (!(props.appId)) {
                        props.actionCallback(Actions.SIGN_IN, { isLogin: true, token: '' });
                    } else {
                        props.refreshCallback();
                    }
                }, 2022);

            } else {
                console.log("Request change password error with statuses: " + response.statusId);
                setMessages({ ...messages, error: 'Đổi mật khẩu bị lỗi: ' + response.msg + '. Vui lòng kiểm tra lại.', success: '' });

            }
        } catch (error) {
            console.log("Error request change password: " + error);
        }

        setLoading(false);
    }

    // RETURN CHANGE PASSWORD
    return (

        <Container component="main" maxWidth="xs">

            <Box className={classes.paper}>

                <Grid container spacing={3} direction="column" justifyContent="space-around" alignItems="stretch">
                    <Grid item xs={12}>
                        <div className={classes.cklogo}>
                            <img src={ck_logo_round} alt="Chuyển Khoản" width="72" height="72" />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="primary" component="h1" variant="h5" >
                            ĐỔI MẬT KHẨU
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {getMessageBox(messages.error, messages.success, messages.info)}
                    </Grid>

                    {
                        !(props.password) &&
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField id="input-password" size="small" variant="outlined" fullWidth
                                    label="Mật khẩu hiện tại"
                                    type="password"
                                    inputProps={{ autoComplete: 'new-password' }}
                                    value={password}
                                    onInput={e => inputPassword(e.target.value)}
                                    helperText={!isValidPassword(password) && (messages.error.length > 0) ? "Mật khẩu hiện tại không hợp lệ" : ""}
                                    error={(messages.error.length > 0) && !isValidPassword(password)}
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid item xs={12}>

                        <Grid item xs={12}>
                            <TextField id="new-password" size="small" variant="outlined" fullWidth
                                label="Mật khẩu mới"
                                type="password"
                                inputProps={{ autoComplete: 'new-password' }}
                                value={newPassword}
                                onInput={e => inputNewPassword(e.target.value)}
                                helperText={!isValidPassword(newPassword) && (messages.error.length > 0) ? "Mật khẩu mới không hợp lệ" : ""}
                                error={(messages.error.length > 0) && !isValidPassword(newPassword)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="retype-password" size="small" variant="outlined" fullWidth
                            label="Nhập lại mật khẩu"
                            type="password"
                            inputProps={{ autoComplete: 'retype-password' }}
                            value={retypePassword}
                            onInput={e => inputRetypePassword(e.target.value)}
                            helperText={(retypePassword !== newPassword) && (messages.error.length > 0) ? "Mật khẩu nhập nhập lại không khớp" : ""}
                            error={(messages.error.length > 0) && !isValidPassword(retypePassword)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth
                            disabled={loading || (messages.success !== '')}
                            style={{ fontWeight: 'bold' }}
                            onClick={() => doChangePassword()}>ĐỔI MẬT KHẨU</Button>
                    </Grid>                

                    <Grid container item xs={12} direction="row" justifyContent="space-around" alignItems="center">
                        <Grid item >
                            {
                                !(props.appId) &&
                                <Link component="button" variant="body2" className={classes.textButton} onClick={() => props.actionCallback(Actions.SIGN_IN)}>
                                    Trở lại
                                </Link>
                            }
                        </Grid>
                        <Grid item >

                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container >

    );

}

const useStyles = makeStyles(theme => ({

    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 389,
        minWidth: 389,
        padding: 20,
        textAlign: 'center',
        margin: 'auto',
    },

    cklogo: {
        textAlign: 'center',
        display: 'block',
        margin: "auto",
    },

}));