
import React, { useEffect, useState } from 'react';
import { Hidden, ThemeProvider, StyledEngineProvider, Link, TextField, MenuItem, Button, Typography, SvgIcon, Checkbox, FormControlLabel } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';

import {
  getStatusColor,
  getTxStatusText,
  getTxMillisText,
  parseTxBean,
  getTxTimeText
} from '../../ckcore/core/TxUtils';
import { useInterval } from '../../ckcore/core/CkUtils';

import Config from '../../ckcore/core/Config';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import PopaConfig from './PopaConfig';
import { getBankSvgIcon } from '../../ckcore/core/CkBank';
import { SettingsInputCompositeRounded } from '@mui/icons-material';

function comparePartner(a, b) {

  if (a.rank < b.rank) {
    return -1;
  }

  if (a.rank > b.rank) {
    return 1;
  }

  return 0;
}

/**
 * List potential partners
 */
export default function PotentialPartnerList(props) {

  const classes = useStyles();


  const requestCategory = (props.queryObj) ? ((props.queryObj.category) ? props.queryObj.category : '') : '';
  const requestQuery = (props.queryObj) ? ((props.queryObj.query) ? props.queryObj.query : '') : '';

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [popasData, setPopasData] = useState({
    incs: [],
    domains: [],
    category: requestCategory,
    query: requestQuery,
    refreshing: false,
    saved: false,
  });

  const handleChangeQuery = (event) => {
    setPopasData({ ...popasData, "query": event.target.value });
  }

  const handleSelectCategory = (event) => {
    setPopasData({ ...popasData, "category": event.target.value });
  }

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(33);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 33));
    setPage(0);
  };

  /**
   * Search potential partners
   */
  const searchPopas = async (cachedTime, regex) => {

    try {

      if (loading) {
        return;
      }

      setMessage("Đang tìm đối tác..");

      // clear results
      setPopasData({ ...popasData, incs: [], domains: [] });

      // request message
      var ckmsg = {
        txType: "search",
        query: popasData.query,
        category: popasData.category,
        cachedTime: (cachedTime) ? cachedTime : 7 * 24 * 60 * 60 * 1000,
        regex: (regex) ? regex : false,
        count: 333,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        if (popasData.query) {
          setPopasData({ ...popasData, incs: response.incs, domains: response.domains, refreshing: true, saved: response.saved });
          setMessage("Số đối tác tìm được: " + response.domains.length + ", đã có thông tin: " + response.incs.length + ".. [đang quét internet]");
        } else {
          setPopasData({ ...popasData, incs: response.incs.sort(comparePartner), domains: response.domains, refreshing: true, saved: response.saved });
          setMessage("Số đối tác đã có thông tin: " + response.incs.length);
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR search popas, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error search popas: " + error);
    }

    setLoading(false);

  };

  const refreshSearchResults = async () => {

    try {

      if (!(popasData.query) || popasData.domains.length < 1 || !popasData.refreshing) {
        // console.log("Query is empty");
        return;
      }

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "refresh",
        query: popasData.query,
        domains: popasData.domains,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        if (popasData.domains.length === response.incs.length) {
          setPopasData({ ...popasData, incs: response.incs, refreshing: false, saved: response.saved });
          setMessage("Số đối tác tìm được: " + popasData.domains.length + ", đã có thông tin: " + response.incs.length);
        } else {
          setPopasData({ ...popasData, incs: response.incs, saved: response.saved });
          setMessage("Số đối tác tìm được: " + popasData.domains.length + ", đã có thông tin: " + response.incs.length + ".. [đang quét internet]");
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR refresh search popas, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error refresh search popas: " + error);
    }

    setLoading(false);

  };

  /**
   * Save search query
   */
  const saveSearchQuery = async () => {

    try {

      if (!(popasData.query) || popasData.domains.length < 1) {
        return;
      }

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "save",
        category: popasData.category,
        query: popasData.query,
        domains: popasData.domains,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setPopasData({ ...popasData, saved: response.saved });
      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR save search popas, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error save search popas: " + error);
    }

    setLoading(false);

  };

  /**
   * Set potential partner to priority partner
   */
  const setPriorityPopas = async (popaObj, index, statusId) => {

    try {

      var incs = [];
      if (popaObj) {
        popaObj.category = popasData.category;
        popaObj.statusId = (statusId) ? statusId : 1;
      } else {
        popasData.incs.map((incObj) => {
          if (incObj.checked && incObj.statusId < 1) {
            incObj.category = popasData.category;
            incs.push(incObj);
          }
        });
      }

      if (loading || (incs.length < 1 && !(popaObj))) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "update_priority",
        incs: incs,
        inc: popaObj,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        if (popaObj) {
          const newIncs = popasData.incs;
          popaObj.statusId = 1;
          newIncs[index] = popaObj;
          setPopasData({ ...popasData, incs: newIncs });
          setMessage("Đã cập nhật " + incs.length + " đối tác");
        } else {
          setPopasData({ ...popasData, refreshing: true });
          setMessage("Đã cập nhật lên priority cho " + incs.length + " đối tác");
        }


      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update priority popas, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error update priority popas: " + error);
    }

    setLoading(false);

  };


  /**
   * Recrawl potential partner to priority partner
   */
  const recrawlPopas = async (popaObj, index) => {

    try {

      if (loading || !(popaObj)) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "recrawl",
        inc: popaObj,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // update inc list
        const newIncs = popasData.incs;
        newIncs[index] = response.inc;

        setPopasData({ ...popasData, incs: newIncs });
        setMessage("Đã cập nhật " + popaObj.domain);
      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update priority popas, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error update priority popas: " + error);
    }

    setLoading(false);
  };

  /**
   * Set checked all partners
   */
  const setCheckedAllPartners = (checked) => {

    const incs = popasData.incs;
    incs.map((popaObj) => {
      popaObj.checked = checked;
    })

    setPopasData({ ...popasData, incs: incs });
  }

  /**
   * Set checked partner
   */
  const setCheckedPartner = (popaObj, index, checked) => {
    popaObj.checked = checked;
    const incs = popasData.incs;
    incs[index] = popaObj;

    setPopasData({ ...popasData, incs: incs });
  }

  /**
   * Has any checked partner?
   */
  const hasCheckedPartner = () => {

    var count = 0;
    popasData.incs.map((popaObj) => {
      if (popaObj.checked && popaObj.statusId < 1) {
        count++;
      }
    });

    if (count > 0) {
      return true;
    }

    return false;
  }


  /**
   * Get contact view
   */
  const getContacts = (popaObj, index) => {

    if (!(popaObj.contacts)) {
      return <div></div>
    }

    var phones = "";
    if ((popaObj.contacts.phones)) {
      phones = popaObj.contacts.phones.map(function (phone) {
        return phone;
      }).join(', ');
    }
    var emails = "";
    if ((popaObj.contacts.emails)) {
      emails = popaObj.contacts.emails.map(function (email) {
        return email;
      }).join(', ');
    }

    const facebook = (popaObj.contacts.facebook) ? popaObj.contacts.facebook : "";

    return (
      <div style={{ paddingTop: 6, paddingBottom: 3 }}>
        <Typography component="div" variant="caption" noWrap={true}>{phones}</Typography>
        <Typography component="div" variant="caption" noWrap={true}>{emails}</Typography>
        <Typography component="div" variant="caption" noWrap={true}><Link href={facebook} target="_blank">{facebook.replace("https://www.facebook.com/", "fb.com/")}</Link></Typography>
      </div>
    );
  }

  /**
   * Get account view
   */
  const getAccounts = (popaObj, index) => {

    if (!(popaObj.accounts)) {
      return <div></div>
    }

    const accounts = popaObj.accounts.filter((account, index) => index < 3);


    return (
      <div style={{ paddingTop: 6, paddingBottom: 3 }}>

        {
          accounts.map((bankAccount, index) => (
            <Typography key={index} component="div" variant="caption" noWrap={true} >

              <IconButton size="small" style={{ padding: 1 }}>
                <SvgIcon className={classes.svgIcon} component={getBankSvgIcon(bankAccount.bankName)} viewBox="0 0 48 48" />
              </IconButton>

              <span> {bankAccount.accountNumber}</span>
            </Typography>

          ))
        }
      </div>
    );
  }

  const getStatuses = (popaObj, index) => {

    const category = PopaConfig.getPopaCategories().find((category) => category.value === popaObj.category);
    const demand = PopaConfig.getPopaDemands().find((demand) => demand.value === popaObj.demand);
    const contactPoint = popaObj.contact;
    const contactStatus = ((category) ? category.label : "") + "-" + ((demand) ? demand.label : "") + "-" + ((contactPoint) ? contactPoint : "");

    return (
      <div style={{ paddingTop: 6, paddingBottom: 3 }}>
        <Typography component="div" variant="caption" noWrap={true}>{contactStatus}</Typography>
        <Typography component="div" variant="body" style={{ color: PopaConfig.getPopaStatus(popaObj.statusId).color }} >{popaObj.status}</Typography>
      </div>
    );
  }


  // Use interval to refresh bank data
  useInterval(() => {
    refreshSearchResults();
  }, 5000);

  // reload on refresh
  useEffect(() => {
    if ((requestQuery) || (requestCategory)) {
      searchPopas();
    }
  }, []);



  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} style={{ padding: 20, paddingBottom: 0 }}>

          <Grid item xs={12}>
            <Box p={1}>

            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-select-category" size="small" fullWidth
              style={{ marginTop: 0 }}
              select
              label="Lĩnh vực hoạt động"
              value={popasData.category}
              onChange={handleSelectCategory}>
              {PopaConfig.getPopaCategories().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              disabled={loading}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField id="partner-domain" size="small" variant="outlined" fullWidth
              label="Tìm đối tác"
              inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 16 } }}
              value={popasData.query}
              onChange={handleChangeQuery}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={2}>
            {
              <Button disabled={loading} variant="outlined" fullWidth onClick={() => searchPopas()}>Tìm đối tác</Button>
            }
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={12}>
            <Box p={1}>
              <span>{message}</span>
            </Box>
          </Grid>

        </Grid>

        <TableContainer className={classes.tableContainer} component={Paper} style={{ paddingLeft: 20, paddingRight: 20 }}>

          <Table className={classes.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <FormControlLabel control={<Checkbox onChange={(e) => setCheckedAllPartners(e.target.checked)} />} label="Chọn tất cả" />

                  <Button disabled={loading || !(popasData.query)} variant="outlined" size="small" style={{ marginLeft: 20 }} onClick={() => searchPopas(24 * 60 * 60 * 1000, true)} >Quét lại</Button>
                  <Button disabled={loading || !(popasData.query) || popasData.saved} variant="outlined" size="small" style={{ marginLeft: 20 }} onClick={() => saveSearchQuery()} >
                    {popasData.saved ? "Đã lưu" : "Lưu thông tin"}
                  </Button>
                  <Button disabled={loading || !hasCheckedPartner()} variant="outlined" size="small" style={{ marginLeft: 20 }} onClick={() => setPriorityPopas()} >+ Đối tác ưu tiên</Button>
                </TableCell>
                <TablePagination
                  rowsPerPageOptions={[33, 66, 99, 333]}
                  colSpan={4}
                  count={popasData.incs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Số ĐT' },
                    native: true,
                  }}
                  labelRowsPerPage="Xem:"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
              <TableRow>
                <TableCell className={classes.colCheck} align="center">Chọn</TableCell>
                <TableCell className={classes.colId} align="center">IncId/Domain</TableCell>
                <TableCell className={classes.colName} align="left">Rank</TableCell>
                <TableCell className={classes.colContact} align="left">Contacts</TableCell>
                <TableCell className={classes.colAccount} align="left">Accounts</TableCell>
                <TableCell className={classes.colStatus} align="left">Statuses</TableCell>
                <TableCell className={classes.colStatus} align="left">Ticket</TableCell>
                <TableCell className={classes.colTime} align="left">Time</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? popasData.incs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : popasData.incs
              ).map((incObj, index) => (
                <TableRow key={index} style={{ height: 40, overflow: 'hidden' }}>
                  <TableCell className={classes.colCheck} align="center">
                    <Checkbox size="small"
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={((incObj.checked !== 'undefined') && (incObj.checked === true)) || (incObj.statusId > 0)}
                      disabled={(incObj.statusId !== 'undefined') && (incObj.statusId > 0)}
                      onChange={(e) => setCheckedPartner(incObj, index, e.target.checked)} />
                  </TableCell>
                  <TableCell className={classes.colId} align="left">
                    <Link href={"http://" + incObj.domain} target="_blank">{incObj.domain}</Link>
                    <br />{incObj.incId}
                  </TableCell>
                  <TableCell className={classes.colName} align="left">{incObj.rank}</TableCell>
                  <TableCell className={classes.colContact} align="left">{getContacts(incObj)}</TableCell>
                  <TableCell className={classes.colAccount} align="left">{getAccounts(incObj)}</TableCell>
                  <TableCell className={classes.colStatus} align="left" >
                    {getStatuses(incObj, index)}
                    {PopaConfig.getPopaStatus(incObj.statusId).label}<br />
                  </TableCell>
                  <TableCell className={classes.colTicket} align="left" style={{ color: PopaConfig.getPopaStatus(incObj.statusId).color }} >
                    {
                      (incObj.statusId > 0) &&
                      <div>
                        {incObj.assignee}<br />
                        <Link href={"https://jira.chuyenkhoan.vn/browse/" + incObj.ticket} target="_blank">{incObj.ticket}</Link>
                      </div>
                    }
                    {
                      (incObj.statusId < 1) &&
                      <Button disabled={loading} variant="text" size="small" onClick={() => setPriorityPopas(incObj, index, 1)} >+PRIORITY</Button>
                    }
                    {
                      (incObj.statusId < 1) &&
                      <Button disabled={loading} variant="text" size="small" onClick={() => setPriorityPopas(incObj, index, 4)} >-IGNORE</Button>
                    }
                  </TableCell>
                  <TableCell className={classes.colTime} align="left">
                    {getTxTimeText(incObj.updated)}
                    {
                      (incObj.statusId !== 4) &&
                      <Button disabled={loading} variant="text" size="small" onClick={() => recrawlPopas(incObj, index)} >RECRAWL</Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </ThemeProvider>
    </StyledEngineProvider>
  );
}



const theme = createTheme({
  // spacing: 3,
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation.
        padding: '2px 6px',
      },
      sizeSmall: {
        padding: '2px 3px 2px 3px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
  },

  alert: {
    marginBottom: 12,
  },

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
  },

  transHead: {
    minHeight: 22,
    padding: '3px 3px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
    width: 200,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

  tableContainer: {
    marginTop: 0,
  },

  table: {
    marginTop: 0,
    marginBottom: 16,
  },

  row: {
    overflow: 'hidden',
    height: 40,
    maxHeight: 48,
    minHeight: 40,
    fontSize: 11,
  },

  colCheck: {
    textAlign: 'center',
    padding: '2px 3px',
    fontSize: 12,
  },

  colId: {
    minWidth: 80,
    maxWidth: 120,
    padding: '2px 3px',
    fontSize: 12,
  },

  colDomain: {
    minWidth: 120,
    maxWidth: 120,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },

  colName: {
    minWidth: 120,
    maxWidth: 120,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },

  colContact: {
    minWidth: 120,
    maxWidth: 120,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },

  colAccount: {
    minWidth: 120,
    maxWidth: 120,
    textAlign: 'left',
    padding: '2px 6px',
    fontSize: 12,
    overflow: 'hidden',
  },


  colTime: {
    minWidth: 120,
    maxWidth: 120,
    padding: '2px 3px',
    fontSize: 12,
  },

  colTxBody: {
    minWidth: 333,
    maxWidth: 360,
    padding: '2px 3px',
    fontSize: 12,
  },
}));
