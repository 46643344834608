import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

// import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlinedIcon';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import { Button, Divider, MenuList, Typography } from '@mui/material';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import ck_logo_white from '../../ckcore/images/chuyenkhoan_logo_white.png'
import ck_bg from '../../ckcore/images/chuyenkhoan_bg.svg'
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import Roles, { getRoleName, getRoleStatus } from '../../ckcore/core/Roles';
import { getStatusColor, getTxTimeText } from '../../ckcore/core/TxUtils';
import { getSubscriptionPackageName } from '../subscription/PricingTable';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Sort roles by ID
 */
function compare(roleA, roleB) {
  if (roleA.partnerId < roleB.partnerId) {
    return -1;
  }
  if (roleA.partnerId > roleB.partnerId) {
    return 1;
  }
  return 0;
}

export default function CkAppBar(props) {

  const classes = useStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const userRoles = userObj.roles.sort(compare);

  const largeScreen = useMediaQuery('(min-width:960px)');

  const [partners, setPartners] = useState({});
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [showPartnerAccount, setShowPartnerAccount] = React.useState(false);
  const [showActionList, setShowActionList] = React.useState(false);
  const [showPartnerList, setShowPartnerList] = React.useState(false);

  // User account menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
    setShowActionList(false);
    setShowPartnerAccount(false);
    setShowPartnerList(false);
  };

  const handleActionOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setShowActionList(true);
  };

  const handlePartnerAccountOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setShowPartnerAccount(true);
  };


  const handlePartnerOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if ((partners[userObj.partnerId.toString()])) {
      setShowPartnerList(true);
    } else {
      loadPartnerList();
    }
  }

  // Close menu and show app
  const requestShowApp = (appId, action) => {
    handleMenuClose();
    props.showApp(appId, action);
  }

  /**
   * Load partner request list
   */
  const loadPartnerList = async () => {

    if (loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const ckc = {
      txTypeId: TxTypes.TX_USER_LIST_PARTNER,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setPartners(response.partners);
        setShowPartnerList(true);
        console.log("Partner list loaded");
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Load partner list error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load partner list: " + error);
    }

    setLoading(false);
  }


  /**
   * User ACTIVE role
   */
  const activeRole = async (role) => {
    if (loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const ckc = {
      txTypeId: TxTypes.TX_USER_ACTIVE_ROLE,
      role: role,
      partnerId: role.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        // hide menu
        setShowPartnerList(false);
        console.log("Set active partnerId: " + role.partnerId);
        props.refreshCallback();
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Active role error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error active role: " + error);
    }

    setLoading(false);
  }

  /**
 * Get partner short name
 */
  const getPartnerShortName = (partnerName) => {

    if (!(partnerName)) {
      return '';
    }

    if (!largeScreen && partnerName.length > 6) {
      return partnerName.substring(0, 6) + "..";
    }

    return partnerName;
  }

  /*
    Hidden note:

        innerWidth  |xs      sm       md       lg       xl
                    |--------|--------|--------|--------|-------->
        width       |   xs   |   sm   |   md   |   lg   |   xl

        smUp        |   show | hide
        mdDown      |                     hide | show
  */

  // RETURN VIEW
  return (
    <AppBar position="fixed" className={classes.appBar}>

      <Toolbar style={{ minHeight: 48 }}>

        {
          (userObj.partnerId > 0) ? (
            <Box className={classes.partnerName} >
              {
                (partnerObj.logo) &&
                <img className={classes.partnerLogo} src={partnerObj.logo} alt={partnerObj.name} onClick={() => requestShowApp("MEMBERS")} />
              }
              <Typography style={{ paddingTop: 3 }} variant="h6" display="inline" onClick={() => requestShowApp("MEMBERS")}>
                {getPartnerShortName(partnerObj.name)}
              </Typography>
              <span>
                {
                  (userRoles.length > 1) &&
                  <IconButton size="normal" onClick={handlePartnerOpen} style={{ color: Colors.BG_WHITE }}>
                    <ArrowDropDownOutlinedIcon />
                  </IconButton>
                }
                {
                  (anchorEl) &&
                  <Menu anchorEl={anchorEl} open={showPartnerList} onClose={handleMenuClose}>
                    {
                      userRoles.map((role, index) => (
                        (partners[role.partnerId.toString()]) &&
                        <MenuItem key={index} onClick={() => activeRole(role)}>
                          {role.partnerId} - {partners[role.partnerId.toString()].name}
                        </MenuItem>
                      ))
                    }
                  </Menu>
                }
              </span>

            </Box>

          ) :
            (
              <img className={classes.logo} onClick={() => requestShowApp("MEMBERS")} src={ck_logo_white} alt="Chuyển Khoản" />
            )
        }

        <Box className={classes.userProfile}>

          {
            (partnerObj.subscription) && (partnerObj.subscription.package === 'large') &&
            <Button variant="outlined" size="small" color="action"
            style={{ marginRight: 20 }}
            startIcon={<AddOutlinedIcon />}
            onClick={handleActionOpen} ><Hidden mdDown>Tạo yêu cầu</Hidden></Button>
          }
          
          <Button variant="outlined" size="small" color="secondary"
            style={{ marginRight: 12 }}
            startIcon={<CardMembershipOutlinedIcon />}
            onClick={handlePartnerAccountOpen} ><Hidden mdDown>CKCOIN: </Hidden>{(partnerObj.ckCoin) ? partnerObj.ckCoin : 0}</Button>

          <IconButton color="inherit" aria-label="logout" onClick={handleMenuOpen} size="large">
            <AccountCircleIcon />
          </IconButton>
        </Box>

        {
          (anchorEl) &&
          <Menu id="ck-account-menu" anchorEl={anchorEl} keepMounted open={menuOpen} onClose={handleMenuClose}>
            <MenuItem style={{ whiteSpace: 'normal' }}>
              <Box>
                <Box><strong>{userObj.name}</strong></Box>
                <Box>SĐT: {getDisplayPhone(userObj.phone)}</Box>
                <Box>Email: {userObj.email}</Box>
                <Divider style={{ marginTop: 8 }} />
              </Box>
            </MenuItem>
            <MenuItem onClick={() => requestShowApp("PROFILE")}><span style={{ color: Colors.DARK_BLUE }}>Sửa thông tin</span></MenuItem>
            <MenuItem onClick={() => requestShowApp("PASSWORD")}><span style={{ color: Colors.DARK_BLUE }}>Đổi mật khẩu</span></MenuItem>
            <MenuItem onClick={() => props.openLogoutDialog()}><span style={{ color: Colors.ORANGE }}>Đăng xuất</span></MenuItem>
          </Menu>
        }

        {
          (anchorEl) &&
          <Menu id="ck-action-menu" anchorEl={anchorEl} keepMounted open={showActionList} onClose={handleMenuClose}>

            {/*
            <MenuItem onClick={() => requestShowApp("BANKS", "addAccount")}>Thêm tài khoản ngân hàng</MenuItem>
            <MenuItem onClick={() => requestShowApp("CKBOX", "registerSim")}>Đăng ký CK SIM</MenuItem>
             */}

            <MenuItem onClick={() => requestShowApp("ORDERS", "createSaleOrder")}><span style={{ color: Colors.DARK_BLUE }}>Tạo đơn bán hàng</span></MenuItem>
            <MenuItem onClick={() => requestShowApp("ORDERS", "paymentRequest")}><span style={{ color: Colors.DARK_BLUE }}>Yêu cầu thanh toán</span></MenuItem>
            <MenuItem onClick={() => requestShowApp("ORDERS", "createPurchaseOrder")}><span style={{ color: Colors.DARK_BLUE }}>Tạo đơn mua hàng</span></MenuItem>
            <MenuItem onClick={() => requestShowApp("ORDERS", "purchaseRequest")}><span style={{ color: Colors.DARK_BLUE }}>Yêu cầu chi phí</span></MenuItem>
          </Menu>
        }

        {
          (anchorEl) &&
          <Menu id="ck-account-menu" anchorEl={anchorEl} keepMounted open={showPartnerAccount} onClose={handleMenuClose}>
            <MenuItem style={{ whiteSpace: 'normal' }}>
              <Box>
                <Box><strong>Gói {(partnerObj.subscription) ? getSubscriptionPackageName(partnerObj.subscription.package) : 'Dùng thử'}</strong></Box>
                <Box style={{ fontSize: 16 }}>Hạn dùng: {(partnerObj.subscription) ? moment(partnerObj.subscription.expired).format('DD/MM/YYYY') : '02/09/2202'}</Box>
                <Box style={{ fontSize: 16 }}>Còn lại {(partnerObj.ckCoin) ? partnerObj.ckCoin : 0} CKCoin</Box>
                <Divider style={{ marginTop: 8 }} />
              </Box>

            </MenuItem>
            <MenuItem onClick={() => requestShowApp("MEMBERS", "upgrade")}>
              <span style={{ color: Colors.DARK_BLUE }}>Gia hạn dịch vụ</span>
            </MenuItem>
            {
             (partnerObj.subscription.package) && 
             <MenuItem onClick={() => requestShowApp("MEMBERS", "ckcoin")}>
                <span style={{ color: Colors.DARK_BLUE }}>Mua thêm CKCoin</span>
              </MenuItem>
            }
           
            <MenuItem onClick={() => { }}>
              <span style={{ color: Colors.DARK_BLUE }}>Gửi góp ý / hỗ trợ</span>
            </MenuItem>
          </Menu>
        }

      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((homeTheme) => ({


  appBar: {
    zIndex: homeTheme.zIndex.drawer + 1,
    transition: homeTheme.transitions.create(["width", "margin"], {
      easing: homeTheme.transitions.easing.sharp,
      duration: homeTheme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#37517f",
    backgroundImage: `url(${ck_bg})`,
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundOrigin: "center",
  },


  logo: {
    height: 40,
    cursor: "pointer"
  },

  partnerName: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    whiteSpace: "nowrap"
  },

  partnerLogo: {
    height: 24,
    width: 24,
    margin: 'auto',
    marginRight: 8,
  },

  userProfile: {
    marginLeft: 'auto',
  }

}));