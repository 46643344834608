import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { getRoleName } from '../../ckcore/core/Roles';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import { getMessageBox } from '../../ckcore/core/TxUtils';


export default function RemoveRoleDialog(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const staffObj = props.staffObj;

  let partnerName = (partnerObj) ? partnerObj.name : "";

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState({ success: '', error: '', info: '' });

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  /**
   * Admin remove staff (suppended)
   */
  const processRemoveStaff = async (txTypeId) => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_STAFF_REMOVE,
      partnerId: userObj.partnerId,
      staff: staffObj,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      if (loading) {
        return;
      }

      setMessages({ ...messages, error: '', changed: false });
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setMessages({ ...messages, error: '', success: "Xóa thành viên thành công" });

        setTimeout(() => {
          handleClose();
        }, 2022);
        // reset partner info
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({ ...messages, error: "Xóa thành viên bị lỗi, vui lòng thử lại sau" });
      }

    } catch (error) {
      console.log("Error... " + error);
      setMessages({ ...messages, error: "Xóa thành viên bị lỗi, vui lòng thử lại sau" });
    }

    setLoading(false);
  };


  // Add bank dialog
  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Xóa thành viên {partnerName}</DialogTitle>
      <DialogContent dividers>

      {
        getMessageBox(messages.error, messages.success, '')
      }

      <Box p={2}>
        Bạn muốn xoá thành viên {staffObj.name}, vai trò: {getRoleName(staffObj.roleId)}?
      </Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Trở lại
        </Button>
        <Button disabled={loading || messages.success !== ''} onClick={() => processRemoveStaff()} variant="outlined" color="warning">
          Đồng ý
        </Button>
      </DialogActions>
    </Dialog>
  );
}
