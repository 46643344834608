import { adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import Autocomplete from '@mui/material/Autocomplete';

import { getMessageBox, getHttpStatusText } from '../../ckcore/core/TxUtils';
import ValUtils from '../../ckcore/core/ValUtils';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import Config from '../../ckcore/core/Config';
import User from '../../ckcore/core/User';
import PaymentStatusList from '../../payment/components/PaymentStatusList';
import PaymentStatus from '../../payment/components/PaymentStatus';
import {isValidOrderId, isValidCustomerCode, isValidNameUTF8} from '../../ckcore/core/Validator';
import { getBankSvgIcon } from '../../ckcore/core/CkBank';
import { ckTheme } from '../../ckcore/ui/CkStyles';
import { ckApiStyles } from './ApiStyles';


export default function ConfirmApiTestDialog(props) {

  const classes = ckApiStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const appId = props.appId;

  const testTxObj = {
    accountNumber: props.bankAccounts[0].accountNumber,
    bankName: props.bankAccounts[0].bankName,
    balance: 110000000000,
    amount: 11000,
    receiver: "0900000000",
    paymentStatus: PaymentStatus.SUCCESS,
    customerId: "KH007",
    customerPhone: "0911111111",
    orderId: "TEST.ORDER001",
    orderInfo: "TEST thanh toan don hang",
  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [txObj, setTxObj] = useState(testTxObj);
  const handleChange = name => event => {

    var val = event.target.value;
    if(name === 'balance' || name === 'amount') {
      val = val.replace(/\D/g, "");
    }

    console.log("Set name: " + name + " = " + val);

    setTxObj({ ...txObj, [name]: val });
    setLoading(false);
    setEditing(true);
  };
  const handleChangeOption = (name, val) => {
    
    console.log("Set option name: " + name + " = " + val);

    if(val === PaymentStatus.PAYMENT_DEBIT) {
      setTxObj({ ...txObj, [name]: val,  amount: -Math.abs(txObj.amount)});
    } else {
      setTxObj({ ...txObj, [name]: val,  amount: Math.abs(txObj.amount)});
    }

    setLoading(false);
    setEditing(true);
  }

  const handleChangeBank = (accountNumber, bankName) => {
    setTxObj({ ...txObj, accountNumber: accountNumber, bankName: bankName});
    setLoading(false);
    setEditing(true);
  }

  /**
   * Request test CKQR
   */
   const requestTestConfirmAPI = async () => {

    // refresh message
    const cka = {
      txType: "testAppConfig",
      timestamp: new Date().getTime(),
      partnerId: userObj.partnerId,
      appId: appId,
      txObj: txObj,
      identity: User.getIdentity(),      
    };

    // Call server
    try {

      setSuccessMessage("");
      setErrorMessage("");

      // validate txobj
      if(!isValidOrderId(txObj.orderId)) {
        setErrorMessage('Mã đơn hàng không hợp lệ');
        return;
      }
      if(!isValidCustomerCode(txObj.customerId)) {
        setErrorMessage('Mã khách hàng không hợp lệ');
        return;
      }

      if(!isValidNameUTF8(txObj.orderInfo)) {
        setErrorMessage('Nội dung chuyển không hợp lệ');
        return;
      }

      setLoading(true);
      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS || response.statusId === TxStatus.SUCCESS) {


        setTxObj(response.txObj);       
        if(response.txObj.txStatusId === TxStatus.HTTP_SUCCESS) {
          setSuccessMessage("Test API thành công");
        } else {
          setErrorMessage("Test API bị lỗi, mã lỗi: " + response.txObj.txStatusId);
        }
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMessage("Gửi test API bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMessage("Gửi test API bị lỗi, vui lòng thử lại sau.")
      console.log("Error update API config " + error);
    }

    setLoading(false);

  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Test API xác nhận giao dịch</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>

              {
                loading && 
                <Typography variant="body1" style={{color: Colors.ORANGE, textAlign: "center"}}>Đang kết nối..</Typography>
              }

              {
                !loading &&
                getMessageBox(errorMessage, successMessage, "CKPay sẽ gọi đến API " + partnerObj.name)
              }
            </Box>

            <Grid container spacing={2}>

              <Grid item xs={8}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Địa chỉ endpoint (webhook)"
                  value={props.apiConfig.endpoint.key}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Giao thức"
                  value={props.apiConfig.endpoint.value}
                  disabled={true}
                />
              </Grid>

              

              <Grid item xs={6}>
                <Autocomplete id="payment-status" disableClearable
                    options={PaymentStatusList.options}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, option) => handleChangeOption('paymentStatus', option.value)}
                    renderInput={(params) =>
                       <TextField {...params} type="text" label="Trạng thái giao dịch" variant="outlined" fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        margin="dense" className={classes.textInput}
                    />}
                  />              
              </Grid>

              
              <Grid item xs={6}>
                <Autocomplete id="bank-list" disableClearable 
                  options={props.bankAccounts}
                  getOptionLabel={(bankAccount) => bankAccount.accountNumber}
                  isOptionEqualToValue={(option, bankAccount) => option.accountNumber === bankAccount.accountNumber}
                  onChange={(event, bankAccount) => handleChangeBank(bankAccount.accountNumber, bankAccount.bankName)}
                  value={{accountNumber: txObj.accountNumber}}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" fullWidth
                      label="Số tài khoản nhận"                  
                      className={classes.textfieldBankName}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small" >
                              <SvgIcon component={getBankSvgIcon(txObj.bankName)} viewBox="0 0 48 48" />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}/>
                )}/>

              </Grid>

              <Grid item xs={6}>
                <TextField type="tel" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Tài khoản nhận"
                  value={"Mặc định"}
                  onChange={handleChange('accountNumber')}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="tel" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Số dư tài khoản"
                  value={ValUtils.formatMoney(txObj.balance)}
                  onChange={handleChange('balance')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="tel" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Số tiền chuyển"
                  value={ValUtils.formatMoney(txObj.amount)}
                  onChange={handleChange('amount')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Mã đơn"
                  value={txObj.orderId}
                  onChange={handleChange('orderId')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Mã khách hàng"
                  value={txObj.customerId}
                  onChange={handleChange('customerId')}
                />
              </Grid>


              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Nội dung chuyển"
                  value={txObj.orderInfo}
                  onChange={handleChange('orderInfo')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label={"Trạng thái " + partnerObj.name + " trả về"}
                  value={(txObj.txStatusId) ? getHttpStatusText(txObj.txStatusId) : "?"}
                  style={{color: txObj.txStatusId === TxStatus.HTTP_SUCCESS ? Colors.DARK_GREEN : Colors.RED}}
                  inputProps={
                    { 
                      readOnly: true, 
                      color: txObj.txStatusId === TxStatus.HTTP_SUCCESS ? Colors.DARK_GREEN : Colors.RED,
                    }
                  }
                />
              </Grid>

            </Grid>
            <Box p={1}>
              <Typography variant="subtitle1">Lưu ý: Giao dịch test luôn có chữ test trong order_id và order_info</Typography>
            </Box>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button disabled={loading || !editing} onClick={() => requestTestConfirmAPI()} color="primary">
              Gửi xác nhận
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
