import PaymentStatus from './PaymentStatus';

const PaymentStatusList = {
    options: [
        {value: PaymentStatus.PAYMENT_SUCCESS, name: "ck.success"},
        {value: PaymentStatus.PAYMENT_DOUBLE_PAY, name: "ck.double.pay"},
        {value: PaymentStatus.PAYMENT_OVER_FUND, name: "ck.over.fund"},
        {value: PaymentStatus.PAYMENT_INSUFFICIENT_FUND, name: "ck.insufficient.fund"},
        {value: PaymentStatus.PAYMENT_DEBIT, name: "ck.debit"},
        {value: PaymentStatus.PAYMENT_ORDER_NOT_FOUND, name: "ck.order.not.found"},
        {value: PaymentStatus.PAYMENT_BANK_ACCOUNT_NOT_FOUND, name: "ck.bank.account.not.found"},
        {value: PaymentStatus.PAYMENT_TIMEOUT, name: "ck.timeout"},
        {value: PaymentStatus.PAYMENT_CANCEL, name: "ck.cancel"},
        {value: PaymentStatus.PAYMENT_IGNORE, name: "ck.ignore"},
        {value: PaymentStatus.PAYMENT_MANUAL_CONFIRM, name: "ck.manual.confirm"}
    ]
}

export default PaymentStatusList;
