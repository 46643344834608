import Colors from "../../ckcore/core/Colors";

var PopaConfig = (function () {

    // Popa Server URL
    const serverURL = "https://dev.chuyenkhoan.com/popa?";
    const getServerURL = function () {
      return serverURL;
    };

    const popa_categories = [
      {
        value: '',
        label: 'Chưa phân loại'
      },
      {
        value: 'thiet_ke_website',
        label: 'Thiết kế website'
      },
      {
        value: 'thiet_ke_app',
        label: 'Thiết kế app'
      },
      {
        value: 'hoc_truc_tuyen',
        label: 'Học trực tuyến'
      },
      {
        value: 'website_ban_hang',
        label: 'Website bán hàng'
      },
      {
        value: 'bao_chi_sach',
        label: 'Báo chí, sách, diễn đàn'
      },
      {
        value: 'giai_tri',
        label: 'Giải trí, game online'
      },
      {
        value: 'vien_thong',
        label: 'Dịch vụ viễn thông'
      },
      {
        value: 'tai_chinh',
        label: 'Tài chính, ngân hàng'
      },
      {
        value: 'kham_chua_benh',
        label: 'Khám, chữa bệnh'
      },
      {
        value: 'rao_vat',
        label: 'Rao vặt'
      },
      {
        value: 'viec_lam',
        label: 'Việc làm'
      },
      {
        value: 'bat_dong_san',
        label: 'Bất động sản'
      },
      {
        value: 'xe_co',
        label: 'Xe cộ'
      },
      {
        value: 'do_dien_tu',
        label: 'Đồ điện tử, điện lạnh'
      },
      {
        value: 'do_gia_dung',
        label: 'Đồ gia dụng, nội thất'
      },
      {
        value: 'thuc_pham',
        label: 'Đồ ăn, thực phẩm'
      },
      {
        value: 'thoi_trang',
        label: 'Thời trang'
      },
      {
        value: 'van_phong_pham',
        label: 'Văn phòng phẩm'
      },
      {
        value: 'me_va_be',
        label: 'Mẹ và bé'
      },
      {
        value: 'do_choi',
        label: 'Đồ chơi, thú cưng'
      },
      {
        value: 'du_lich',
        label: 'Du lịch, khách sạn, nhà hàng'
      },
      {
        value: 'tu_thien',
        label: 'Từ thiện, phi lợi nhuận'
      }
    ];

    const getPopaCategories = function () {
      return popa_categories;
    }

    const popa_demands = [
      {
        value: '',
        label: 'Tất cả'
      },
      {
        value: 'checkout',
        label: 'Thanh toán đơn'
      },
      {
        value: 'topup',
        label: 'CK Topup'
      },
      {
        value: 'ckqr_website',
        label: 'CKQR cho website'
      },
      {
        value: 'ckqr_app',
        label: 'CKQR cho apps'
      },
      {
        value: 'ckqr_store',
        label: 'CKQR cửa hàng'
      },
      {
        value: 'transparency',
        label: 'Minh bạch'
      }
    ];

    const getPopaDemands = function () {
      return popa_demands;
    }
    
    const popa_users = [
      {
        value: 'huy',
        label: 'huy',
      },
      {
        value: 'cham',
        label: 'cham',
      },
      {
        value: 'thuynguyen',
        label: 'thuy',
      },
      {
        value: 'cskh',
        label: 'cskh1',
      },
      {
        value: "cskh2",
        label: 'cskh2',
      }
    ];

    const getPopaUsers = function () {
      return popa_users;
    }

    const popa_statuses = [
        {
          value: -1,
          label: 'Tất cả',
          color: Colors.DARK
        },
        {
          value: 0,
          label: 'NEW',
          color: Colors.DARK
        },
        {
          value: 1,
          label: 'PRIORITY',
          color: Colors.DARK_BLUE
        },
        {
          value: 2,
          label: 'BANKED',
          color: Colors.YELLOW
        },
        {
          value: 3,
          label: 'CALLED',
          color: Colors.ORANGE
        },
        {
          value: 4,
          label: 'IGNORED',
          color: Colors.GRAY
        },
        {
          value: 5,
          label: 'CONNECTED',
          color: Colors.DARK_GREEN
        }
      ];

    const getPopaStatuses = function () {
        return popa_statuses;
    }

    const getPopaStatus = function (statusId) {

      const status = popa_statuses.find((status) => status.value === statusId);
      if(status) {
        return status;
      } else {
        return popa_statuses[0];
      }
    }

    const getPopaCachedTime = function () {
      return [
        { value: 0, label: 'chạy mới' },
        { value: 1, label: '01 ngày' },
        { value: 3, label: '03 ngày' },
        { value: 7, label: '07 ngày' },
        { value: 15, label: '15 ngày' },
        { value: 30, label: '30 ngày' },
      ];
    }
  
    // Return declare functions
    return {
      getServerURL: getServerURL,
      getPopaStatuses: getPopaStatuses,
      getPopaStatus: getPopaStatus,
      getPopaUsers: getPopaUsers,
      getPopaCategories: getPopaCategories,
      getPopaDemands: getPopaDemands,
      getPopaCachedTime: getPopaCachedTime
    };
  })();
  
  export default PopaConfig;
  