import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
//  change local color
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { isValidUrl, isValidName, isValidRegex } from '../../ckcore/core/Validator';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import User from '../../ckcore/core/User';
import Colors from '../../ckcore/core/Colors';

/**
 * SMS for automatic confirm order
 */
export default function SmsHttpConfig(props) {
  const classes = useStyle();
  
  const userObj = props.userObj;
  // const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const appId = props.appId;

  const [smsConfig, setSmsConfig] = useState({});
  const [smsChanged, setSmsChanged] = useState(false);
  const [changedValue, setChangedValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleChangeKey = (field) => event =>  {
    smsConfig[field]["key"] = event.target.value;
    setSmsChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  const handleChangeValue = (field)  => event =>  {
    smsConfig[field]["value"] = event.target.value;
    setSmsChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  // Validate all key
  const validateKeys = () => {

    var allValid = true;

    smsConfig.fields.map((field) => {
      var fieldObj = smsConfig[field];

      smsConfig[field]["error"] = false;

      // Validate end point and key
      if(field === 'endpoint') {
        if(!isValidUrl(fieldObj["key"])) {
          smsConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid endpoint: " + fieldObj["key"]);
        }
      } else if(!isValidName(fieldObj["key"])) {
        smsConfig[field]["error"] = true;
        allValid = false;
      } else {
        smsConfig[field]["error"] = false;
      }

      // validate value
      if((fieldObj["value"])) {
        if(!isValidRegex(fieldObj["value"])) {
          smsConfig[field]["regexError"] = true;
          allValid = false;
        } else {
          smsConfig[field]["regexError"] = false;
        }
      }


    });

    setChangedValue(!changedValue);

    return allValid;
  }


  const submitForm = (event) => {

    event.preventDefault();
    event.stopPropagation();
    
    setSuccessMsg("");
    setErrorMsg("");

    if(validateKeys()) {
      // do update API config
      processUpateSmsConfig();
    } else {
      setErrorMsg("Giá trị không hợp lệ, vui lòng kiểm tra lại");
    }

  }

  // load confirm API config
  const loadAppConfig = async () => {

    setSuccessMsg("");
    setErrorMsg("");

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setSmsConfig(response.appConfig);
        // console.log("Loaded SMS config: " + JSON.stringify(response.appConfig));
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);


  // Update API config
  const processUpateSmsConfig = async () => {

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      appId: appId,
      appConfig: smsConfig,      
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),      
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        console.log("update done");

        
        setSmsChanged(false);
        setSuccessMsg("Cập nhật API SMS thành công");
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMsg("Cập nhật API SMS bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMsg("Cập nhật API bị lỗi, vui lòng thử lại sau.")
      console.log("Error update API config " + error);
    }
  }

  // Test API config
  const processTestSmsConfig = async () => {

    // Build message
    const cka = {
      txType: "testAppConfig",
      timestamp: new Date().getTime(),
      smsConfig: smsConfig,
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cka=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        console.log("test done");
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error restore API config: " + error);
    }
  }

  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = smsConfig[field];

    return (
      <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
        <Box className={classes.fieldNameStyle}>
          {fieldObj["name"]}:
        </Box>
        <Box className={classes.fieldKeyStyle}>
          {
            getFieldConfigKey(field)
          }
        </Box>
        <Box className={classes.fieldValueStyle}>
          {
            getFieldConfigValue(field)
          }         
        </Box>        
      </Box>
    );
  }

  /**
   * Get field config key
   */
  const getFieldConfigKey = (field) => {

    const fieldObj = smsConfig[field];
    const isError = (smsConfig[field]["error"]);

    // Endpoint POST or Get
    if(field === "endpoint") {
      return <TextField
              error={isError}
              type="text"
              className={classes.textFieldKey}
              inputProps={{min: 0, style: { textAlign: 'left' }}}
              fullWidth
              size="small"
              variant="outlined"
              defaultValue={fieldObj["key"]}
              margin="dense"
              label={fieldObj["guide"]}
              onChange={handleChangeKey(field)}
            />
    } else if(field === "secretKey") {

      return (
        <TextField
                error={isError}
                className={classes.textFieldKey}
                fullWidth
                size="small"
                margin="dense"
                variant="outlined"
                defaultValue={fieldObj["key"]}
                label={(fieldObj["value"] === "secret_key") ? fieldObj["guide"] : "Mật khẩu"}
                onChange={handleChangeKey(field)}
                type={showPassword ? "text" : "password"}
                disabled={(fieldObj["disabled"])}
                inputProps={{ autoComplete: 'new-password' }}
                InputProps={{      
                  min: 0, 
                  style: { textAlign: 'left' }, 
                  autoComplete: 'new-password',         
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
      );
    }    

    return <TextField
            error={isError}
            type="text"
            className={classes.textFieldKey}
            inputProps={{min: 0, style: { textAlign: 'right' }}}
            fullWidth
            size="small"
            variant="outlined"
            defaultValue={fieldObj["key"]}
            margin="dense"
            label={fieldObj["guide"]}
            onChange={handleChangeKey(field)}
            disabled={(fieldObj["disabled"])}
            />
  }

  /**
   * Get field config value
   */
  const getFieldConfigValue = (field) => {
    const fieldObj = smsConfig[field];
    const isRegexError = (smsConfig[field]["regexError"]);

    // Endpoint POST or Get               
    if(field === "endpoint") {
      return <TextField
                select
                type="text"
                className={classes.textFieldValue}
                inputProps={{min: 0, autoComplete: 'new-password', style: { textAlign: 'left' }}}
                fullWidth
                size="small"
                margin="dense"
                variant="outlined"
                label={fieldObj["exLabel"]}
                onChange={handleChangeValue(field)}
                defaultValue="POST">
                  <MenuItem key="post" value="POST">
                    POST
                  </MenuItem>
                  <MenuItem key="get" value="GET">
                    GET
                  </MenuItem>
              </TextField>
    } else if(field === "secretKey") {
      return <TextField
                select
                type="text"
                className={classes.textFieldValue}
                inputProps={{min: 0, autoComplete: 'new-password', style: { textAlign: 'left' }}}
                fullWidth
                size="small"
                margin="dense"
                variant="outlined"
                autoComplete='new-password'
                label={fieldObj["exLabel"]}
                onChange={handleChangeValue(field)}
                disabled={true}
                defaultValue="secret_key">
                <MenuItem key="secret_key" value="secret_key">
                  KHOÁ BÍ MẬT
                </MenuItem>
                <MenuItem key="password" value="password">
                  MẬT KHẨU
                </MenuItem>
            </TextField>
    }

    if((fieldObj["value"])) {
      return <TextField
              error={isRegexError}
              type="text"
              className={classes.textFieldValue}
              fullWidth
              size="small"
              margin="dense"
              variant="outlined"
              defaultValue={fieldObj["value"]}
              label={fieldObj["exLabel"]}
              onChange={handleChangeValue(field)}
              disabled={(fieldObj["disabled"])}
            />      

    } else if((fieldObj["exValue"])) {
      return <TextField
                type="text"
                className={classes.textFieldValue}
                fullWidth
                size="small"
                margin="dense"
                variant="outlined"
                defaultValue={fieldObj["exValue"]}
                label={fieldObj["exLabel"]}
                disabled={(fieldObj["disabled"])}
            />      
    }
  }

  return (
    <Container className={classes.paper}>

          <h3 className={classes.title}>KẾT NỐI CHUYỂN TIẾP TIN NHẮN QUA HTTP</h3>

          <Box p={1} m={1}>
            {
              getMessageBox(errorMsg, successMsg, "Mời bạn cập nhật URL nhận SMS")
            }
            <Box className={classes.guide}>
              Tài liệu hướng dẫn kết nối: <Link href="https://github.com/chuyenkhoan" target="_blank" rel="noreferrer">https://github.com/chuyenkhoan</Link>
            </Box>
          </Box>

          <form className={classes.box_form} onSubmit={submitForm}>
            {
              (smsConfig.fields) &&
              smsConfig.fields.map((field, index) => (
                getFieldConfig(field)
              ))
            }
            <div className={classes.grow}></div>

            <Divider />

            <Button className={classes.btnAdd} variant="contained" color="primary" type="submit" disabled={!smsChanged}>
              Cập nhật
            </Button>

            <Button className={classes.btnAdd} variant="contained" color="primary" type="submit" 
              onClick={() => processTestSmsConfig()}
              disabled={!(smsConfig.endpoint) || !isValidUrl(smsConfig.endpoint.key)}>
              Test gọi API
            </Button>
          </form>

    </Container>
  );
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },

  guide: {
    padding: 8,
  },

  fieldsBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },

  fieldNameStyle: {
    width: 150,
    textAlign: "right",
  },

  fieldKeyStyle: {
    width: 320,
    marginLeft: 8,
    marginRight: 8,
  },

  fieldValueStyle: {
    width: 250,
  },

  
  textFieldKey: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },  

  textFieldOption: {
  },

  grow: {
    flexFlow: 1,
    minHeight: "15px",
  },

  btnAdd: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));
