
import React, { useState, useEffect } from 'react';
import {
  // Hidden, 
  Hidden, ThemeProvider, StyledEngineProvider
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';


import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import NumberFormat from 'react-number-format';
import SvgIcon from '@mui/material/SvgIcon';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import {
  getPaymentStatusColor,
  getStatusColor,
  getPaymentStatusText,
  getConfirmStatusText,
  getTxTimeText,
  getTxName,
  parseTxBean,
  getTxStatusText
} from '../../ckcore/core/TxUtils';

import { ckTheme, ckTableStyles } from '../../ckcore/ui/CkStyles';

import { getBankInfo, getBankAccount } from '../../ckcore/core/CkBank';
import Colors from '../../ckcore/core/Colors';
import { timeOffsetString } from '../../ckcore/core/CkUtils';
import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import TxStatus from '../../ckcore/core/TxStatuses';
import OrderDetailsDialog from './OrderDetails';

/**
 * Payment transaction list
 */
export default function PaymentTxList(props) {

  const classes = useStyles();
  const tableStyles = ckTableStyles();

  // user transaction list
  const bankAccounts = (props.bankAccounts) ? props.bankAccounts : [];
  const filterObj = props.filterObj;

  // parse list
  const paymentTxList = [];
  if (props.txList) {
    var txObj;
    const currentSeconds = (new Date().getTime()) / 1000;
    props.txList.map((txLine) => {
      txObj = parseTxBean(txLine);
      txObj.time = timeOffsetString(txObj.created / 1000, currentSeconds);

      if (!(filterObj) || filterObj.serviceId === 0 || filterObj.serviceId !== 0 && txObj.serviceId === filterObj.serviceId) {
        // filter by service ID
        paymentTxList.push(txObj);
      }

    });
  }

  const [inlineKeywords, setInlineKeywords] = useState("");
  const [selectedTxObj, setSelectedTxObj] = useState({});
  const [selectedTxList, setSelectedTxList] = useState([]);

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState((props.rowsPerPage) ? props.rowsPerPage : 22);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [txList, setTxList] = useState(paymentTxList);

  /**
  * Handler search event typing in this component
  */
  const handleSearch = (keywords) => {
    setInlineKeywords(keywords);
    requestSearch(keywords);
  }

  const requestSearch = (keywords) => {
    const filteredRows = paymentTxList.filter((txObj) => {

      // build content for search
      const content = txObj.txId + ' ' + txObj.content + ' ' + txObj.customerId + ' ' + txObj.txnRef + ' ' + txObj.srcPhone;

      return content.toLowerCase().includes(keywords.toLowerCase());
    });
    setTxList(filteredRows);
  };

  // Show order details
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const openOrderDetails = (txObj) => {
    setSelectedTxObj(txObj);
    setShowOrderDetails(true);
  }
  const closeOrderDetails = () => {
    setShowOrderDetails(false);
  }

  // Get ben bank name
  const getBenBankName = (benAccountNumber) => {

    let bankAccount = getBankAccount(bankAccounts, benAccountNumber);
    if (bankAccount) {
      return bankAccount.bankName;
    } else {
      return "";
    }
  }

  // set selected order
  const onSelectOrder = (txObj) => {
    setSelectedTxObj(txObj);
    props.selectCallback(txObj)
  }


  /**
   * Check if tx is in selected multiple state
   */
  const isMultipleSelected = (txObj) => {
    let txIndex = selectedTxList.findIndex((orderObj) => orderObj.txId === txObj.txId);
    if(txIndex >= 0) {
      return true;
    }
    return false;
  }

  // set multiple selected orders
  const onSelectMultipleOrders = (txObj) => {

    let txList = [...selectedTxList];
    let txIndex = txList.findIndex((orderObj) => orderObj.txId === txObj.txId);
    if(txIndex >= 0) {
      txList.splice(txIndex, 1);
    } else {
      txList.push(txObj);
    }
    setSelectedTxList(txList);
    props.selectMultipleCallback(txList);
  }


  const requestManualConfirm = (txObj) => {
    setSelectedTxObj(txObj);
  }

  // Get tx actions
  const getActions = (txObj, index) => {

    // return for other task
    if ((props.selectCallback)) {
      return (
        <div>
          <Tooltip title="" aria-label="Select"><span>
            <IconButton size="medium" className={(txObj.txId === selectedTxObj.txId) ? classes.selectedIcon : ""} onClick={() => onSelectOrder(txObj)}>
              {
                (txObj.txId === selectedTxObj.txId) ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )
              }
            </IconButton></span>
          </Tooltip>
        </div>
      );
    }

    if ((props.selectMultipleCallback)) {
      return (
        <div>
          <Tooltip title="" aria-label="Select"><span>
            <IconButton size="medium" className={isMultipleSelected(txObj) ? classes.selectedIcon : ""} onClick={() => onSelectMultipleOrders(txObj)}>
              {
                (isMultipleSelected(txObj)) ? (
                  <CheckBoxOutlinedIcon />
                ) : (
                  <CheckBoxOutlineBlankOutlinedIcon />
                )
              }
            </IconButton></span>
          </Tooltip>
        </div>
      );
    }


    // action inside
    return (
      <div>

        {
          (txObj.confirmStatus === TxStatus.PROCESSING) &&
          <Tooltip title="Xác nhận" aria-label="Confirm"><span>
            <IconButton size="small" className={(txObj.txId === selectedTxObj.txId) ? classes.selectedIcon : ""} onClick={() => requestManualConfirm(txObj)}>
              <DoneAllOutlinedIcon />
            </IconButton></span>
          </Tooltip>
        }

      </div>
    );
  }

  const getDetailsAction = (txObj, index) => {
    if ((props.selectCallback) || (props.selectMultipleCallback)) {
      return <div></div>;
    }
    return (
      <Tooltip title="Xem chi tiết" aria-label="Details"><span>
        <IconButton size="small" className={classes.smsButton} onClick={() => openOrderDetails(txObj)}>
          <MoreOutlinedIcon />
        </IconButton></span>
      </Tooltip>
    );
  }

  useEffect(() => {
    try {

      let keywords = ((filterObj) && filterObj.keyword !== '') ? filterObj.keyword : inlineKeywords;
      if (keywords) {
        requestSearch(filterObj.keyword)
      } else {
        setTxList(paymentTxList);
      }
    } catch (error) {
      console.log("Error show order tx list: " + error);
    }
  }, [props.txList, filterObj]);

  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>
        {
          !(props.filterObj) &&
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item className={tableStyles.transHead}>
              <Box display="inline" alignItems="flex-end" onClick={() => props.refreshCallback("ORDERS")}>
                <span className={tableStyles.title}>DANH SÁCH ĐƠN HÀNG</span>
              </Box>
            </Grid>

            <Grid item className={tableStyles.transHead}>
              <Paper className={tableStyles.searchBox}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Lọc giao dịch.."
                  inputProps={{ 'aria-label': 'search transactions' }}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        }


        <TableContainer className={tableStyles.tableContainer} component={Paper} >

          <Table className={tableStyles.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow className={tableStyles.row} style={{ height: 24 }}>
                <TableCell align="center" className={tableStyles.colTxId}>Mã GD</TableCell>
                <TableCell align="center" className={tableStyles.colIconStatus}>Trạng thái</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Số tiền</TableCell>
                <Hidden lgDown>
                  <TableCell className={tableStyles.colHeader}>Tài khoản nhận</TableCell>
                </Hidden>
                <TableCell align="center" className={tableStyles.colHeader}>Mã đơn</TableCell>
                <Hidden mdDown>
                  <TableCell align="center" className={tableStyles.colHeader}>Khách hàng</TableCell>
                </Hidden>
                <TableCell className={tableStyles.colHeader}>Thời gian</TableCell>
                <TableCell className={tableStyles.colContent}>Nội dung</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Thao tác</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? txList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : txList
              ).map((txObj, index) => (
                <TableRow hover key={index} className={tableStyles.row} style={{ backgroundColor: (txObj.txId === selectedTxObj.txId) ? Colors.CK_BG_WARNING : '' }}>

                  <TableCell align="center" className={tableStyles.colTxId} style={{ color: Colors.DARK_BLUE }} onClick={() => openOrderDetails(txObj)}>
                    {txObj.txId}
                  </TableCell>

                  <TableCell align="center" className={tableStyles.colTextStatus} >
                    <div style={{ color: getStatusColor(txObj.statusId) }}>{getTxStatusText(txObj.statusId)}</div>
                    {
                      (txObj.statusId === TxStatus.SUCCESS) &&
                      <div style={{ color: getStatusColor(txObj.confirmStatus) }}>{getConfirmStatusText(txObj.confirmStatus)}</div>
                    }
                  </TableCell>

                  {
                    (txObj.amount === 0) ? (
                      <TableCell className={tableStyles.colValue} style={{ color: Colors.GRAY }}>
                        {"---"}
                      </TableCell>
                    ) :
                      (
                        <TableCell className={tableStyles.colValue} style={{ color: getStatusColor(txObj.statusId) }}>
                          <NumberFormat value={txObj.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
                        </TableCell>
                      )
                  }

                  <Hidden lgDown>
                    <TableCell align="left" className={tableStyles.colBenBank}>
                      <IconButton size="small">
                        <SvgIcon component={getBankSvgIcon(getBenBankName(txObj.ben))} viewBox="0 0 48 48" />
                      </IconButton>
                      {txObj.ben}
                    </TableCell>
                  </Hidden>

                  <TableCell align="center" className={tableStyles.colTextStatus} style={{ color: getPaymentStatusColor(txObj.paymentStatus) }}>
                    {txObj.txnRef}<br />
                    {getPaymentStatusText(txObj.paymentStatus)}
                  </TableCell>

                  <Hidden mdDown>
                    <TableCell align="center" className={tableStyles.colCustomer}>{txObj.srcPhone}<br />{txObj.customerId}</TableCell>
                  </Hidden>
                  <TableCell className={tableStyles.colTime}>{txObj.time}<br />{getTxTimeText(txObj.created)}</TableCell>
                  <TableCell className={tableStyles.colContent}>{txObj.content}</TableCell>
                  <TableCell align="right" className={tableStyles.colActions}>
                    <div>
                      {getActions(txObj, index)}
                      {getDetailsAction(txObj, index)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {
                (txList.length === 0) &&
                <TableRow key={0} className={tableStyles.row} style={{ }}>
                  <TableCell colSpan={9} align="center" className={tableStyles.colHeader}>
                    <Box p={2}>
                      Chưa có đơn hàng gửi đến Chuyenkhoan.com
                    </Box>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
            {
              (txList.length > 5) &&
              <TableFooter >
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 11, 22, 33]}
                    colSpan={9}
                    count={txList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Số GĐ' },
                      native: true,
                    }}
                    labelRowsPerPage="Số GD / trang:"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            }
          </Table>
        </TableContainer>

        {
          showOrderDetails &&
          <OrderDetailsDialog open={showOrderDetails} closeCallback={closeOrderDetails}
            orderTxObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
    textAlign: 'left',
    paddingBottom: 24,
  },

  selectedIcon: {
    color: Colors.DARK_GREEN,
  }
}));
