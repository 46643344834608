import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export default function Report(props) {
  const classes = useStyle();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <h3 className={classes.title}>BÁO CÁO TỔNG HỢP</h3>

          {/* <div className={classes.toolbar_height} />
          <Button onClick={() => loadGGDR()}> Đăng nhập google </Button> */}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
        </Paper>
      </Grid>
    </Grid>
  ); 
}

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#37517f",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  title: {
    margin: 0,
  },
  toolbar_height: {
    height: "30px",
  },
  imgUnderConstruction: {
    [theme.breakpoints.up("lg")]: {
      minWidth: "1000px",
      maxWidth: "1000px",
    },
    [theme.breakpoints.down('xl')]: {
      minWidth: "776px",
      maxWidth: "776px",
    },
    [theme.breakpoints.down('md')]: {
      minWidth: "616px",
      maxWidth: "616px",
    },
  }
}));
