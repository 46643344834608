import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';

import PriorityPartnerList from './PriorityPartnerList';
import PotentialPartnerList from './PotentialPartnerList';
import QueriesTree from './QueriesTree';

export default function PopasTabs(props) {

  const [value, setValue] = useState((props.selectedIndex) ? props.selectedIndex : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [queryObj, setQueryObj] = useState({});
  const queryCallback = (queryObject) => {
    setQueryObj(queryObject);

    // move to potential search tab
    setValue(2);
  }
 
  const categoryCallback = (category) => {

    console.log("Searching category: " + category);
    
    if(!category) {
      return;
    }
    
    var queryObject = {
      category: category,
      query: ''
    }

    // call search category with query is empty
    queryCallback(queryObject);
  }


  return (
    <Paper square>

      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Popas Functions"
      >
        <Tab label="Priority Partners" />
        <Tab label="Queries Tree" />
        <Tab label="Potential Partners" />
      </Tabs>

      <Divider />
      

      {
        (value === 0) &&
        <PriorityPartnerList
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }


      {
        (value === 1) &&
        <QueriesTree
          queryCallback={queryCallback}
          categoryCallback={categoryCallback}
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }


      {
        (value === 2) &&
        <PotentialPartnerList
          queryObj={queryObj}
          userObj={props.userObj}
          partnerObj={props.partnerObj} 
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.refreshCallback}/>
      }
      
    </Paper>
  );
}