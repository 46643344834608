import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';

import moment from 'moment';

import { useInterval } from '../../ckcore/core/CkUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';

import BankAccountList from '../bank/BankAccountList';
import BankTxList from '../bank/BankTxList';
import { Box, FormControl, FormControlLabel, FormLabel, IconButton, MenuItem, Radio, RadioGroup, Tooltip } from '@mui/material';
import Colors from '../../ckcore/core/Colors';
import { getDateRange, TIME_RANGES } from '../../ckcore/core/TimeRanges';
import ExportGoogleSheets from '../export/ExportGoogleSheets';
import WebhookConfig from '../api/WebhookConfig';

/**
 * Partner Bank Account and Transactions
 * @param {*} props
 */
export default function PartnerBank(props) {

  const userObj = props.userObj;
  const action = (props.action) ? props.action : "";

  const [delay, setDelay] = useState(100);
  const [loading, setLoading] = useState(false);

  const [bankData, setBankData] = useState({
    bankConfig: {},
    bankTx: {},
  });

  const [filterObj, setFilterObj] = useState({
    index: 0,
    count: 99,
    status: 'latest',
    timeRange: 'today',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    keyword: '',
    all: true,
    internal: false,
    creditSuccess: false,
    debitSuccess: false,
    other: false,
    creditError: false,
    debitError: false,

  });

  const [accountNumber, setAccountNumber] = useState('all');

  /**
    * Export accounting sheet
    */
  const [showSyncGoogleSheetsDialog, setShowSyncGoogleSheetsDialog] = useState(false);
  const closeSyncGoogleSheetsDialog = () => {
    setShowSyncGoogleSheetsDialog(false);
    props.refreshCallback('BANKS', '');
    loadBankData();
  }

  /**
   * Send tx to telegram
   */
  const [showSendTelegramDialog, setShowSendTelegramDialog] = useState(false);
  const closeSendTelegramDialog = () => {
    setShowSendTelegramDialog(false);
    props.refreshCallback('BANKS', '');
    loadBankData();
  }

  /**
   * Change filter status
   */
  const handleChangeStatus = (event) => {
    var status = event.target.value;
    setFilterObj({ ...filterObj, 'status': status });
  }

  /**
   * Change time range
   */
  const handleChangeTimeRange = (event) => {

    let timeRange = event.target.value;
    let dateRange = getDateRange(timeRange);

    let fromDate = (dateRange.fromDate) ? dateRange.fromDate : filterObj.fromDate;
    let toDate = (dateRange.toDate) ? dateRange.toDate : filterObj.toDate;
    setFilterObj({ ...filterObj, timeRange: timeRange, fromDate: fromDate, toDate: toDate });
  }

  /**
   * Change date to filter bank tx
   */
  const handleChangeDate = name => event => {
    var val = event.target.value;
    setFilterObj({ ...filterObj, [name]: val, timeRange: 'manual' });
  };

  const handleChangeAccount = (event) => {
    var accountNumber = event.target.value;
    setAccountNumber(accountNumber);
  }
  /**
   * Change search keyword
   */
  const handleChangeKeyword = (event) => {
    var keyword = event.target.value.toLowerCase();
    setFilterObj({ ...filterObj, 'keyword': keyword });
  }

  /**
   * toggle filter type
   */
  const onToggleFilter = (name) => {

    // if all
    if (filterObj[name]) {
      if (name === 'all') {
        // clear others
        setFilterObj({ ...filterObj, [name]: false });
      } else {
        setFilterObj({ ...filterObj, [name]: false });
      }
    } else {
      if (name === 'all') {
        setFilterObj({
          ...filterObj, [name]: true,
          internal: false,
          creditSuccess: false,
          debitSuccess: false,
          other: false,
          creditError: false,
          debitError: false,
        });
      } else {
        // clear [all]
        setFilterObj({ ...filterObj, [name]: true, all: false });
      }
    }
  }


  // load bank data
  const loadBankData = async () => {
    try {

      if (loading) {
        return;
      }

      // load data
      var fromTime = filterObj.status === 'latest' ? 0 : moment(filterObj.fromDate, 'YYYY-MM-DD').toDate().getTime();
      var toTime = filterObj.status === 'latest' ? 0 : moment(filterObj.toDate, 'YYYY-MM-DD').add(24, 'hours').toDate().getTime();

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_BANK_TX_LIST,
        partnerId: userObj.partnerId,
        index: filterObj.index,
        count: filterObj.count,
        dateStart: fromTime,
        dateEnd: toTime,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "bank=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setBankData(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load bank status, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get bank data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Get bank account array from bank config
   */
  const getBankAccounts = (bankConfig) => {

    var bankAccounts = [];

    if (!(bankConfig.banks)) {
      return bankAccounts;
    }

    bankConfig.banks.map((accountNumber) => {
      bankAccounts.push(bankConfig[accountNumber]);
    })
    return bankAccounts;
  }

  const getMenuAccountNumbers = (bankConfig) => {

    var accountNumbers = [];
    accountNumbers.push({ accountName: 'Tất cả các tài khoản', accountNumber: 'all' });

    if (!(bankConfig.banks)) {
      return accountNumbers;
    }

    bankConfig.banks.map((accountNumber) => {
      accountNumbers.push({ accountName: accountNumber, accountNumber: accountNumber });
    });

    return accountNumbers;
  }


  // Use interval to refresh bank data
  useInterval(() => {

    if (filterObj.status === 'latest' && filterObj.keyword === '') {
      loadBankData();
    }

    setDelay(11000);
  }, delay);

  useEffect(() => {
    // reload on refresh
    loadBankData();
  }, [userObj, filterObj.status, filterObj.fromDate, filterObj.toDate]);


  return (

    <Grid container spacing={1}>

      <Grid item xs={12} style={{ paddingTop: 3 }}>
        <BankAccountList
          bankAccounts={getBankAccounts(bankData.bankConfig)}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
      </Grid>

      <Grid item xs={10} style={{ paddingTop: 20 }}>
        <FormControl component="fieldset">
          <FormLabel component="legend" style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>BIẾN ĐỘNG SỐ DƯ: </FormLabel>
          <RadioGroup size="small" row aria-label="ckStatus" name="ckStatus" value={filterObj.status} onChange={handleChangeStatus}>
            <FormControlLabel value="latest" control={<Radio size="small" />} label="Mới nhất" />
            <FormControlLabel value="all" control={<Radio size="small" />} label="Sao kê" />
          </RadioGroup>
        </FormControl>
        <TextField id="ck-time-range" variant="standard" margin="dense" size="small"
          label={"Chọn thời gian"}
          select
          value={filterObj.timeRange}
          style={{ marginLeft: 20, maxWidth: 138, minWidth: 111 }}
          InputProps={{ readOnly: false }}
          disabled={filterObj.status === 'latest'}
          onChange={(e) => handleChangeTimeRange(e)}>
          {TIME_RANGES.map((rangeItem, index) => (
            <MenuItem key={rangeItem.value} value={rangeItem.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{rangeItem.name}</Box>
            </MenuItem>
          ))}

        </TextField>
        <TextField
          label="Từ ngày: "
          type="date"
          size="small"
          variant="standard" margin="dense"
          style={{ marginLeft: 12, marginTop: 12, maxWidth: 138 }}
          value={filterObj.fromDate}
          onChange={handleChangeDate('fromDate')}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={filterObj.status === 'latest'}
          onClick={() => { setFilterObj({ ...filterObj, status: 'all' }) }}
        />
        <TextField
          label="Đến ngày: "
          type="date"
          size="small"
          variant="standard" margin="dense"
          style={{ marginLeft: 12, marginTop: 12, maxWidth: 138 }}
          value={filterObj.toDate}
          onChange={handleChangeDate('toDate')}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={filterObj.status === 'latest'}
          onClick={() => { setFilterObj({ ...filterObj, status: 'all' }) }}
        />
        <TextField id="ck-bank-account" variant="standard" margin="dense" size="small"
          label={"Chọn tài khoản"}
          select
          value={accountNumber}
          style={{ marginLeft: 20, maxWidth: 200 }}
          InputProps={{ readOnly: false }}
          onChange={(e) => handleChangeAccount(e)}>
          {getMenuAccountNumbers(bankData.bankConfig).map((bankAccount, index) => (
            <MenuItem key={bankAccount.accountNumber} value={bankAccount.accountNumber}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{bankAccount.accountName}</Box>
            </MenuItem>
          ))}

        </TextField>

        <Tooltip title="Đưa giao dịch lên GoogleSheets" aria-label="export-banktx-googlesheets">
          <IconButton aria-label="export" size="large"
            style={{ marginLeft: 12, marginTop: 12, color: Colors.DARK_BLUE }}
            onClick={() => { setShowSyncGoogleSheetsDialog(true) }}>
            <CloudUploadOutlinedIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Gửi giao dịch vào nhóm Telegram" aria-label="export-banktx-telegram">
          <IconButton aria-label="export" size="large"
            style={{ marginLeft: 3, marginTop: 12, color: Colors.DARK_BLUE }}
            onClick={() => { setShowSendTelegramDialog(true) }}>
            <TelegramIcon />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item xs={2} style={{ paddingTop: 32, textAlign: 'left' }}>
        <Paper component="form" sx={{ p: '1px 3px', display: 'flex', alignItems: 'center', width: '100%' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Tìm nội dung"
            inputProps={{ 'aria-label': 'tim noi dung' }}
            onChange={handleChangeKeyword}
          />
          <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <BankTxList
          showHeader={false}
          showReport={(filterObj.status !== 'latest')}
          bankAccounts={getBankAccounts(bankData.bankConfig)}
          bankTx={bankData.bankTx}
          filterObj={filterObj}
          onToggleFilter={onToggleFilter}
          accountNumber={accountNumber}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
      </Grid>

      {showSyncGoogleSheetsDialog &&
        <ExportGoogleSheets open={showSyncGoogleSheetsDialog} closeCallback={closeSyncGoogleSheetsDialog}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          txTypeId={TxTypes.PARTNER_ADMIN_BANK_TX_LIST_EXPORT}
          filterObj={filterObj} />
      }
      {showSendTelegramDialog &&
      <WebhookConfig appId="WEBHOOK"
        userObj={props.userObj}
        partnerObj={props.partnerObj} 
        configWebhook="telegram"
        closeCallback={closeSendTelegramDialog}
        logoutCallback={props.logoutCallback} 
        refreshCallback={props.refreshCallback}/>
      }
    </Grid>

  );
}
