import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
import Box from "@mui/material/Box";

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import PopaConfig from './PopaConfig';

export default function UpdatePopaStatusDialog(props) {

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const popaObj = props.popaObj;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(true);

  const [popaStatus, setPopaStatus] = useState({
    statusId: popaObj.statusId,
    status: popaObj.status,
    category: (popaObj.category) ? popaObj.category : '',
    demand: (popaObj.demand) ? popaObj.demand : '',
    contact: (popaObj.contact) ? popaObj.contact : '',
    assignee: (popaObj.assignee) ? popaObj.assignee : 'huy',
    bankName: (popaObj.accounts.length > 0) ? popaObj.accounts[0].bankName : 'Vietcombank',
    accountNumber: (popaObj.accounts.length > 0) ? popaObj.accounts[0].accountNumber : '',
    accountName: (popaObj.accounts.length > 0) ? popaObj.accounts[0].accountName : '',
    accounts: popaObj.accounts,
    ticket: popaObj.ticket
  })

  const handleSelectStatus = (event) => {
    setPopaStatus({ ...popaStatus, "statusId": parseInt(event.target.value) });
    setCompleted(false);
  }

  const handleSelectAssignee = (event) => {
    setPopaStatus({ ...popaStatus, "assignee": event.target.value });
    setCompleted(false);
  }

  const handleSelectCategory = (event) => {
    setPopaStatus({ ...popaStatus, "category": event.target.value });
    setCompleted(false);
  }

  const handleSelectDemand = (event) => {
    setPopaStatus({ ...popaStatus, "demand": event.target.value });
    setCompleted(false);
  }

  const handleSelectBank = (event) => {
    var bankName = event.target.value;
    const account = popaStatus.accounts.find((account) => account.bankName === bankName);

    if (account) {
      setPopaStatus({ ...popaStatus, "bankName": bankName, "accountNumber": account.accountNumber, "accountName": account.accountName });
    } else {
      setPopaStatus({ ...popaStatus, "bankName": bankName, "accountNumber": '', "accountName": '' });
    }

    setCompleted(false);

  }

  const handleChange = name => event => {
    setPopaStatus({ ...popaStatus, [name]: event.target.value });
    setCompleted(false);

    if (name === 'accountNumber' || name === 'accountName') {

    }
  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Update bank account
  const updateAccount = (bankName, accountNumber, accountName) => {

    const accounts = popaStatus.accounts;
    const accountIndex = accounts.findIndex((account => account.bankName === bankName));

    if (accountIndex >= 0) {
      // update account name
      if (accountNumber) {
        accounts[accountIndex].accountNumber = accountNumber;
      }

      // update account number
      if (accountName) {
        accounts[accountIndex].accountName = accountName;
      }

    } else {
      // add new account
      var bankAccount = {
        bankName: bankName,
        accountNumber: accountNumber,
        accountName: accountName
      }
      accounts.push(bankAccount);
    }
    // update state
    setPopaStatus({ ...popaStatus, accounts: accounts });
  }

  // create jira ticket
  const createJiraTicket = async () => {
    try {

      if (loading) {
        return;
      }

      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txType: "create_ticket",
        domain: popaObj.domain,
        incId: popaObj.incId,
        category: popaStatus.category,
        demand: popaStatus.demand,
        contact: popaStatus.contact,
        statusId: popaStatus.statusId,
        status: popaStatus.status,
        assignee: popaStatus.assignee,
        accounts: popaStatus.accounts,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        setSuccessMessage("Cập nhật thông tin thành công!");
        setPopaStatus({ ...popaStatus, ticket: response.ticket });
      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update status, msg: " + response.msg);
        setErrorMessage("Cập nhật thông tin bị lỗi!!!");
      }
    } catch (error) {
      setErrorMessage("Cập nhật thông tin bị lỗi!!!");
      console.log("Error update status.. " + error);
    }

    setLoading(false);
  }

  // process update popa status
  const doUpdatePopaStatus = async () => {

    try {

      if (loading) {
        return;
      }

      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txType: "update_status",
        domain: popaObj.domain,
        incId: popaObj.incId,
        category: popaStatus.category,
        demand: popaStatus.demand,
        contact: popaStatus.contact,
        statusId: popaStatus.statusId,
        status: popaStatus.status,
        accounts: popaStatus.accounts,
        assignee: popaStatus.assignee,
        ticket: popaStatus.ticket,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        setSuccessMessage("Cập nhật thông tin thành công!");
        setCompleted(true);
      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update status, msg: " + response.msg);
        setErrorMessage("Cập nhật thông tin bị lỗi!!!");
      }
    } catch (error) {
      setErrorMessage("Cập nhật thông tin bị lỗi!!!");
      console.log("Error update status.. " + error);
    }

    setLoading(false);

  };


  useEffect(() => {

    // update bank account
    updateAccount(popaStatus.bankName, popaStatus.accountNumber, popaStatus.accountName);

  }, [popaStatus.accountNumber, popaStatus.accountName]);


  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Trạng thái đối tác</DialogTitle>
          <DialogContent dividers>

            <Box p={2}>
              {getMessageBox(errorMessage, successMessage, "Cập nhật trạng thái chăm sóc khách hàng")}
            </Box>

            <Grid container spacing={3}>

              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <TextField size="small" margin="dense" id="incId" fullWidth
                  label="Mã số thuế"
                  type="text"
                  value={popaObj.incId}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField size="small" margin="dense" id="domain" fullWidth
                  label="Website"
                  type="text"
                  value={popaObj.domain}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField autoFocus size="small" margin="dense" id="incName" fullWidth
                  label="Tên công ty"
                  type="text"
                  value={popaObj.name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={1}>
              </Grid>

              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-select-category" size="small" fullWidth
                  style={{ marginTop: 8 }}
                  select
                  label="Lĩnh vực hoạt động"
                  value={popaStatus.category}
                  onChange={handleSelectCategory}>
                  {PopaConfig.getPopaCategories().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}

                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-select-demand" size="small" fullWidth
                  style={{ marginTop: 8 }}
                  select
                  label="Nhu cầu"
                  value={popaStatus.demand}
                  onChange={handleSelectDemand}>
                  {PopaConfig.getPopaDemands().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}

                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField autoFocus size="small" margin="dense" id="contact-point" fullWidth
                  label="Đầu mối liên hệ"
                  type="text"
                  value={popaStatus.contact}
                  onChange={handleChange('contact')}
                />
              </Grid>
              <Grid item xs={1}>
              </Grid>

              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-select-banks" size="small" fullWidth
                  style={{ paddingTop: 8 }}
                  select
                  label="Ngân hàng"
                  value={popaStatus.bankName}
                  onChange={handleSelectBank}
                >
                  {Config.getAvailableBanks().map((bank) => (
                    <MenuItem key={bank.name} value={bank.name}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField size="small" margin="dense" id="account-number" fullWidth
                  label="Số tài khoản"
                  type="text"
                  value={popaStatus.accountNumber}
                  onChange={handleChange('accountNumber')}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField size="small" margin="dense" id="account-name" fullWidth
                  label="Tên tài khoản"
                  type="text"
                  value={popaStatus.accountName}
                  onChange={handleChange('accountName')}
                />
              </Grid>
              <Grid item xs={1}>
              </Grid>


              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="outlined-select-status" size="small" fullWidth
                  style={{ paddingTop: 8 }}
                  select
                  label="Trạng thái"
                  value={popaStatus.statusId}
                  onChange={handleSelectStatus}
                >
                  {PopaConfig.getPopaStatuses().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  id="outlined-select-status" size="small" fullWidth
                  style={{ paddingTop: 8 }}
                  select
                  label="Nhân viên CSKH"
                  value={popaStatus.assignee}
                  onChange={handleSelectAssignee}
                >
                  {PopaConfig.getPopaUsers().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={2}>
                <TextField size="small" margin="dense" id="ticket" fullWidth
                  label="Ticket JIRA"
                  type="text"
                  value={popaStatus.ticket}
                  onChange={handleChange('ticket')}
                />
              </Grid>
              <Grid item xs={2}>
                <Button disabled={loading || (popaStatus.ticket !== '')} style={{ marginTop: 8 }} variant="text" onClick={() => createJiraTicket()}>Tạo ticket</Button>
              </Grid>
              <Grid item xs={1}>
              </Grid>

              <Grid item xs={1}>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  id="outlined-multiline-static" fullWidth
                  label="Mô tả"
                  type="text"
                  multiline
                  rows={3}
                  value={popaStatus.status}
                  onChange={handleChange('status')}
                />
              </Grid>
              <Grid item xs={1}>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button disabled={loading || completed} color="primary" onClick={() => doUpdatePopaStatus()}>
              Cập nhật
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});
