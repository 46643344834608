
import React, { useEffect, useState } from 'react';
import { Hidden, ThemeProvider, StyledEngineProvider, Link, TextField, Button, Typography, SvgIcon, MenuItem } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import {
  getStatusColor,
  getTxStatusText,
  getTxMillisText,
  parseTxBean,
  getTxTimeText,
  compareUpdatedDesc
} from '../../ckcore/core/TxUtils';
import { useInterval } from '../../ckcore/core/CkUtils';

import Config from '../../ckcore/core/Config';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import PopaConfig from './PopaConfig';
import UpdatePopaStatusDialog from './UpdatePopaStatus';
import { getBankSvgIcon } from '../../ckcore/core/CkBank';
import BankMarketingDialog from './BankMarketing';
import { LocalizationProvider } from '@mui/lab';

/**
 * Manage potential partner queries
 */
export default function QueriesTree(props) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [popasData, setPopasData] = useState({
    queries: {},
    category: '',
    query: '',
    crawlTime: '22:00',
    cachedTime: 7,
    crawling: false,
    changeConfig: false,
    hasQuery: false,
  });

  const handleChangeCrawlTime = (event) => {
    setPopasData({ ...popasData, "crawlTime": event.target.value, changeConfig: true });
  }

  const handleSelectCachedTime = (event) => {
    setPopasData({ ...popasData, "cachedTime": parseInt(event.target.value), changeConfig: true });
  }

  const handleSelectCategory = (event) => {
    setPopasData({ ...popasData, "category": event.target.value });
  }

  const handleChangeQuery = (event) => {
    setPopasData({ ...popasData, "query": event.target.value, hasQuery: false });
  }

  const handleChangeCategory = (queryObj, index, newCategory) => {
    
  }

  const requestSearch = (query) => {

    const filteredRows = popasData.incs.filter((popaObj) => {

      // build content for search
      const content = popaObj.domain + ' ' + popaObj.status;

      return content.toLowerCase().includes(query.toLowerCase());
    });

    setPopasData({ ...popasData, filteredRows: filteredRows });

  };


  /**
   * Add query to queries tree
   */
  const handleAddQuery = async () => {

    try {

      if (!(popasData.query)) {
        console.log("Query is empty");
        return;
      }

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "add_query",
        query: popasData.query,
        category: popasData.category,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        showQueriesTree(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR add query, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error add query.. " + error);
    }

    setLoading(false);

  }

  /**
   * Change query category
   */
  const handleMoveQuery = (queryObj, index) => {

    var queries = popasData.queries;
    var queriesByCategory = queries[queryObj.category];
    queryObj.changeCategory = true;
    queriesByCategory[index] = queryObj;
    queries[queryObj.category] = queriesByCategory;

    setPopasData({...popasData, queries: queries});
  }

  /**
   * Add query to queries tree
   */
  const handleRemoveQuery = async (queryObj) => {

    try {

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "remove_query",
        query: queryObj.query,
        category: queryObj.category,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        showQueriesTree(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR remove query, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error remove query.. " + error);
    }

    setLoading(false);

  }

  /**
   * Config queries tree
   */
  const handleConfigQueries = async () => {

    try {

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "config_queries",
        crawlTime: popasData.crawlTime,
        cachedTime: popasData.cachedTime,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        showQueriesTree(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR config query, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error config query.. " + error);
    }

    setLoading(false);

  }

  /**
   * Run queries NOW
   */
  const handleRunQueries = async () => {

    try {

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "run_queries",
        crawlTime: popasData.crawlTime,
        cachedTime: popasData.cachedTime,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        showQueriesTree(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR run query, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error run query.. " + error);
    }

    setLoading(false);

  }

  /**
   * Request stop queries
   */
  const handleStopQueries = async () => {

    try {

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "stop_queries",
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        showQueriesTree(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR stop query, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error stop query.. " + error);
    }

    setLoading(false);

  }

  // load system data
  const loadQueries = async () => {

    try {

      if (loading) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "queries",
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        showQueriesTree(response);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR queries, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error load queries data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Update queries tree
   */
  const showQueriesTree = (response) => {
    // group queries by category
    const queriesTree = response.queriesTree;

    // rebuild queries
    var queriesByCategory = {};
    var category;
    var queryObj;

    // check if current query exists
    var hasQuery = false;
    const currentQuery = popasData.query.trim().toLowerCase();

    queriesByCategory.all = [];
    queriesTree.queries.map((queryHash) => {

      queryObj = queriesTree[queryHash];
      category = queryObj.category;

      if (!(category)) {

        queriesByCategory.all.push(queryObj);
      } else {

        // add empty category
        if (!(queriesByCategory[category])) {
          queriesByCategory[category] = [];
        }
        queriesByCategory[category].push(queryObj);
      }

      if (currentQuery !== '' && queryObj.query === currentQuery) {
        hasQuery = true;
      }
    });

    // get cached time and crawl time
    var crawlTime = queriesTree.crawlTime;
    if (!(crawlTime)) {
      crawlTime = popasData.crawlTime;
    }
    var cachedTime = queriesTree.cachedTime;
    if (!(cachedTime)) {
      cachedTime = popasData.cachedTime;
    }
    var crawling = queriesTree.crawling;
    if (!(crawling)) {
      crawling = false;
    }

    setPopasData({
      ...popasData, queries: queriesByCategory, crawlTime: crawlTime, cachedTime: cachedTime, crawling: crawling,
      changeConfig: false, hasQuery: hasQuery
    });

  }

  /**
   * Show queries tree
   */
  const getCategoryQueries = (category, index) => {


    var categoryKey = category.value;
    if (categoryKey === '') {
      categoryKey = "all";
    }

    // not show other category
    if (popasData.category !== '' && categoryKey !== popasData.category) {
      return <div key={index}></div>;
    }

    // get data
    var queriesByCategory = popasData.queries[categoryKey];
    if (!(queriesByCategory)) {
      queriesByCategory = [];
    }

    return (
      <>
        <TableRow key={index} style={{ height: 40, overflow: 'hidden' }}>
          <TableCell colSpan={2} className={classes.categoryName} align="left">{category.label}</TableCell>
          <TableCell className={classes.colActions} align="left">{getCategoryActions(category.value)}</TableCell>
        </TableRow>
        {
          queriesByCategory.map((queryObj, qIndex) => (
            <TableRow key={"query_" + index + "_" + qIndex} style={{ height: 25, overflow: 'hidden' }}>
              <TableCell className={classes.colName} align="left">
                <span style={{marginRight: 8}}>{queryObj.query}</span>
              </TableCell>
              <TableCell className={classes.colLink} align="left">
                <span style={{marginRight: 8}}>{queryObj.links}</span>
                </TableCell>
              <TableCell className={classes.colActions} align="left">
               {
                  queryObj.changeCategory &&
                  <TextField
                    id="outlined-select-category" size="small"
                    select
                    label=""
                    variant="standard"
                    InputProps={{style: {fontSize: 12, width: 121, margin: 5}}}
                    value={queryObj.category}
                    onChange={(e) => handleChangeCategory(queryObj, index, e.target.value)}>
                    {PopaConfig.getPopaCategories().map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                }
                {getActions(queryObj, qIndex)}
              </TableCell>
            </TableRow>
          ))
        }
      </>
    )
  }

  // AddOutlinedIcon
  const getCategoryActions = (category) => {

    // action inside
    return (
      <div>

        <Tooltip title="Tìm đối tác" aria-label="search"><span>
          <IconButton size="small" className={classes.actionButton}>
            <SearchOutlinedIcon className={classes.svgIcon} onClick={() => props.categoryCallback(category)} />
          </IconButton></span>
        </Tooltip>
        <Tooltip title="Thêm tìm kiếm" aria-label="add"><span>
          <IconButton size="small" className={classes.actionButton} onClick={() => handleAddQuery()}>
            <AddOutlinedIcon className={classes.svgIcon} />
          </IconButton></span>
        </Tooltip>

      </div>
    );
  }

  // Get tx actions
  const getActions = (queryObj, index) => {

    // action inside
    return (
      <div>

        <Tooltip title="Tìm đối tác" aria-label="search"><span>
          <IconButton size="small" className={classes.actionButton}>
            <SearchOutlinedIcon className={classes.svgIcon} onClick={() => props.queryCallback(queryObj)} />
          </IconButton></span>
        </Tooltip>
        <Tooltip title="Đổi danh mục" aria-label="remove"><span>
          <IconButton size="small" className={classes.actionButton} onClick={() => handleMoveQuery(queryObj, index)}>
            <SwapVertOutlinedIcon className={classes.svgIcon} />
          </IconButton></span>
        </Tooltip>
        <Tooltip title="Xoá tìm kiếm" aria-label="remove"><span>
          <IconButton size="small" className={classes.actionButton} onClick={() => handleRemoveQuery(queryObj)}>
            <DeleteOutlineOutlinedIcon className={classes.svgIcon} />
          </IconButton></span>
        </Tooltip>
      </div>
    );
  }

  useInterval(() => {
    if (popasData.crawling) {
      loadQueries();
    }
  }, 5000);

  useEffect(() => {
    loadQueries();
  }, []);

  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>


        <Grid container spacing={2} style={{ padding: 20 }}>

          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button variant="text">Daily Crawl Scheduler:</Button>
          </Grid>
          <Grid item xs={2}>
            <TextField id="time" label="Crawl Time" type="time" size="small" fullWidth
              value={popasData.crawlTime}
              onChange={handleChangeCrawlTime}
              InputLabelProps={{ shrink: true, }}
              inputProps={{ step: 300, }}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              id="cached-time" size="small" fullWidth
              select
              label="Cached Time"
              value={popasData.cachedTime}
              onChange={handleSelectCachedTime}>
              {PopaConfig.getPopaCachedTime().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <Button disabled={loading || !popasData.changeConfig} variant="text" onClick={() => handleConfigQueries()}>UPDATE</Button>
          </Grid>
          <Grid item xs={2}>
            {
              popasData.crawling ? (
                <Button disabled={loading || !popasData.crawling} variant="text" onClick={() => handleStopQueries()}>STOP CRAWL</Button>
              ) : (
                <Button disabled={loading || popasData.crawling} variant="text" onClick={() => handleRunQueries()}>RUN NOW</Button>
              )
            }
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ padding: 0 }}>

          <Grid item xs={3}>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-select-category" size="small" fullWidth
              select
              label="Category"
              value={popasData.category}
              onChange={handleSelectCategory}>
              {PopaConfig.getPopaCategories().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

            </TextField>
          </Grid>

          <Grid item xs={3}>
            <TextField id="query" size="small" variant="outlined" fullWidth
              label="Query"
              inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 16 } }}
              value={popasData.query}
              onChange={handleChangeQuery}
            />
          </Grid>
          <Grid item xs={3}>
            <Button disabled={loading || popasData.query === '' || popasData.hasQuery} variant="text" onClick={() => handleAddQuery()}>ADD query</Button>

          </Grid>

        </Grid>


        <TableContainer className={classes.tableContainer} component={Paper} style={{ paddingLeft: 20, paddingRight: 20 }}>

          <Table className={classes.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow>
                <TableCell className={classes.colName} align="center">Query</TableCell>
                <TableCell className={classes.colLink} align="center">Links</TableCell>
                <TableCell className={classes.colActions} align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>

              {
                PopaConfig.getPopaCategories().map((category, index) => (
                  getCategoryQueries(category, index)
                ))
              }

            </TableBody>


          </Table>
        </TableContainer>

      </ThemeProvider>
    </StyledEngineProvider>
  );
}



const theme = createTheme({
  // spacing: 3,
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation.
        padding: '2px 6px',
      },
      sizeSmall: {
        padding: '2px 3px 2px 3px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
    MuiIconButton: {
      root: {
        width: 18,
        height: 18,
      }
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
  },

  alert: {
    marginBottom: 12,
  },

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
  },

  transHead: {
    minHeight: 22,
    padding: '3px 3px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
    width: 200,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

  tableContainer: {
    marginTop: 8,
  },

  table: {
    marginTop: 16,
    marginBottom: 16,
  },

  row: {
    overflow: 'hidden',
    height: 40,
    maxHeight: 48,
    minHeight: 40,
    fontSize: 11,
  },

  categoryName: {
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
    overflow: 'hidden',
  },

  colLink: {
    textAlign: 'right',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },


  colActions: {
    minWidth: 80,
    maxWidth: 111,
    padding: '2px 6px',
    fontSize: 12,
    overflow: 'hidden',
    textAlign: 'right'
  },


  actionButton: {
    padding: '0px 8px',
  },
  svgIcon: {
    fontSize: 18,
    padding: 0
  }
}));

