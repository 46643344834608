import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { ckApiStyles } from '../ApiStyles';
import Colors from '../../../ckcore/core/Colors';
import TxStatus from '../../../ckcore/core/TxStatuses';
import Config from '../../../ckcore/core/Config';
import User from '../../../ckcore/core/User';

/**
 * Test function
 */
export const testConnectTelegram = async (apiObj, callback) => {
  try {
    // load request
    var ckmsg = {
      txType: "telegram",
      groupId: apiObj.client_id,
      message: "CK Dashboar kiểm tra kết nối..",
      time: new Date().getTime(),
      identity: User.getIdentity(),
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ckmsg)
    };

    // fetch response
    const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/export/check?'), requestOptions);
    const response = await rawResponse.json();

    if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
      callback(response.statusId, 'Đã kiểm tra nhóm: ' + response.groupTitle);
    } else {
      callback(response.statusId, '');
    }

  } catch (error) {
    console.log("Error check telegram group " + error);
    callback(TxStatus.ERROR, '');
  }
}

export default function WebhookTelgram(props) {

  const classes = ckApiStyles();

  const [webhookObj, setWebhookObj] = useState(props.webhookObj);

  /**
   * Set change, validate, and callback
   */
  const handleChange = (name, value) => {

    var apiObj = { ...webhookObj.api };
    apiObj[name] = value;

    setWebhookObj({ ...webhookObj, api: apiObj });
  };

  const handleCheck = (name, value) => {
    setWebhookObj({ ...webhookObj, [name]: value });
  };

  useEffect(() => {

    // callback update change
    props.handleChange(webhookObj);

  }, [webhookObj]);

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={(webhookObj.enable)}
              onChange={e => handleCheck("enable", e.target.checked)}
              name="enable"
              color={(webhookObj.enable) ? "primary" : "success"}
            />
          }
          label={<Typography variant="h6" style={{ color: webhookObj.enable ? "primary" : "success" }}>Đăng ký gửi thông tin chuyển khoản vào nhóm Telegram</Typography>}
        />
      </Grid>      

      <Grid item xs={12}>
        <Box>
          <strong>Bước 1:</strong> Thêm <strong>@chuyenkhoanbot</strong> vào nhóm cần gửi thông tin chuyển khoản.
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
          <strong>Bước 2:</strong> <span>Tìm ID của nhóm</span><br/>
          <span>Bạn có thể vào <a target='_blank' rel="noreferrer" href='https://web.telegram.org'>web.telegram.org</a> --&gt; vào nhóm --&gt; lấy ID từ URL có dạng <strong>https://web.telegram.org/#-6762339xx</strong>(6762339xx là ID nhóm Telegram).</span>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
        <strong>Bước 3:</strong> Nhập ID vào form bên dưới và cập nhật.
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="ID nhóm Telegram"
          placeholder='ID nhóm'
          disabled={!webhookObj.enable}
          value={(webhookObj.api.client_id) ? webhookObj.api.client_id : ""}
          onChange={e => handleChange('client_id', e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>

      </Grid>

    </Grid>
  );
}
