import { adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Autocomplete from '@mui/material/Autocomplete';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Colors from '../../ckcore/core/Colors';

export default function SetPrimaryBankAccountDialog(props) {

  const classes = useStyles();
  const userObj = props.userObj;
  const bankObj = props.bankObj;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Set bank account as primary
  const setPrimaryBankAccount = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_BANK_SET_PRIMARY,
      partnerId: userObj.partnerId,
      bankAccount: bankObj,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setSuccessMessage("");
      setErrorMessage("");
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {
        
        setSuccessMessage("Đặt tài khoản mặc định thành công");

         // handle close
         setTimeout(() => {
          handleClose();
        }, 1800);

        // reset partner info
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMessage("Đặt tài khoản mặc định bị lỗi, vui lòng thử lại sau");
      }

    } catch (error) {
      console.log("Error... " + error);
      setErrorMessage("Đặt tài khoản mặc định bị lỗi, vui lòng thử lại sau");
    }

    setLoading(false);
  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Set bank account as primary dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Đặt làm tài khoản chính</DialogTitle>
        <DialogContent dividers>

          <Box p={1}>
            {getMessageBox(errorMessage, successMessage, "BẠN MUỐN ĐẶT TÀI KHOẢN MẶC ĐỊNH")}
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="auto-highlight"
                autoHighlight
                options={Config.getAvailableBanks()}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                defaultValue={{ name: bankObj.bankName }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tên ngân hàng"
                    variant="outlined"
                    className={classes.textfieldBankName}
                    margin="dense"
                    fullWidth
                  />
                )}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textfieldAccNumberNew}
                fullWidth
                margin="dense"
                label="Số tài khoản"
                type="text"
                variant="outlined"
                value={bankObj.accountNumber}
                inputProps={{
                  maxLength: 15,
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textfieldAccName}
                fullWidth
                margin="dense"
                label="Tên tài khoản"
                type="text"
                variant="outlined"
                value={bankObj.accountName}
                disabled={true}
              />
            </Grid>

          </Grid>       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Trở lại
          </Button>

          <Button disabled={loading || successMessage.length > 0} onClick={() => setPrimaryBankAccount()} color="primary">
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const theme = createTheme(adaptV4Theme({
  palette: {
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
}));

const useStyles = makeStyles(theme => ({
  root: {

  },
}));
