
import React, { useState, useEffect } from 'react';
import {
  // Hidden, 
  Hidden, ThemeProvider, StyledEngineProvider, Snackbar, SnackbarContent
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import NumberFormat from 'react-number-format';

import {
  getPaymentStatusColor,
  getStatusColor,
  getPaymentStatusText,
  getConfirmStatusText,
  getTxTimeText,
  getTxName,
  parseTxBean,
  getTxStatusText
} from '../../ckcore/core/TxUtils';

import { ckTheme, ckTableStyles } from '../../ckcore/ui/CkStyles';

import { getBankInfo, getBankAccount } from '../../ckcore/core/CkBank';
import Colors from '../../ckcore/core/Colors';
import { timeOffsetString } from '../../ckcore/core/CkUtils';
import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import TxStatus from '../../ckcore/core/TxStatuses';
import User from '../../ckcore/core/User';
import TxTypes from '../../ckcore/core/TxTypes';
import Config from '../../ckcore/core/Config';
import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';
import { getPopaTypeName, parsePopaBean } from '../../ckcore/core/CustomerUtils';
import CustomerDetailsDialog from './CustomerDetailsDialog';
import { getProvinceName } from '../../ckcore/core/LocationUtils';


/**
 * Customer list
 */
export default function CustomerList(props) {

  const tableStyles = ckTableStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const customers = props.customers;
  const [selectedCustomer, setSelectedCustomer] = useState({ popaId: '' });

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState((props.rowsPerPage) ? props.rowsPerPage : 22);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 22));
    setPage(0);
  };

  // Show customer details
  const [showCustomerDetails, setShowCustomerDetails] = useState(false);
  const openCustomerDetails = (selectedCustomer) => {
    setSelectedCustomer(selectedCustomer);
    setShowCustomerDetails(true);
  }
  const closeCustomerDetails = () => {
    setShowCustomerDetails(false);
    props.reloadCallback();
  }

  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>

        <TableContainer className={tableStyles.tableContainer} component={Paper} >

          <Table className={tableStyles.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow className={tableStyles.row} style={{ height: 24 }}>
                <TableCell align="center" className={tableStyles.colHeader}>STT</TableCell>
                <TableCell align="left" className={tableStyles.colHeader}>Mã KH</TableCell>
                <TableCell align="left" className={tableStyles.colHeader}>Tên KH</TableCell>
                <TableCell align="left" className={tableStyles.colHeader}>Đầu mối liên hệ</TableCell>
                <TableCell align="left" className={tableStyles.colHeader}>Tài khoản ngân hàng</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Thu/Chi</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Thao tác</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>

              {
                customers.map((customer, index) => (
                  <TableRow hover key={index} className={tableStyles.row} style={{ height: 48, backgroundColor: (customer.code === selectedCustomer.code) ? Colors.CK_BG_WARNING : '' }}>

                    <TableCell align="center" className={tableStyles.colTxId} style={{ color: Colors.DARK_BLUE }} onClick={() => { openCustomerDetails(customer) }}>{(index + 1)}</TableCell>
                    <TableCell align="left" className={tableStyles.colHeader} style={{ color: Colors.DARK_BLUE, cursor: 'pointer' }} onClick={() => { }}>
                      {customer.code}<br />
                      {getPopaTypeName(customer.typeId)}
                    </TableCell>
                    <TableCell align="left" className={tableStyles.colHeader} style={{}} >
                      {customer.name}<br />{getProvinceName(customer.provinceId)}
                    </TableCell>
                    <TableCell align="left" className={tableStyles.colHeader} style={{}} >
                      {customer.phone}<br />{customer.email}
                    </TableCell>
                    <TableCell align="left" className={tableStyles.colHeader} style={{}} >
                      {customer.bankName}<br />{customer.accountNumber}
                    </TableCell>
                    <TableCell align="right" className={tableStyles.colHeader} style={{}} >{""}</TableCell>
                    <TableCell align="center" className={tableStyles.colHeader} style={{}} >{""}</TableCell>
                  </TableRow>
                ))
              }

            </TableBody>
            {
              (customers.length > 5) &&
              <TableFooter >
                <TableRow className={tableStyles.paging}>
                  <TablePagination
                    rowsPerPageOptions={[5, 11, 22, 33]}
                    colSpan={9}
                    count={customers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Số GĐ' },
                      native: true,
                    }}
                    labelRowsPerPage="Số KH / trang:"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            }
          </Table>
        </TableContainer>


        {
          showCustomerDetails &&
          <CustomerDetailsDialog open={showCustomerDetails} closeCallback={closeCustomerDetails}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            popaObj={selectedCustomer}
          />
        }

      </ThemeProvider>
    </StyledEngineProvider>
  );
}
