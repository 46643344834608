
import React, { useState, useEffect } from 'react';
import { Button, Hidden, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import SvgIcon from '@mui/material/SvgIcon';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import NumberFormat from 'react-number-format';

import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import Tooltip from '@mui/material/Tooltip';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';

import {
  getPaymentStatusColor,
  getStatusColor,
  getPaymentStatusText,
  getTxTimeText,
  parseTxBean,
  compareTxDesc
} from '../../ckcore/core/TxUtils';

import { ckTheme, ckTableStyles } from '../../ckcore/ui/CkStyles';

import { getBankInfo, getBankAccount } from '../../ckcore/core/CkBank';
import Colors from '../../ckcore/core/Colors';
import ServiceTypes from '../../ckcore/core/ServiceTypes';
import TxStatus from '../../ckcore/core/TxStatuses';
import { timeOffsetString } from '../../ckcore/core/CkUtils';
import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import CkqrRefundOrder from '../order/CkqrRefundOrder';
import PaymentStatus, { isCreditError, isCreditSuccess, isDebitError, isDebitSuccess, isInternalTx, isOtherTx } from '../../payment/components/PaymentStatus';
import MatchingOrderDialog from '../order/MatchingOrder';
import ExportAccountingDialog from '../accounting/ExportAccountingDialog';
import SyncSmsToBankTxDialog from '../sim/SyncSmsToBankTx';
import BankTxDetailsDialog from './BankTxDetails';
import BankTxReportTable from './ui/BankTxReportTable';
import OrderDetailsDialog from '../order/OrderDetails';
import MatchingMultipleOrdersDialog from '../order/MatchingMultipleOrders';


/**
 * Bank transaction list
 */
export default function BankTxList(props) {

  const tableStyles = ckTableStyles();

  const partnerObj = props.partnerObj;

  // user transaction list
  const bankAccounts = (props.bankAccounts) ? props.bankAccounts : [];
  const filterObj = props.filterObj;

  const [inlineKeywords, setInlineKeywords] = useState("");
  const [selectedTxObj, setSelectedTxObj] = useState({});
  const [showMatchingOrder, setShowMatchingOrder] = useState(false);
  const [showMatchingMultipleOrders, setShowMatchingMultipleOrders] = useState(false);

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState((props.rowsPerPage) ? props.rowsPerPage : 11);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [allTxList, setAllTxList] = useState([]);
  const [txList, setTxList] = useState([]);
  const [reportObj, setReportObj] = useState({
    transparency: false,
    totalTxCount: 0, totalDebitCount: 0, totalCreditCount: 0, totalCreditValue: 0, totalDebitValue: 0,
    totalDebitSuccessCount: 0, totalDebitErrorCount: 0, totalCreditSuccessCount: 0, totalCreditErrorCount: 0,
    totalInternalCount: 0, totalOtherCount: 0

  });

  // Update bank tx list by bank accounts data and filter object
  const updateBankTxList = () => {

    // parse bank transaction
    const bankTxList = [];
    var txObj;
    var accountTxList;
    var transparency = false;
    var totalTxCount = 0, totalDebitCount = 0, totalDebitValue = 0, totalCreditCount = 0, totalCreditValue = 0;
    var totalDebitSuccessCount = 0, totalDebitErrorCount = 0, totalCreditSuccessCount = 0, totalCreditErrorCount = 0;
    var amount, paymentStatus, totalInternalCount = 0, totalOtherCount = 0;

    const currentSeconds = (new Date().getTime()) / 1000;
    bankAccounts.map((bankAccount) => {
      // get tx list for all banks
      accountTxList = props.bankTx[bankAccount.accountNumber];
      if (accountTxList && (!(props.accountNumber) || props.accountNumber === 'all' || props.accountNumber === bankAccount.accountNumber)) {
        accountTxList.map((txLine) => {

          txObj = parseTxBean(txLine);
          txObj.time = timeOffsetString(txObj.created / 1000, currentSeconds);

          txObj.transparency = bankAccount.transparency;
          if (txObj.transparency) {
            transparency = true;
          }

          amount = txObj.amount;
          paymentStatus = txObj.paymentStatus;
          if (amount > 0) {
            totalCreditCount += 1;
            totalCreditValue += amount;
            if (isCreditSuccess(paymentStatus)) {
              totalCreditSuccessCount += 1;
            } else if (isCreditError(paymentStatus)) {
              totalCreditErrorCount += 1;
            } else {
              totalOtherCount += 1;
            }

          } else if (amount < 0) {
            totalDebitCount += 1;
            totalDebitValue += amount;
            if (isDebitSuccess(paymentStatus)) {
              totalDebitSuccessCount += 1;
            } else if (isDebitError(paymentStatus)) {
              totalDebitErrorCount += 1;
            } else if (isInternalTx(paymentStatus)) {
              totalInternalCount += 1;
            } else {
              totalOtherCount += 1;
            }
          }

          // add to list (not care about statuses)
          bankTxList.push(txObj);

          return true;
        });
      }
    });
    bankTxList.sort(compareTxDesc);
    totalTxCount = bankTxList.length;

    // update report
    setReportObj({
      ...reportObj, transparency: transparency, totalTxCount: totalTxCount,
      totalCreditCount: totalCreditCount, totalCreditValue: totalCreditValue,
      totalDebitCount: totalDebitCount, totalDebitValue: totalDebitValue,
      totalCreditSuccessCount: totalCreditSuccessCount, totalCreditErrorCount: totalCreditErrorCount,
      totalDebitSuccessCount: totalDebitSuccessCount, totalDebitErrorCount: totalDebitErrorCount,
      totalInternalCount: totalInternalCount, totalOtherCount: totalOtherCount

    });
    setAllTxList(bankTxList);

    let keywords = ((filterObj) && filterObj.keyword !== '') ? filterObj.keyword : inlineKeywords;
    // set tx list
    if (keywords) {
      requestSearch(keywords)
    } else {
      setTxList(bankTxList);
    }
  }

  /**
   * Handler search event typing in this component
   */
  const handleSearch = (keywords) => {
    setInlineKeywords(keywords);
    requestSearch(keywords);
  }

  /**
   * Search tx by content
   */
  const requestSearch = (keywords) => {
    const filteredRows = allTxList.filter((txObj) => {

      // build content for search
      const content = txObj.txId + ' ' + txObj.content + ' ' + txObj.customerId + ' ' + txObj.txnRef + ' ' + txObj.srcPhone;

      return content.toLowerCase().includes(keywords.toLowerCase());
    });
    setTxList(filteredRows);
  };

  /**
   * Filter tx by statuses
   */
  const filterByStatuses = () => {

    const filteredRows = allTxList.filter((txObj) => {

      // credit success
      if ((filterObj.creditSuccess) && (txObj.amount > 0) && isCreditSuccess(txObj.paymentStatus)) {
        return true;
      }

      // credit error
      if ((filterObj.creditError) && (txObj.amount > 0) && isCreditError(txObj.paymentStatus)) {
        return true;
      }

      // debit success
      if ((filterObj.debitSuccess) && (txObj.amount < 0) && isDebitSuccess(txObj.paymentStatus)) {
        return true;
      }

      // debit error
      if ((filterObj.debitError) && (txObj.amount < 0) && isDebitError(txObj.paymentStatus)) {
        return true;
      }

      // internal
      if ((filterObj.internal) && isInternalTx(txObj.paymentStatus)) {
        return true;
      }

      // other
      if ((filterObj.other) && isOtherTx(txObj.paymentStatus)) {
        return true;
      }

    });

    setTxList(filteredRows);
  }

  // Get ben bank name
  const getBenBankName = (benAccountNumber) => {

    let bankAccount = getBankAccount(bankAccounts, benAccountNumber);
    if (bankAccount) {
      return bankAccount.bankName;
    } else {
      return "";
    }
  }

  /**
   * Find order to match with bank tx
   */
  const searchingOrder = async (txObj) => {
    // close details dialog if shown
    setShowTxDetailsDialog(false);

    setSelectedTxObj(txObj);
    setShowMatchingOrder(true);
  }
  const closeMatchingOrder = () => {
    setShowMatchingOrder(false);
  }

  /**
   * Find multiple order to match with bank tx
   */
   const searchingMultipleOrders = async (txObj) => {
    // close details dialog if shown
    setShowTxDetailsDialog(false);

    setSelectedTxObj(txObj);
    setShowMatchingMultipleOrders(true);
  }
  const closeMatchingMultipleOrders = () => {
    setShowMatchingMultipleOrders(false);
  }

  // Show tx details
  const [showTxDetailsDialog, setShowTxDetailsDialog] = useState(false);
  const showTxDetails = (txObj) => {
    setSelectedTxObj(txObj);
    setShowTxDetailsDialog(true);
  }
  const closeTxDetailsDialog = () => {
    setShowTxDetailsDialog(false);
  }

  // Show order details
  const [showOrderDetailsDialog, setShowOrderDetailsDialog] = useState(false);
  const showOrderDetails = (txObj) => {
    setSelectedTxObj(txObj);
    if (txObj.txnRef) {
      setShowOrderDetailsDialog(true);
    }
  }
  const closeOrderDetailsDialog = () => {
    setShowOrderDetailsDialog(false);
  }

  /**
 * Admin request refund transaction
 */
  const [showRefundOrderDialog, setShowRefundOrderDialog] = useState(false);
  const requestRefund = (txObj) => {
    setSelectedTxObj(txObj);
    setShowRefundOrderDialog(true);
  }
  const closeRefundOrderDialog = () => {
    setShowRefundOrderDialog(false);
  }


  /**
   * Origin admin sync bank tx
   */
  const [showSyncBankTx, setShowSyncBankTx] = useState(false);
  const requestSyncBankTx = (txObj) => {
    setSelectedTxObj(txObj);
    setShowSyncBankTx(true);
  }
  const closeSyncBankTx = () => {
    setShowSyncBankTx(false);
  }


  // Get tx actions
  const getActions = (txObj, index) => {

    if (partnerObj.delegate) {
      return (
        <Tooltip title="Đồng bộ lại giao dịch" aria-label="search order">
          <span>
            <IconButton size="small" onClick={() => requestSyncBankTx(txObj)}>
              <FindReplaceOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    // matching order
    if (!reportObj.transparency && txObj.paymentStatus === PaymentStatus.PAYMENT_ORDER_NOT_FOUND) {
      return (
        <Tooltip title="Khớp đơn" aria-label="search order">
          <span>
            <IconButton size="small" onClick={() => searchingOrder(txObj)}>
              <FindReplaceOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      );
    }

    // refund
    if (txObj.paymentStatus === PaymentStatus.PAYMENT_OVER_FUND
      || txObj.paymentStatus === PaymentStatus.PAYMENT_DOUBLE_PAY
      || txObj.paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND
      || (txObj.serviceId === ServiceTypes.VERIFY_BANK_ACOUNT && txObj.statusId === TxStatus.SUCCESS)) {
      return (
        <Tooltip title="Hoàn tiền" aria-label="Verify"><span>
          <IconButton size="small" onClick={() => requestRefund(txObj)}>
            <ReplayOutlinedIcon />
          </IconButton></span>
        </Tooltip>
      );
    }

    return (
      <div>
      </div>
    );
  }

  const getDetailsAction = (txObj, index) => {
    return (
      <Tooltip title="Xem chi tiết" aria-label="Details"><span>
        <IconButton size="small" onClick={() => showTxDetails(txObj)}>
          <MoreOutlinedIcon />
        </IconButton></span>
      </Tooltip>
    );
  }

  // update bank tx list
  useEffect(() => {
    updateBankTxList();
  }, [props.bankTx, props.accountNumber]);

  // search tx list
  useEffect(() => {
    try {
      if ((filterObj) && filterObj.keyword !== '') {
        requestSearch(filterObj.keyword);
      } else if ((filterObj) && !(filterObj.all)) {
        filterByStatuses();
      } else {
        setTxList(allTxList);
      }
    } catch (error) {
      console.log("Error show bank tx list: " + error);
    }
  }, [props.filterObj]);


  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>

        {
          (props.showHeader === true) &&
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item className={tableStyles.transHead}>
              <Box display="inline" alignItems="flex-end" onClick={() => props.refreshCallback("BANKS")}>
                <span className={tableStyles.title}>BIẾN ĐỘNG SỐ DƯ</span>
              </Box>
            </Grid>

            <Grid item className={tableStyles.transHead}>
              {
                <Paper className={tableStyles.searchBox}>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Tìm nội dung"
                    inputProps={{ 'aria-label': 'tim noi dung' }}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              }
            </Grid>
          </Grid>
        }

        {
          (props.showReport === true) &&
          <BankTxReportTable reportObj={reportObj} filterObj={filterObj} onToggleFilter={props.onToggleFilter} />
        }

        <TableContainer className={tableStyles.tableContainer} component={Paper} >

          <Table className={tableStyles.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow className={tableStyles.row} style={{ height: 24 }}>
                <TableCell align="center" className={tableStyles.colTxId}>Mã GD</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Số tiền</TableCell>
                <Hidden lgDown>
                  <TableCell className={tableStyles.colHeader}>Tài khoản nhận</TableCell>
                  <TableCell align="center" className={tableStyles.colHeader}>{reportObj.transparency ? "Số dư" : "Mã đơn"}</TableCell>
                  <TableCell align="center" className={tableStyles.colHeader}>SIM nhận</TableCell>
                </Hidden>
                <TableCell className={tableStyles.colHeader}>Thời gian</TableCell>
                <TableCell className={tableStyles.colContent}>Nội dung</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Thao tác</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? txList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : txList
              ).map((txObj, index) => (
                <TableRow hover key={index} className={tableStyles.row} style={{ backgroundColor: (txObj.txId === selectedTxObj.txId) ? Colors.CK_BG_WARNING : '' }}>

                  <TableCell align="center" className={tableStyles.colTxId} style={{ color: Colors.DARK_BLUE }} onClick={() => showTxDetails(txObj)}>{txObj.txId}</TableCell>

                  {
                    (txObj.amount <= 0) ? (
                      <TableCell className={tableStyles.colValue} style={{ color: Colors.ORANGE }}>
                        <NumberFormat value={txObj.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
                      </TableCell>
                    ) :
                      (
                        <TableCell className={tableStyles.colValue} style={{ color: Colors.DARK_BLUE }}>
                          +<NumberFormat value={txObj.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
                        </TableCell>
                      )
                  }

                  <Hidden lgDown>
                    <TableCell align="left" className={tableStyles.colBenBank}>
                      <IconButton size="small">
                        <SvgIcon component={getBankSvgIcon(getBenBankName(txObj.ben))} viewBox="0 0 48 48" />
                      </IconButton>
                      {txObj.ben}
                    </TableCell>

                    {
                      (txObj.transparency) ? (
                        <TableCell className={tableStyles.colValue} style={{ color: Colors.DARK_BLUE }}>
                          <NumberFormat value={txObj.balance} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} />
                        </TableCell>
                      ) :
                        (
                          <TableCell align="center" className={tableStyles.colTextStatus}
                            style={{ color: getPaymentStatusColor(txObj.paymentStatus), cursor: 'pointer' }}
                            onClick={() => showOrderDetails(txObj)}>
                            {txObj.txnRef}<br />
                            {getPaymentStatusText(txObj.paymentStatus)}
                          </TableCell>
                        )
                    }

                    <TableCell align="center" className={tableStyles.colDestPhone}>{txObj.destPhone}</TableCell>
                  </Hidden>
                  <TableCell className={tableStyles.colTime}>{txObj.time}<br />{getTxTimeText(txObj.created)}</TableCell>
                  <TableCell className={tableStyles.colContent}>{txObj.content}</TableCell>
                  <TableCell align="right" className={tableStyles.colActions}>
                    <div>
                      {getActions(txObj, index)}
                      {getDetailsAction(txObj, index)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}

              {
                (txList.length === 0) &&
                <TableRow key={0} className={tableStyles.row} style={{ }}>
                  <TableCell colSpan={8} align="center" className={tableStyles.colHeader}>
                    <Box p={2}>
                      Hệ thống chưa nhận được giao dịch nào.
                      <br/>
                      Mời bạn đăng ký Biến động số dư của tài khoản gửi về Chuyenkhoan.com
                    </Box>
                  </TableCell>
                </TableRow>
              }

            </TableBody>

            {
              (txList.length > 5) &&
              <TableFooter >
                <TableRow className={tableStyles.paging}>
                  <TablePagination
                    rowsPerPageOptions={[5, 11, 22, 33]}
                    colSpan={9}
                    count={txList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Số GĐ' },
                      native: true,
                    }}
                    labelRowsPerPage="Số GD / trang:"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            }

          </Table>
        </TableContainer>
        {
          showTxDetailsDialog &&
          <BankTxDetailsDialog open={showTxDetailsDialog} closeCallback={closeTxDetailsDialog}
            bankTxObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            searchingOrder={searchingOrder}
            searchingMultipleOrders={searchingMultipleOrders}
          />
        }
        {
          showOrderDetailsDialog &&
          <OrderDetailsDialog open={showOrderDetailsDialog} closeCallback={closeOrderDetailsDialog}
            orderTxObj={{ txnRef: selectedTxObj.txnRef }}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
        {
          showMatchingOrder &&
          <MatchingOrderDialog open={showMatchingOrder} closeCallback={closeMatchingOrder}
            bankTxObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
        {
          showMatchingMultipleOrders &&
          <MatchingMultipleOrdersDialog open={showMatchingMultipleOrders} closeCallback={closeMatchingMultipleOrders}
            bankTxObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
        {
          showRefundOrderDialog &&
          <CkqrRefundOrder open={showRefundOrderDialog} closeCallback={closeRefundOrderDialog}
            txObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

        {
          showSyncBankTx &&
          <SyncSmsToBankTxDialog open={showSyncBankTx} closeCallback={closeSyncBankTx}
            txObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

      </ThemeProvider>
    </StyledEngineProvider>
  );
}

