
import { ckTableStyles } from '../../../ckcore/ui/CkStyles';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { getPaymentStatusColor, getPaymentStatusText, getStatusColor, getTxStatusText, getTxTimeVN } from '../../../ckcore/core/TxUtils';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import IconButton from '@mui/material/IconButton';

import NumberFormat from 'react-number-format';
import Colors from '../../../ckcore/core/Colors';

export default function BankTxReportTable(props) {
  const tableStyles = ckTableStyles();
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };
  const reportObj = props.reportObj;
  const filterObj = props.filterObj;

  const onToggleFilter = (name) => {
    props.onToggleFilter(name);
  }

  return (
    <TableContainer className={tableStyles.tableContainer} sx={{ backgroundColor: 'white', marginBottom: 3 }}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" width={'15%'} className={tableStyles.colHeader} sx={cellBorder}>Tổng số giao dịch:
            </TableCell>
            <TableCell align="left" width={'3%'}>
              <IconButton size="small" color={(filterObj.all) ? "primary" : "default"} onClick={() => {onToggleFilter('all')}}>
                {(filterObj.all) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left" width={'16%'} className={tableStyles.colHeaderStrong} sx={cellBorder}>{reportObj.totalTxCount}</TableCell>

            <TableCell align="left" width={'18%'} className={tableStyles.colHeader} sx={cellBorder}>Số tiền thu:</TableCell>
            <TableCell align="left" width={'3%'}></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder} style={{ color: Colors.DARK_GREEN }}>
              +<NumberFormat value={reportObj.totalCreditValue} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' VND'} />
            </TableCell>

            <TableCell align="left" width={'18%'} className={tableStyles.colHeader} sx={cellBorder}>Số tiền chi:</TableCell>
            <TableCell align="left" width={'3%'}></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder} style={{ color: Colors.ORANGE }}>
              <NumberFormat value={reportObj.totalDebitValue} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' VND'} />
            </TableCell>


          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" width={'15%'} className={tableStyles.colHeader} sx={cellBorder}>CK nội bộ: </TableCell>
            <TableCell align="left" width={'3%'}><IconButton size="small" onClick={() => {onToggleFilter('internal')}}>
                {(filterObj.internal) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder}>{reportObj.totalInternalCount}</TableCell>

            <TableCell align="left" width={'18%'} className={tableStyles.colHeader} sx={cellBorder}>Đã xác nhận thu: </TableCell>
            <TableCell align="left" width={'3%'}><IconButton size="small" onClick={() => {onToggleFilter('creditSuccess')}}>
                {(filterObj.creditSuccess) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder} style={{ color: Colors.DARK_GREEN }}>{reportObj.totalCreditSuccessCount}</TableCell>

            <TableCell align="left" width={'18%'} className={tableStyles.colHeader} sx={cellBorder}>Đã xác nhận chi: </TableCell>
            <TableCell align="left" width={'3%'}><IconButton size="small" onClick={() => {onToggleFilter('debitSuccess')}}>
                {(filterObj.debitSuccess) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder} style={{ color: Colors.DARK_GREEN }}>{reportObj.totalDebitSuccessCount}</TableCell>


          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" width={'15%'} className={tableStyles.colHeader} sx={cellBorder}>Giao dịch khác: </TableCell>
            <TableCell align="left" width={'3%'}><IconButton size="small" onClick={() => {onToggleFilter('other')}}>
                {(filterObj.other) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder}>{reportObj.totalOtherCount}</TableCell>

            <TableCell align="left" width={'16%'} className={tableStyles.colHeader} sx={cellBorder}>Giao dịch thu lỗi: </TableCell>
            <TableCell align="left" width={'3%'}><IconButton size="small" onClick={() => {onToggleFilter('creditError')}}>
                {(filterObj.creditError) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder} style={{ color: Colors.RED }}>{reportObj.totalCreditErrorCount}</TableCell>

            <TableCell align="left" width={'16%'} className={tableStyles.colHeader} sx={cellBorder}>Giao dịch chi lỗi: </TableCell>
            <TableCell align="left" width={'3%'}><IconButton size="small" onClick={() => {onToggleFilter('debitError')}}>
                {(filterObj.debitError) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
              </IconButton></TableCell>
            <TableCell align="left" width={'12%'} className={tableStyles.colHeaderStrong} sx={cellBorder} style={{ color: Colors.RED }}>{reportObj.totalDebitErrorCount}</TableCell>


          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>

  );
}