import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';

import { ckTheme } from '../../ckcore/ui/CkStyles';

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';

import OrderProductTable from './ui/OrderProductTable';
import OrderPaymentHistory from './ui/OrderPaymentHistory';
import OrderChangeHistory from './ui/OrderChangeHistory';
import OrderContactTable from './ui/OrderContactTable';
import OrderPaymentInfo from './ui/OrderPaymentInfo';
import { getBankInfo, getDefaultBanks } from '../../ckcore/core/CkBank';
import CKQRGen from '../../ckcore/core/CKQRGen';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import ServiceTypes from '../../ckcore/core/ServiceTypes';
import PurchaseRequestContactTable from './ui/PurchaseRequestContactTable';
import { toVnAscii, useInterval } from '../../ckcore/core/CkUtils';
import { dataURItoBlob, downloadBase64Png } from '../../ckcore/core/ExportUtils';

  
export default function OrderDetailsDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const orderTxObj = props.orderTxObj;

  const [delay, setDelay] = useState(1000);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [messages, setMessages] = useState({ info: '', success: '', error: '' });


  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const [orderDetailsObj, setOrderDetailsObj] = useState({});
  const [products, setProducts] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);

  // load order details
  const loadOrderDetails = async () => {
    try {

      if (loading) {
        return;
      }

      // load request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_DETAILS,
        partnerId: userObj.partnerId,
        order: orderTxObj,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);
      setMessages({ info: '', success: '', error: '' });

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setBankAccounts(response.bankAccounts);

        var productList = response.order.order.products;
        if (!productList) {
          productList = [];
          productList.push(response.order.order.product);
        }
        setProducts(productList);

        setOrderDetailsObj(response.order);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order details, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get order details.. " + error);
    }

    setLoading(false);

  };

  /**
   * Update order information
   */
  const updateOrder = async () => {

    try {

      if (loading || !changed) {
        return;
      }

      // update request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_MANUAL_UPDATE,
        partnerId: userObj.partnerId,
        order: orderDetailsObj,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ckmsg)
      };
      setLoading(true);
      setMessages({ ...messages, success: '', error: '' });

      // fetch response
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/dashboard/order?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        console.log("Update order success");
        setBankAccounts(response.bankAccounts);

        var productList = response.order.order.products;
        if (!productList) {
          productList = [];
          productList.push(response.order.order.product);
        }
        setProducts(productList);

        setOrderDetailsObj(response.order);

        setChanged(false);
        setMessages({ ...messages, success: 'Cập nhật đơn hàng thành công' });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update order, msg: " + response.msg);
        setMessages({ ...messages, error: 'Cập nhật đơn hàng bị lỗi' });
      }
    } catch (error) {
      console.log("Error update order.. " + error);
    }

    setLoading(false);

  };

  /**
   * Change ben bank account
   */
  const changeBankAccount = (bankAccount) => {

    // copy order data
    var orderData = { ...orderDetailsObj };
    orderData.order.bank_name = bankAccount.bankName;
    orderData.order.account_number = bankAccount.accountNumber;
    orderData.order.account_name = bankAccount.accountName;

    var remainAmount = orderDetailsObj.amount;
    if (orderDetailsObj.order.order_paid) {
      remainAmount = orderDetailsObj.amount - orderDetailsObj.order.order_paid;
    }

    // change CKQR data
    var bankObj = getBankInfo(getDefaultBanks(), bankAccount.bankName);
    orderData.bankCode = bankObj.bankCodeNumber;
    orderData.ben = bankAccount.accountNumber;
    orderData.ckqr = CKQRGen.generateCKQR(bankObj.bankCodeNumber.toString(), bankAccount.accountNumber, remainAmount, orderDetailsObj.order.ckqr_content);

    // set change
    setOrderDetailsObj(orderData);
    setChanged(true);
    setMessages({ ...messages, info: 'Bạn vừa đổi thông tin thanh toán, bấm "CẬP NHẬT" để lưu lại thay đổi.' });
  }


  /**
   * Print order to PDF
   */
  const printOrder = async (fileType) => {
    try {

      if (loading) {
        return;
      }

      // load request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_PRINT,
        partner: partnerObj,
        order: orderDetailsObj,
        fileType: fileType,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ckmsg)
      };
      setLoading(true);

      // fetch response
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/export/print?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        if(fileType === 'png') {
          let fileName = toVnAscii(partnerObj.name).replace(" ", "-") + '_' + orderDetailsObj.txnRef + '.png';
          console.log("Print png image success to file: " + fileName);
          downloadBase64Png(response.blob, fileName);
        } else {
          console.log("Print pdf success");
          // Create a Blob from the PDF Stream
          const blob = dataURItoBlob(response.blob);
          const fileURL = URL.createObjectURL(blob); //Open the URL on new Window
          window.open(fileURL);
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR print order, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error print order.. " + error);
    }

    setLoading(false);

  }

  /**
   * Get Dialog title base on service type
   */
  const getDialogTitle = (orderObj) => {
    let serviceId = orderObj.serviceId;
    if (serviceId === ServiceTypes.PURCHASE_REQUEST) {
      return <Box>Yêu cầu chi phí <strong>#{orderObj.txnRef}</strong></Box>;
    } else if (serviceId === ServiceTypes.PURCHASE_ORDER) {
      return <Box>Đơn mua hàng <strong>#{orderObj.txnRef}</strong> mã giao dịch {orderObj.txId}</Box>;
    } else if (serviceId === ServiceTypes.PAYMENT_REQUEST) {
      return <Box>Yêu cầu thanh toán <strong>#{orderObj.txnRef}</strong></Box>;
    }
    return <Box>Đơn hàng bán <strong>#{orderObj.txnRef}</strong> mã giao dịch {orderObj.txId}</Box>;
  }

  /**
   * Get contact table
   */
  const getContactTable = (orderObj) => {

    let serviceId = orderObj.serviceId;

    if (serviceId === ServiceTypes.PURCHASE_REQUEST) {
      return <PurchaseRequestContactTable orderObj={orderDetailsObj} />
    }

    return <OrderContactTable orderObj={orderDetailsObj} />
  }

  // Use interval to refresh data
  useInterval(() => {

    loadOrderDetails();

    setDelay(5000);
  }, delay);


  useEffect(() => {
    // reload on refresh
    loadOrderDetails();
  }, []);


  // Add bank dialog
  return (
    <ThemeProvider theme={ckTheme}>
      <Dialog open={props.open} fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item>
              {getDialogTitle(orderDetailsObj)}
            </Grid>
            <Grid item>
              <span>
                <Button className={classes.actionButton} disabled={loading} variant="outlined" color="primary" onClick={() => printOrder()}>GỬI EMAIL</Button>
                <Button className={classes.actionButton} disabled={loading} variant="outlined" color="primary" onClick={() => printOrder('png')}>TẢI ĐƠN (PNG)</Button>
                <Button className={classes.actionButton} disabled={loading} variant="outlined" color="primary" onClick={() => printOrder('pdf')}>IN ĐƠN (PDF)</Button>
              </span>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box style={{ marginBottom: 12 }}>
            {
              getMessageBox(messages.error, messages.success, messages.info)
            }
          </Box>

          {
            orderDetailsObj.order &&
            <Grid container spacing={2}>

              <Grid item xs={6}>
                {getContactTable(orderDetailsObj)}
              </Grid>
              <Grid item xs={4}>
                <OrderPaymentInfo orderObj={orderDetailsObj} bankAccounts={bankAccounts} changeBankAccount={changeBankAccount} />
              </Grid>
              <Grid item xs={2}>
                <Box style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                  Chuyển Khoản <span style={{ color: Colors.RED, fontWeight: 'bold' }}>Viet</span><span style={{ color: Colors.DARK_BLUE, fontWeight: 'bold' }}>QR</span>
                </Box>
                <Box style={{ textAlign: 'center', margin: 'auto' }}>
                  <QRCode size={140} eyeRadius={3} value={orderDetailsObj.ckqr} />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <OrderProductTable orderObj={orderDetailsObj} products={products} />
              </Grid>

              <Grid item xs={6}>
                <Typography component="div" variant="body1" style={{ marginTop: 8, marginBottom: 3, fontWeight: 'bold' }}>Lịch sử đơn hàng: </Typography>
                <OrderChangeHistory orderObj={orderDetailsObj} />
              </Grid>
              <Grid item xs={6}>
                <Typography component="div" variant="body1" style={{ marginTop: 8, marginBottom: 3, fontWeight: 'bold' }}>Lịch sử thanh toán: </Typography>
                <OrderPaymentHistory orderObj={orderDetailsObj} />
              </Grid>
            </Grid>
          }

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">Trở lại</Button>
          <Button className={classes.actionButton} disabled={!changed} variant="outlined" color="warning" onClick={() => updateOrder()}>CẬP NHẬT</Button>

        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}


const useStyles = makeStyles(theme => ({
  root: {

  },

  actionButton: {
    marginLeft: 8,
  },
}));
