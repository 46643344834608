import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { isValidUrl, isValidDomain, isValidNameUTF8, isValidEmail, isValidAddress } from '../../ckcore/core//Validator';
import { getMessageBox } from '../../ckcore/core//TxUtils';
import Colors from '../../ckcore/core//Colors';
import Config from '../../ckcore/core//Config';
import TxStatus from '../../ckcore/core//TxStatuses';
import User from '../../ckcore/core//User';
import TxTypes from '../../ckcore/core/TxTypes';

export default function Profile(props) {
  const classes = useStyle();

  const userObj = props.userObj;
  // const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const appId = props.appId;

  const [profileConfig, setProfileConfig] = useState({});
  const [configChanged, setConfigChanged] = useState(false);
  const [changedValue, setChangedValue] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [requestAuthCode, setRequestAuthCode] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeValue = (field) => event => {
    profileConfig[field]["value"] = event.target.value;
    setConfigChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  const handleChangeAuthCode = () => event => {
    setAuthCode(event.target.value)
  }

  // Validate all key
  const validateKeys = () => {

    var allValid = true;

    profileConfig.fields.map((field) => {

      var fieldObj = profileConfig[field];

      // set to valid first
      profileConfig[field]["error"] = false;

      if (field === 'email') {

        if (!isValidEmail(fieldObj["value"])) {
          profileConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid Email = " + fieldObj["value"]);
        }
      } else if (field === 'website') {

        if (!isValidUrl(fieldObj["value"]) && !isValidDomain(fieldObj["value"])) {
          profileConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid Wesite = " + fieldObj["value"]);
        }
      } else if (field === 'address' || field === 'province') {

        if (!isValidAddress(fieldObj["value"])) {
          profileConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid Address = " + fieldObj["value"]);
        }
      } else if (!Array.isArray(fieldObj["value"]) && !isValidNameUTF8(fieldObj["value"])) {
        profileConfig[field]["error"] = true;
        console.log("Invalid " + fieldObj["name"] + " = " + fieldObj["value"]);
        allValid = false;
      } else {
        profileConfig[field]["error"] = false;
      }

      // Validate emailRecipients

      // Validate smsRecipients


    });

    setChangedValue(!changedValue);

    return allValid;
  }


  const submitForm = (event) => {

    event.preventDefault();
    event.stopPropagation();

    setSuccessMsg("");
    setErrorMsg("");

    if (validateKeys()) {
      if (authCode === "") {
        requestEmailAuthCode();
      } else {
        // do update forward config
        processUpateProfileConfig();
      }
    } else {
      setErrorMsg("Thông tin không hợp lệ, vui lòng kiểm tra lại");
    }

  }

  /**
   * Request send authCode to email
   */
  const requestEmailAuthCode = async () => {

    setLoading(true);

    const emailAddress = profileConfig["email"]["value"];

    // send authCode to email
    const authCodeMsg = {
      txTypeId: TxTypes.TX_USER_RESEND_AUTHCODE,
      email: emailAddress,
      timestamp: (new Date()).getTime(),
      identity: User.getIdentity()
    };

    try {

      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(authCodeMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {
        setRequestAuthCode(true);
      } else {
        console.log("Get authCode error: " + response.msg);
        setErrorMsg('Gửi yêu cầu xác minh email bị lỗi, mong bạn vui lòng thử lại sau.');
      }
    } catch (error) {
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
      console.log("Request send authCode error: " + error);
    }

    setLoading(false);
  }



  // load forward config
  const loadAppConfig = async () => {

    setSuccessMsg("");
    setErrorMsg("");

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {

        setProfileConfig(response.appConfig);
        // console.log("Loaded Profile config: " + JSON.stringify(response.appConfig));

      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // Update Forward API config
  const processUpateProfileConfig = async () => {

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      appConfig: profileConfig,
      authCode: authCode,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {

        setConfigChanged(false);
        setSuccessMsg("Cập nhật thông tin thành công");

        // request to refresh after update profile
        setTimeout(
          () => props.refreshCallback("HOME"), 2222
        );

      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        
        setErrorMsg("Mã xác minh không hợp lệ");        

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request update error with statuses: " + response.statusId);
        setErrorMsg("Cập nhật thông tin bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMsg("Cập nhật thông tin bị lỗi, vui lòng thử lại sau.")
      console.log("Error update Profile config " + error);
    }
  }

  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);

  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = profileConfig[field];
    const isError = (profileConfig[field]["error"]);

    return (
      <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
        <Box className={classes.fieldNameStyle}>
          {fieldObj["name"]}:
        </Box>
        <Box className={classes.fieldValueStyle}>
          <TextField
            error={isError}
            type="text"
            className={classes.textFieldValue}
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            fullWidth
            size="small"
            variant="outlined"
            value={fieldObj["value"]}
            margin="dense"
            label={fieldObj["label"]}
            disabled={((fieldObj["disabled"]) ? fieldObj["disabled"] : false) || requestAuthCode}
            onChange={handleChangeValue(field)}
          />
        </Box>

      </Box>
    );

  }

  return (
    <Container maxWidth="md">
      <Box className={classes.paper}>
        <h3 className={classes.title}>CẬP NHẬT THÔNG TIN CÁ NHÂN</h3>

        <Box style={{paddingBottom: 20}}>
          {
            getMessageBox(errorMsg, successMsg, "Mời bạn cập nhật email và địa chỉ")
          }          
        </Box>

        <form className={classes.box_form} onSubmit={submitForm}>
          {
            (profileConfig.fields) &&
            profileConfig.fields.map((field, index) => (
              getFieldConfig(field)
            ))
          }
          <div className={classes.grow}></div>

          {
            requestAuthCode &&
            <Box justifyContent="center" alignItems="center" style={{marginBottom: 20}}>
              <div style={{padding: 8}}>Mời bạn nhập mã xác minh địa chỉ email</div>
              <TextField
                type="number"
                className={classes.textFieldValue}
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                size="small"
                autoFocus={true}
                variant="outlined"
                value={authCode}
                margin="dense"
                label={"Mã xác minh"}
                onChange={handleChangeAuthCode()}
                disabled={(successMsg !== "")}
              />
              <div style={{padding: 20, paddingBottom: 8}}>Vui lòng kiểm tra email cả trong mục Spam/Junk nếu bạn không thấy trong Inbox</div>
            </Box>
          }


          <Divider />

          <Box >

            <Button className={classes.ckButton} variant="contained" color="primary" onClick={() => props.refreshCallback("HOME")}>
              Trở lại
            </Button>
            {
              !requestAuthCode &&
              <Button className={classes.ckButton} variant="contained" color="primary" type="submit" disabled={!configChanged}>
                Cập nhật
              </Button>
            }
            {
              requestAuthCode &&
              <Button className={classes.ckButton} variant="contained" color="primary" type="submit" disabled={(successMsg !== "")}>
                Xác minh
              </Button>
            }


          </Box>

        </form>
      </Box>
    </Container>
  );
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },

  fieldNameStyle: {
    width: 111,
    textAlign: "right",
  },

  fieldValueStyle: {
    width: 333,
    marginLeft: 8,
    marginRight: 8,
    textAlign: "left",
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldKey: {
  },

  textFieldValue: {
  },

  textFieldOption: {
  },

  grow: {
    flexFlow: 1,
    minHeight: "15px",
  },

  ckButton: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));


