import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { ckApiStyles } from '../ApiStyles';

export default function WebhookGetfly(props) {

  const classes = ckApiStyles();

  const [webhookObj, setWebhookObj] = useState(props.webhookObj);

  /**
   * Set change, validate, and callback
   */
  const handleChange = (name, value) => {

    var apiObj = {...webhookObj.api};
    apiObj[name] = value;

    setWebhookObj({ ...webhookObj, api: apiObj });
  };

  const handleCheck = (name, value) => {
    setWebhookObj({ ...webhookObj, [name]: value });
  };

  useEffect(() => {

    // callback update change
    props.handleChange(webhookObj);

  }, [webhookObj]);

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={(webhookObj.enable)}
              onChange={e => handleCheck("enable", e.target.checked)}
              name="enable"
              color={(webhookObj.enable) ? "primary" : "success"}
            />
          }
          label={<Typography variant="h6" style={{color: webhookObj.enable ? "primary" : "success"}}>Đăng ký gửi thông tin chuyển khoản tới Getfly</Typography>}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Base URL (endpoint)"
          placeholder='Base URL có dạng: https://tencongty.getflycrm.com'
          disabled={!webhookObj.enable}
          value={(webhookObj.api.endpoint) ? webhookObj.api.endpoint : ""}
          onChange={e => handleChange('endpoint', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Getfly API Key"
          placeholder='Ví dụ API-KEY: 9HzV4BkrWu26Qj6YPIbRPLvvp2kkbQ'
          disabled={!webhookObj.enable}
          value={(webhookObj.api.client_key) ? webhookObj.api.client_key : ""}
          onChange={e => handleChange('client_key', e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Box>
          Để lấy được key này bạn phải đăng nhập vào tài khoản quản trị {'>'} Getfly CRM {'>'} Tích hợp {'>'} Getfly API Key.
        </Box>
      </Grid>

    </Grid>
  );
}
