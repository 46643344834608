import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { isValidPhone, isValidPassword, isValidNameUTF8 } from '../core/Validator';
import Config from '../core/Config';
import TxStatus from '../core/TxStatuses';
import TxTypes from '../core/TxTypes';
import User from '../core/User';
import Actions from './Actions'
import ck_logo_round from '../images/ck_lgrb_256.png'

export default function SignUp(props) {

  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState(User.getDisplayPhone());
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [checkedAgree, setCheckedAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const inputName = (text) => {
    setName(text);
    setErrorMsg('');
  }

  const inputPhone = (number) => {
    setPhone(number);
    setErrorMsg('');
  }

  const inputPassword = (text) => {
    setPassword(text);
    setErrorMsg('');
  }

  const inputRetypePassword = (text) => {
    setRetypePassword(text);
    setErrorMsg('');
  }

  // Process register
  const doRegister = async () => {

    // clear error message
    setErrorMsg('');

    // validate name, phone and password
    if (!isValidNameUTF8(name) || !isValidPhone(phone) || !isValidPassword(password) || retypePassword !== password) {
      setErrorMsg('INPUT ERROR');
      return;
    }

    // need verify recaptcha first
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setErrorMsg("Chưa kiểm tra được mã bảo vệ. Bạn vui lòng thử lại.");
      return;
    }

    // get captcha
    const captcha = await executeRecaptcha('doSignup');

    // check loading
    if (loading) {
      return;
    }
    setLoading(true);

    // request data
    var date = new Date();

    // Set phone and name
    User.setPhone(phone);

    // register message
    const registerMsg = {
      txTypeId: TxTypes.TX_USER_REGISTER,
      timestamp: date.getTime(),
      name: name,
      captcha: captcha,
      identity: User.getIdentity()
    };

    try {
      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(registerMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.REGISTER_SUCCESS) {
        // console.log("Request register success, start authorise userId: " + response.userId);

        // Get user ID
        User.setUserId(response.userId);
        User.setPhone(response.phone);

        // show authorise
        props.actionCallback(Actions.AUTHORIZE, {isLogin: false, name: name, password: password, token: "", smsc: response.smsc});
      } else if (response.statusId === TxStatus.REGISTER_ERROR_PHONE_EXISTS) {
        console.log("Register error, user already registered, response status " + response.statusId);
        setErrorMsg('Số điện thoại đã được đăng ký.');
      } else if (response.statusId === TxStatus.AUTHORISE_ERROR_CAPTCHA_INVALID) {
        console.log("Authorise error, invalid captcha, response status " + response.statusId);
        setErrorMsg('Mã bảo về (captcha) không đúng. Mời bạn thử lại sau.');
      } else {
        console.log("Request register error with statuses: " + response.statusId);
        setErrorMsg('Lỗi đăng ký tài khoản: ' + response.msg);
      }
    } catch (error) {
      console.log("Register connection error");
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
    }

    setLoading(false);
  }

  /**
   * Handle submit on enter
   */
   const handleSubmit = (e) => {
    e.preventDefault();
    doRegister();
  }

  // RETURN REGISTER
  return (

    <Container component="main" maxWidth="xs">

      <Paper className={classes.paper}>
        <form style={{width: "100%"}} onSubmit={(e) => handleSubmit(e)}>

        <Grid container spacing={3} direction="column" justifyContent="space-around" alignItems="stretch">
          <Grid item xs={12}>
            <div className={classes.cklogo}>
              <img src={ck_logo_round} alt="Chuyển Khoản" width="72" height="72"/>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" component="h1" variant="h5" >
              ĐĂNG KÝ MỚI
            </Typography>
          </Grid>

          <Grid item xs={12}>

            <TextField id="input-name" size="small" variant="outlined" fullWidth
              label="Họ tên"
              type="text"
              autoFocus
              inputProps={{ autoComplete: 'new-password' }}
              value={name}
              onInput={e => inputName(e.target.value)}
              helperText={!isValidNameUTF8(name) && (errorMsg.length > 0) ? "Họ và tên từ 5 đến 45 ký tự, chỉ chấp nhận chữ cái tiếng Việt, số, dấu chấm, dấu gạch ngang, và dấu gạch chân" : ""}
              error={(errorMsg.length > 0) && !isValidNameUTF8(name)}
            />
          </Grid>

          <Grid item xs={12}>

            <TextField id="input-phone" size="small" variant="outlined" fullWidth
              label="Số điện thoại"
              type="tel"
              inputProps={{ autoComplete: 'new-password' }}
              value={phone}
              onInput={e => inputPhone(e.target.value)}
              helperText={!isValidPhone(phone) && (errorMsg.length > 0) ? "Số điện thoại không hợp lệ" : ""}
              error={(errorMsg.length > 0) && !isValidPhone(phone)}
            />
          </Grid>

          <Grid item xs={12}>

            <TextField id="input-password" size="small" variant="outlined" fullWidth
              label="Mật khẩu"
              type="password"
              inputProps={{ autoComplete: 'new-password' }}
              value={password}
              onInput={e => inputPassword(e.target.value)}
              helperText={!isValidPassword(password) && (errorMsg.length > 0) ? "Mật khẩu dài từ 6 tới 16 ký tự gồm chữ thường, chữ hoa, số hoặc ký tự đặc biệt" : ""}
              error={(errorMsg.length > 0) && !isValidPassword(password)}
            />

          </Grid>
          <Grid item xs={12}>

            <TextField id="retype-password" size="small" variant="outlined" fullWidth
              label="Nhập lại mật khẩu"
              type="password"
              inputProps={{ autoComplete: 'new-password' }}
              value={retypePassword}
              onInput={e => inputRetypePassword(e.target.value)}
              helperText={!isValidPassword(retypePassword) && (errorMsg.length > 0) ? "Mật khẩu nhập lại không đúng" : ""}
              error={(errorMsg.length > 0) && !isValidPassword(retypePassword) && (retypePassword !== password)}
            />

          </Grid>
          <Grid item xs={12}>
            <FormControlLabel 
              control={<Checkbox value="remember" color="primary" size="small"
                onChange={e => setCheckedAgree(e.target.checked)} />}
              label={
              <div>
                  <span>Tôi đồng ý </span>
                  <Link target="_blank" rel="noopener" href="https://chuyenkhoan.com/dieu-khoan-su-dung">điều khoản dùng</Link>
                  <span> CKPay</span>
              </div>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth
              disabled={loading || !checkedAgree}
              style={{fontWeight: 'bold'}}
              onClick={() => doRegister()}>ĐĂNG KÝ</Button>

          </Grid>

          {
            (errorMsg.length > 0) && isValidNameUTF8(name) && isValidPhone(phone) 
            && isValidPassword(password) && (retypePassword === password) &&
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle2" >
                {errorMsg}
              </Typography>
            </Grid>
          }

          <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item >
              <Typography variant="subtitle2" >Bạn đã có tài khoản? </Typography>
            </Grid>
            <Grid item >
              <Link className={classes.textButton} component="button" variant="body2" disableripple="true"
                onClick={() => props.actionCallback(Actions.SIGN_IN)}>Đăng nhập lại!</Link>
            </Grid>
          </Grid>

        </Grid>
        </form>
      </Paper>
    </Container>

  );

}

const useStyles = makeStyles(theme => ({

  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 389,
    minWidth: 389,
    padding: 20,
    textAlign: 'center',
    margin: 'auto',
  },

  cklogo: {
    textAlign: 'center',
    display: 'block',
    margin: "auto",
  },

}));
