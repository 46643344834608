import moment from "moment";

export const TIME_RANGES = [
  {value: 'today', name: 'Hôm nay'},
  {value: 'yesterday', name: 'Hôm qua'},
  {value: 'this_week', name: 'Tuần này'},
  {value: 'last_week', name: 'Tuần trước'},
  {value: 'this_month', name: 'Tháng này'},
  {value: 'last_month', name: 'Tháng trước'},
  {value: 'this_year', name: 'Năm nay'},
  {value: 'last_year', name: 'Năm ngoái'},
  {value: 'manual', name: 'Tự chọn'},
];

/**
 * Get date range by time range name
 */
export const getDateRange = (timeRange) => {
    
  var fromDate = ''; 
  var toDate = '' 
  if(timeRange === 'today') {
    fromDate = moment().startOf('day').format('YYYY-MM-DD');
    toDate = fromDate;

  } else if(timeRange === 'yesterday') {
    fromDate = moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
    toDate = fromDate;

  } else if(timeRange === 'this_week') {
    fromDate = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD');
    toDate = moment().format('YYYY-MM-DD');

  } else if(timeRange === 'last_week') {
    // use start of week from Monday
    fromDate = moment().subtract(1, 'weeks').startOf('week').add(1, 'days').format('YYYY-MM-DD');
    toDate = moment().subtract(1, 'weeks').endOf('week').add(1, 'days').format('YYYY-MM-DD');

  } else if(timeRange === 'this_month') {
    fromDate = moment().startOf('month').format('YYYY-MM-DD');
    toDate = moment().format('YYYY-MM-DD');

  } else if(timeRange === 'last_month') {
    fromDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    toDate =  moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

  } else if(timeRange === 'this_year') {
    fromDate = moment().startOf('year').format('YYYY-MM-DD');
    toDate = moment().format('YYYY-MM-DD');

  } else if(timeRange === 'last_year') {
    fromDate = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    toDate = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
  }

  return {fromDate: fromDate, toDate: toDate};
}