import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Colors from '../../ckcore/core/Colors';
import CKQRBox from '../../checkout/components/CKQRBox';
import { getBankInfo, getDefaultBanks } from '../../ckcore/core/CkBank';
import CKQRGen from '../../ckcore/core/CKQRGen';
import User from '../../ckcore/core/User';
import PopaConfig from './PopaConfig';
import TxStatus from '../../ckcore/core/TxStatuses';

// RANDOM transfer value for marketing
const marketing_values = [1011, 1022, 1033, 1055, 1066, 1088, 1099];

export default function BankMarketingDialog(props) {

    const partnerObj = props.partnerObj;
    const userObj = props.userObj;
    const popaObj = props.popaObj;
    const selectedBankAccount = props.selectedBankAccount;

    // get amount
    var amount = 222; //marketing_values[Math.floor(Math.random() * marketing_values.length)];

    // Cᴴᵁyᴱᴺᴳᴷᴴᴼᴬᴺcom ᴴᴼᵀᴿᴼᴷᴴ ᵀᴼ ᴳᴾ
    // Chuyenkhoanᴼcom ᴴᵀᴷᴴ
    // Max content length for VietinBank: 94 char (94 + 2 + 4)
    // Chuyenkhoan.com ho tro VietQR thanh toan tu dong LH 0906246196 ket noi chuyenkhoan.com xxx xxx
    // 00020101021238570010A00000072701270006970436011300210011311050208QRIBFTTA53037045407101111162980894Chuyenkhoan.com ho tro VietQR thanh toan tu dong LH 0906246196 ket noi chuyenkhoan.com xxx xxx5802VN630424C9
    //            'Chuyenkhoan.com CMNM 2022 va mong hop tac thanh toan VietQR voi abcwebsitexy.com LH 0906246196 
    //            'Chuyenkhoan.com CMNM 2022 va mong hop tac TT CK VietQR voi abcwebsitexyzzzzz.com LH 0906246196 
    // var message = 'Chuyenkhoan.com ho tro VietQR thanh toan tu dong LH 0949861101 ket noi web ' + popaObj.domain;
    var domain = popaObj.domain;
    var message = 'CMNM 2022 Chuyenkhoan.com ';
    if (domain.length <= 16) {
        message = 'CMNM 2022, Chuyenkhoan.com mong hop tac thanh toan CK VietQR voi ' + domain + " ZL 0949861101";
    } else {
        if (domain.length > 21) {
            domain = popaObj.domain.substring(0, 20) + '.';
        }
        message = 'CMNM 2022, Chuyenkhoan.com mong hop tac TT CK VietQR voi ' + domain + " ZL 0949861101";
    }

    // generate CKQR
    popaObj.accounts.map((bankAccount, index) => {
        var bankObj = getBankInfo(getDefaultBanks(), bankAccount.bankName);
        popaObj.accounts[index].ckqr = CKQRGen.generateCKQR(bankObj.bankCodeNumber.toString(), bankAccount.accountNumber, amount, message);
    });


    var ckqrInfo = {
        title: 'BANK MARKETING TO ' + popaObj.domain.toUpperCase(),
        subtitle: 'Chuyển khoản giới thiệu kết nối thanh toán',
        accounts: popaObj.accounts,
        amount: amount,
        message: message,
        guide: 'Mời bạn quét mã QR bằng ứng dụng ngân hàng',
        labels: {
            accountNumber: 'Số tài khoản',
            amount: 'Số tiền (VNĐ)',
            message: 'Nội dung',
            amountError: 'Số tiền không hợp lệ',
            messageError: 'Nội dung chuyển khoản không hợp lệ',
            copyNotify: 'Đã copy',
            messageGuide: 'Nội dung chuyển khoản:'
        }
    }

    const [popaStatus, setPopaStatus] = useState({
        statusId: popaObj.statusId,
        status: popaObj.status,
        category: (popaObj.category) ? popaObj.category : '',
        demand: (popaObj.demand) ? popaObj.demand : '',
        contact: (popaObj.contact) ? popaObj.contact : '',
        assignee: (popaObj.assignee) ? popaObj.assignee : 'huy',
        bankName: (popaObj.accounts.length > 0) ? popaObj.accounts[0].bankName : 'Vietcombank',
        accountNumber: (popaObj.accounts.length > 0) ? popaObj.accounts[0].accountNumber : '',
        accountName: (popaObj.accounts.length > 0) ? popaObj.accounts[0].accountName : '',
        accounts: popaObj.accounts,
        ticket: popaObj.ticket
    });

    const [banked, setBanked] = useState(popaObj.statusId === 2);
    const handleCheck = (name, value) => {
        setBanked(value);
    }

    // process update popa status
    const doUpdatePopaStatus = async () => {

        try {

            // load CKBox status
            var ckmsg = {
                txType: "update_status",
                domain: popaObj.domain,
                incId: popaObj.incId,
                category: popaStatus.category,
                demand: popaStatus.demand,
                contact: popaStatus.contact,
                statusId: 2,
                status: popaStatus.status,
                accounts: popaStatus.accounts,
                assignee: popaStatus.assignee,
                ticket: popaStatus.ticket,
                time: new Date().getTime(),
                identity: User.getIdentity(),
            };

            // fetch response
            const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
            const response = await rawResponse.json();

            if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
                setPopaStatus({ ...popaStatus, statusId: 2 });
            } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
                console.log("User session has expired!");
                props.logoutCallback();
            } else {
                console.log("ERROR update status, msg: " + response.msg);
            }
        } catch (error) {
            console.log("Error update status.. " + error);
        }
    };


    // User click close
    const handleClose = () => {
        props.closeCallback();
    };


    // Add bank dialog
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogContent dividers>

                        <CKQRBox
                            partnerId={10001}
                            ckqrInfo={ckqrInfo}
                        />

                    </DialogContent>
                    <DialogActions>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={banked}
                                    onChange={e => handleCheck("banked", e.target.checked)}
                                    name="bankedMarketing"
                                    color="primary"
                                />
                            }
                            label="Đã chuyển khoản giới thiệu"
                        />
                        <Button disabled={!banked || popaStatus.statusId === 2} color="primary" onClick={() => doUpdatePopaStatus()}>
                            Cập nhật
                        </Button>
                        <Button color="primary" onClick={() => handleClose()}>
                            Trở lại
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


const theme = createTheme({
    palette: {
        primary: {
            main: Colors.BG_BLUE,
        },
        secondary: {
            main: Colors.DARK_GREEN,
            contrastText: "#fff" //button text white instead of black
        },
        text: {
            disabled: Colors.DARK_BLUE
        }
    },
});
