
import { ckTableStyles } from '../../../ckcore/ui/CkStyles';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { getPaymentStatusColor, getPaymentStatusText, getStatusColor, getTxStatusText, getTxTimeVN } from '../../../ckcore/core/TxUtils';

export default function OrderContactTable(props) {
  const tableStyles = ckTableStyles();
  const orderObj = props.orderObj;
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>
        <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>
              <strong>Trạng thái:</strong> <span style={{ color: getStatusColor(orderObj.txStatusId) }}> {getTxStatusText(orderObj.txStatusId)}</span>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <strong>Thanh toán:</strong> <span style={{ color: getPaymentStatusColor(orderObj.paymentStatus) }}> {getPaymentStatusText(orderObj.paymentStatus)}</span></TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}><strong>Khách hàng:</strong> {orderObj.order.customer_id} {orderObj.order.customer_name}</TableCell>
            <TableCell align="left" className={tableStyles.colHeader}><strong>SĐT:</strong> {orderObj.order.customer_phone}</TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}><strong>Người nhận:</strong></TableCell>
            <TableCell align="left" className={tableStyles.colHeader}></TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell colSpan={2} align="left" className={tableStyles.colHeader} sx={cellBorder}><strong>Địa chỉ giao hàng:</strong></TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}><strong>Người lập:</strong> {orderObj.order.staff_name}</TableCell>
            <TableCell align="left" className={tableStyles.colHeader}><strong>Thời gian:</strong> {getTxTimeVN(orderObj.created)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}