import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { sha256 } from 'js-sha256';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import QRCode from 'qrcode.react';

import { readCookie } from '../core/CkUtils';
import { isValidPhone, isValidPassword } from '../core/Validator';
import Colors from '../core/Colors';
import Config from '../core/Config';
import TxStatus from '../core/TxStatuses';
import TxTypes from '../core/TxTypes';
import User from '../core/User';
import Actions from './Actions'
import { useInterval } from '../../ckcore/core/CkUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';

import ck_logo_round from '../images/ck_lgrb_256.png'

export default function SignIn(props) {

  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [phone, setPhone] = useState(User.getDisplayPhone());
  const [password, setPassword] = useState('');
  const [pushcode, setPushcode] = useState({ code: '', qrcode: '', smsc: '' });
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [signInResult, setSignInResult] = useState({
    loggedIn: false,
    validToken: false,
    token: "",
  });

  const inputPhone = (number) => {
    setPhone(number);
    setErrorMsg('');
  }

  const inputPassword = (text) => {
    setPassword(text);
    setErrorMsg('');
  }

  // check using pushcode
  const isUsingPushcode = () => {
    return (pushcode.code.length > 0);
  }

  /**
   * Request login to server
   */
  const doLogin = async () => {

    // clear error message
    setErrorMsg('');

    // validate phone and password
    if (!isValidPhone(phone) || !isValidPassword(password)) {
      setErrorMsg('INPUT ERROR');
      return;
    }

    // need verify recaptcha first
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setErrorMsg("Chưa kiểm tra được mã bảo vệ. Bạn vui lòng thử lại.");
      return;
    }

    // get captcha
    const captcha = await executeRecaptcha('doSignin');

    // check loading
    if (loading) {
      return;
    }
    setLoading(true);

    // get token from cookie
    const token = readCookie(phone + '_token');

    // get current
    const date = new Date();

    // Set phone
    User.setPhone(phone);

    // build login key
    var md5 = require('md5');
    const hashKey = sha256(0 + User.getFormatedPhone() + date.getTime() + md5(password));
    // console.log("Hashkey of formatted phone " + User.getFormatedPhone() + " key: " + hashKey);

    // login message
    const loginMsg = {
      txTypeId: TxTypes.TX_USER_LOGIN,
      userKey: hashKey.toUpperCase(),
      token: (token) ? "yes" : "",
      captcha: captcha,
      timestamp: date.getTime(),
      identity: User.getIdentity()
    };

    // Call server
    try {

      // call ckserver
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(loginMsg));
      const response = await rawResponse.json();

      // check response
      if (response.statusId === TxStatus.LOGIN_SUCCESS) {
        // console.log("Request login success, start authorise userId: " + response.userId);

        // Get user ID and phone (right format)
        User.setUserId(response.userId);
        User.setPhone(response.phone);


        // set sign in results for use effect to callback
        setSignInResult({
          loggedIn: true,
          validToken: (response.token === "yes"),
          token: token
        });

      } else if (response.statusId === TxStatus.LOGIN_ERROR_NOT_REGISTER) {
        console.log("Login error, user not register, response status " + response.statusId);
        setErrorMsg('Số điện thoại chưa đăng ký.');
      } else if (response.statusId === TxStatus.LOGIN_ERROR_INVALID_NODE) {
        console.log("Login error, invalid node " + response.statusId);
        setErrorMsg('Bạn không có quyền đăng nhập.');
      } else if (response.statusId === TxStatus.LOGIN_ERROR_ACCOUNT_LOCK) {
        console.log("Login error, account locked " + response.statusId);
        setErrorMsg('Tài khoản bị khoá.');
      } else if (response.statusId === TxStatus.LOGIN_ERROR_ADMIN_LOCK) {
        console.log("Login error, admin of this account locked " + response.statusId);
        setErrorMsg('Tài khoản đại lý bị khoá.');
      } else if (response.statusId === TxStatus.LOGIN_CHANGE_PASSWORD) {
        console.log("Login need change password.");
        setErrorMsg('Vui lòng đổi mật khẩu đăng nhập.');
        // setIsLogin(false);
        // setChangePassword(true);
        props.actionCallback(Actions.CHANGE_PASSWORD, { password: password, message: 'Vui lòng đổi mật khẩu đăng nhập' });
      } else if (response.statusId === TxStatus.AUTHORISE_ERROR_CAPTCHA_INVALID) {
        console.log("Authorise error, invalid captcha, response status " + response.statusId);
        setErrorMsg('Mã bảo về (captcha) không đúng. Mời bạn thử lại sau.');
      } else {
        console.log("Request login error with statuses: " + response.statusId);
        setErrorMsg('Số điện thoại hoặc mật khẩu không đúng. Vui lòng liên hệ admin nếu bạn muốn đặt lại mật khẩu.');
      }
    } catch (error) {
      console.log("Login connection error: " + error);
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
    }

    setLoading(false);
  }

  /**
   * Process user forgot password
   */
  const forgotPassword = async () => {

    // check phone
    if (!isValidPhone(phone)) {
      setErrorMsg('Mời bạn nhập số điện thoại');
      return;
    }

    // Set phone
    User.setPhone(phone);

    // request to server
    if (!executeRecaptcha) {
      console.log('Login: Execute recaptcha not yet available');
    }

    setLoading(true);

    const token = await executeRecaptcha('login');
    // console.log("Got recaptcha login token: " + token);

    // fpw message
    const forgotPasswordMsg = {
      txTypeId: TxTypes.TX_USER_FORGOT_PASSWORD,
      token: token,
      timestamp: (new Date()).getTime(),
      identity: User.getIdentity()
    };

    try {

      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(forgotPasswordMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        let smsc = response.smsc;
        let code = response.pushCode;

        let pushcodeSms = "SMSTO:" + smsc + ":CK MATKHAU " + code;

        setPushcode({ code: code, qrcode: pushcodeSms, smsc: smsc });
        setVerifiedCaptcha(true);
        setErrorMsg("");
      } else if (response.statusId === TxStatus.INVALID) {

        setVerifiedCaptcha(false);
        console.log("Verify captcha ERROR: " + response.statusId + " msg: " + response.msg);

      } else {
        resetPushcode();
        setErrorMsg('Lấy mã xác minh bị lỗi, mong bạn vui lòng thử lại sau.');
        console.log("Get pushcode error: " + response.msg);
      }
    } catch (error) {
      console.log("Check user recaptcha erro: " + error);
    }
    setLoading(false);

  }

  const resetPushcode = () => {
    setPushcode({ code: '', qrcode: '', smsc: '' });
  }

  /**
   * Check user pushcode
   */
  const checkPushcode = async (showMessage) => {
    setLoading(true);

    // get pushcode message
    const getPushcodeMsg = {
      txTypeId: TxTypes.TX_USER_CHECK_PUSHCODE,
      pushCode: pushcode.code,
      timestamp: (new Date()).getTime(),
      identity: User.getIdentity()
    };

    try {

      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(getPushcodeMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        // let newToken = response.token;
        // createCookie(phone + '_token', newToken, 7);
        // console.log("Authorize user with new token after check pushcode: " + newToken);
        // props.actionCallback(Actions.AUTHORIZE, {isLogin: true, token: newToken})

        // props.actionCallback(Actions.CHANGE_PASSWORD, {message: 'Vui lòng đổi mật khẩu đăng nhập'});

        resetPushcode();
        setErrorMsg('Mời bạn đăng nhập bằng mật khẩu tạm thời được gửi về điện thoại.');
      } else if (response.statusId === TxStatus.WAITING) {
        if (showMessage) {
          setErrorMsg('Hệ thống chưa nhận được SMS của bạn, vui lòng chờ trong giây lát và bấm kiểm tra lại.');
        }
      } else {
        resetPushcode();
        setErrorMsg('Kiểm tra SMS bị lỗi, mong bạn vui lòng thử lại sau.');
        console.log("Get pushcode error: " + response.msg);
      }
    } catch (error) {
      resetPushcode();
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
      console.log("Request send pushcode error: " + error);
    }

    setLoading(false);
  }

  /**
   * Handle submit on enter
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submit on enter")
    if (isUsingPushcode()) {
      checkPushcode(true);
    } else {
      doLogin();
    }
  }

  // Use interval to check pushcode
  useInterval(() => {

    if (isUsingPushcode()) {
      // console.log("Login checking pushcode every 8 seconds..");
      checkPushcode(false);
    }

  }, 8000);

  useEffect(() => {


    if (signInResult.loggedIn) {
      if (signInResult.validToken) {
        // authorise with token
        props.actionCallback(Actions.AUTHORIZE, { isLogin: true, token: signInResult.token })
      } else {          // show authorise
        props.actionCallback(Actions.AUTHORIZE, { isLogin: true, token: '' });
      }
    }

  }, [signInResult]);

  // RETURN LOGIN
  return (

    <Container component="main" maxWidth="xs">

      <Paper className={classes.paper}>
        <form style={{ width: "100%" }} onSubmit={(e) => handleSubmit(e)}>
          <Grid container spacing={3} direction="column" justifyContent="space-around" alignItems="stretch">
            <Grid item xs={12}>
              <div className={classes.cklogo}>
                <img src={ck_logo_round} alt="Chuyển Khoản" width="72" height="72" />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography color="primary" component="h1" variant="h5" >
                ĐĂNG NHẬP
              </Typography>
            </Grid>

            <Grid item xs={12}>

              <TextField id="input-phone" size="small" variant="outlined" fullWidth
                label="Số điện thoại"
                type="tel"
                autoComplete="tel"
                autoFocus
                value={phone}
                onInput={e => inputPhone(e.target.value)}
                helperText={!isValidPhone(phone) && (errorMsg.length > 0) ? "Số điện thoại không hợp lệ" : ""}
                error={(errorMsg.length > 0) && !isValidPhone(phone)}
              />
            </Grid>

            {
              isUsingPushcode() ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" style={{ paddingBottom: 8, color: Colors.DARK_BLUE }}>
                    Mời bạn quét QR bằng Camera của điện thoại để gửi SMS lấy lại mật khẩu
                  </Typography>
                  <QRCode size={128} value={pushcode.qrcode} />
                  <Typography variant="subtitle1" style={{ paddingTop: 8, color: Colors.DARK_BLUE }}>
                    Hoặc soạn <strong style={{ color: Colors.DARK_GREEN }}>CK MATKHAU {pushcode.code}</strong> gửi tới SĐT <strong style={{ color: Colors.DARK_GREEN }}>{getDisplayPhone(pushcode.smsc)}</strong>
                  </Typography>
                </Grid>
              ) :
                (
                  <Grid item xs={12}>
                    <TextField id="input-password" size="small" variant="outlined" fullWidth
                      label="Mật khẩu"
                      type="password"
                      autoComplete="password"
                      value={password}
                      onInput={e => inputPassword(e.target.value)}
                      helperText={!isValidPassword(password) && (errorMsg.length > 0) ? "Mật khẩu không hợp lệ" : ""}
                      error={(errorMsg.length > 0) && !isValidPassword(password)}
                    />
                  </Grid>
                )

            }


            {/*
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" size="small" />}
                label="Nhớ đăng nhập"
              />
            </Grid>
          */}

            {
              isUsingPushcode() ? (
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth
                    disabled={loading}
                    style={{ fontWeight: 'bold' }}
                    onClick={() => checkPushcode(true)}>KIỂM TRA</Button>
                </Grid>
              ) :
                (
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth
                      disabled={loading}
                      style={{ fontWeight: 'bold' }}
                      onClick={() => doLogin()}>ĐĂNG NHẬP</Button>
                  </Grid>

                )
            }

            {
              (errorMsg.length > 0) && isValidPhone(phone) && isValidPassword(password) &&
              <Grid item xs={12}>
                <Typography color="error" variant="subtitle2" >
                  {errorMsg}
                </Typography>
              </Grid>
            }

            <Grid container item xs={12} direction="row" justifyContent="space-around" alignItems="center">
              {
                !Config.isStandalone() &&
                <Grid item >
                  <Link component="button" variant="body2" className={classes.textButton} onClick={() => props.actionCallback(Actions.SIGN_UP)}>
                    Đăng ký CKPay
                  </Link>
                </Grid>
              }

              <Grid item >
                {
                  isUsingPushcode() ? (
                    <Link component="button" variant="body2" className={classes.textButton} onClick={() => {
                      resetPushcode();
                      setErrorMsg("");
                    }}>
                      Đã nhớ mật khẩu
                    </Link>
                  ) : (
                    <Link component="button" variant="body2" className={classes.textButton} onClick={() => forgotPassword()}>
                      Quên mật khẩu?
                    </Link>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>

  );

}

const useStyles = makeStyles(theme => ({

  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 389,
    minWidth: 389,
    padding: 20,
    textAlign: 'center',
    margin: 'auto',
  },

  cklogo: {
    textAlign: 'center',
    display: 'block',
    margin: "auto",
  },


}));