import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';

import { getMessageBox } from '../../../ckcore/core/TxUtils';
import Colors from '../../../ckcore/core/Colors';
import TxStatus from '../../../ckcore/core/TxStatuses';
import Config from '../../../ckcore/core/Config';
import User from '../../../ckcore/core/User';
import PaymentStatus from '../../../payment/components/PaymentStatus';
import { ckTheme } from '../../../ckcore/ui/CkStyles';
import { ckApiStyles } from '../ApiStyles';
import WebhookGetfly from './WebhookGetfly';
import WebhookGeneric, { testConnectWebhook } from './WebhookGeneric';
import WebhookGoogleSheets, { testConnectGoogleSheets } from './WebhookGoogleSheets';
import WebhookTelgram, { testConnectTelegram } from './WebhookTelegram';


export default function WebhookDialog(props) {

  const classes = ckApiStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const appId = props.appId;
  const webhook = props.webhook;
  const apiConfig = props.apiConfig;

  const testTxObj = {
    balance: 110000000000,
    amount: 11000,
    receiver: "0900000000",
    paymentStatus: PaymentStatus.SUCCESS,
    customerId: "KH007",
    customerPhone: "0911111111",
    orderId: "TEST.ORDER001",
    orderInfo: "TEST thanh toan don hang",
  };
  const [txObj, setTxObj] = useState(testTxObj);

  const [messages, setMessages] = useState({success: "", error: "", info: ""});
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [webhookObj, setWebhookObj] = useState(apiConfig[webhook]);
  const handleChange = (newApiObj) => {

    // no change
    if(JSON.stringify(webhookObj) === JSON.stringify(newApiObj)) {
      console.log("Webhook obj no change");
      return;
    } else {
      console.log("Webhook obj changed");
    }

    setWebhookObj({ ...newApiObj });
    setLoading(false);
    setEditing(true);
    setMessages({...messages, success: "", error: ""});
  };

  /**
   * Request test config
   */
  const requestTestWebhookConfig = () => {

    if (webhook === 'webhook') {
      testConnectWebhook(userObj.partnerId, webhookObj.api, testWebhookCallback);
      setLoading(true);
    } else if (webhook === 'googlesheets') {
      setLoading(true);
      testConnectGoogleSheets(webhookObj.api, testWebhookCallback);
    } else if (webhook === 'telegram') {
      setLoading(true);
      testConnectTelegram(webhookObj.api, testWebhookCallback);
    } else if (webhook === 'getfly') {
    } else if (webhook === 'haravan') {
    } else if (webhook === 'kiotviet') {
    } else if (webhook === 'sapo') {
    } else if (webhook === 'ipos') {
    } else if (webhook === 'nhanh') {

    }
  }

  /**
   * Test webhook callback
   */
  const testWebhookCallback = (statusId, message) => {
    if(statusId === TxStatus.SUCCESS || statusId === TxStatus.HTTP_SUCCESS) {
      setMessages({...messages, success: message, error: ""});
    } else {
      setMessages({...messages, success: "", error: "Kiểm tra kết nối bị lỗi: " + message});
    }
    setLoading(false);
  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };


  // Update API config
  const processUpdateApiConfig = async () => {


    if(loading) {
      return;
    }

    var newAppConfig = {...apiConfig};
    newAppConfig.webhook_name = webhook;
    newAppConfig.webhook_obj = webhookObj;

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      partnerId: userObj.partnerId,
      appId: appId,
      appConfig: newAppConfig,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // clear message
    setMessages({...messages, success: "", error: ""});
    setLoading(true);
    
    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        console.log("update done");

        setMessages({...messages, success: "Cập nhật API thành công", error: ""});
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({...messages, error: "Cập nhật API bị lỗi, vui lòng thử lại sau."});
      }

    } catch (error) {
      setMessages({...messages, error: "Cập nhật API bị lỗi, vui lòng thử lại sau."});
      console.log("Error update API config " + error);
    }

    setLoading(false);
    setEditing(false);
  }

  /**
   * Get webhook config by name
   */
  const getWebhookConfig = (webhook) => {

    if (webhook === 'webhook') {
      return <WebhookGeneric webhookObj={webhookObj} handleChange={handleChange} />
    } else if (webhook === 'googlesheets') {
      return <WebhookGoogleSheets webhookObj={webhookObj} handleChange={handleChange} />
    } else if (webhook === 'telegram') {
      return <WebhookTelgram webhookObj={webhookObj} handleChange={handleChange} />
    } else if (webhook === 'getfly') {
      return <WebhookGetfly webhookObj={webhookObj} handleChange={handleChange} />
    } else if (webhook === 'haravan') {
    } else if (webhook === 'kiotviet') {
    } else if (webhook === 'sapo') {
    } else if (webhook === 'ipos') {
    } else if (webhook === 'nhanh') {

    }

    return <Box/>;
  }

  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>
        <Dialog open={props.open} onClose={handleClose} fullWidth={true} maxWidth="md">
          <DialogTitle id="form-dialog-title">
            <Grid justifyContent="space-between" alignItems="flex-end" container>
              <Grid item>
                <span>{webhookObj.name}</span>
              </Grid>
              <Grid item>
                <span>
                  <Button className={classes.actionButton} disabled={loading} variant="outlined" color="primary" onClick={() => requestTestWebhookConfig()}>
                    Kiểm tra kết nối
                  </Button>
                </span>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>

            <Box p={2}>

              {
                loading &&
                <Typography variant="body1" style={{ color: Colors.ORANGE, textAlign: "center" }}>Đang kiểm tra kết nối..</Typography>
              }

              {
                !loading &&
                getMessageBox(messages.error, messages.success, webhookObj.desc)
              }
            </Box>

            {
              getWebhookConfig(webhook)
            }

          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button variant="outlined" color="warning"
              style={{marginRight: 20}}
              disabled={loading || !editing} 
              onClick={() => processUpdateApiConfig()} >
              Cập nhật
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
