import React, { useState, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Hidden from "@mui/material/Hidden";
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';
import SvgIcon from '@mui/material/SvgIcon';


import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import Colors from '../../ckcore/core/Colors';
import EditBankAccountDialog from "./EditBankAccount";
import VerifyBankAccountDialog from './VerifyBankAccount';
import SetPrimaryBankAccountDialog from './SetPrimaryBankAccount';
import ValUtils from '../../ckcore/core/ValUtils';
import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import Roles from '../../ckcore/core/Roles';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';


const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
}));


export default function BankAccountList(props) {
  const classes = useStyles();

  const userObj = props.userObj;
  const activeRole = User.getActiveRole(userObj);
  const partnerObj = props.partnerObj;
  const bankAccounts = (props.bankAccounts) ? props.bankAccounts : [];

  const [showEditBankDialog, setShowEditBankDialog] = useState(false);
  const [showVerifyBankDialog, setShowVerifyBankDialog] = useState(false);
  const [showSetPrimaryBankDialog, setShowSetPrimaryBankDialog] = useState(false);
  const [selectedBankObj, setSelectedBankObj] = useState({});

  // Edit bank account
  const editBankAccount = (bankObj) => {
    setSelectedBankObj(bankObj);
    setShowEditBankDialog(true);
  }

  const closeEditBankDialog = () => {
    setShowEditBankDialog(false);
    props.refreshCallback();
  }

  const verifyBankAccount = (bankObj) => {
    setSelectedBankObj(bankObj);
    setShowVerifyBankDialog(true);
  }

  const closeVerifyBankDialog = () => {
    setShowVerifyBankDialog(false);
  }

  const setPrimaryBankAccount = (bankObj) => {
    setSelectedBankObj(bankObj);
    setShowSetPrimaryBankDialog(true);
  }

  const closeSetPrimaryBankDialog = () => {
    setShowSetPrimaryBankDialog(false);
  }

  useEffect(() => {
    try {

      if (props.loaded && bankAccounts.length === 0) {
        editBankAccount({});
      }

    } catch (error) {
      console.log("Error load bank info: " + error);
    }
  }, []);


  // set selected order
  const onSelectBankAccount = (bankObj) => {
    setSelectedBankObj(bankObj);
    props.selectCallback(bankObj)
  }

  const getUpdateStatus = (bankObj) => {

    if(bankObj.partnerId > 0 && bankObj.partnerId !== userObj.partnerId) {
      return <span>DELEGATE</span>;
    }

    return (
      <div>
        {
          (bankObj.upToDate) ? (
            <Tooltip title="Tài khoản đã được cập nhật BĐSD" aria-label="Verify">
              <span style={{ color: Colors.DARK_GREEN }}>Cập nhật</span>
            </Tooltip>
          ) : (
            <Tooltip title={"Mời bạn chuyển khoản ít nhất một lần để gửi BĐSD của tài khoản " + bankObj.accountNumber + " về số điện thoại: " + getDisplayPhone(bankObj.notifier)} aria-label="Verify">
              <span style={{ color: Colors.ORANGE }}>Chưa cập nhật</span>
            </Tooltip>
          )
        }
      </div>
    );
  }

  // Get bank account actions
  const getActions = (bankObj, index) => {

    // return for other task
    if ((props.selectCallback)) {
      return (
        <div>
          <Tooltip title="Chọn" aria-label="Verify"><span>
            <IconButton size="small" className={(bankObj.accountNumber === selectedBankObj.accountNumber) ? classes.selectedIcon : ""} onClick={() => onSelectBankAccount(bankObj)}>
              <CheckOutlinedIcon />
            </IconButton></span>
          </Tooltip>
        </div>
      );
    }

    return (
      <div>
        {
          (activeRole.roleId === Roles.ADMIN) &&
          <Tooltip title="Cài đặt" aria-label="Setting">
            <span>
              <IconButton size="small" className={classes.smsButton} onClick={() => editBankAccount(bankObj)}>
                <SettingsOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        }

        {
          (bankObj.verified) &&
          <Tooltip title="Tài khoản đã xác thực" aria-label="Verify"><span>
            <IconButton size="small" className={classes.smsButton} style={{ color: Colors.DARK_GREEN }} >
              <VerifiedUserOutlinedIcon />
            </IconButton></span>
          </Tooltip>
        }

        {
          !(bankObj.verified) &&
          <Tooltip title="Đang xác thực tài khoản" aria-label="Verify"><span>
            <IconButton size="small" className={classes.smsButton} style={{ color: Colors.YELLOW }} >
              <VerifiedUserOutlinedIcon />
            </IconButton></span>
          </Tooltip>
        }

        <Tooltip title="Đặt làm TK chính" aria-label="Setting">
          <span>
            <IconButton size="small" className={classes.smsButton} style={{ color: (index === 0) ? Colors.DARK_GREEN : Colors.GRAY }} onClick={() => setPrimaryBankAccount(bankObj)}>
              <StarBorderIcon />
            </IconButton>
          </span>
        </Tooltip>

      </div>
    );
  }


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid justifyContent="space-between" alignItems="flex-end" container>
          <Grid item className={classes.transHead}>
            <Box display="inline" alignItems="flex-end">
              <span className={classes.title}><strong>TÀI KHOẢN NGÂN HÀNG</strong> của</span>
              <span className={classes.name}>{partnerObj.name}</span>
            </Box>
          </Grid>
          <Grid item>
          </Grid>

          <Grid item>
            <Box display="inline">
              {
                (activeRole.roleId === Roles.ADMIN) &&
                <Button color="primary" size="small" className={classes.modemButton} onClick={() => editBankAccount({})}>Thêm tài khoản</Button>
              }
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="live banks table"
          >
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell className={classes.colNo}>STT</TableCell>
                <TableCell className={classes.colBankName}>Ngân hàng</TableCell>
                <TableCell className={classes.colBankName}>Tài khoản</TableCell>
                <Hidden mdDown>
                  <TableCell className={classes.colAccountName}>Tên tài khoản</TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell className={classes.colBankName}>SĐT BĐSD</TableCell>
                </Hidden>
                {
                  (partnerObj.delegate) &&
                  <TableCell align="center" className={classes.colBankName}>PartnerId</TableCell>
                }
                <TableCell align="center" className={classes.colBankName}>Trạng thái</TableCell>
                <TableCell align="center" className={classes.colBankName}>Số dư</TableCell>
                <TableCell align="center" className={classes.colActions}>Thao tác</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bankAccounts.map((bank, index) => (
                <TableRow hover key={index} padding="none" className={classes.row}>
                  <TableCell className={classes.colNo}>{index + 1}</TableCell>
                  <TableCell className={classes.colBankName}>
                    <IconButton size="small">
                      <SvgIcon component={getBankSvgIcon(bank.bankName)} viewBox="0 0 48 48" />
                    </IconButton>
                    {bank.bankName}
                  </TableCell>
                  {
                    (bank.verified) ? (
                      <TableCell className={classes.colAccountNumber}>{ValUtils.formatBankAccountNumber(bank.accountNumber)}</TableCell>
                    ) : (
                      <Tooltip title="Tài khoản chưa được xác thực, mời bạn bấm xác thực" aria-label="Need Verify">
                        <TableCell className={classes.colAccountNumberWaiting}>
                          {ValUtils.formatBankAccountNumber(bank.accountNumber)}
                        </TableCell>
                      </Tooltip>
                    )
                  }

                  <Hidden mdDown>
                    <TableCell className={classes.colAccountName}>{bank.accountName}</TableCell>
                  </Hidden>
                  <Hidden mdDown>
                    <TableCell className={classes.colAccountNumber}>{bank.notifier}</TableCell>
                  </Hidden>
                  {
                    (partnerObj.delegate) &&
                    <TableCell align="center" className={classes.colBankName}>{bank.partnerId}</TableCell>
                  }
                  <TableCell align="center" className={classes.colBankName}>{getUpdateStatus(bank)}</TableCell>

                  {
                    (bank.allowUpdateBalance) ? (
                      <TableCell className={classes.colBalance}>
                        <NumberFormat value={bank.accountBalance} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.colNoBalance}>
                        {"---"}
                      </TableCell>
                    )
                  }
                  <TableCell align="center" className={classes.colActions}>
                    {getActions(bank, index)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {
          showEditBankDialog &&
          <EditBankAccountDialog open={showEditBankDialog} closeCallback={closeEditBankDialog}
            bankAccounts={bankAccounts}
            bankObj={selectedBankObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

        {
          showVerifyBankDialog &&
          <VerifyBankAccountDialog open={showVerifyBankDialog} closeCallback={closeVerifyBankDialog}
            bankObj={selectedBankObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

        {
          showSetPrimaryBankDialog &&
          <SetPrimaryBankAccountDialog open={showSetPrimaryBankDialog} closeCallback={closeSetPrimaryBankDialog}
            bankObj={selectedBankObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    textAlign: "left",
  },

  title: {
    color: Colors.DARK_BLUE,
    paddingLeft: 2,
    marginBottom: 6,
  },
  name: {
    color: Colors.DARK_GREEN,
    fontWeight: 'bold',
    paddingLeft: 8,
  },

  transHead: {
    minHeight: 30,
    padding: "3px 3px",
  },

  modemButton: {
    fontWeight: 'bold',
  },

  table: {
    whiteSpace: "nowrap",
    marginTop: 4,
    marginBottom: 0,
  },

  row: {
    height: 30,
    maxHeight: 36,
    fontSize: 12,
  },

  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },

  colBankName: {
    minWidth: 50,
    padding: "2px 3px",
    whiteSpace: 'nowrap'
  },

  colAccountNumber: {
    minWidth: 80,
    padding: "2px 6px",
    fontWeight: "bold",
    color: Colors.DARK_GREEN,
  },

  colAccountNumberWaiting: {
    minWidth: 80,
    padding: "2px 6px",
    fontWeight: "bold",
    color: Colors.YELLOW,

  },

  colAccountName: {
    minWidth: 111,
    maxWidth: 222,
    overflow: "hidden",
    padding: "2px 6px",
    fontSize: 12,
  },

  colBalance: {
    minWidth: 80,
    padding: "2px 6px",
    color: Colors.DARK_GREEN,
    textAlign: 'right',
    fontWeight: 600,
  },
  colAmount: {
    minWidth: 96,
    padding: "2px 6px",
  },

  colNoBalance: {
    minWidth: 80,
    padding: "2px 6px",
    color: Colors.GRAY,
    textAlign: 'right',
  },

  colTime: {
    minWidth: 92,
    padding: "2px 6px",
  },

  colActions: {
    minWidth: 80,
    maxWidth: 161,
    padding: "3px 3px",
    textAlign: "center",
  },
  valueIn: {
    color: "green",
    textAlign: "right",
  },
  valueOut: {
    color: "orange",
    textAlign: "right",
  },
  valueBold: {
    fontWeight: "bold",
    textAlign: "center",
  },
  valueEmpty: {
    color: Colors.DARK_BLUE,
    textAlign: "center",
    fontSize: 12,
  },
  valueSuccess: {
    color: Colors.DARK_GREEN,
    textAlign: "center",
    fontSize: 12,
  },
  valueError: {
    color: Colors.RED,
    textAlign: "center",
    fontSize: 12,
  },

  valueSmall: {
    fontSize: 12,
  },

  smsButton: {
    fontSize: 10,
    padding: "0px 6px",
  },

  selectedIcon: {
    color: Colors.DARK_GREEN
  }
}));
