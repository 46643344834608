
/*
* Build order JSON object from request payment URL
    const payment_url = "http://192.168.1.5:3000/pay/v1/ckpay.html?"
                          + "partner_id=" + merchantParnterId
                          + "&bank_name=" + merchantBankName
                          + "&account_number=" + merchantBankAccount
                          + "&order_id=" + orderId
                          + "&value=" + value
                          + "&customer_phone=" + phone
                          + "&content=" + encodeURIComponent(content)
                          + "&return_url=" + encodeURIComponent(returnURL)
                          + "&timestamp=" + timestamp
                          + "&checksum=" + checksum;
                          
    ex: http://localhost:3000/checkout/v1/ckpay.html?order=%7b%22partner_id%22%3a%2210004%22%2c%22account_number%22%3a%222868686188888%22%2c%22order_total%22%3a440000%2c%22order_id%22%3a%2238921%22%2c%22customer_phone%22%3a%22132456798%22%2c%22customer_id%22%3a%22107179890%22%2c%22order_info%22%3a%22%22%2c%22products%22%3a%5b%7b%22name%22%3a%22Gh%c3%a9p+nh%c3%b3m+mua+chung+Raz-kids+m%e1%bb%9f+r%e1%bb%99ng+(HSD+1+n%22%2c%22code%22%3a%22121%22%2c%22price%22%3a320000%2c%22discount%22%3a0%2c%22tax%22%3a0%2c%22quantity%22%3a1%2c%22image%22%3a%22https%3a%2f%2fwww.contuhoc.com%2fcontent%2fimages%2fthumbs%2f0003220_ghep-nhom-mua-chung-raz-kids-mo-rong-hsd-1-nam.png%22%2c%22description%22%3a%22%22%7d%5d%2c%22return_url%22%3a%22https%3a%2f%2fwww.contuhoc.com%2fPaymentCKPay%2fPaymentComplete%22%2c%22timestamp%22%3a1638408065535%2c%22checksum%22%3a%2220eaab4497caf4068c3b650cae749e39e50a51831ce60ede50d5db8dc23a0b3f%22%7d
*/
function buildOrderObj(payment_url_params) {
  return JSON.parse('{"' + payment_url_params.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
  // return JSON.parse('{"' + payment_url_params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : value });
}

// do export functions
export { buildOrderObj };
