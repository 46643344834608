import makeStyles from '@mui/styles/makeStyles';
import Colors from '../../ckcore/core/Colors';

/**
 * Export API style
 */
export const ckApiStyles = makeStyles(theme => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },

  guide: {
    padding: 8,
  },

  fieldsBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },

  fieldNameStyle: {
    width: 150,
    textAlign: "right",
  },

  fieldNameArrayStyle: {
    width: 150,
    textAlign: "right",
    paddingTop: 8,
  },

  fieldValueStyle: {
    width: 480,
    marginLeft: 8,
    marginRight: 8,
    textAlign: "left",
  },

  
  textFieldKey: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },  

  textFieldOption: {
  },

  grow: {
    flexFlow: 1,
    minHeight: "15px",
  },

  btnAdd: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));