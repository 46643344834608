import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';

import { ckTheme } from '../../ckcore/ui/CkStyles';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import PricingTable from './PricingTable';
import ExtendSubTable from './ExtendSubTable';
import CkCheckout from '../../checkout/components/CkCheckout';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import CKPayment from '../../payment/components/CKPayment';
import { pricingData } from './PricingTable';
import moment from 'moment';


export default function PricingDialog(props) {

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;

  const partnerPackage = (partnerObj.subscription.package) ? partnerObj.subscription.package : 'small';
  const partnerPrice = (partnerObj.subscription.price) ? partnerObj.subscription.price : 0;
  const partnerFee = (partnerObj.subscription.fee) ? partnerObj.subscription.fee : 0;

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState({ info: '', success: '', error: '' });

  const [subscriptionPackage, setSubscriptionPackage] = useState(partnerPackage);
  const [subInfo, setSubInfo] = useState({ subMonths: 6, amount: 0, bonus: 0, price: partnerPrice, fee: partnerFee, expired: 0, convertedCKCoin: 0 });

  const [order, setOrder] = useState({});

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // User change subscription
  const onChangeSubscription = (ckpk) => {

    // only super admin can change package for large
    if (ckpk.id === 'large' && !Config.isSuperAdmin(userObj.phone)) {
      return;
    }

    setSubscriptionPackage(ckpk);
  }

  const onChangeExtendService = (months) => {

    let currentExpired = (partnerObj.subscription) ? partnerObj.subscription.expired : new Date().getTime();
    let currentSubPackage = pricingData.packages.find((ckpk) => ckpk.id === partnerPackage);
    let newSubPackage = pricingData.packages.find((ckpk) => ckpk.id === subscriptionPackage);

    // if change package then convert current package validate to CKCOIN = day*(price/30)/fee
    var convertedCKCoin = 0;
    var expired = currentExpired;
    if (partnerPackage != subscriptionPackage) {
      let dayRemain = moment(currentExpired).diff(moment(), 'days') + 1;
      if (dayRemain > 0 && currentSubPackage.fee > 0) {
        convertedCKCoin = parseInt(dayRemain * (currentSubPackage.price / 30) / currentSubPackage.fee);
      }
      expired = moment().add(months, 'months').valueOf();
    } else {
      expired = moment(currentExpired).add(months, 'months').valueOf();
    }

    // calculate price
    let price = (newSubPackage.price > 0 ? newSubPackage.price : subInfo.price);
    let fee = (newSubPackage.fee > 0 ? newSubPackage.fee : subInfo.fee);
    let amount = months * price;
    let bonus = (months * 50) + convertedCKCoin;

    let newSubInfo = { ...subInfo, subMonths: months, amount: amount, bonus: bonus, price: price, fee: fee, expired: expired, convertedCKCoin: convertedCKCoin };

    setSubInfo(newSubInfo);
  }

  /**
   * Handle super admin change price and fee
   */
  const handleAdminChange = (name, value) => {
    if (!(value >= 0) || !(name === 'fee' || name === 'price' || name === 'expired')) {
      console.log("Admin change value invalid: " + name + ' = ' + value);
      return;
    }

    // recalculate amount
    let amount = (name === 'price') ? subInfo.subMonths * value : subInfo.subMonths * subInfo.price;

    setSubInfo({ ...subInfo, [name]: value, amount: amount });
  }

  useEffect(() => {
    onChangeExtendService(subInfo.subMonths);
  }, [subscriptionPackage]);


  // Request Topup CKCoin to get order id and CKPay payment info
  const requestExtendSubscription = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_EXTEND_SUBSCRIPTION,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setOrder(response.order);

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request topup extend subscription error with statuses: " + response.statusId);
        // setErrorMessage("Yêu cầu mua CKCoin bị lỗi, vui lòng thử lại sau");
      }

    } catch (error) {
      console.log("Error... " + error);
      // setErrorMessage("Yêu cầu mua CKCoin bị lỗi, vui lòng thử lại sau");
    }

    setLoading(false);
  };


  /**
   * Process do payment by CkCheckout
   */
  const processPayment = () => {

    let newCkpk = pricingData.packages.find((ckpk) => ckpk.id === subscriptionPackage);

    // get selected topup item

    CkCheckout.init(order);


    // update topup order
    const ckCoinOrder = order;
    ckCoinOrder.payment_key = '';// reset payment key
    ckCoinOrder.order_total = subInfo.amount;
    ckCoinOrder.order_info = User.getDisplayPhone() + ' gia han dich vu';
    ckCoinOrder.customer_phone = userObj.phone;
    ckCoinOrder.customer_id = userObj.partnerId;
    ckCoinOrder.product = {
      name: "Gia hạn gói " + newCkpk.name + " dịch vụ Chuyenkhoan.com",
      code: "SUB_" + newCkpk.id.toUpperCase(),
      price: subInfo.price,
      quantity: subInfo.subMonths,
      discount: 0,
      tax: 0,
      bonus_amount: subInfo.bonus,
      image: "https://chuyenkhoan.com/images/chuyenkhoan_banner_1200x620.png",
      description: "Gia hạn dịch vụ Chuyenkhoan.com"
    };

    console.log("Process pay order: " + JSON.stringify(ckCoinOrder));

    // request pay
    CkCheckout.payNow(ckCoinOrder, errorCallback, paymentCallback);

    console.log("Process payment done.");
  }


  /**
   * Error create order callback
   */
  const errorCallback = (error) => {
    console.log("Eror callback: " + JSON.stringify(error));
  }

  /**
   * Payment callback with order token
   */
  const paymentCallback = (token) => {
    console.log("Request order success. Order token: " + token);
    setOrder({ ...order, token: token });
  }


  /**
   * CKPayment return callback
   */
  const returnCallback = (returnObj) => {
    console.log("Return callback: " + JSON.stringify(returnObj));
    handleClose();
  }

  /**
   * Process on change
   */
  useEffect(() => {
    // request create order
    if (!(order.order_id)) {
      requestExtendSubscription();
    }
  }, []);


  /**
   * Super admin extend partner subscription
   */
  const adminExtendSubscription = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.ORIGIN_ADMIN_PARTNER_EXTEND_SUB,
      package: subscriptionPackage,
      price: subInfo.price,
      fee: subInfo.fee,
      expired: subInfo.expired,
      partnerId: partnerObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "admin=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {
        handleClose();
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request topup extend subscription error with statuses: " + response.statusId);
        // setErrorMessage("Yêu cầu mua CKCoin bị lỗi, vui lòng thử lại sau");
      }

    } catch (error) {
      console.log("Error... " + error);
      // setErrorMessage("Yêu cầu mua CKCoin bị lỗi, vui lòng thử lại sau");
    }

    setLoading(false);
  };

  if ((order.token)) {

    // show payment page
    return (
      <Dialog fullScreen maxWidth="md" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-payment">
        <DialogContent>
          <CKPayment token={order.token} returnCallback={returnCallback} />
        </DialogContent>
      </Dialog>
    );
  } else {
    // Add bank dialog
    return (
      <ThemeProvider theme={ckTheme}>
        <Dialog open={props.open} fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="pricing-dialog-title">
          <DialogTitle id="pricing-dialog-title">
            <Grid justifyContent="space-between" alignItems="flex-end" container>
              <Grid item>
                <span>Gia hạn gói dịch vụ Chuyenkhoan.com</span>
              </Grid>
              <Grid item>
                <span>
                </span>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>

            <ExtendSubTable
              userObj={userObj}
              partnerObj={props.partnerObj}
              subscriptionPackage={subscriptionPackage}
              subInfo={subInfo}
              onChangeExtendService={onChangeExtendService}
              handleAdminChange={handleAdminChange} />

            <Typography component="div" variant="body1" style={{ marginTop: 12, fontWeight: 'bold' }}>Bảng giá dịch vụ: </Typography>
            <PricingTable
              userObj={userObj}
              subscriptionPackage={subscriptionPackage}
              onChangeSubscription={onChangeSubscription} />

          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">Trở lại</Button>
            <Button variant="outlined" color="warning"
              disabled={!(subInfo.amount > 0)}
              onClick={() => { processPayment() }}>Gia hạn</Button>

            {
              Config.isSuperAdmin(userObj.phone) &&
              <Button variant="outlined" color="warning"
                disabled={!(subInfo.amount > 0)}
                onClick={() => { adminExtendSubscription() }}>Admin gia hạn</Button>
            }

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}
