import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { Grid, Typography } from '@mui/material';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Colors from '../../ckcore/core/Colors';
import ValUtils from '../../ckcore/core/ValUtils';
import CkCheckout from '../../checkout/components/CkCheckout';
import CKPayment from '../../payment/components/CKPayment';

export default function PreorderCKBox(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState({
    order_total: 11000,
    bonus: 100
  });

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Request Topup CK Coin to get order id and CKPay payment info
  const requestTopupCkCoin = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_ORDER_CKBOX,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setSuccessMessage("");
      setErrorMessage("");
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        if (response.txObj) {
          setOrder({ ...order, txObj: response.txObj });
        } else {
          setOrder(response.order);
        }

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request topup CKCoin error with statuses: " + response.statusId);
        setErrorMessage("Yêu cầu đặt mua CKBox bị lỗi, vui lòng thử lại sau");
      }

    } catch (error) {
      console.log("Error... " + error);
      setErrorMessage("Yêu cầu đặt mua CKBox bị lỗi, vui lòng thử lại sau");
    }

    setLoading(false);
  };


  /**
   * Process do payment by CkCheckout
   */
  const processPayment = () => {

    // get order total
    CkCheckout.init(order);

    // update topup order
    const ckCoinOrder = order;
    ckCoinOrder.payment_key = '';// reset payment key
    ckCoinOrder.order_info = '';
    ckCoinOrder.customer_phone = userObj.phone;
    ckCoinOrder.customer_id = userObj.partnerId;
    ckCoinOrder.product = {
      name: "Đặt mua CKBox",
      code: "CKBOX",
      price: order.order_total,
      quantity: 1,
      discount: 0,
      tax: 0,
      image: "https://chuyenkhoan.com/web/images/ck_box.jpg",
      description: "Chuyển khoản đặt mua CKBox"
    };

    console.log("Process pay order: " + JSON.stringify(ckCoinOrder));

    // request pay
    CkCheckout.payNow(ckCoinOrder, errorCallback, paymentCallback);

    console.log("Process payment done.");
  }

  /**
   * Error create order callback
   */
  const errorCallback = (error) => {
    console.log("Eror callback: " + JSON.stringify(error));
  }

  /**
   * Payment callback with order token
   */
  const paymentCallback = (token) => {
    console.log("Request order success. Order token: " + token);
    setOrder({ ...order, token: token });
  }

  /**
   * CKPayment return callback
   */
  const returnCallback = (returnObj) => {
    console.log("Return callback: " + JSON.stringify(returnObj));
    handleClose();
  }

  /**
   * Process on change
   */
  useEffect(() => {
    // request create order
    if (!(order.order_id)) {
      requestTopupCkCoin();
    }
  }, []);


  if ((order.token)) {

    // show payment page
    return (
      <Dialog fullScreen maxWidth="md" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-payment">
        <DialogContent>
          <CKPayment token={order.token} returnCallback={returnCallback} />
        </DialogContent>
      </Dialog>
    );
  } else {

    // Topup CKCoin dialog
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>


          <Dialog fullWidth={true} maxWidth="sm" open={props.open} onClose={handleClose} aria-labelledby="form-dialog-topup">
            <DialogTitle id="form-dialog-title">Đặt mua CKBox</DialogTitle>
            <DialogContent dividers>

              <Box p={1}>
                {getMessageBox(errorMessage, successMessage, "Đặt mua CKBox cho " + partnerObj.name)}
              </Box>

              <Box p={1} style={{ textAlign: 'center' }}>
                <img src={"https://chuyenkhoan.com/web/images/ck_box.jpg"} alt="Thiết bị CKBox" style={{ height: 220, maxHeight: 222, maxWidth: '100%', margin: 'auto' }} className="ProductImage-image" />
              </Box>

              <Box p={1}>
                {
                  (order.txObj) &&
                  <Typography variant="subtitle1" style={{ color: Colors.DARK_GREEN }} >
                    Bạn đã chuyển khoản đặt hàng CKBox. Vui lòng liên hệ email: cskh@chuyenkhoan.com hoặc hotline: 0906246196.
                  </Typography>
                }
                {
                  !(order.txObj) &&
                  <Grid container style={{ marginTop: 8 }}>

                    <Grid item xs={6}>
                      <Typography component="div" variant="subtitle1" >Giá thiết bị CKBox (chưa VAT):</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="div" variant="subtitle1" ><strong style={{ color: Colors.DARK_BLUE }}>{ValUtils.formatMoney(3000000)}</strong> (VNĐ)</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="div" variant="subtitle1" >Số tiền đặt trước:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="div" variant="subtitle1" ><strong style={{ color: Colors.DARK_BLUE }}>{ValUtils.formatMoney(order.order_total)}</strong> (VNĐ)</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography component="div" variant="subtitle1" >Số lượt CK được tặng:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography component="div" variant="subtitle1" ><strong style={{ color: Colors.DARK_GREEN }}>{ValUtils.formatMoney(order.bonus)}</strong> (CK Coin)</Typography>
                    </Grid>
                  </Grid>
                }

              </Box>

              <Box p={1}>
                <Typography variant="subtitle1" style={{ color: Colors.DARK_BLUE }} >
                  CKBox là thiết bị máy tính nhúng chuyên biệt phục vụ xác nhận giao dịch chuyển khoản tự động, bảo mật, với độ tin cậy cao.
                </Typography>
              </Box>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Trở lại
              </Button>

              <Button disabled={loading || !(order.order_id) || (order.txObj)} onClick={() => processPayment()} color="primary">
                Đồng ý
              </Button>
            </DialogActions>
          </Dialog>

        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

}

const theme = createTheme({
  palette: {
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});
