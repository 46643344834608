import React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import Colors from '../core/Colors';


export default function SignOut(props) {

  const handleLogout = (fullLogout) => {
    props.logoutCallback(fullLogout);
  }
  
  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Send SMS dialog
  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Thoát {((window.ckDashboardTitle) ? window.ckDashboardTitle : "CK Dashboard")}</DialogTitle>

        <DialogContent style={{paddingBottom: 20}}>          
          <Box p={1} >
            <Alert severity="warning">
              <AlertTitle>Bạn thực sự muốn thoát?</AlertTitle>
              Hệ thống sẽ yêu cầu xác minh số điện thoại hoặc email khi bạn đăng nhập lại.
            </Alert>
          </Box>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Trở lại
          </Button>
          <Button onClick={() => handleLogout(true)} variant="outlined" color="warning">
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>
  );

}
