import { adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import Autocomplete from '@mui/material/Autocomplete';

import { sha256 } from 'js-sha256';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import {isValidOrderId, isValidNameUTF8, isValidUrl } from '../../ckcore/core/Validator';
import Colors from '../../ckcore/core/Colors';
import ValUtils from '../../ckcore/core/ValUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { getBankSvgIcon } from '../../ckcore/core/CkBank'


export default function CkqrConfigTestDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const testKey = props.testKey;
  const paymentLink = props.paymentLink;

  const currentSecondId = Math.floor(((new Date().getTime())/1000)%100000);

  const testOrdderObj = {

    partner_id: partnerObj.partnerId,
    account_number: props.bankAccounts[0].accountNumber,
    bank_name: props.bankAccounts[0].bankName,
    order_id: 'TEST' + currentSecondId,
    order_total: 11000,
    customer_phone: getDisplayPhone(partnerObj.phone),
    customer_id: partnerObj.partnerId,
    order_info: 'TEST CKQR',
    return_url: 'https://dashboard.chuyenkhoan.com',
    product: {
      name: partnerObj.name + ' SP001' ,
      code: 'CKQR',
      description: 'Test Chuyển Khoản QR',
      image: (partnerObj.logo)? partnerObj.logo : '',
      price: 11000,
      discount: 0,
      tax: 0,
      quantity: 1
    },

  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editing, setEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderObj, setOrderObj] = useState(testOrdderObj);


  const handleChange = name => event => {
    var val = event.target.value;
    if(name === 'order_total') {
      val = val.replace(/\D/g, "");
    }

    setOrderObj({ ...orderObj, [name]: val });
    setLoading(false);
    setEditing(true);
  };

  const handleChangeProduct = name => event => {

    const productObj = orderObj.product;
    productObj[name] = event.target.value;
    setOrderObj({ ...orderObj, product: productObj });
    setLoading(false);
    setEditing(true);
  };

  const handleChangeBank = (accountNumber, bankName) => {
    setOrderObj({ ...orderObj, account_number: accountNumber, bank_name: bankName});
    setLoading(false);
    setEditing(true);
  }

  // Create test CKQR link
  const createTestCKQRLink = async () => {

    if(!isValidNameUTF8(orderObj.product.name)) {
      setErrorMessage("Tên sản phẩm không hợp lệ");
      return;
    }

    if(!isValidOrderId(orderObj.order_id)) {
      setErrorMessage("Mã đơn không hợp lệ");
      return;
    }

    if(!isValidUrl(orderObj.product.image)) {
      setErrorMessage("Link ảnh không hợp lệ");
      return;
    }

    const timestamp = new Date().getTime();

    // create checksum
    const checksum = sha256(
      orderObj.partner_id + orderObj.account_number + orderObj.order_total
      + orderObj.order_id + orderObj.customer_phone + orderObj.customer_id + orderObj.order_info 
      + orderObj.product.name + orderObj.product.code + orderObj.product.price + orderObj.product.discount + orderObj.product.tax 
      + orderObj.product.quantity + decodeURIComponent(orderObj.product.image) + orderObj.product.description  
      + decodeURIComponent(orderObj.return_url) + timestamp + testKey
    );

    // 1000111111ck.success10001Agribank13002064552961660740000010100011000ORDER_001595796-MBVCB.1244353265.076602.0906246196CK23459.CT tu 0021001131105 HOANG TRONG HUY ...166074000001qgX6vPmKZHWrPKbdomxjuuQiXxujyVT
    // 10001122ck.success10001Agribank13002064552961660740000010100011000ORDER_001595796-MBVCB.1244353265.076602.0906246196CK23459.CT tu 0021001131105 HOANG TRONG HUY ...166074000001qgX6vPmKZHWrPKbdomxjuuQiXxujyVT

    orderObj.timestamp = timestamp;
    orderObj.checksum = checksum;

    const payment_url = paymentLink + "order=" + encodeURIComponent(JSON.stringify(orderObj));

    // console.log(payment_url);
    window.location.href = payment_url;

  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Tạo đơn hàng test Chuyển Khoản QR</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>
              {getMessageBox(errorMessage, successMessage, "Mời bạn nhập tên sản phẩm, mã đơn, và link ảnh sản phẩm (nếu có)")}
            </Box>

            <Grid container spacing={2}>

              <Grid item xs={6}>
                <Autocomplete id="bank-list" disableClearable 
                  options={props.bankAccounts}
                  getOptionLabel={(bankAccount) => bankAccount.accountNumber}
                  isOptionEqualToValue={(option, bankAccount) => option.accountNumber === bankAccount.accountNumber}
                  onChange={(event, bankAccount) => handleChangeBank(bankAccount.accountNumber, bankAccount.bankName)}
                  value={{accountNumber: orderObj.account_number}}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" fullWidth
                      label="Số tài khoản nhận"                  
                      className={classes.textfieldBankName}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small" >
                              <SvgIcon component={getBankSvgIcon(orderObj.bank_name)} viewBox="0 0 48 48" />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}/>
                )}/>

              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Số tiền"
                  value={ValUtils.formatMoney(orderObj.order_total, '.')}
                  onChange={handleChange('order_total')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Mã đơn"
                  value={orderObj.order_id}
                  onChange={handleChange('order_id')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Nội dung đơn"
                  value={orderObj.order_info}
                  onChange={handleChange('order_info')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Mã khách hàng"
                  value={orderObj.customer_id}
                  onChange={handleChange('customer_id')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="SĐT khách hàng"
                  value={orderObj.customer_phone}
                  onChange={handleChange('customer_phone')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Tên sản phẩm"
                  value={orderObj.product.name}
                  onChange={handleChangeProduct('name')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Ảnh sản phẩm"
                  value={orderObj.product.image}
                  onChange={handleChangeProduct('image')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Mô tả sản phẩm"
                  value={orderObj.product.description}
                  onChange={handleChangeProduct('description')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="URL trở về"
                  value={orderObj.return_url}
                  onChange={handleChange('return_url')}
                />
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button disabled={loading || !editing} onClick={() => createTestCKQRLink()} color="primary">
              Tạo đơn hàng
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
}));

const useStyles = makeStyles(theme => ({
  root: {

  },
}));
