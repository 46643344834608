import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Config from '../../ckcore/core//Config';
import TxStatus from '../../ckcore/core//TxStatuses';
import User from '../../ckcore/core//User';
import Colors from '../../ckcore/core/Colors';
import { Grid } from '@mui/material';
import WebhookCard from './webhook/WebhookCard';
import WebhookDialog from './webhook/WebhookDialog';

/**
 * API for connecting to partner system
 */
export default function WebhookConfig(props) {

  const classes = useStyle();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const appId = props.appId;
  const configWebhook = (props.configWebhook) ? props.configWebhook : '';


  const [apiConfig, setApiConfig] = useState({});

  const [selectedWebhook, setSelectedWebhook] = useState(configWebhook);
  const [showWebhookDialog, setShowWebhookDialog] = useState(false);


  const onSelectWebhook = (webhook) => {
    setSelectedWebhook(webhook);
    setShowWebhookDialog(true);
  }

  const closeWebhookDialog = () => {
    setShowWebhookDialog(false);

    if(configWebhook) {
      if(props.closeCallback) {
        props.closeCallback();
      }
    } else {
      // reload config
      loadAppConfig();
    }
  }

  // load confirm API config
  const loadAppConfig = async () => {

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setApiConfig(response.appConfig);

        if(configWebhook) {
          setShowWebhookDialog(true);
        }

      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }


  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);



  if(configWebhook) {

    return (
      <div>
        {
          showWebhookDialog &&
          <WebhookDialog open={showWebhookDialog} closeCallback={closeWebhookDialog}
            appId={appId}
            webhook={selectedWebhook}
            apiConfig={apiConfig}
            userObj={props.userObj}
            partnerObj={props.partnerObj} />
        }
      </div>
    );

  } else {
    return (
      <Container className={classes.paper}>
  
        <h3 className={classes.title}>TÍCH HỢP KẾT NỐI TỚI CÁC HỆ THỐNG ĐỐI TÁC</h3>
  
        <Grid container spacing={3} className={classes.grid}>
          {
            (apiConfig.fields) &&
            apiConfig.fields.map((field, index) => (
              <Grid key={index} item md={4} lg={3}>
                <WebhookCard webhook={field} webhookObj={apiConfig[field]} actionCallback={onSelectWebhook} />
              </Grid>
            ))
          }
  
        </Grid>
  
        {
          showWebhookDialog &&
          <WebhookDialog open={showWebhookDialog} closeCallback={closeWebhookDialog}
            appId={appId}
            webhook={selectedWebhook}
            apiConfig={apiConfig}
            userObj={props.userObj}
            partnerObj={props.partnerObj} />
        }
  
      </Container>
    );
  }


  
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    paddingBottom: 20,
    textAlign: "center",
    margin: "auto",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },

  grid: {
    padding: 20,
  },

  guide: {
    padding: 8,
  },
}));
