import moment from "moment";

export function parsePopaBean(popaLine) {
  /*
    long popaId;
    short statusId;
    short typeId;
    int industry;
    long optional; // for future use

    int birthday;
    byte gender;
    short provinceId;
    short districtId;
    int communeId;
    double lat;
    double lng;

    short creditCount;
    long creditValue;
    short debitCount;
    long debitValue;

    long updated;
    long created;

    String code = "";
    String phone = "";
    String email = "";
    String website = "";
    String bankName = "";
    String accountNumber = "";
    String accountName = "";
    String name = "";
    String address = "";

    JSONObject info;
    JSONArray contacts;
    JSONArray accounts;

  */

  let values = popaLine.split("\t");

  return {
    popaId: Number(values[0]),
    statusId: parseInt(values[1]),
    typeId: parseInt(values[2]),
    industry: parseInt(values[3]),
    optional: parseInt(values[4]),

    birthday: parseInt(values[5]),
    gender: parseInt(values[6]),
    provinceId: parseInt(values[7]),
    districtId: parseInt(values[8]),
    communeId: parseInt(values[9]),
    lat: Number(values[10]),
    lng: Number(values[11]),

    credits: parseInt(values[12]),
    credit: Number(values[13]),
    debits: parseInt(values[14]),
    debit: Number(values[15]),
    created: Number(values[16]),
    updated: Number(values[17]),

    code: values[18],
    phone: values[19],
    email: values[20],
    website: values[21],
    bankName: values[22],
    accountNumber: values[23],
    accountName: values[24],
    name: values[25],
    address: values[26],
    
    info: {},
    contacts: [],
    accounts: []
  };
}

export const POPA_STATUS_LIST = [
  {name: '---', value: 0},

  {name: 'Hoạt động', value: 1},
  {name: 'Tạm dừng', value: 2},
];
export function getPopaStatusName(statusId) {

  let popaStatusObj = POPA_TYPE_LIST.find((statusObj) => statusObj.value === statusId);
  if(popaStatusObj) {
    return popaStatusObj.name;
  } else {
    return '---';
  }
}


export const POPA_TYPE_LIST = [
  {name: '---', value: 0},

  {name: 'Khách lẻ', value: 1},
  {name: 'Đại lý', value: 2},
  {name: 'Nhà phân phối', value: 3},
  {name: 'Nhà cung cấp', value: 5},
  {name: 'Nhân viên', value: 9},
];
export function getPopaTypeName(typeId) {

  let popaTypeObj = POPA_TYPE_LIST.find((typeObj) => typeObj.value === typeId);
  if(popaTypeObj) {
    return popaTypeObj.name;
  } else {
    return '---';
  }
}


export const GENDER_LIST = [
  {name: '---', value: 0},
  {name: 'Nam', value: 1},
  {name: 'Nữ', value: 2}
];

export function getGenderName(genderId){
  if(genderId == 1) {
    return GENDER_LIST[1].name;
  } else if(genderId == 2) {
    return GENDER_LIST[2].name;
  }
  return '---';
}

export const INDUSTRY_LIST = [
  {name: '---', value: 0},
  {name: 'Kinh doanh', value: 100011},
  {name: 'Công nghệ', value: 200011}
];

export const EMPTY_LIST = [
  {name: '---', value: 0}
];

export function getIndustryName(industryId) {

  let industryObj = INDUSTRY_LIST.find((indObj) => indObj.value === industryId);
  if(industryObj) {
    return industryObj.name;
  } else {
    return '---';
  }
}

export function formatBirthday(birtday){
  if(birtday < 100000) {
    return '---';
  }

  return moment(birtday*1000).format('YYYY-MM-DD');
};