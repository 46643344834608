
import { ckTableStyles } from '../../../ckcore/ui/CkStyles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import { formatAmount, getTxTimeShort } from '../../../ckcore/core/TxUtils';
import { Box } from '@mui/system';
import Colors from '../../../ckcore/core/Colors';

export default function OrderPaymentHistory(props) {
  const tableStyles = ckTableStyles();
  const orderObj = props.orderObj;
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };


  return (
    <Box sx={{ padding: '12px', paddingTop: '4px', borderRadius: 3, backgroundColor: Colors.BG_WHITE }}>

      <TableContainer className={tableStyles.tableContainer}>

        <Table className={tableStyles.table} size="small" style={{borderRadius: 3, backgroundColor: '#FFF'}} sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>

          <TableHead>
            <TableRow className={tableStyles.row} style={{ height: 32 }}>
              <TableCell align="center" className={tableStyles.colTxId} sx={cellBorder}>STK</TableCell>
              <TableCell align="center" className={tableStyles.colHeader} sx={cellBorder}>Số tiền</TableCell>
              <TableCell align="center" className={tableStyles.colHeader} sx={cellBorder}>Thời gian</TableCell>
              <TableCell align="center" className={tableStyles.colHeader} sx={cellBorder}>Nội dung</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {
              orderObj.txList.map((txObj, index) => (
                <TableRow key={index}>
                  <TableCell className={tableStyles.colTxId} sx={cellBorder}>{txObj.txId}</TableCell>
                  <TableCell className={tableStyles.colValue} sx={cellBorder}>{formatAmount(txObj.amount)}</TableCell>
                  <TableCell className={tableStyles.colTime} sx={cellBorder}>{getTxTimeShort(txObj.created)}</TableCell>
                  <TableCell className={tableStyles.colContent} sx={cellBorder}>{txObj.content}</TableCell>
                </TableRow>
              ))
            }

            {
              (orderObj.txList.length === 0) &&
              <TableRow className={tableStyles.row} style={{ height: 32 }}>
                <TableCell colSpan={4} align="center" className={tableStyles.colName} sx={cellBorder}>Chưa có giao dịch</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}