import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { isValidEmail, isValidNameUTF8, isValidPhone } from '../../ckcore/core/Validator';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { Roles, getRoleName, getRoleStatus } from '../../ckcore/core/Roles';


export default function EditStaffRoleDialog(props) {
  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const userRole = User.getActiveRole(userObj);

  // get seletect staff obj
  const selectedStaffObj = (props.staffObj.phone) ? props.staffObj : {
    phone: '',
    roleId: Roles.STAFF,
    name: '',
    partnerId: userObj.partnerId,
    statusId: TxStatus.WAITING,
    updated: new Date().getTime(),
  };

  const isUpdate = (selectedStaffObj.phone.length > 0);

  const [messages, setMessages] = useState({
    success: '',
    error: '',
    info: isUpdate ? '' : 'Mời bạn nhập số điện thoại thành viên muốn thêm',
    changed: false,
    exists: true,
  });
  const [loading, setLoading] = useState(false);
  const [staffObj, setStaffObj] = useState(selectedStaffObj);

  // handle input phone
  const handleInputPhone = (phone) => {
    // change phone and reset name
    setStaffObj({ ...staffObj, phone: phone, name: "" });
    setMessages({ ...messages, changed: true });
  };

  // handle input staff info
  const handleInput = (name, value) => {
    setStaffObj({ ...staffObj, [name]: value });
  };

  // change role
  const handleChangeRole = (roleId) => {
    console.log("Change role to: " + roleId);
    setStaffObj({ ...staffObj, roleId: roleId });
    setMessages({ ...messages, changed: true });
  };

  // change status
  const handleChangeStatus = (statusId) => {
    console.log("Change status to: " + statusId);
    setStaffObj({ ...staffObj, statusId: parseInt(statusId) });
    setMessages({ ...messages, changed: true });
  };

  /**
   * Check user info by user name
   */
  const checkUserInfo = async () => {

    // request get user info
    const ckc = {
      txTypeId: TxTypes.PARTNER_ADMIN_STAFF_CHECK,
      partnerId: userObj.partnerId,
      staffPhone: staffObj.phone,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      if (loading) {
        return;
      }

      setMessages({ ...messages, error: '', success: '' });
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {


        setMessages({...messages, error: '', success: '', info: 'Mời bạn chọn vai trò và bấm THÊM THÀNH VIÊN', exists: true});

        // update staff name
        setStaffObj({ ...staffObj, name: response.name, roleId: Roles.STAFF, statusId: TxStatus.WAITING });

      } else if (response.statusId === TxStatus.LOGIN_ERROR_NOT_REGISTER) {
        
        setMessages({ ...messages, 
          error: '', 
          info: 'Số điện thoại ' + staffObj.phone + ' chưa đăng ký. Vui lòng nhập họ tên và email để đăng ký thành viên mới.', 
          exists: false 
        });

      } else if (response.statusId === TxStatus.DUPLICATED) {
        if(!isUpdate) {
          setMessages({ ...messages, error: response.name + ' đã là thành viên của tổ chức', exists: true});
        }
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({ ...messages, error: 'Tìm thành viên bị lỗi, vui lòng thử lại sau', exists: true });
      }

    } catch (error) {
      console.log("Error... " + error);
      setMessages({ ...messages, error: 'Tìm thành viên bị lỗi, vui lòng thử lại sau' });
    }

    setLoading(false);
  }

  // User click agree
  const handleUpdateStaffRole = () => {
    setMessages({ ...messages, error: '', success: '' });

    // do update
    processUpdateStaff(TxTypes.PARTNER_ADMIN_STAFF_UPDATE);
  };


  const handleAddStaffRole = () => {

    // validate data
    if (!isValidPhone(staffObj.phone)) {
      setMessages({ ...messages, error: 'Số điện thoại không hợp lệ' });
      return;
    }

    if (staffObj.name.length === 0) {
      setMessages({ ...messages, error: 'Họ tên không hợp lệ' });
      return;
    }

    if(messages.exists) {
      processUpdateStaff(TxTypes.PARTNER_ADMIN_STAFF_REQUEST);
    } else {
      processCreateStaffAccount();
    }
  }

  /**
   * Update partner staff
   */
  const processUpdateStaff = async (txTypeId) => {

    // refresh message
    const cka = {
      txTypeId: txTypeId,
      partnerId: userObj.partnerId,
      staff: staffObj,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      if (loading) {
        return;
      }

      setMessages({ ...messages, error: '', success: '', changed: false });
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setMessages({ ...messages, success: getEditText() + " thành viên thành công" });
        setTimeout(() => {
          handleClose();
        }, 2022);

        // reset partner info
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({ ...messages, error: getEditText() + " tài khoản bị lỗi, vui lòng thử lại sau" });
      }

    } catch (error) {
      console.log("Error... " + error);
      setMessages({ ...messages, error: getEditText() + " tài khoản bị lỗi, vui lòng thử lại sau" });
    }

    setLoading(false);
  };

  /**
   * Admin create staff account
   */
  const processCreateStaffAccount = async () => {

    // validate email
    if (!isValidEmail(staffObj.email)) {
      setMessages({ ...messages, error: 'Địa chỉ email không hợp lệ' });
      return;
    }

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_STAFF_CREATE,
      partnerId: userObj.partnerId,
      staff: staffObj,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      if (loading) {
        return;
      }

      setMessages({ ...messages, error: '', success: '', changed: false });
      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setMessages({ ...messages, success: getEditText() + " thành viên thành công. Mật khẩu đã được gửi về số điện thoại: " + staffObj.phone + '.' });
        setTimeout(() => {
          handleClose();
        }, 3033);
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({ ...messages, error: getEditText() + " tài khoản bị lỗi, vui lòng thử lại sau" });
      }

    } catch (error) {
      console.log("Error... " + error);
      setMessages({ ...messages, error: getEditText() + " tài khoản bị lỗi, vui lòng thử lại sau" });
    }

    setLoading(false);
  };


  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const getEditText = () => {
    if (isUpdate) {
      return "Cập nhật";
    } else {
      return "Thêm"
    }
  }


  const enableSelectRole = (roleId) => {

    // can not change owner
    if (userObj.phone === partnerObj.phone) {
      if (staffObj.phone === userObj.phone) {
        return false;
      } else {
        return true;
      }
    }

    // allow change role
    if (userRole.roleId === Roles.ADMIN && staffObj.roleId === Roles.MANAGER && roleId === Roles.ADMIN) {
      return true;
    }

    if (staffObj.roleId === Roles.ADMIN || roleId === Roles.ADMIN) {
      return false;
    }

    // not enough rights
    if (userRole.roleId === Roles.MANAGER || userRole.roleId === Roles.STAFF) {
      return false;
    }

    return true;
  }

  const enableSelectStatus = (statusId) => {

    // can not change owner
    if (staffObj.roleId === Roles.ADMIN && staffObj.phone === partnerObj.phone) {
      return false;
    }

    // can not disable my self
    if (staffObj.phone === userObj.phone) {
      return false;
    }

    // can not change waiting
    if (staffObj.statusId === TxStatus.WAITING || statusId === TxStatus.WAITING) {
      return false;
    }

    return true;
  }


  useEffect(() => {

    if (isValidPhone(staffObj.phone)) {
      checkUserInfo();
    }
  }, [staffObj.phone]);

  // Edit staff dialog
  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{getEditText()} thành viên</DialogTitle>
      <DialogContent dividers>

        <Box p={1}>
          {getMessageBox(messages.error, messages.success, messages.info)}
        </Box>

        <Grid container spacing={2} style={{marginTop: 8}}>

          {
            (messages.exists) ? (
              <>
                <Grid item xs={6}>
                  <TextField fullWidth margin="dense" type="text" variant="outlined"
                    label="Số điện thoại"
                    value={getDisplayPhone(staffObj.phone)}
                    onChange={e => handleInputPhone(e.target.value)}
                    inputProps={{ maxLength: 10 }}
                    disabled={isUpdate}
                    className={classes.disabledText}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth margin="dense" type="text" variant="outlined"
                    label="Họ tên"
                    value={staffObj.name}
                    disabled={true}
                    className={classes.disabledText}
                  />
                </Grid>
              </>

            ) : (
              <>
                <Grid item xs={6}>
                  <TextField id="ck-phone" fullWidth margin="dense" type="text" variant="outlined"
                    label="Số điện thoại"
                    value={getDisplayPhone(staffObj.phone)}
                    onChange={e => handleInput('phone', e.target.value)}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="ck-name" fullWidth margin="dense" type="text" variant="outlined"
                    label="Họ tên"
                    value={staffObj.name}
                    onChange={e => handleInput('name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="ck-email" fullWidth margin="dense" type="text" variant="outlined"
                    label="Địa chỉ email"
                    value={staffObj.email}
                    onChange={e => handleInput('email', e.target.value)}
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="ck-password" fullWidth margin="dense" type="text" variant="outlined"
                    label="Mật khẩu mặc định"
                    value={staffObj.password}
                    onChange={e => handleInput('password', e.target.value)}
                    disabled={true}
                  />
                </Grid>
              </>
            )
          }



          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ paddingLeft: 6 }}>
              <FormLabel component="legend">Vai trò</FormLabel>
              <RadioGroup row aria-label="role" name="role" value={(staffObj.roleId) ? staffObj.roleId : Roles.STAFF} 
                onChange={e => handleChangeRole(e.target.value)}>

                <FormControlLabel control={<Radio color="primary" />} value={Roles.ADMIN}
                  label={getRoleName(Roles.ADMIN)} disabled={!enableSelectRole(Roles.ADMIN)} />

                <FormControlLabel control={<Radio color="primary" />} value={Roles.MANAGER}
                  label={getRoleName(Roles.MANAGER)} disabled={!enableSelectRole(Roles.MANAGER)} />

                <FormControlLabel control={<Radio color="primary" />} value={Roles.STAFF}
                  label={getRoleName(Roles.STAFF)} disabled={!enableSelectRole(Roles.STAFF)} />
              </RadioGroup>
            </FormControl>

          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" style={{ paddingLeft: 6 }}>
              <FormLabel component="legend">Trạng thái</FormLabel>
              <RadioGroup row aria-label="status" name="status" value={staffObj.statusId.toString()} onChange={e => handleChangeStatus(e.target.value)}>

                <FormControlLabel control={<Radio color="primary" value={TxStatus.SUCCESS.toString()} />}
                  label={getRoleStatus(TxStatus.SUCCESS)}
                  disabled={!enableSelectStatus(TxStatus.SUCCESS)} />

                <FormControlLabel control={<Radio color="primary" value={TxStatus.CANCEL.toString()} />}
                  label={getRoleStatus(TxStatus.CANCEL)}
                  disabled={!enableSelectStatus(TxStatus.CANCEL)} />

                <FormControlLabel control={<Radio color="primary" value={TxStatus.WAITING.toString()} />}
                  label={getRoleStatus(TxStatus.WAITING)}
                  disabled={!enableSelectStatus(TxStatus.WAITING)} />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Trở lại
        </Button>
        {
          isUpdate &&
          <Button disabled={loading || !messages.changed} onClick={handleUpdateStaffRole} color="primary">
            Cập nhật
          </Button>
        }
        {
          !isUpdate &&
          <Button disabled={loading || !messages.changed || messages.error !== ''} onClick={handleAddStaffRole} color="primary">
            Thêm thành viên
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
}


const useStyles = makeStyles(theme => ({
  root: {

  },

  disabledText: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  }
}));
