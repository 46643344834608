
import { ckTableStyles } from '../../ckcore/ui/CkStyles';
import { Box, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { getSubscriptionPackageName, pricingData } from './PricingTable';
import Colors from '../../ckcore/core/Colors';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { formatAmountVND } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';

export default function ExtendSubTable(props) {
  const tableStyles = ckTableStyles();
  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const subscriptionPackage = props.subscriptionPackage;
  const subInfo = props.subInfo;

  const subscription = (partnerObj.subscription) ? partnerObj.subscription : {
    package: 'small',
    expired: moment().format("DD/MM/YYYY"),
    updated: new Date().getTime(),
    created: new Date().getTime(),
    txRefId: ''
  }
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  const handleSubTime = (months) => {
    // callback
    props.onChangeExtendService(months);
  }

  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>

          <TableRow className={tableStyles.row} style={{ height: 40 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>
              Gói dịch vụ hiện tại:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <strong style={{ color: Colors.DARK_BLUE }}>{getSubscriptionPackageName(subscription.package)}</strong>, hạn dùng <strong>{moment(subscription.expired).format('DD/MM/YYYY')}</strong>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              Gia hạn mới:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <TextField id="ck-subscription" variant="standard" margin="dense" size="small"
                select
                value={subInfo.subMonths}
                style={{ marginLeft: 20, maxWidth: 200 }}
                InputProps={{ readOnly: false }}
                onChange={(e) => handleSubTime(parseInt(e.target.value))}>
                {pricingData.subtimes.map((subTime, index) => (
                  <MenuItem key={subTime.value} value={subTime.value}>
                    <Box style={{ color: Colors.DARK_BLUE }}>{subTime.name} {getSubscriptionPackageName(subscriptionPackage)}</Box>
                  </MenuItem>
                ))}

              </TextField>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <span>Sử dụng đến <strong>{moment(subInfo.expired).format('DD/MM/YYYY')}</strong></span>
            </TableCell>
          </TableRow>

          <TableRow className={tableStyles.row} style={{ height: 40 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>
              Ưu đãi:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <Box style={{ color: Colors.DARK_GREEN, fontWeight: '500' }}>Tặng {subInfo.bonus} CKCoin</Box>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              Số tiền thanh toán:
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              <strong style={{ color: Colors.RED, marginLeft: 16 }}>{formatAmountVND(subInfo.amount)}</strong>
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeader}>
              {
                (subInfo.convertedCKCoin > 0) &&
                <span style={{ color: Colors.ORANGE, fontWeight: '500' }}>Gói cũ được quy đổi thành {subInfo.convertedCKCoin} CKCoin</span>
              }
            </TableCell>
          </TableRow>

          {
            Config.isSuperAdmin(userObj.phone) &&
            <TableRow className={tableStyles.row} style={{ height: 40 }}>
              <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>
                Giá gói ưu đãi:
              </TableCell>
              <TableCell align="left" className={tableStyles.colHeader}>
                <TextField type="text" variant="standard" size="small" margin="dense" fullWidth style={{maxWidth: 160}}
                  label={"Giá gói / tháng"}
                  value={subInfo.price}
                  onChange={(e) => props.handleAdminChange('price', (e.target.value) ? parseInt(e.target.value) : 0)}
                />
              </TableCell>
              <TableCell align="left" className={tableStyles.colHeader}>
                Giá ckcoin ưu đãi:
              </TableCell>
              <TableCell align="left" className={tableStyles.colHeader}>
                <TextField type="text" variant="standard" size="small" margin="dense" fullWidth style={{maxWidth: 160, marginLeft: 16}}
                  label={"Giá CKCoin"}
                  value={subInfo.fee}
                  onChange={(e) => props.handleAdminChange('fee', (e.target.value) ? parseInt(e.target.value) : 0)}
                />
              </TableCell>
              <TableCell align="left" className={tableStyles.colHeader}>
                <TextField
                  label={"Sử dụng đến"}
                  type="date"
                  size="small"
                  variant="outlined" margin="dense" fullWidth
                  value={moment(subInfo.expired).format('YYYY-MM-DD')}
                  onChange={(e) => props.handleAdminChange('expired', moment(e.target.value, 'YYYY-MM-DD').toDate().getTime())}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </TableCell>
            </TableRow>
          }


        </TableBody>
      </Table>
    </TableContainer>

  );
}