import React from 'react';
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import User from '../../ckcore/core/User';
import TxTypes from '../../ckcore/core/TxTypes';

/**
 * Update partner information
 */
export default function RootUpdatePartnerDialog(props) {
  const classes = useStyle();

  const userObj = props.userObj;
  const requestPartnerId = props.partnerId;

  const [settingConfig, setSettingConfig] = useState({ transparency: false });
  const [configChanged, setConfigChanged] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const setAllowTransparency = (value) => {
    setSettingConfig({ ...settingConfig, transparency: value });
    setConfigChanged(true);
  }

  const setAllowDelegate = (value) => {
    setSettingConfig({ ...settingConfig, delegate: value });
    setConfigChanged(true);
  }

  const setAllowSilence = (value) => {
    setSettingConfig({ ...settingConfig, silence: value });
    setConfigChanged(true);
  }


  // load forward config
  const loadAppConfig = async () => {

    setSuccessMsg("");
    setErrorMsg("");

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: "SETTING",
      partnerId: requestPartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setSettingConfig(response.appConfig);
        console.log("Loaded Profile config: " + JSON.stringify(response.appConfig));
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // Update Forward API config
  const processUpatePartner = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.ORIGIN_ADMIN_PARTNER_UPDATE,
      partnerId: requestPartnerId,
      partner: settingConfig,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "admin=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS) {

        setConfigChanged(false);
        setSuccessMsg("Cập nhật thông tin thành công");

        // request to refresh after update profile
        // props.refreshCallback();

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request update error with statuses: " + response.statusId);
        setErrorMsg("Cập nhật thông tin bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMsg("Cập nhật thông tin bị lỗi, vui lòng thử lại sau.")
      console.log("Error update Profile config " + error);
    }
  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);

  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = settingConfig[field];
    const isError = (settingConfig[field]["error"]);

    return (
      <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
        <Box className={classes.fieldNameStyle}>
          {fieldObj["name"]}:
        </Box>
        <Box className={classes.fieldValueStyle}>
          <TextField
            error={isError}
            type="text"
            className={classes.textFieldValue}
            inputProps={{ min: 0, style: { textAlign: 'left' } }}
            fullWidth
            size="small"
            variant="outlined"
            value={fieldObj["value"]}
            margin="dense"
            label={fieldObj["label"]}
            disabled={true}
          />
        </Box>

      </Box>
    );

  }

  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">CẬP NHẬT THÔNG TIN TỔ CHỨC</DialogTitle>
      <DialogContent dividers>
        <Paper className={classes.paper}>
          <Box p={1} m={1}>
            {
              getMessageBox(errorMsg, successMsg, "")
            }
            <Box className={classes.guide}>
            </Box>
          </Box>


          {
            (settingConfig.fields) &&
            settingConfig.fields.map((field, index) => (
              getFieldConfig(field)
            ))
          }

          {
            Config.isSuperAdmin(userObj.phone) &&
            <Box>
              <Box key="transparency" display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
                <Box className={classes.fieldNameStyle}>

                </Box>
                <Box className={classes.fieldValueStyle}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(settingConfig.transparency) ? settingConfig.transparency : false}
                        onChange={e => setAllowTransparency(e.target.checked)}
                        name="transparency"
                        color="primary"
                      />
                    }
                    label="Đối tác MINH BẠCH THU CHI"
                  />
                </Box>
              </Box>

              <Box key="silence" display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
                <Box className={classes.fieldNameStyle}>

                </Box>
                <Box className={classes.fieldValueStyle}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(settingConfig.silence) ? settingConfig.silence : false}
                        onChange={e => setAllowSilence(e.target.checked)}
                        name="silence"
                        color="primary"
                      />
                    }
                    label="Đối tác SILENCE"
                  />
                </Box>
              </Box>

              <Box key="delegate" display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
                <Box className={classes.fieldNameStyle}>

                </Box>
                <Box className={classes.fieldValueStyle}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(settingConfig.delegate) ? settingConfig.delegate : false}
                        onChange={e => setAllowDelegate(e.target.checked)}
                        name="delegate"
                        color="primary"
                      />
                    }
                    label="Đối tác DELEGATE"
                  />
                </Box>
              </Box>
            </Box>

          }
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Trở lại
        </Button>
        <Button className={classes.ckButton} variant="contained" color="primary" type="submit" disabled={!configChanged} onClick={() => processUpatePartner()}>
          Cập nhật
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: Colors.DARK_BLUE,
    marginTop: 20,
  },

  fieldNameStyle: {
    width: 111,
    textAlign: "right",
  },

  fieldValueStyle: {
    width: 333,
    marginLeft: 8,
    marginRight: 8,
    textAlign: "left",
  },

  textFieldKey: {
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldOption: {
  },

  grow: {
    minHeight: "15px",
  },

  ckButton: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));
