
import React, { useEffect, useState } from 'react';
import { Hidden, ThemeProvider, StyledEngineProvider, Link, TextField, Button, Typography, SvgIcon, MenuItem, InputAdornment } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';

import {
  getStatusColor,
  getTxStatusText,
  getTxMillisText,
  parseTxBean,
  getTxTimeText,
  compareUpdatedDesc,
  getTxTimeShort
} from '../../ckcore/core/TxUtils';
import { useInterval } from '../../ckcore/core/CkUtils';

import Config from '../../ckcore/core/Config';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import PopaConfig from './PopaConfig';
import UpdatePopaStatusDialog from './UpdatePopaStatus';
import { getBankSvgIcon } from '../../ckcore/core/CkBank';
import BankMarketingDialog from './BankMarketing';
import { Visibility, VisibilityOff } from '@mui/icons-material';

/**
 * List priority partners
 */
export default function PriorityPartnerList(props) {

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [popasData, setPopasData] = useState({
    incs: [],
    filteredRows: [],
    category: '',
    demand: '',
    statusId: -1,
  });

  const [domain, setDomain] = useState("");
  const handleChange = (name) => event => {
    setDomain(event.target.value);
  };

  const handleSelectStatus = (event) => {
    setPopasData({ ...popasData, "statusId": parseInt(event.target.value) });
  }

  const handleSelectCategory = (event) => {
    setPopasData({ ...popasData, "category": event.target.value });
  }

  const handleSelectDemand = (event) => {
    setPopasData({ ...popasData, "demand": event.target.value });
  }

  const requestSearch = (query) => {

    const filteredRows = popasData.incs.filter((popaObj) => {

      // build content for search
      const content = popaObj.domain + ' ' + popaObj.status;

      return content.toLowerCase().includes(query.toLowerCase());
    });

    setPopasData({ ...popasData, filteredRows: filteredRows });

  };

  const [selectedPopaObj, setSelectedPopaObj] = useState({});
  const [selectedBankAccount, setSelectedBankAccount] = useState({});

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(33);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /**
   * Get active inc list
   */
  const getActiveIncList = () => {

    if (domain) {
      return popasData.filteredRows;
    } else {
      return popasData.incs;
    }
  }

  // load system data
  const loadPopas = async () => {

    try {

      if (loading) {
        return;
      }

      var startTime = new Date().getTime();

      // request message
      var ckmsg = {
        txType: "priority",
        statusId: popasData.statusId,
        category: popasData.category,
        demand: popasData.demand,
        index: 0,
        count: 999,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        console.log("Loaded time: " + (new Date().getTime() - startTime));
        setPopasData({ ...popasData, incs: response.incs.sort(compareUpdatedDesc) });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load bank status, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get bank data.. " + error);
    }

    setLoading(false);

  };

  /**
   * Recrawl potential partner to priority partner
   */
  const recrawlPopas = async (popaObj, index) => {

    try {

      if (loading || !(popaObj)) {
        return;
      }

      // request message
      var ckmsg = {
        txType: "recrawl",
        inc: popaObj,
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(PopaConfig.getServerURL() + "popa=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // update inc list
        setPopasData({
          ...popasData, incs: [
            ...popasData.incs.slice(0, index),
            response.inc,
            ...popasData.incs.slice(index + 1)
          ]
        });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update priority popas, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error update priority popas: " + error);
    }

    setLoading(false);
  };

  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const requestUpdateStatus = (popaObj) => {
    setSelectedPopaObj(popaObj);
    setShowUpdateStatus(true);
  }
  const closeUpdateStatus = () => {
    setShowUpdateStatus(false);
    loadPopas();
  }

  /**
   * Get contact view
   */
  const getContacts = (popaObj, index) => {

    if (!(popaObj.contacts)) {
      return <div></div>
    }

    var phones = "";
    if ((popaObj.contacts.phones)) {
      phones = popaObj.contacts.phones.map(function (phone) {
        return phone;
      }).join(', ');
    }
    var emails = "";
    if ((popaObj.contacts.emails)) {
      emails = popaObj.contacts.emails.map(function (email) {
        return email;
      }).join(', ');
    }

    const facebook = (popaObj.contacts.facebook) ? popaObj.contacts.facebook : "";

    return (
      <div style={{ paddingTop: 6, paddingBottom: 3 }}>
        <Typography component="div" variant="caption" noWrap={true}>{phones}</Typography>
        <Typography component="div" variant="caption" noWrap={true}>{emails}</Typography>
        <Typography component="div" variant="caption" noWrap={true}><Link href={facebook} target="_blank">{facebook.replace("https://www.facebook.com/", "fb.com/")}</Link></Typography>
      </div>
    );
  }

  /**
   * Get account view
   */
  const getAccounts = (popaObj, index) => {

    if (!(popaObj.accounts)) {
      return <div></div>
    }

    const accounts = popaObj.accounts.filter((account, index) => index < 3);


    return (
      <div style={{ paddingTop: 6, paddingBottom: 3 }}>

        {
          accounts.map((bankAccount, index) => (
            <Typography key={index} component="div" variant="caption" noWrap={true} onClick={() => requestBankMarketing(popaObj, bankAccount)} >

              <IconButton size="small" style={{ padding: 1 }}>
                <SvgIcon className={classes.svgIcon} component={getBankSvgIcon(bankAccount.bankName)} viewBox="0 0 48 48" />
              </IconButton>

              <span> {bankAccount.accountNumber}</span>
            </Typography>

          ))
        }
      </div>
    );
  }

  const getStatuses = (popaObj, index) => {

    const category = PopaConfig.getPopaCategories().find((category) => category.value === popaObj.category);
    const demand = PopaConfig.getPopaDemands().find((demand) => demand.value === popaObj.demand);
    const contactPoint = popaObj.contact;
    const contactStatus = ((category) ? category.label : "") + "-" + ((demand) ? demand.label : "") + "-" + ((contactPoint) ? contactPoint : "");

    return (
      <div style={{ paddingTop: 6, paddingBottom: 3 }}>
        <Typography component="div" variant="caption" noWrap={true}>{contactStatus}</Typography>
        <Typography component="div" variant="body" style={{ color: PopaConfig.getPopaStatus(popaObj.statusId).color }} >{popaObj.status}</Typography>
      </div>
    );
  }

  const getActions = (popaObj, index) => {

    return (
      <div>
        <Tooltip title="Bank Marketing" aria-label="marketing"><span>
          <IconButton size="small" className={classes.actionIcon} onClick={() => requestBankMarketing(popaObj)}>
            <AccountBalanceOutlinedIcon style={{ fontSize: 16 }} />
          </IconButton></span>
        </Tooltip>

        <Tooltip title="Edit" aria-label="edit"><span>
          <IconButton size="small" className={classes.actionIcon} onClick={() => requestUpdateStatus(popaObj)}>
            <EditOutlinedIcon style={{ fontSize: 16 }} />
          </IconButton></span>
        </Tooltip>

        <Tooltip title="Recrawl" aria-label="crawl"><span>
          <IconButton size="small" className={classes.actionIcon} onClick={() => recrawlPopas(popaObj, index)}>
            <RefreshOutlinedIcon style={{ fontSize: 16 }} />
          </IconButton></span>
        </Tooltip>
      </div>
    );
  }

  /**
   * Send marketing message via bank transfer 
   */
  const [showBankMarketing, setShowBankMarketing] = useState(false);
  const closeBankMarketing = () => {
    setShowBankMarketing(false);
    loadPopas();
  }
  const requestBankMarketing = (popaObj, bankAccount) => {
    setSelectedPopaObj(popaObj);
    setSelectedBankAccount(bankAccount);
    setShowBankMarketing(true);
  }

  // reload on refresh
  useEffect(() => {
    loadPopas();
  }, [props, popasData.statusId, popasData.category, popasData.demand]);

  useEffect(() => {
    if (domain) {
      requestSearch(domain);
    }
  }, [domain]);



  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>

        <Grid container spacing={2} style={{ padding: 20 }}>

          <Grid item xs={12}>
            <Box p={1}>

            </Box>
          </Grid>

          <Grid item xs={2}>
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-select-category" size="small" fullWidth
              select
              label="Lĩnh vực"
              value={popasData.category}
              onChange={handleSelectCategory}>
              {PopaConfig.getPopaCategories().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-select-demand" size="small" fullWidth
              select
              label="Nhu cầu"
              value={popasData.demand}
              onChange={handleSelectDemand}>
              {PopaConfig.getPopaDemands().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}

            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-select-status" size="small" fullWidth
              select
              label="Trạng thái"
              value={popasData.statusId}
              onChange={handleSelectStatus}
            >
              {PopaConfig.getPopaStatuses().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField id="partner-domain" size="small" variant="outlined" fullWidth
              label="Lọc đối tác"
              inputProps={{ min: 0, style: { textAlign: 'left', fontSize: 16 } }}
              value={domain}
              onChange={handleChange('domain')}
              InputProps={{
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton
                      aria-label="clear search domain"
                      onClick={() => setDomain('')}
                      edge="end"
                    >
                      {(domain.length > 0) ? <ClearOutlinedIcon /> : <div />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={2}>

          </Grid>

        </Grid>


        <TableContainer className={classes.tableContainer} component={Paper} style={{ paddingLeft: 20, paddingRight: 20 }}>

          <Table className={classes.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow>

                <TableCell colSpan={3}>
                  <Button disabled={loading || !(popasData.query)} variant="outlined" size="small" style={{ marginLeft: 20 }} onClick={() => recrawlPopas(24 * 60 * 60 * 1000, true)} >Quét lại</Button>
                </TableCell>

                <TablePagination
                  rowsPerPageOptions={[33, 66, 99, 333]}
                  colSpan={3}
                  count={getActiveIncList().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Số ĐT' },
                    native: true,
                  }}
                  labelRowsPerPage="Xem:"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
              <TableRow>
                <TableCell className={classes.colId} align="center">IncId/Domain</TableCell>
                <TableCell className={classes.colContact} align="left">Contacts</TableCell>
                <TableCell className={classes.colAccount} align="left">Accounts</TableCell>
                <TableCell className={classes.colStatus} align="left">Statuses</TableCell>
                <TableCell className={classes.colTicket} align="left">Ticket</TableCell>
                <TableCell className={classes.colTime} align="left">Time</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? getActiveIncList().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : getActiveIncList()
              ).map((incObj, index) => (
                <TableRow key={page * rowsPerPage + index} style={{ height: 40, overflow: 'hidden' }}>
                  <TableCell className={classes.colId} component="th" scope="row" onClick={() => setDomain(incObj.domain)}>
                    <Link href={"http://" + incObj.domain} target="_blank">{incObj.domain}</Link>
                    <br />{incObj.incId}
                  </TableCell>

                  <TableCell className={classes.colContact} align="left">{getContacts(incObj)}</TableCell>
                  <TableCell className={classes.colAccount} align="left">{getAccounts(incObj)}</TableCell>
                  <TableCell className={classes.colStatus} align="left" onClick={() => requestUpdateStatus(incObj)} >
                    {getStatuses(incObj, page * rowsPerPage + index)}
                  </TableCell>
                  <TableCell className={classes.colTicket} align="left" style={{ color: PopaConfig.getPopaStatus(incObj.statusId).color }} >
                    {incObj.assignee.toUpperCase()}<br />
                    {PopaConfig.getPopaStatus(incObj.statusId).label}<br />
                    <Link href={"https://jira.chuyenkhoan.vn/browse/" + incObj.ticket} target="_blank">{incObj.ticket}</Link>
                  </TableCell>
                  <TableCell className={classes.colTime} align="left">
                    {getTxTimeShort(incObj.updated)}
                    {getActions(incObj, page * rowsPerPage + index)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {
          showUpdateStatus &&
          <UpdatePopaStatusDialog open={showUpdateStatus} closeCallback={closeUpdateStatus}
            popaObj={selectedPopaObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }

        {
          showBankMarketing &&
          <BankMarketingDialog open={showBankMarketing} closeCallback={closeBankMarketing}
            selectedBankAccount={selectedBankAccount}
            popaObj={selectedPopaObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
      </ThemeProvider>
    </StyledEngineProvider >
  );
}



const theme = createTheme({
  // spacing: 3,
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation.
        padding: '2px 6px',
      },
      sizeSmall: {
        padding: '2px 3px 2px 3px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
    MuiIconButton: {
      root: {
        width: 18,
        height: 18,
      }
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    padding: 8,
  },

  alert: {
    marginBottom: 12,
  },

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
  },

  transHead: {
    minHeight: 22,
    padding: '3px 3px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
    width: 200,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

  tableContainer: {
    marginTop: 8,
  },

  table: {
    marginTop: 16,
    marginBottom: 16,
  },

  row: {
    overflow: 'hidden',
    height: 40,
    maxHeight: 48,
    minHeight: 40,
    fontSize: 11,
  },


  colId: {
    paddingLeft: 12,
    minWidth: 120,
    maxWidth: 160,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
    cursor: 'pointer'
  },

  colDomain: {
    minWidth: 120,
    maxWidth: 160,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },

  colName: {
    minWidth: 120,
    maxWidth: 120,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },

  colContact: {
    minWidth: 120,
    maxWidth: 260,
    textAlign: 'left',
    padding: '2px 3px',
    fontSize: 12,
    overflow: 'hidden',
  },

  colAccount: {
    minWidth: 120,
    maxWidth: 160,
    textAlign: 'left',
    padding: '2px 6px',
    fontSize: 12,
    overflow: 'hidden',
    cursor: 'pointer'
  },

  colStatus: {
    fontSize: 12,
    maxWidth: 220,
    overflow: 'hidden',
    cursor: 'pointer'
  },

  colTicket: {
    fontSize: 12,
    minWidth: 111,
    noWrap: true
  },

  colTime: {
    minWidth: 80,
    maxWidth: 120,
    padding: '2px 3px',
    fontSize: 12,
  },

  actionIcon: {
    fontSize: 12,
    paddingLeft: 6
  },
  svgIcon: {
    fontSize: 16,
    padding: 0
  }
}));

