import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import QRCode from 'qrcode.react';

import { createCookie, } from '../core/CkUtils';
import { isValidPhone, isValidAuthcode } from '../core/Validator';
import Colors from '../core/Colors';
import Config from '../core/Config';
import TxStatus from '../core/TxStatuses';
import TxTypes from '../core/TxTypes';
import User from '../core/User';
import Actions from './Actions'
import { useInterval } from '../../ckcore/core/CkUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';

import ck_logo_round from '../images/ck_lgrb_256.png'

export default function Authorization(props) {

  const COUNT_DOWN_TIME = 30*1000;

  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const phone = User.getDisplayPhone();
  const [token, setToken] = useState((props.token) ? props.token : '');
  const [authcode, setAuthcode] = useState('');
  const [pushcode, setPushcode] = useState({code: '', qrcode: '', smsc: ''});
  const [authorizeTime, setAuthorizeTime] = useState(new Date().getTime());
  const [refreshTime, setRefreshTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [authorizedSuccess, setAuthorizedSuccess] = useState(false);

  const inputAuthcode = (number) => {
    setAuthcode(number);
    setErrorMsg('');
  }

  // check using pushcode
  const isUsingPushcode = () => {
    return (pushcode.code.length > 0);
  }
  const resetPushcode = () => {
    setPushcode({code: '', qrcode: '', smsc: ''});
  }

  /**
   * Get remain time
   * @returns Remain time for waiting order
   */
   const getRemainTime = () => {
    return (authorizeTime - refreshTime + COUNT_DOWN_TIME);
  }

  /**
   * Get countdown time
   * @returns Countdown time in format HH:mm
   */ 
   const getCountDown = () => {

    var remainTime = getRemainTime();
    if(remainTime > COUNT_DOWN_TIME) {
      remainTime = COUNT_DOWN_TIME;
    }


    if(remainTime > 0) {
      let minute = Math.floor(remainTime/60000);
      let second = Math.floor((remainTime%60000)/1000);
      return (minute < 10 ? "0" + minute : minute) + ':' + (second < 10 ? "0" + second : second);
    } else {
      return "00:00";
    }
  }


  /**
   * User request authorize
   */
  const doAuthorize = async () => {

    setErrorMsg('');

    // validate phone and authcode
    if (!isValidPhone(phone) 
        || (!isValidAuthcode(authcode) && !(token))) {

          setErrorMsg('Thông tin xác minh không đúng');

      return;
    }

    // need verify recaptcha first
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setErrorMsg("Chưa kiểm tra được mã bảo vệ. Bạn vui lòng thử lại.");
      return;
    }

    // check loading
    if (loading) {
      return;
    }
    setLoading(true);

    // get captcha
    const captcha = await executeRecaptcha('doAuthorize');
    // console.log("Got recaptcha token: " + captcha);

    // authorise message
    const authoriseMsg = {
      txTypeId: TxTypes.TX_USER_AUTHORIZE,
      timestamp: (new Date()).getTime(),
      authCode: (authcode) ? authcode : "",
      token: token,
      captcha: captcha,
      password: (props.isLogin) ? "" : props.password,
      identity: User.getIdentity()
    };

    try {

      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(authoriseMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.AUTHORISE_SUCCESS) {

        // console.log("Request authorise success, start go to main page userId: " + response.userId + ' status: ' + response.status);

        // Get user ID
        User.setUserId(response.userId);
        User.setSessionId(response.sessionId);
        User.setStatus(response.status);

        const email = (response.email) ? response.email : "";
        User.setEmail(email);

        // create cookie to store userId, sessionId,.. for three months
        const userSession = {
          userId: User.getUserId(),
          sessionId: User.getSessionId(),
          phone: User.getPhone(),
          email: User.getEmail(),
          status: User.getStatus()
        }
        createCookie('user_session', JSON.stringify(userSession), 90);

        // update service config
        Config.setAvailableBanks(response.availableBanks);
        createCookie('banks', JSON.stringify(response.availableBanks), 90);

        // update response token
        if ((response.token)) {
          createCookie(phone + '_token', response.token, 90);
        }

        // store the logged ip address
        if ((response.loggedIp)) {
          createCookie(phone + '_loggedIp', response.loggedIp, 90);
        }

        // show how page
        setAuthorizedSuccess(true);

      } else if (response.statusId === TxStatus.AUTHORISE_ERROR_TOKEN_TIMEOUT) {
        // if invalid token, then set for authCode
        // props.actionCallback(Actions.AUTHORIZE);
        console.log("Authorize error: token timeout");
        setToken('');

      } else if (response.statusId === TxStatus.AUTHORISE_ERROR_CAPTCHA_INVALID) {
        console.log("Authorise error, invalid captcha, response status " + response.statusId);
        setErrorMsg('Mã bảo về (captcha) không đúng. Mời bạn thử lại sau.');
      } else if (response.statusId === TxStatus.AUTHORISE_ERROR_AUTHCODE_INVALID) {
        console.log("Authorise error, invalid authcode, response status " + response.statusId);
        setErrorMsg('Mã OTP không đúng.');
      } else {
        console.log("Request authorise error with statuses: " + response.statusId);
        setErrorMsg('Xác minh bảo mật không đúng.');
      }
    } catch (error) {
      console.log("Authorize connection error: " + error);
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
    }

    setLoading(false);

  }

  /**
   * User get no authcode, then need a pushcode 
   * to verify phone
   */
  const requestPushcode = async () => {
    setLoading(true);

    // get pushcode message
    const getPushcodeMsg = {
      txTypeId: TxTypes.TX_USER_RESEND_PUSHCODE,
      timestamp: (new Date()).getTime(),
      identity: User.getIdentity()
    };

    try {

      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(getPushcodeMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        let smsc = response.smsc;
        let code = response.pushCode;

        let pushcodeSms = "SMSTO:" + smsc + ":CK OTP " + code;
        setLoading(false);
        setPushcode({code: code, qrcode: pushcodeSms, smsc: smsc});
      } else {
        resetPushcode();
        setErrorMsg('Lấy mã xác minh bị lỗi, mong bạn vui lòng thử lại sau.');
        console.log("Get pushcode error: " + response.msg);
      }
    } catch (error) {
      resetPushcode();
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
      console.log("Request send pushcode error: " + error);
    }

    setLoading(false);
  }

  /**
   * Check user pushcode
   */
  const checkPushcode = async (showMessage) => {
    setLoading(true);

    // get pushcode message
    const getPushcodeMsg = {
      txTypeId: TxTypes.TX_USER_CHECK_PUSHCODE,
      pushCode: pushcode.code,
      timestamp: (new Date()).getTime(),
      identity: User.getIdentity()
    };

    try {

      // Call server
      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(getPushcodeMsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {
        
        let newToken = response.token;
        createCookie(phone + '_token', newToken, 7);
        // console.log("Saved user token after check pushcode: " + newToken);

        // authorize by chaging token
        setLoading(false);
        setToken(newToken);

      } else if (response.statusId === TxStatus.WAITING) {
        if(showMessage) {
          setErrorMsg('Hệ thống chưa nhận được SMS của bạn, vui lòng chờ trong giây lát..');
        } else {
          setErrorMsg('Đang chờ mã xác minh..');
        }

      } else {
        resetPushcode();
        setErrorMsg('Kiểm tra SMS bị lỗi, mong bạn vui lòng thử lại sau.');
        console.log("Get pushcode error: " + response.msg);
      }
    } catch (error) {
      resetPushcode();
      setErrorMsg('Kết nối bị lỗi, vui lòng kiểm tra lại.');
      console.log("Request send pushcode error: " + error);
    }

    setLoading(false);
  }


  /**
   * Handle submit on enter
   */
   const handleSubmit = (e) => {
    e.preventDefault();
    if(isUsingPushcode()) {
      checkPushcode(true);
    } else {
      doAuthorize();
    }
  }

  // Use interval to check pushcode
  useInterval(() => {

    if(isUsingPushcode() && !(token)) {
      // console.log("Checking pushcode every 5 seconds..");
      checkPushcode(false);
    }

  }, 5000);

  // Update count down
  useInterval(() => {

    let currentTime = (new Date()).getTime();

    if(authorizeTime > 0 && currentTime - authorizeTime < COUNT_DOWN_TIME + 3000) {
      setRefreshTime(currentTime);
      // console.log("Refresh time: " + getCountDown());
    } 


  }, 1000);

  // Request checkout on load
  useEffect(() => {

    if(authorizedSuccess) {
      // authorize success
      props.actionCallback(Actions.LOGGED_IN, {isLogin: true, token: ''});
      return;
    }

    if ((token)) {
      // do authorize with token
      doAuthorize();
    } else if (!(props.smsc) && !(props.isLogin)) {
      // automatic using push code for register phone
      console.log("Auto using pushcode because no SMSC for send SMS.");
      requestPushcode();
    } 
    
    
  }, [token, authorizedSuccess]);

  // RETURN AUTHORIZE
  return (
    <Container component="main" maxWidth="xs">

      <Paper className={classes.paper}>
      <form style={{width: "100%"}} onSubmit={(e) => handleSubmit(e)}>

        <Grid container spacing={3} direction="column" justifyContent="space-around" alignItems="stretch">
          <Grid item xs={12}>
            <div className={classes.cklogo}>
              <img src={ck_logo_round} alt="Chuyển Khoản" width="72" height="72"/>
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" component="h1" variant="h5" >
              XÁC MINH BẢO MẬT
            </Typography>
          </Grid>

          <Grid item xs={12}>

            <TextField id="input-phone" size="small" variant="outlined" fullWidth
              label="Số điện thoại"
              type="tel"
              value={phone}
              disabled={true}
              className={classes.textFieldValue}
            />
          </Grid>

          {
            isUsingPushcode() ? (
              <Grid item xs={12}>
                <Typography variant="subtitle2" style={{paddingBottom: 8, color: Colors.DARK_BLUE}}>
                  Mời bạn quét QR bằng Camera của điện thoại để gửi SMS đăng nhập / đăng ký
                </Typography>
                
                <QRCode size={128} value={pushcode.qrcode} />

                <Typography variant="subtitle1" style={{ paddingTop: 8, color: Colors.DARK_BLUE }}>
                  Hoặc soạn <strong style={{color: Colors.DARK_GREEN}}>CK OTP {pushcode.code}</strong> gửi tới SĐT <strong style={{color: Colors.DARK_GREEN}}>{getDisplayPhone(pushcode.smsc)}</strong>
                </Typography>
              </Grid>
            ):
            (
              <Grid item xs={12}>
               <TextField id="input-authcode" size="small" variant="outlined" fullWidth
                 label="Mã xác minh"
                 type="number"
                 inputProps={{ autoComplete: 'new-password' }}
                 value={authcode}
                 autoFocus
                 disabled={loading}
                 onInput={e => inputAuthcode(e.target.value)}
                 helperText={!isUsingPushcode() && !isValidAuthcode(authcode) && (errorMsg.length > 0) ? "Mã xác minh không hợp lệ" : ""}
                 error={(errorMsg.length > 0) && !isValidAuthcode(authcode)}
                 className={classes.textFieldValue}
                 />
             </Grid>
            )
            
          }
          {
            isUsingPushcode() ? (
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" fullWidth
                  disabled={loading}
                  style={{fontWeight: 'bold'}}
                  onClick={() => checkPushcode(true)}>KIỂM TRA</Button>
              </Grid>
            ):
            (
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" fullWidth
                  disabled={loading}
                  style={{fontWeight: 'bold'}}
                  onClick={() => doAuthorize()}>XÁC MINH</Button>
              </Grid>
            )
            
          }
          

          {
            (errorMsg.length > 0) && isValidPhone(phone) && (isValidAuthcode(authcode) || isUsingPushcode()) &&
            <Grid item xs={12}>
              <Typography color="error" variant="subtitle2" >
                {errorMsg}
              </Typography>
            </Grid>
          }

          <Grid container item xs={12} direction="row" justifyContent="space-around" alignItems="center">
            <Grid item >
              <Link component="button" variant="body2" className={classes.textButton} onClick={() => props.actionCallback((props.isLogin) ? Actions.SIGN_IN : Actions.SIGN_UP)}>
                Trở lại
              </Link>
            </Grid>
            <Grid item >
              {

                isUsingPushcode() &&
                <Link component="button" variant="body2" className={classes.textButton} onClick={() => {
                    resetPushcode();
                    setErrorMsg("");
                  }}>
                    Đã nhận được SMS/Email OTP
                </Link>
              }
              {
                !isUsingPushcode() && (getRemainTime() > 0) &&
                <Link component="button" variant="body2" refresh={refreshTime} style={{color: Colors.ORANGE}}>
                  Thời gian chờ SMS/Email: {getCountDown()}
                </Link>
              }
              {
                !isUsingPushcode() && (getRemainTime() <= 0) &&
                <Link component="button" variant="body2" refresh={refreshTime} className={classes.textButton} onClick={() => requestPushcode()}>
                  Không nhận được SMS/Email OTP?
                </Link> 
              }
            </Grid>
          </Grid>
        </Grid>
        </form>
      </Paper>
    </Container>
  );

}

const useStyles = makeStyles(theme => ({

  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 389,
    minWidth: 389,
    padding: 20,
    textAlign: 'center',
    margin: 'auto',
  },

  cklogo: {
    textAlign: 'center',
    display: 'block',
    margin: "auto",
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },
}));
