import { adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';
import Box from "@mui/material/Box";

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import ValUtils from '../../ckcore/core/ValUtils';
import { getMessageBox } from '../../ckcore/core/TxUtils';

export default function SyncSmsToBankTxDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const txObj = props.txObj;
  const destPartnerId = (props.destPartnerId) ? props.destPartnerId : 0;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [destId, setDestId] = useState(destPartnerId);
  const handleChange = name => event => {
    setDestId(parseInt(event.target.value));
  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // process matching order
  const doMoveBankTx = async () => {

    try {
  
      if(loading) {
        return;
      }
  
      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.ORIGIN_ADMIN_SUPPORT_SYNC_BANK_TX,
        partnerId: partnerObj.partnerId,
        txId: txObj.txId,
        destId: destId,
        accountNumber: txObj.ben,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };
  
      setLoading(true);
  
      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "admin=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();
  
      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        setSuccessMessage("Đồng bộ giao dịch thành công!");       
      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load order data, msg: " + response.msg);
        setErrorMessage("Đồng bộ giao dịch bị lỗi!!!");
      }
    } catch (error) {
      setErrorMessage("Đồng bộ giao dịch bị lỗi!!!");
      console.log("Error get order data.. " + error);
    }
  
    setLoading(false);
  
  };


  useEffect(() => {
    // reload on refresh
  }, []);


  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Đồng bộ SMS về tài khoản ngân hàng</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>
             { getMessageBox(errorMessage, successMessage, "Mời bạn nhập ID đối tác cần đồng bộ giao dịch")}
            </Box>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Typography component="div" variant="body1">Giao dịch CK: <strong>{txObj.txId}</strong>, số tiền: <strong>{ValUtils.formatMoney(txObj.amount)}</strong>đ</Typography>
                <Typography component="div" variant="body1">Ngân hàng: {txObj.src}, STK: {txObj.ben}, nội dung CK: {txObj.content}</Typography>
              </Grid>

              <Grid item xs={12}>
                  <TextField autoFocus size="small" margin="dense" id="destId" label="ID đối tác"
                    style = {{width: 222}}
                    type="number"
                    value={destId===0 ? "" : destId}
                    onChange={handleChange('destId')}
                  />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button disabled={loading || (destId === 0) || (successMessage)} color="primary" onClick={() => doMoveBankTx()}>
              Đồng bộ
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
}));

const useStyles = makeStyles(theme => ({
  root: {

  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

}));
