
import React, { useState, useEffect } from 'react';
import {
  // Hidden, 
  Hidden, ThemeProvider, StyledEngineProvider, SnackbarContent, Snackbar
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';


import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import NumberFormat from 'react-number-format';
import SvgIcon from '@mui/material/SvgIcon';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';

import {
  getPaymentStatusColor,
  getStatusColor,
  getPaymentStatusText,
  getConfirmStatusText,
  getTxTimeText,
  getTxName,
  parseTxBean,
  getTxStatusText
} from '../../ckcore/core/TxUtils';

import { ckTheme, ckTableStyles } from '../../ckcore/ui/CkStyles';

import { getBankInfo, getBankAccount } from '../../ckcore/core/CkBank';
import Colors from '../../ckcore/core/Colors';
import { timeOffsetString } from '../../ckcore/core/CkUtils';
import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import Config from '../../ckcore/core/Config';

/**
 * Product list
 */
export default function ProductList(props) {

  const tableStyles = ckTableStyles();


  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState({ error: '', success: '', info: '', showStatus: false });
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});

  /**
   * Load all products of partner
   */
  const loadProducts = async () => {
    try {

      if (loading) { return; }

      // build request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_LOAD_PRODUCTS,
        partnerId: userObj.partnerId,
        index: 0,
        count: 0,
        dateStart: 0,
        dateEnd: 0,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "product=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setProducts(response.products);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load products, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get products.. " + error);
    }

    setLoading(false);

  };

  /**
   * Sync products from orders
   */
  const syncProducts = async () => {
    try {

      if (loading) { return; }

      // build request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_SYNC_PRODUCTS,
        partnerId: userObj.partnerId,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "product=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        // setProducts(response.products);
        setMessages({ ...messages, success: 'Đồng bộ sản phẩm thành công', showStatus: true });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load products, msg: " + response.msg);
        setMessages({ ...messages, error: 'Đồng bộ sản phẩm bị lỗi', showStatus: true });
      }
    } catch (error) {
      console.log("Error get products.. " + error);
    }

    setLoading(false);

  };

  useEffect(() => {
    // reload on refresh
    loadProducts();
  }, []);


  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>

        <Grid justifyContent="space-between" alignItems="flex-end" container>
          <Grid item className={tableStyles.transHead}>
            <Box display="inline" alignItems="flex-end">
              <span className={tableStyles.title}>SẢN PHẨM &amp; DỊCH VỤ</span>
              <Tooltip title="Đồng bộ sản phẩm từ đơn hàng" aria-label="sync-contact-from-orders">
                <IconButton aria-label="sync-contact" size="large"
                  style={{ marginRight: 12, color: Colors.DARK_BLUE }}
                  onClick={() => { syncProducts() }}>
                  <SyncOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>            
          </Grid>

          <Grid item className={tableStyles.transHead}>
            {
              <Paper className={tableStyles.searchBox}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Tìm sản phẩm"
                  inputProps={{ 'aria-label': 'tim tai khoan' }}
                  onChange={(e) => { }}
                />
                <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            }
          </Grid>
        </Grid>

        <Snackbar key="customer-status" open={messages.showStatus} autoHideDuration={2200}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}          
          onClose={() => setMessages({ ...messages, showStatus: false })}>
          <SnackbarContent style={{ backgroundColor: Colors.CK_BG_WARNING, color: (messages.error) ? Colors.RED : Colors.DARK_GREEN }}
            message={(messages.error) ? messages.error : messages.success}/>
        </Snackbar>

        <TableContainer className={tableStyles.tableContainer} component={Paper} >

          <Table className={tableStyles.table} size="small" aria-label="trans list table">

            <TableHead>
              <TableRow className={tableStyles.row} style={{ height: 24 }}>
                <TableCell align="center" className={tableStyles.colHeader}>STT</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Mã sản phẩm</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Tên sản phẩm</TableCell>
                <TableCell className={tableStyles.colHeader}>Đơn vị tính</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Giá bán (VNĐ)</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Chiết khấu</TableCell>
                <TableCell className={tableStyles.colHeader}>Chiết khấu (%)</TableCell>
                <TableCell className={tableStyles.colContent}>Thuế VAT (%)</TableCell>
                <TableCell align="center" className={tableStyles.colHeader}>Thao tác</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
            {
                products.map((product, index) => (
                  <TableRow hover key={index} className={tableStyles.row} style={{ backgroundColor: (product.code === selectedProduct.code) ? Colors.CK_BG_WARNING : '' }}>

                    <TableCell align="center" className={tableStyles.colTxId} style={{ color: Colors.DARK_BLUE }} onClick={() => { }}>{(index + 1)}</TableCell>
                    <TableCell align="left" className={tableStyles.colTxId} style={{ color: Colors.DARK_BLUE, textAlign: 'left' }} onClick={() => { }}>{product.code}</TableCell>
                    <TableCell align="left" className={tableStyles.colName} style={{  }} >{product.name}</TableCell>
                    <TableCell align="center" className={tableStyles.colHeader} style={{  }} >{""}</TableCell>
                    <TableCell align="right" className={tableStyles.colValue} style={{  }} >
                      <NumberFormat value={product.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={''} />
                    </TableCell>
                    <TableCell align="center" className={tableStyles.colHeader} style={{  }} >{""}</TableCell>
                    <TableCell align="center" className={tableStyles.colHeader} style={{  }} >{""}</TableCell>
                    <TableCell align="center" className={tableStyles.colHeader} style={{  }} >{""}</TableCell>
                    <TableCell align="center" className={tableStyles.colHeader} style={{  }} >{""}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>

          </Table>
        </TableContainer>

      </ThemeProvider>
    </StyledEngineProvider>
  );
}
