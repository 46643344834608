
import { ckTableStyles } from '../../../ckcore/ui/CkStyles';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { getPaymentStatusColor, getPaymentStatusText, getStatusColor, getTxStatusText, getTxTimeVN } from '../../../ckcore/core/TxUtils';
import NumberFormat from 'react-number-format';

export default function BankTxDetailsTable(props) {
  const tableStyles = ckTableStyles();
  const bankTxObj = props.bankTxObj;
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  // Get bank tx content with manual description
  const getBankTxContent = () => {
    
    if(!(bankTxObj.description)) {
      return bankTxObj.content;
    }
    
    let descStartIndex = bankTxObj.content.indexOf('[');

    if(descStartIndex > 0) {
      // replace desc
      return bankTxObj.content.substring(0, descStartIndex) + '[' + bankTxObj.description + ']';
    } else {
      // append desc
      return bankTxObj.content + ' [' + bankTxObj.description + ']';
    }
  }

  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>
        <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" width={'25%'} className={tableStyles.colHeader} sx={cellBorder}>
              Ngân hàng:
            </TableCell>
            <TableCell align="left" width={'25%'} className={tableStyles.colHeaderStrong} sx={cellBorder}> 
              {bankTxObj.src}</TableCell>
            <TableCell align="left" width={'25%'} className={tableStyles.colHeader} sx={cellBorder}>
              Trạng thái thanh toán: </TableCell>
            <TableCell align="left" width={'25%'} className={tableStyles.colHeaderStrong} sx={cellBorder}>
              <span style={{ color: getPaymentStatusColor(bankTxObj.paymentStatus) }}> {getPaymentStatusText(bankTxObj.paymentStatus)}</span></TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>Số tài khoản nhận:</TableCell>
            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>{bankTxObj.ben}</TableCell>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>Số tiền: 
            </TableCell>
            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>
              {bankTxObj.amount >= 0 ? '+' : ''}<NumberFormat value={bankTxObj.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={' VNĐ'} />
            </TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>SĐT khách hàng:</TableCell>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}></TableCell>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>Mã đơn hàng: </TableCell>
            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>{bankTxObj.txnRef}</TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>SĐT nhận BĐSD:  </TableCell>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>{bankTxObj.destPhone}</TableCell>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>Thời gian: </TableCell>
            <TableCell align="left" className={tableStyles.colHeader} sx={cellBorder}>{getTxTimeVN(bankTxObj.created)}</TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 40 }}>
            <TableCell colSpan={4} align="left" className={tableStyles.colContent} sx={cellBorder}>Nội dung chuyển khoản: <strong><i>{getBankTxContent()}</i></strong></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  );
}