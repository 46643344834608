import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import moment from 'moment';
import { ckTheme } from '../../ckcore/ui/CkStyles';
import User from '../../ckcore/core/User';
import Config from '../../ckcore/core/Config';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import TxStatus from '../../ckcore/core/TxStatuses';

export default function ExportGoogleSheets(props) {

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const filterObj = props.filterObj;
  const exportTxTypeId = props.txTypeId;

  let isOrder = (exportTxTypeId === TxTypes.PARTNER_ADMIN_ORDER_LIST_EXPORT) ? true : false;

  // reuse previous spreadsheet ID
  let orderSpreadsheetId = (partnerObj.exportConfig.orderSpreadsheetId) ? partnerObj.exportConfig.orderSpreadsheetId : "";

  const [sheetObj, setSheetObj] = useState({ spreadsheetId: orderSpreadsheetId });
  const [messages, setMessages] = useState({ success: '', error: '', info: '' });
  const [loading, setLoading] = useState(false);

  /**
   * Set spreadsheet ID
   */
  const handleChange = (name, value) => {

    // escapse #
    if (value.indexOf('#') > 11) {
      value = value.substring(0, value.indexOf('#'));
    }

    var spreadsheetId = "";
    // get google sheets ID as client_key
    if (name === 'spreadsheetId') {
      let sheetIdStartIndex = value.indexOf('d/') + 2;
      var sheetIdEndIndex = value.indexOf('/', sheetIdStartIndex + 1);
      if (sheetIdEndIndex < 0) {
        sheetIdEndIndex = value.length;
      }

      if (sheetIdStartIndex > 2 && sheetIdEndIndex > sheetIdStartIndex) {
        spreadsheetId = value.substring(sheetIdStartIndex, sheetIdEndIndex);
      }
    }

    setSheetObj({ ...sheetObj, spreadsheetId: spreadsheetId, checked: false});
  };

  const handleClose = () => {
    props.closeCallback();
  }


  /**
   * Check spreadsheet ID
   */
  const checkSpreadSheetId = async () => {
    try {

      if (loading) { return; }
      setLoading(true);

      setMessages({ ...messages, error: '', success: '' });

      // load request
      var ckmsg = {
        txType: "googlesheets",
        spreadsheetId: sheetObj.spreadsheetId,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ckmsg)
      };


      // fetch response
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/export/check?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        let spreadsheetTitle = response.spreadsheetTitle;
        console.log("Check spreadsheet success. Got spreadsheet title: " + spreadsheetTitle);
        setSheetObj({...sheetObj, checked: true});
        setMessages({...messages, error: '', success: 'Đã kiểm tra trang tính: ' + spreadsheetTitle});

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR check spreadsheet, msg: " + response.msg);
        setMessages({ ...messages, error: "Không lấy được thông tin trang tính. Mong bạn vui lòng kiểm tra lại.", success: '' });
      }
    } catch (error) {
      console.log("Error check spreadsheet.. " + error);
    }

    setLoading(false);
  }

  /**
   * Request export data list
   */
  const exportData = async () => {
    try {

      if (loading) { return; }
      setLoading(true);

      // clear messages
      setMessages({ ...messages, error: '', success: '' });

      // load data
      var fromTime = filterObj.status === 'latest' ? 0 : moment(filterObj.fromDate, 'YYYY-MM-DD').toDate().getTime();
      var toTime = filterObj.status === 'latest' ? 0 : moment(filterObj.toDate, 'YYYY-MM-DD').add(24, 'hours').toDate().getTime();
      let timeName = filterObj.status === 'latest' ? 'mới nhất' : moment(filterObj.fromDate, 'YYYY-MM-DD').format('DD.MM') + '_' + moment(filterObj.toDate, 'YYYY-MM-DD').format('DD.MM');
      var sheetTitle = (isOrder ? 'DH ' : 'CK ') + timeName;

      // request sync order status
      var ckmsg = {
        txTypeId: exportTxTypeId,
        partnerId: userObj.partnerId,
        serviceId: filterObj.serviceId,
        index: filterObj.index,
        count: filterObj.count,
        dateStart: fromTime,
        dateEnd: toTime,
        filterObj: filterObj,
        spreadsheetId: sheetObj.spreadsheetId,
        sheetTitle: sheetTitle,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };


      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + (isOrder ? "order=" : "bank=") + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setSheetObj({...sheetObj, exported: true});
        setMessages({ ...messages, error: '', success: "Xuất báo cáo thành công, mời bạn vào trang tính kiểm tra dữ liệu." });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        setMessages({ ...messages, error: "Yêu cầu xuất báo cáo bị lỗi. Mong bạn vui lòng thử lại sau." });
        console.log("ERROR export data, msg: " + response.msg);
      }
    } catch (error) {
      setMessages({ ...messages, error: "Yêu cầu xuất báo cáo bị lỗi kết nối mạng. Mong bạn vui lòng thử lại sau." });
      console.log("Error export data.. " + error);
    }

    setLoading(false);

  };

  useEffect(() => {

    // auto matic check
    if(orderSpreadsheetId) {
      checkSpreadSheetId();
    }

  }, []);

  return (
    <ThemeProvider theme={ckTheme}>
      <Dialog open={props.open} fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Xuất báo cáo lên GoogleSheets
        </DialogTitle>
        <DialogContent dividers>

          <Box p={1}>
            {getMessageBox(messages.error, messages.success, messages.info)}
          </Box>

          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Box>
                <strong>Bước 1:</strong> Vào trang tính cần đưa dữ liệu lên --&gt; Chọn Chia sẻ/Share --&gt; Thêm quyền Editor/Ghi cho email: <strong style={{ color: Colors.DARK_BLUE }}>chuyenkhoan@chuyenkhoan.iam.gserviceaccount.com</strong>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <strong>Bước 2:</strong> Copy đường link trang tính và dán vào form bên dưới để kiểm tra
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField type="text" variant="outlined" margin="dense" fullWidth
                label="ID trang tính GoogleSheets (Lấy từ URL)"
                placeholder='URL có dạng: https://docs.google.com/spreadsheets/d/1_LJru5YJY4y7LS0cj-lHxUd2opjGdOXBrGxTl1ulTNE/edit'
                value={(sheetObj.spreadsheetId) ? sheetObj.spreadsheetId : ""}
                onChange={e => handleChange('spreadsheetId', e.target.value)}
                disabled={(sheetObj.exported)}
              />
            </Grid>

            <Grid item xs={12}>
              <Box>
                <span><strong>Bước 3:</strong> Bấm xuất báo cáo và chuyển sang trang tính để xem dữ liệu được cập nhật</span>
                {
                  (messages.success !== '') &&
                  <span> tại <a target='_blank' rel="noreferrer" href={'https://docs.google.com/spreadsheets/d/' + sheetObj.spreadsheetId}>Gooogle Sheets</a></span>
                }
              </Box>
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">Trở lại</Button>

          {
            (sheetObj.checked) ? (
              <Button disabled={loading || (sheetObj.exported)} variant="outlined" color="warning" onClick={() => exportData()}>XUẤT BÁO CÁO</Button>
            ) : (
              <Button disabled={loading || (sheetObj.spreadsheetId === '')} variant="outlined" color="primary" onClick={() => checkSpreadSheetId()}>KIỂM TRA</Button>
            )
          }

        </DialogActions>

      </Dialog>
    </ThemeProvider>
  );
}
