import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { getMessageBox } from '../../ckcore/core/TxUtils';
import { isValidNameUTF8, isValidAddress, isValidDomain, isValidUrl } from '../../ckcore/core/Validator';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';


export default function AddPartnerDialog(props) {

  const classes = useStyles();

  const [messages, setMessages] = useState({success: '', error: '', info: ''});
  const [loading, setLoading] = useState(false);

  const [partnerObj, setPartnerObj] = useState({
      partnerId: 0,
      groupId: 0,
      phone: User.getPhone(),
      name: '',
      website: '',
      email: '',
      tin: '',
      province: '',
      address: '',
      updated: new Date().getTime(),
  });

  const handleChange = name => event => {
    setPartnerObj({ ...partnerObj, [name]: event.target.value });
    // clear error
    setMessages({...messages, error: ''});
  };


  // Add partner
  const processAddPartner = async () => {



    // validate name, website and address
    if (!isValidNameUTF8(partnerObj.name) || (!isValidUrl(partnerObj.website) && !isValidDomain(partnerObj.website)) || !isValidAddress(partnerObj.address)) {
      setMessages({...messages, error: 'Thông tin không hợp lệ'});
      return;
    }

    if(loading) {
      return;
    }
    setLoading(true);

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_CREATE,
      partner: partnerObj,
      partnerId: 0,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setMessages({...messages, error: ''});

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setMessages({...messages, error: '', success: 'Tạo tài khoản tổ chức thành công'});

         // handle close
         setTimeout(() => {
          handleClose();
        }, 1800);

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({...messages, error: 'Tạo tài khoản bị lỗi, vui lòng thử lại sau'});
      }
      
    } catch (error) {
      console.log("Error... " + error);
      setMessages({...messages, error: 'Tạo tài khoản bị lỗi, vui lòng thử lại sau'});
    }

    setLoading(false);

  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Add bank dialog
  return (
    <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Tạo tài khoản tổ chức</DialogTitle>
      <DialogContent dividers>

        <Box p={1}>
          {getMessageBox(messages.error, messages.success, "Mời bạn tạo tài khoản tổ chức, doanh nghiệp, cửa hàng, hoặc wesbite")}
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
                className={classes.accountName}
                fullWidth
                margin="dense"
                label="Tên tổ chức"
                type="text"
                variant="outlined"
                value={partnerObj.name}
                onChange={handleChange('name')}
                helperText={!isValidNameUTF8(partnerObj.name) && (messages.error.length > 0) ? "Tên tổ chức từ 3 đến 45 ký tự, chỉ chấp nhận chữ cái tiếng Việt, số, dấu chấm, dấu gạch ngang, và dấu gạch chân" : ""}
                error={(messages.error.length > 0) && !isValidNameUTF8(partnerObj.name)}
              />              
          </Grid>
          <Grid item xs={12}>
            <TextField
                className={classes.website}
                fullWidth
                margin="dense"
                label="Website"
                type="text"
                variant="outlined"
                value={partnerObj.website}
                onChange={handleChange('website')}
                helperText={!isValidUrl(partnerObj.website) && (messages.error.length > 0) ? "Website không hợp lệ" : ""}
                error={(messages.error.length > 0) && !isValidUrl(partnerObj.website)}
              />     
          </Grid>
          <Grid item xs={12}>
            <TextField
                className={classes.address}
                fullWidth
                margin="dense"
                label="Địa chỉ"
                type="text"
                variant="outlined"
                value={partnerObj.address}
                onChange={handleChange('address')}
                helperText={!isValidAddress(partnerObj.address) && (messages.error.length > 0) ? "Địa chỉ không hợp lệ" : ""}
                error={(messages.error.length > 0) && !isValidAddress(partnerObj.address)}
              />                         
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Trở lại
        </Button>
        <Button disabled={loading || (messages.success.length > 0)} onClick={processAddPartner} color="primary">
          Tạo tài khoản
        </Button>
      </DialogActions>
    </Dialog>
  );
}


const useStyles = makeStyles(theme => ({
   root: {

   },
}));
