import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { ckApiStyles } from '../ApiStyles';

import withStyles from '@mui/styles/withStyles';
import { green } from '@mui/material/colors';
import TxStatus from '../../../ckcore/core/TxStatuses';
import User from '../../../ckcore/core/User';
import Config from '../../../ckcore/core/Config';
import Colors from '../../../ckcore/core/Colors';
const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


/**
 * Test function
 */
export const testConnectWebhook = async (partnerId, apiObj, callback) => {
  try {
    // load request
    var ckmsg = {
      txType: "webhook",
      partnerId: partnerId,
      api: apiObj,
      time: new Date().getTime(),
      identity: User.getIdentity(),
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ckmsg)
    };

    // fetch response
    const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/export/check?'), requestOptions);
    const response = await rawResponse.json();

    if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
      callback(response.statusId, 'Đã kiểm tra Webhook: ' + response.msg);
    } else {
      callback(response.statusId, response.msg);
    }

  } catch (error) {
    console.log("Error check webhook call " + error);
    callback(TxStatus.ERROR, '');
  }
}

export default function WebhookGeneric(props) {

  const classes = ckApiStyles();

  const [webhookObj, setWebhookObj] = useState(props.webhookObj);

  const [textObj, setTextObj] = useState({});

  // handle checkbox
  const handleCheckBox = (itemName, checked) => {

    var apiObj = { ...webhookObj.api };
    var apiBodyObj = apiObj.body;

    // do update
    apiBodyObj.map((item, index) => {
      if (item.name === itemName) {
        apiBodyObj[index].active = checked;
        console.log("Change checked item: " + itemName + ' to ' + checked);

        // set timestamp
        apiObj.timestamp = new Date().getTime();
      }
    });
    
    apiObj.body = apiBodyObj;

    setWebhookObj({ ...webhookObj, api: apiObj });
  }

  const getExampleData = () => {
    const valueArray = webhookObj.api.body;
    const exampleData = {};

    // do update
    valueArray.map((item, index) => {

      if (valueArray[index].active) {
        var fieldObj = valueArray[index];
        exampleData[fieldObj.name] = fieldObj.data;
      }
    });

    return exampleData;
  }


  /**
   * Set change, validate, and callback
   */
  const handleChange = (name, value) => {

    var apiObj = { ...webhookObj.api };

    if (name === "headers" || name === "body") {
      try {
        const valueObj = JSON.parse(value);
        apiObj[name] = valueObj;
        setTextObj({ ...textObj, [name + "_text"]: "" });
      } catch (e) {

        // hacking text to json
        setTextObj({ ...textObj, [name + "_text"]: value });
        console.log('invalid json of name: ' + name + ' value: ' + value);
        return;
      }
    } else {
      apiObj[name] = value;
    }


    setWebhookObj({ ...webhookObj, api: apiObj });
  };

  const handleCheck = (name, value) => {
    setWebhookObj({ ...webhookObj, [name]: value });
  };

  // Check valid endpoint
  const isValidEndpoint = () => {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?$/;
    return urlPattern.test(webhookObj.api.endpoint);
    // return webhookObj.api.endpoint && webhookObj.api.endpoint.length > 0;
  }

  // Check if valid headers
  const isValidHeaders = () => {

    // check headers is json object
    try {
      JSON.parse(JSON.stringify(webhookObj.api.headers));
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {

    // callback update change
    props.handleChange(webhookObj);

  }, [webhookObj]);


  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={webhookObj.enable}
              onChange={e => handleCheck("enable", e.target.checked)}
              name="enable"
              color={webhookObj.enable ? "primary" : "success"}
            />
          }
          label={<Typography variant="h6" style={{ color: webhookObj.enable ? Colors.DARK_GREEN : Colors.ORANGE }}>Đăng ký gửi thông tin chuyển khoản qua Webhook</Typography>}
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <strong>Bước 1:</strong> Nhập địa chỉ máy chủ nhận Webhook
        </Box>
      </Grid>
      <Grid item xs={8}>

        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Endpoint"
          placeholder=''
          disabled={!webhookObj.enable}
          value={webhookObj.api.endpoint}
          onChange={e => handleChange('endpoint', e.target.value)}
          error={!isValidEndpoint() && webhookObj.enable}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          select
          label="Method"
          disabled={!webhookObj.enable || true}
          onChange={e => handleChange('method', e.target.value)}
          defaultValue="POST">
          <MenuItem key="post" value="POST">POST</MenuItem>
          <MenuItem key="get" value="GET">GET</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <strong>Bước 2:</strong> Thêm header như API-KEY hoặc Authorization (dạng object JSON):
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
        </Box>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Headers"
          placeholder=''
          disabled={!webhookObj.enable}
          value={(textObj.headers_text) ? textObj.headers_text : JSON.stringify(webhookObj.api.headers, null, 2)}
          multiline
          rows={3}
          onChange={e => handleChange('headers', e.target.value)}
          error={!isValidHeaders() && webhookObj.enable}
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <strong>Bước 3:</strong> Chọn các trường thông tin cần gửi và bấm "Kiểm tra kết nối" trước khi Cập nhật:
        </Box>
      </Grid>
      {/** Body text area */}
      <Grid item xs={12}>
        <Box>
          {
            webhookObj.api.body.map((item, index) => (
              <FormControlLabel key={item.name} label={(item.label) ? item.label : item.name}
                disabled={!webhookObj.enable || (item.required)}
                control={<GreenCheckbox name={item.name} checked={item.active} onChange={() => handleCheckBox(item.name, !item.active)} />}
              />
            ))
          }
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Body Sample"
          placeholder=''
          disabled={true}
          value={JSON.stringify(getExampleData(), null, 2)}
          multiline
          rows={5}
        />
        </Box>
      </Grid>

    </Grid>
  );
}
