import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { adaptV4Theme, Checkbox, FormControlLabel, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { getBankInfo } from '../../ckcore/core/CkBank';

export default function RegisterCKSimDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const selectedSim = (props.simObj) ? props.simObj : { phone: '' };

  const [simObj, setSimObj] = useState(selectedSim);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [delegateSims, setDelegateSims] = useState([]);


  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [allowRegister, setAllowRegister] = useState(false);
  const [allowRemove, setAllowRemove] = useState(false);

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };


  // load delegate data
  const loadDelegateSims = async () => {
    try {

      if (loading) {
        return;
      }

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_LIST_DELEGATE_SIM,
        partnerId: userObj.partnerId,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "partner=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setBankAccounts(response.bankAccounts);
        setDelegateSims(response.delegateSims);
        if (response.delegateSims.length > 0 && (!(simObj) || !(simObj.phone))) {
          setSimObj(response.delegateSims[0]);
        }

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR load partner data, msg: " + response.msg);
      }
    } catch (error) {
      console.log("Error get partner data.. " + error);
    }

    setLoading(false);

  };


  /**
   * Bank account select callback
   */
  const onSelectBankAccount = (bankObj, index, checked) => {

    if (checked) {
      bankObj.notifier = simObj.phone;
    } else {
      bankObj.notifier = "";
    }

    // update bank obj
    setBankAccounts([
      ...bankAccounts.slice(0, index),
      bankObj,
      ...bankAccounts.slice(index + 1)
    ]);

    setAllowRegister(true);
    setAllowRemove(false);
  }


  /**
   * Select delegate sim callback
   * @param {*} delegateSim 
   * @param {*} index 
   */
  const onSelectDelegateSim = (delegateSim) => {
    setSimObj(delegateSim);
  }

  // set allow remove delegate sim
  const onAllowRemove = (checked) => {

    setAllowRemove(checked);
    if (checked) {
      const updatedAccounts = bankAccounts;
      updatedAccounts.map((bankObj) => {
        bankObj.notifier = "";
      });
      setBankAccounts([...updatedAccounts]);
    }
  }

  // process register delegate sim
  const registerDelegateSim = async () => {

    try {

      if (loading) {
        return;
      }

      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_REGISTER_DELEGATE_SIM,
        partnerId: userObj.partnerId,
        sim: simObj,
        bankAccounts: bankAccounts,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "partner=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setSuccessMessage("Đăng ký CKSIM " + getDisplayPhone(simObj.phone) + " thành công");

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR register CKSIM, msg: " + response.msg);
        setErrorMessage("Lỗi đăng ký CKSIM, vui lòng thử lại sau");
      }
    } catch (error) {
      console.log("Error register CKSIM. " + error);
      setErrorMessage("Lỗi đăng ký CKSIM, vui lòng thử lại sau");
    }

    setLoading(false);

  };


  // process deregister delegate sim
  const deregisterDelegateSim = async () => {

    try {

      if (loading) {
        return;
      }

      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_REMOVE_DELEGATE_SIM,
        partnerId: userObj.partnerId,
        sim: simObj,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "partner=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        setSuccessMessage("Huỷ đăng ký CKSIM " + getDisplayPhone(simObj.phone) + " thành công");

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR register CKSIM, msg: " + response.msg);
        setErrorMessage("Lỗi huỷ đăng ký CKSIM, vui lòng thử lại sau");
      }
    } catch (error) {
      console.log("Error register CKSIM. " + error);
      setErrorMessage("Lỗi huỷ đăng ký CKSIM, vui lòng thử lại sau");
    }

    setLoading(false);

  };

  /**
   * Get bank text
   */
  const getBankText = (bankObj, index) => {

    if (isSupportCKSim(bankObj)) {
      return (
        <Typography key={index} component="div" variant="body1">
          {(index + 1)}. STK <span>{bankObj.bankName}: </span>
          <strong style={{ color: Colors.DARK_BLUE }}>{bankObj.accountNumber}, </strong>
          <span>{bankObj.accountName}</span>
        </Typography>
      );
    }

    return (
      <Typography key={index} component="div" variant="body1">
        {(index + 1)}. STK <span>{bankObj.bankName}: </span>
        <strong style={{ color: Colors.GRAY }}>{bankObj.accountNumber}</strong>
        <span> [chưa hỗ trợ]</span>
      </Typography>
    );

  }

  /**
   * Check bank support CKSim
   */
  const isSupportCKSim = (bankObj) => {

    const bankInfo = getBankInfo(Config.getAvailableBanks(), bankObj.bankName);
    if (bankInfo.multiSim === "yes") {
      return true;
    }

    return false;
  }

  const getBankSupportCKSim = () => {

    var bankList = [];

    Config.getAvailableBanks().map((bankInfo, index) => {
      if (bankInfo.multiSim === "yes") {
        bankList.push(bankInfo.name);
      }
    });

    return bankList.join(", ");
  }

  const getSimActions = (sim) => {
    return (
      <div>
        <Tooltip title="Chọn" aria-label="Verify"><span>
          <IconButton size="small" className={(sim.phone === simObj.phone) ? classes.selectedIcon : classes.smsButton} onClick={() => setSimObj(sim)}>
            <CheckOutlinedIcon />
          </IconButton></span>
        </Tooltip>
      </div>
    );
  }


  useEffect(() => {
    // reload on refresh
    loadDelegateSims();
  }, []);


  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">CKSIM nhận biến động số dư (BĐSD)</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>
              {getMessageBox(errorMessage, successMessage, "")}
            </Box>

            <Grid container spacing={1} style={{ paddingLeft: 20, paddingRight: 20 }}>

              <Grid item xs={12}>
                <Typography component="div" variant="body1">Số điện thoại <strong style={{ color: Colors.DARK_BLUE }}>{getDisplayPhone(simObj.phone)}</strong> được cấp cho {partnerObj.name} để nhận BĐSD. Mời bạn chọn tài khoản ngân hàng sẽ gửi SMS BĐSD:</Typography>
              </Grid>
              <Grid item xs={12}>
                {
                  bankAccounts.map((bankObj, index) => (
                    <Box key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={(bankObj.notifier === simObj.phone)}
                            onChange={e => onSelectBankAccount(bankObj, index, e.target.checked)}
                            name="allowRemove"
                            color="primary"
                          />
                        }
                        label={getBankText(bankObj, index)}
                        disabled={(successMessage !== "") || !isSupportCKSim(bankObj) && !Config.isSuperAdmin(userObj.phone)}
                      />
                    </Box>
                  ))
                }
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowRemove}
                      onChange={e => onAllowRemove(e.target.checked)}
                      name="allowRemove"
                      color="primary"
                    />
                  }
                  label="Yêu cầu huỷ đăng ký CKSIM"
                  disabled={(successMessage !== "")}
                />
              </Grid>

              <Grid item xs={12}>

                <span className={classes.title}>DANH SÁCH CKSIM</span>

                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="delegate table">
                    <TableHead>
                      <TableRow className={classes.row}>
                        <TableCell className={classes.colNo}>STT</TableCell>
                        <TableCell className={classes.colPhone}>SĐT</TableCell>
                        <TableCell className={classes.colStatus}>Trạng thái</TableCell>
                        <TableCell className={classes.colTime}>Cập nhật</TableCell>
                        <TableCell className={classes.colActions}>Chọn</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {delegateSims.map((sim, index) => (
                        <TableRow hover key={index} padding="none" className={classes.row}>
                          <TableCell className={classes.colNo}>{index + 1}</TableCell>
                          <TableCell className={classes.colPhone} style={{ color: Colors.DARK_BLUE }}>{getDisplayPhone(sim.phone)}</TableCell>
                          <TableCell className={classes.colStatus} style={{ color: Colors.DARK_BLUE }}>{sim.status}</TableCell>
                          <TableCell className={classes.colTime} style={{ color: Colors.DARK_BLUE }}>{}</TableCell>
                          <TableCell className={classes.colActions}>{getSimActions(sim)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>


              <Grid item xs={12}>
                <Typography component="div" variant="body1">
                  <span>Lưu ý: Chỉ một số ngân hàng hỗ trợ gửi BĐSD về CKSIM từ nhiều tài khoản như: </span>
                  <strong>{getBankSupportCKSim()}</strong>
                  <span>. Sau khi đăng ký xong CKSIM, bạn cần cấu hình ứng dụng ngân hàng để gửi BĐSD qua SMS về số điện thoại {getDisplayPhone(simObj.phone)}.</span>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            {
              allowRemove &&
              <Button disabled={loading || (successMessage !== "")} color="primary" onClick={() => deregisterDelegateSim()}>
                Huỷ đăng ký CKSIM
              </Button>
            }

            {
              allowRegister && !allowRemove &&
              <Button disabled={loading || (successMessage !== "")} color="primary" onClick={() => registerDelegateSim()}>
                Cập nhật CKSIM
              </Button>
            }

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.LIGHT_BLUE,
      contrastText: "#fff" //button text white instead of black
    }
  }
}));


const useStyles = makeStyles(theme => ({
  root: {

  },

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
  },

  table: {
    marginTop: 4,
    marginBottom: 0,
    whiteSpace: "nowrap",
  },

  row: {
    height: 30,
    maxHeight: 36,
    fontSize: 12,
  },

  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },

  colPhone: {
    minWidth: 80,
    padding: '2px 6px',
    paddingLeft: 16,
  },

  colStatus: {
    minWidth: 110,
    padding: '2px 6px',
    fontWeight: 'bold',
  },

  colBalance: {
    minWidth: 50,
    padding: '2px 12px',
  },

  colExpired: {
    minWidth: 80,
    padding: '2px 12px',
  },

  colTime: {
    minWidth: 110,
    padding: '2px 12px',
  },
  colActions: {
    minWidth: 80,
    maxWidth: 161,
    padding: "3px 3px",
    textAlign: "center",
  },
  smsButton: {
    fontSize: 10,
    padding: '0px 6px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

  selectedIcon: {
    fontSize: 10,
    padding: '0px 6px',
    color: Colors.DARK_GREEN
  }

}));
