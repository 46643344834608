import React, { useState, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { comparePartner, getStatusColor, getTxTimeText } from '../../ckcore/core/TxUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { timeOffsetString } from '../../ckcore/core/CkUtils';
import { useInterval } from '../../ckcore/core/CkUtils';
import RootUpdatePartnerDialog from './RootUpdatePartner';
import moment from 'moment';
import { getSubscriptionPackageName } from '../subscription/PricingTable';
import PricingDialog from '../subscription/PricingDialog';

export default function PartnerListAll(props) {
  const classes = useStyle();

  const userObj = props.userObj;

  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showUpdatePartnerDialog, setShowUpdatePartnerDialog] = useState(false);
  const [selectedPartnerObj, setSelectedPartnerObj] = useState({});

  // Edit partner
  const updatePartner = (partnerObj) => {
    setSelectedPartnerObj(partnerObj);
    setShowUpdatePartnerDialog(true);
  }

  const closeUpdatePartnerDialog = () => {
    setShowUpdatePartnerDialog(false);
    loadPartnerList();
  }

  const [showPartnerSubscriptionDialog, setShowPartnerSubscriptionDialog] = useState(false);
  const showPartnerSubscription = (partnerObj) => {
    setSelectedPartnerObj(partnerObj);
    setShowPartnerSubscriptionDialog(true);
  }

  const closePartnerSubscriptionDialog = () => {
    setShowPartnerSubscriptionDialog(false);
    loadPartnerList();
  }


  /**
   * Load partner request list
   */
  const loadPartnerList = async () => {

    if (loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const cksu = {
      txTypeId: TxTypes.ORIGIN_ADMIN_PARTNER_REPORT,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "admin=" + JSON.stringify(cksu));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        const partnerList = response.partners;
        partnerList.sort(comparePartner);
        setPartners(partnerList);

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Load partner list error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load partner list: " + error);
    }

    setLoading(false);
  }


  const getSimStatusColor = (simStatus, timestamp) => {

    // fix timestamp
    if (new Date().getTime() - timestamp > 30000) {
      return Colors.YELLOW;
    }

    if (simStatus === "READY") {
      return 'green';
    } else if (simStatus === "READING" || simStatus === "WRITING" || simStatus === "TRANSFERING" || simStatus === "RECHARGING") {
      return Colors.DARK_BLUE;
    } else if (simStatus === "PROCESSING" || simStatus === "TRANSFER_WAITING" || simStatus === "RECHARG_WAITING" || simStatus === "WAITING") {
      return Colors.YELLOW;
    } else {
      return Colors.RED;
    }

  }

  /**
   * Subscription expired time
   */
  const getExpiredTime = (partnerObj) => {

    const expired = moment(partnerObj.subscription.expired).format('DD/MM/YYYY');
    const ckpk = getSubscriptionPackageName(partnerObj.subscription.package);

    return <div className={classes.colExpired} style={{cursor: 'pointer'}}>{ckpk}<br/>{expired}</div>

  }

  /**
   * Get bank acount
   */
  const getBankCount = (partnerObj) => {

    const banks = partnerObj.bankConfig.banks;

    if (!(banks)) {
      return <div className={classes.valueSmall}>
        {
          (partnerObj.transparency) &&
          <span>TRANSPARENCY</span>
        }
      </div>
    }

    return (
      <div className={classes.valueSmall}>
        <span>{banks.length} accounts</span>
        <br/>
        {
          (partnerObj.transparency) &&
          <span>TRANSPARENCY</span>
        }
      </div>
    );

  }

  const getCKCoin = (partnerObj) => {

    const ckCoin = partnerObj.ckCoin;

    if (!(ckCoin)) {
      return <div className={classes.valueSmall}></div>
    }

    return (
      <div className={classes.valueSmall}>
        {ckCoin}
        <br/>
        {
          (partnerObj.silence) &&
          <span>SILENCE</span>
        }  
      </div>
    );

  }

  /**
   * Get staff count
   */
  const getStaffCount = (partnerObj) => {

    const staffs = partnerObj.staffConfig.staffs;

    if (!(staffs)) {
      return <div className={classes.valueSmall}>NONE</div>
    }

    return (
      <div className={classes.valueSmall}>{staffs.length}</div>
    );

  }

  const getWebsiteLink = (partnerObj) => {
    return (
      <a href={partnerObj.website} target="_blank" alt="" rel="noreferrer">{partnerObj.website.replace("https://", "").replace("http://", "")}</a>
    );
  }


  const getActions = (partnerObj) => {
    return (
      <div>
        {
          <Tooltip title="Cài đặt" aria-label="Setting">
            <span>
              <IconButton size="small" className={classes.smsButton} onClick={() => updatePartner(partnerObj)}>
                <SettingsOutlinedIcon />
              </IconButton>
            </span>
          </Tooltip>
        }

      </div>
    );
  }

  // Use interval to refresh bank data
  useInterval(() => {
    loadPartnerList();
  }, 5000);

  useEffect(() => {
    loadPartnerList();
  }, []);


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>



        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="modem usb table">
            <TableHead>
              <TableRow className={classes.row}>
                <TableCell className={classes.colNo}>ID</TableCell>
                <TableCell className={classes.colStatus}>Name</TableCell>
                <TableCell className={classes.colPhone}>Admin</TableCell>
                <TableCell className={classes.colPhone}>Staffs</TableCell>
                <TableCell className={classes.colPhone}>Banks</TableCell>
                <TableCell className={classes.colPhone}>CKCoin</TableCell>
                <TableCell className={classes.colExpired} style={{ textAlign: 'center' }}>Expired</TableCell>
                <TableCell className={classes.colExpired}>Updated</TableCell>
                <TableCell className={classes.colActions}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partners.map((partnerObj, index) => (
                <TableRow hover key={index} padding="none" className={classes.row}>
                  <TableCell className={classes.colNo}>{partnerObj.partnerId}</TableCell>
                  <TableCell className={classes.partnerName} >
                    {
                      (partnerObj.logo) &&
                      <img className={classes.partnerLogo} src={partnerObj.logo} alt={partnerObj.name} />
                    }
                    <span className={classes.activePartner}>{partnerObj.name}</span>
                  </TableCell>
                  <TableCell className={classes.colPhone} >
                    {getDisplayPhone(partnerObj.phone)}
                    <br />
                    {getWebsiteLink(partnerObj)}
                  </TableCell>
                  <TableCell className={classes.colPhone} >{getStaffCount(partnerObj)}</TableCell>
                  <TableCell className={classes.colPhone} >{getBankCount(partnerObj)}</TableCell>
                  <TableCell className={classes.colPhone} >{getCKCoin(partnerObj)}</TableCell>
                  <TableCell className={classes.colExpired} onClick={() => showPartnerSubscription(partnerObj)}>{getExpiredTime(partnerObj)}</TableCell>
                  <TableCell className={classes.colExpired} >{getTxTimeText(partnerObj.updated)}</TableCell>
                  <TableCell className={classes.colActions}>{getActions(partnerObj)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {
          showUpdatePartnerDialog &&
          <RootUpdatePartnerDialog open={showUpdatePartnerDialog} closeCallback={closeUpdatePartnerDialog}
            userObj={props.userObj}
            partnerId={selectedPartnerObj.partnerId}
          />
        }
        {
          showPartnerSubscriptionDialog &&
          <PricingDialog open={showPartnerSubscriptionDialog} closeCallback={closePartnerSubscriptionDialog}
            userObj={props.userObj}
            partnerObj={selectedPartnerObj}
          />
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
});



const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#37517f",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  title: {
    color: Colors.DARK_BLUE,
    paddingLeft: 2,
  },
  name: {
    color: Colors.DARK_GREEN,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  toolbar_height: {
    height: "30px",
  },
  transHead: {
    minHeight: 30,
    padding: "3px 3px",
  },

  table: {
    whiteSpace: "nowrap",
    marginTop: 5,
    marginBottom: 0,
  },

  row: {
    height: 56,
    maxHeight: 60,
    fontSize: 12,
  },


  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },
  partnerName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "normal",
    overflow: "hidden",
    padding: 8,
    height: 56,
    maxHeight: 56,
    maxWidth: 160
  },

  activePartner: {
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
  },

  partnerLogo: {
    width: 18,
    height: 18,
    marginRight: 8,
  },
  colWebsite: {
    cursor: 'pointer',
  },
  ckAppStatus: {
    textAlign: 'right',
    paddingRight: 6,
    minWidth: 99
  }
}));
