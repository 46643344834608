import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { Button, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import { getBankSvgIcon } from '../../../ckcore/core/CkBank'
import ValUtils from '../../../ckcore/core/ValUtils';
import Colors from '../../../ckcore/core/Colors';
import TxStatus from '../../../ckcore/core/TxStatuses';
import ServiceTypes from '../../../ckcore/core/ServiceTypes';
import Config from '../../../ckcore/core/Config';
import { EMPTY_LIST, formatBirthday, GENDER_LIST, INDUSTRY_LIST, POPA_TYPE_LIST } from '../../../ckcore/core/CustomerUtils';
import moment from 'moment';
import { getCommunes, getDistricts, getProvinces } from '../../../ckcore/core/LocationUtils';

export default function CustomerFields(props) {

  const bankList = Config.getAvailableBanks();

  const [popaObj, setPopaObj] = useState(props.popaObj);

  /**
   * Handle change fields
   */
  const handleChange = name => event => {

    let info = { ...popaObj.info };
    let contacts = { ...popaObj.contacts };
    let accounts = { ...popaObj.accounts };

    var val = event.target.value;

    // trim data
    if (typeof val === 'string' || val instanceof String) {
      // val = val.trim();
    }

    if (name === 'phone') {
      val = ValUtils.getNumberOnly(val);
      val = ValUtils.getDisplayPhone(val);
    }

    if (name === 'birthDate') {
      console.log("BirthDate: " + val);
      let birthday = moment(val, 'YYYY-MM-DD').toDate().getTime() / 1000;
      console.log("Birthday new: " + birthday);
      setPopaObj({ ...popaObj, birthday: birthday });
      return;
    }

    if (name === 'accountName') {
      val = val.toUpperCase();
    } else
    if (name === 'accountNumber') {
      val = val.replace(/ /g, "");
    }

    // set popa info
    if (name === 'tin' || name === 'contact' || name === 'industry' || name === 'source' || name === 'incharge' || name === 'relation') {
      info[name] = val;
      setPopaObj({ ...popaObj, info: info});
    } else {
      // set popa fields
      setPopaObj({ ...popaObj, [name]: val, info: info});
    }

  };

  useEffect(() => {
    props.updateCallback(popaObj);
  }, [popaObj]);

  //
  useEffect(() => {
    setPopaObj(props.popaObj);
  }, [props.popaObj]);

  return (
    <Grid container spacing={2}>

      <Grid item xs={2.5}>
        <TextField id="customer-code" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Mã khách hàng"}
          value={popaObj.code}
          onChange={handleChange('code')}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-type-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Nhóm khách hàng"}
          select
          value={(popaObj.typeId) ? popaObj.typeId : 0}
          onChange={handleChange('typeId')}>
          {POPA_TYPE_LIST.map((typeObj, index) => (
            <MenuItem key={typeObj.name} value={typeObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{typeObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="customer-tin" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Mã số thuế"}
          value={(popaObj.info.tin) ? popaObj.info.tin : ''}
          onChange={handleChange('tin')}
        />
      </Grid>
      <Grid item xs={4.5}>
        <TextField id="customer-name" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Tên khách hàng"}
          value={popaObj.name}
          onChange={handleChange('name')}
        />
      </Grid>
     


      <Grid item xs={2.5}>
        <TextField id="ck-province-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Tỉnh / thành phố"}
          select
          value={popaObj.provinceId}
          onChange={handleChange('provinceId')}>
          {getProvinces().map((provinceObj, index) => (
            <MenuItem key={provinceObj.pn} value={provinceObj.pi}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{provinceObj.pn}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-district-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Quận / huyện"}
          select
          value={popaObj.districtId}
          onChange={handleChange('districtId')}>
          {getDistricts(popaObj.provinceId).map((districtObj, index) => (
            <MenuItem key={districtObj.dn} value={districtObj.di}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{districtObj.dn}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-commnue-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Phường / xã"}
          select
          value={popaObj.communeId}
          onChange={handleChange('communeId')}>
          {getCommunes(popaObj.provinceId, popaObj.districtId).map((communeObj, index) => (
            <MenuItem key={communeObj.cn} value={communeObj.ci}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{communeObj.cn}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4.5}>
        <TextField id="address" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Địa chỉ"}
          value={popaObj.address}
          onChange={handleChange('address')}
        />
      </Grid>



      <Grid item xs={2.5}>
        <TextField id="contact-name" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Tên người liên hệ"}
          value={(popaObj.info.contact) ? popaObj.info.contact : ''}
          onChange={handleChange('contact')}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="phone" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Điện thoại"}
          value={popaObj.phone}
          onChange={handleChange('phone')}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="email" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Email"}
          value={popaObj.email}
          onChange={handleChange('email')}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="birthday"
          label={"Sinh nhật"}
          type="date"
          size="small"
          variant="outlined" margin="dense" fullWidth
          value={(popaObj.birthday > 0) ? formatBirthday(popaObj.birthday) : ''}
          onChange={handleChange('birthDate')}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField id="ck-gender-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Nam/Nữ"}
          select
          value={popaObj.gender}
          onChange={handleChange('gender')}>
          {GENDER_LIST.map((genderObj, index) => (
            <MenuItem key={genderObj.name} value={genderObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{genderObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>


      <Grid item xs={2.5}>
        <TextField id="website" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label={"Website"}
          value={popaObj.website}
          onChange={handleChange('website')}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-industry-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Lĩnh vực hoạt động"}
          select
          value={(popaObj.info.industry) ? popaObj.info.industry : 0}
          onChange={handleChange('industry')}>
          {INDUSTRY_LIST.map((industryObj, index) => (
            <MenuItem key={industryObj.name} value={industryObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{industryObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-source-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Nguồn khách"}
          select
          value={0}
          onChange={handleChange('source')}>
          {EMPTY_LIST.map((sourceObj, index) => (
            <MenuItem key={sourceObj.name} value={sourceObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{sourceObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-incharge-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Người phụ trách"}
          select
          value={0}
          onChange={handleChange('incharge')}>
          {EMPTY_LIST.map((inchargeObj, index) => (
            <MenuItem key={inchargeObj.name} value={inchargeObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{inchargeObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2}>
        <TextField id="ck-relation-list" variant="outlined" size="small" margin="dense" fullWidth
          label={"Mối quan hệ"}
          select
          value={0}
          onChange={handleChange('relation')}>
          {EMPTY_LIST.map((relationObj, index) => (
            <MenuItem key={relationObj.name} value={relationObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{relationObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>



      <Grid item xs={2.5}>
        <TextField id="ck-bank-list" variant="outlined" size="small" margin="dense" fullWidth
          label="Ngân hàng nhận"
          select
          defaultValue={""}
          value={(popaObj.bankName) ? popaObj.bankName : ''}
          InputProps={{ readOnly: false }}
          onChange={handleChange('bankName')}>
          {bankList.map((bankObj, index) => (
            <MenuItem key={bankObj.name} value={bankObj.name}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{bankObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={2.5}>
        <TextField id="ck-bank-account-number" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label="Số tài khoản nhận"
          value={popaObj.accountNumber}
          onChange={handleChange('accountNumber')}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField id="ck-bank-account-name" type="text" variant="outlined" size="small" margin="dense" fullWidth
          label="Tên tài khoản nhận (tiếng Việt không dấu)"
          value={popaObj.accountName}
          onChange={handleChange('accountName')}
        />
      </Grid>
      <Grid item xs={2}>
          <Button size="small" variant='outlined' fullWidth style={{marginTop: 12}} disabled={true} > Thêm tài khoản </Button>
      </Grid>

    </Grid>

  );
}
