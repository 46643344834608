
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import { useInterval } from '../../ckcore/core/CkUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';

const TIME_ORANGE = 303;
const TIME_RED = 606;

export default function CKBoxList(props) {

  const classes = useStyles();

  // Get ckbox list from props
  const [ckboxes, setCkboxes] = useState([]);
  const [serverTime, setServerTime] = useState((new Date()).getTime());

  // Get CPU temperature warning color
  const getCpuColor = (ckbox) => {
    const temperature = parseInt(ckbox.statistics.cpuTemp);

    if (temperature < 40) {
      return Colors.DARK_GREEN;
    } else if (temperature < 50) {
      return Colors.DARK_BLUE;
    } else if (temperature < 80) {
      return Colors.ORANGE;
    } else if (temperature >= 80) {
      return Colors.RED;
    }

    return '';
  }

  // Get Ping time warning color
  const getPingTimeColor = (ckbox) => {

    const diffTime = (serverTime - ckbox.statistics.timestamp) / 1000;

    if (diffTime < TIME_ORANGE) {
      return Colors.DARK_GREEN;
    } else if (diffTime < TIME_RED) {
      return Colors.ORANGE;
    } else {
      return Colors.RED;
    }
  }


  // Get ckbox status color
  const getStatusColor = (ckbox) => {

    const diffTime = (serverTime - ckbox.statistics.timestamp) / 1000;
    const temperature = parseInt(ckbox.statistics.cpuTemp);

    if (temperature > 80 || diffTime > TIME_RED) {
      return Colors.RED;
    } else if (temperature > 50 || diffTime > TIME_ORANGE) {
      return Colors.ORANGE;
    } else if (ckbox.ckboxId === 0) {
      return Colors.YELLOW;
    } else {
      return Colors.DARK_BLUE;
    }

  }


  // Format diff time to second
  const getTimeDiff = (timestamp) => {
    return ((serverTime - timestamp) / 1000 | 0) + "s";
  }

  // Format free memory to MB
  const getFreeMem = (freeMemory) => {
    return (freeMemory | 0) + "MB";
  }

  // Format up time in hours
  const getUpTime = (upTime) => {
    if (upTime > 360) {
      return (upTime / 3600 | 0) + "h";
    } else {
      return (upTime / 60 | 0) + "m";
    }
  }

  // Format version with status color
  const getVersion = (ckbox) => {
    const version = ckbox.statistics.version;
    if (version) {
      return version;
    } else {
      return "";
    }
  }


  // Opne CKBox in local IP
  const openCKBoxLink = (lanIp) => {
    window.open("http://" + lanIp, "_blank");
  }

  /**
   * Load ckbox list from CK Master
   */
  const loadCKBoxes = async () => {

    try {

      const cksu = {
        timestamp: new Date().getTime(),
        identity: User.getIdentity(),
      };

      var ckmasterUrl = Config.getServerURL();
      ckmasterUrl = ckmasterUrl.substring(0, ckmasterUrl.lastIndexOf('/')) + '/ckmaster?';

      const rawRespone = await fetch(ckmasterUrl + "ckm=" + JSON.stringify(cksu));
      const response = await rawRespone.json();

      if (response.ckboxes) {
        setCkboxes(response.ckboxes);
        setServerTime(response.timestamp);
      }

    } catch (error) {
      console.log("Error master server... " + error);
    }
  };

  // Use interval to refresh partner list
  useInterval(() => {

    // Fetch partners info
    loadCKBoxes();

  }, 5000);

// reload on refresh
useEffect(() => {
  loadCKBoxes();
}, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>

        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="ckboxes table">
            <TableHead>
              <TableRow className={classes.row}>

                <TableCell align="center" className={classes.colNo}>No.</TableCell>
                <TableCell align="center" className={classes.colckboxId}>ckboxId</TableCell>
                <TableCell align="center" className={classes.colStatus}>Status</TableCell>
                <TableCell align="center" className={classes.colMessage}>Pings</TableCell>
                <TableCell align="center" className={classes.colMessage}>Temp</TableCell>

                <TableCell align="center" className={classes.colIp}>LAN IP</TableCell>
                <TableCell align="center" className={classes.colIp}>MAC</TableCell>
                <TableCell align="center" className={classes.colIp}>Public IP</TableCell>

                <TableCell align="center" className={classes.colIp}>PartnerId</TableCell>
                <TableCell align="center" className={classes.colIp}>Admin</TableCell>

                <TableCell align="center" className={classes.colMessage}>Load Average</TableCell>
                <TableCell align="center" className={classes.colMessage}>Free Mem</TableCell>
                <TableCell align="center" className={classes.colMessage}>Storage</TableCell>
                <TableCell align="center" className={classes.colMessage}>Up Time</TableCell>
                <TableCell align="center" className={classes.colMessage}>Version</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {ckboxes.map((ckbox, index) => (
                <TableRow hover key={index} padding="none" className={classes.row}>

                  <TableCell align="center" className={classes.colNo} style={{ color: getStatusColor(ckbox) }}>{index + 1}</TableCell>
                  <TableCell align="center" className={classes.colckboxId} style={{ color: getStatusColor(ckbox) }}>{ckbox.ckboxId}</TableCell>
                  <TableCell align="center" className={classes.colStatus} style={{ color: getStatusColor(ckbox) }}>{ckbox.status}</TableCell>
                  <TableCell align="center" className={classes.colPingTime} style={{ color: getPingTimeColor(ckbox) }}>{getTimeDiff(ckbox.statistics.timestamp)}</TableCell>
                  <TableCell align="center" className={classes.colCPUTemperature} style={{ color: getCpuColor(ckbox) }}>{ckbox.statistics.cpuTemp}</TableCell>

                  <TableCell align="center" className={classes.colIpLocal} onClick={() => openCKBoxLink(ckbox.statistics.ip)}>{ckbox.statistics.ip}</TableCell>
                  <TableCell align="center" className={classes.colIp}>{ckbox.statistics.mac}</TableCell>
                  <TableCell align="center" className={classes.colIp}>{ckbox.statistics.publicIp}</TableCell>

                  <TableCell align="center" className={classes.colckboxId}>{(ckbox.statistics.partnerId) ? ckbox.statistics.partnerId : ""}</TableCell>
                  <TableCell align="center" className={classes.colIp}>{(ckbox.statistics.adminPhone) ? getDisplayPhone(ckbox.statistics.adminPhone) : ""}</TableCell>

                  <TableCell align="center" className={classes.colActions}>{ckbox.statistics.loadAverage}</TableCell>
                  <TableCell align="center" className={classes.colActions}>{getFreeMem(ckbox.statistics.freeMemory)}</TableCell>
                  <TableCell align="center" className={classes.colActions}>{ckbox.statistics.freeStorage}</TableCell>
                  <TableCell align="center" className={classes.colActions}>{getUpTime(ckbox.statistics.upTime)}</TableCell>
                  <TableCell align="center" className={classes.colActions}>{getVersion(ckbox)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>



      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation.
        padding: '2px 6px',
      },
      sizeSmall: {
        padding: '2px 3px 2px 3px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    textAlign: 'left',
    minWidth: 480,
  },
  title: {
    color: Colors.BG_WHITE,
    fontWeight: 'bold',
    paddingLeft: 2,
    marginBottom: 6,
  },

  transHead: {
    minHeight: 30,
    padding: '3px 3px',
  },

  modemButton: {
    padding: '0px 6px',
    marginLeft: '8px',
  },

  table: {
    minWidth: 480,
    maxWidth: 1216,
    whiteSpace: "nowrap",
    marginTop: 5,
    marginBottom: 0,
    overflow: "hidden",
  },

  row: {
    minWidth: 480,
    maxWidth: 1216,
    height: 30,
    maxHeight: 36,
    fontSize: 12,
    overflow: "hidden",
  },

  colNo: {
    minWidth: 30,
    textAlign: 'center',
    padding: '2px 3px',
  },

  colckboxId: {
    textAlign: 'center',
    minWidth: 50,
    padding: '2px 3px',
    fontWeight: 'bold',
  },

  colStatus: {
    textAlign: 'center',
    minWidth: 80,
    padding: '2px 6px',
  },

  colIpLocal: {
    minWidth: 80,
    padding: '2px 6px',
    cursor: 'pointer',
  },

  colIp: {
    minWidth: 80,
    padding: '2px 6px',
  },

  colCPUTemperature: {
    minWidth: 50,
    padding: '2px 12px',
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
  },

  colPingTime: {
    minWidth: 96,
    padding: '2px 6px',
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
  },

}));