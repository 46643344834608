import { sha256 } from 'js-sha256';
import Config from '../../ckcore/core/Config';

var CkCheckout = (function () {

  var partnerId = 0;
  var paymentKey = '';
  var accountNumber = '';
  var returnUrl = '';

  /**
   * Initialize CK checkout
   */
  const init = function (initObj) {
    partnerId = initObj.partner_id;
    paymentKey = initObj.payment_key;
    accountNumber = initObj.account_number;
    returnUrl = initObj.return_url;
  }

  /**
   * Get option value
   */
  const getOptValue = (fieldValue) => {
    
    // field = 0
    if(fieldValue === 0) {
      return fieldValue;
    }

    if(fieldValue) {
      return fieldValue;
    }

    return '';
  }

  /**
   * Get optional int value
   */
  const getOptIntValue = (fieldValue) => {

    // field = 0
    if(fieldValue === 0) {
      return fieldValue;
    }
    
    if(fieldValue) {
      return fieldValue;
    }

    return '';
  }

  /**
   * Process request CK Pay order
   */
  const payNow = function (order, errorCallback, paymentCallback, method) {

    // request init order
    if(window.ckInit) {
      window.ckInit();
    }

    const orderObj = order;

    if(!(orderObj.partner_id)) {
      orderObj.partner_id = partnerId;
    }
    if(!(orderObj.account_number)) {
      orderObj.account_number = accountNumber;
    }
    if(!(orderObj.return_url)) {
      orderObj.return_url = returnUrl;
    }

    // set order timestamp
    const timestamp = (new Date()).getTime();
    order.timestamp = timestamp;

    // build checksum content
    var checksumContent = "";

    // add partner and order info
    checksumContent = orderObj.partner_id + orderObj.account_number + orderObj.order_total
      + getOptValue(orderObj.order_id) 
      + getOptValue(orderObj.customer_phone) 
      + getOptValue(orderObj.customer_id)
      + getOptValue(orderObj.order_info);

    // option value
    checksumContent = checksumContent + getOptValue(orderObj.shipping_fee);
    checksumContent = checksumContent + getOptValue(orderObj.install_fee);

    // add product
    if (orderObj.product) {
      checksumContent = checksumContent
        + orderObj.product.name + orderObj.product.code + orderObj.product.price 
        + getOptValue(orderObj.product.discount) 
        + getOptValue(orderObj.product.tax)
        + getOptIntValue(orderObj.product.discount_amount)
        + getOptIntValue(orderObj.product.bonus_amount)
        + getOptIntValue(orderObj.product.tax_amount)
        + orderObj.product.quantity + decodeURIComponent(orderObj.product.image) + orderObj.product.description
    } else if (orderObj.products) {
      orderObj.products.map((product) => {
        checksumContent = checksumContent
          + product.name + product.code + product.price 
          + getOptValue(product.discount) 
          + getOptValue(product.tax)
          + getOptIntValue(product.discount_amount)
          + getOptIntValue(product.bonus_amount)
          + getOptIntValue(product.tax_amount)
          + product.quantity + decodeURIComponent(product.image) + product.description
      });
    } else {
      errorCallback('Order product not found');
    }

    // add return url, time, and payment key
    checksumContent = checksumContent + decodeURIComponent(orderObj.return_url) + orderObj.timestamp;

    if (!(orderObj.payment_key)) {
      checksumContent = checksumContent + paymentKey;
    } else {
      checksumContent = checksumContent + orderObj.payment_key;
    }

    // console.log("Checksum content: " + checksumContent);

    // create checksum
    const checksum = sha256(checksumContent);
    orderObj.checksum = checksum;

    // build post request
    const messageRequest = {
      order: orderObj,
      txType: 'pay'
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(messageRequest)
    };

    // fetch payment URL to get order token
    fetch(Config.getPaymentServerURL(), requestOptions)
      .then(res => res.json())
      .then((response) => {

        if (response.token) {

          if(paymentCallback) {
            // callback, response token
            paymentCallback(response.token);
          } else {
            // goto new location
            window.location.href = Config.getCheckoutServerURL() + response.token;
          }
        } else {

          // show error message
          if (errorCallback) {            
            errorCallback(response);
          } else {
            // set error
            console.log("Error generate CK QR: " + JSON.stringify(response));
          }
        }
      },
        (error) => {
          if (errorCallback) {
            errorCallback("Request CkCheckout error: " + error)
          } else {
            console.log("Request CkCheckout error: " + error);
          }
        }
      );
  }

  // Return declare functions
  return {
    init: init,
    payNow: payNow,
  };
})();

export default CkCheckout;


// THE KEY EXPORT as GLOBE is here
window.CkCheckout = CkCheckout;
