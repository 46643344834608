import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import Box from "@mui/material/Box";

import Colors from '../../ckcore/core/Colors';
import { Typography } from '@mui/material';
import './ckpayment.css';
import TxStatus from '../../ckcore/core/TxStatuses';

/**
 * Show error when create payment
 */
export default function PaymentErrorDialog(props) {

  const classes = styles(theme);

  const errorOrder = props.errorOrder;

  // Customer click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Customer click to return partner site
  const handleReturn = () => {
    props.returnCallback('home');
  }

  /**
   * Warning titles
   */
  const getWarningTitle = () => {

    if (errorOrder.statusId === TxStatus.HTTP_BAD_REQUEST) {
      return "Thông tin thanh toán không hợp lệ";
    } else if (errorOrder.statusId === TxStatus.HTTP_NOT_FOUND) {
      return "Không tìm thấy đơn hàng cần thanh toán";
    } else {
      return "Không tìm thấy đơn hàng cần thanh toán";
    }

  }

  /**
   * Error details
   */
  const getErrorDetails = () => {
    if (!errorOrder.errors) {
      return "";
    }

    if (errorOrder.errors.customer_phone) {
      return "Số điện thoại khách hàng không hợp lệ";
    } else if (errorOrder.errors.checksum) {
      return "Mã kiểm tra (checksum) không hợp lệ";
    }
    return "";
  }

  /**
   * Warning content
   */
  const getWarningContent = () => {

    if (errorOrder.statusId === TxStatus.HTTP_BAD_REQUEST || errorOrder.statusId === TxStatus.HTTP_UNAUTHORIZED) {
      return (
        <Alert severity="error">
          <AlertTitle>Thông tin đơn hàng cần thanh toán không hợp lệ</AlertTitle>
          {
            (getErrorDetails() !== '') ? (
              <Box>
                <Typography>Đơn hàng do bên bán chuyển sang Chuyenkhoan.com bị lỗi: </Typography>
                <Typography style={{color: Colors.RED}}>- {getErrorDetails()}</Typography>
                <Typography>Vui lòng liên lạc với bên bán hàng để kiểm tra và khắc phục. </Typography>
              </Box>
            ) : (
              <Typography>Thông tin thanh toán do bên bán hàng chuyển sang Chuyenkhoan.com bị lỗi. Vui lòng liên lạc với bên bán hàng để kiểm tra và khắc phục.</Typography>
            )
          }

        </Alert>
      );
    } else if (errorOrder.statusId === TxStatus.HTTP_NOT_FOUND) {
      return (
        <Alert severity="warning">
          <AlertTitle>Đơn hàng cần thanh toán không tìm thấy hoặc quá thời gian</AlertTitle>
          Cổng thanh toán Chuyenhoan.com không tìm thấy đơn hàng theo token bạn yêu cầu. Vui lòng kiểm tra lại hoặc liên lạc với bên bán hàng để khắc phục.
        </Alert>
      );
    } else {

      return (
        <Alert severity="info">
          <AlertTitle>Website chỉ phụ vụ thanh toán chuyển khoản</AlertTitle>
          Trang thanhtoan.chuyenkhoan.com chỉ nhận yêu cầu thanh toán từ bên bán hàng.
        </Alert>
      );
    }

  }


  // Payment transfer bank guide dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} onClose={handleClose} maxWidth="sm" fullWidth={true}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{getWarningTitle()}</DialogTitle>
          <DialogContent dividers>

            <Box p={1} style={{ marginBottom: 8 }}>
              {getWarningContent()}
            </Box>

            <Box p={1} style={{ marginBottom: 8 }}>
              <Typography component="div" variant="body1">
                Mời bạn vào <a href='https://chuyenkhoan.com' target="_blank" rel="noreferrer">chuyenkhoan.com</a> để tìm hiểu thêm về giải pháp thanh toán chuyển khoản, hoặc bấm "TRỞ VỀ" để quay lại trang trước.
              </Typography>
            </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleReturn} color="primary">
              Trở về
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    text: {
      disabled: Colors.DARK_BLUE
    }
  }
});

const styles = (theme) => ({
  dialog: {
    [theme.breakpoints.down('sm')]: {
      "& .MuiDialog-container .MuiDialog-paper": {
          margin: "24px 0px",
          borderRadius: 0
      },
  }
  },
});