
import React, { useState } from 'react';
import { Hidden, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import { createTheme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import TablePaginationActions from '../../ckcore/ui/TablePaginationActions';

import {
  getStatusColor,
  getTxStatusText,
  getTxTimeText,
  parseTxBean
} from '../../ckcore/core/TxUtils';

import Config from '../../ckcore/core/Config';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import SyncSmsToBankTxDialog from './SyncSmsToBankTx';

/**
 * Bank transaction list
 */
export default function CKAppSmsList(props) {

  const classes = useStyles();

  // parse bank transaction
  const smsTxList = [];    
  var txObj;
  var txList// get tx list for all banks
  txList = props.smsList;
  if(txList) {
    txList.map((txLine) => {
      txObj = parseTxBean(txLine);
      txObj.time = getTxTimeText(txObj.created);
      smsTxList.push(txObj);
    });
  }

  const [selectedTxObj, setSelectedTxObj] = useState({});
  const [loading, setLoading] = useState(false);

  // handle paging
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(11);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [showSyncBankTx, setShowSyncBankTx] = useState(false);
  const requestSyncBankTx = (txObj) => {
    setSelectedTxObj(txObj);
    setShowSyncBankTx(true);
  }
  const closeSyncBankTx = () => {
    setShowSyncBankTx(false);
  }

  // Get tx actions
  const getActions = (txObj, index) => {

    if(Config.isSuperAdmin(props.userObj.phone) && txObj.statusId === TxStatus.NOT_FOUND) {
      return (
        <div>
         <Tooltip title="Đồng bộ lại giao dịch" aria-label="search order">
              <span>
                <IconButton size="small" className={classes.smsButton} onClick={() => requestSyncBankTx(txObj)}>
                  <FindReplaceOutlinedIcon/>
                </IconButton>
              </span>
            </Tooltip>
        </div>
      );
    }

    return (
      <div>       
      </div>
    );
  }

  /**
   * User request search
   */
  const handleSearch = (searchTxt) => {

  }


  // return
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid justifyContent="space-between" alignItems="flex-end" container>
          <Grid item className={classes.transHead}>
            <Box display="inline" alignItems="flex-end">
              <span className={classes.title}>DANH SÁCH TIN NHẮN</span>
            </Box>
          </Grid>

          <Grid item className={classes.transHead}>
            {
              <Paper className={classes.searchBox}>

                <InputBase
                  size="small"
                  className={classes.searchInput}
                  placeholder="Lọc giao dịch.."
                  onChange={handleSearch('searchLog')}
                  inputProps={{ 'aria-label': 'search transactions' }}
                />
                <IconButton className={classes.searchIcon} aria-label="search" size="large">
                  <SearchIcon />
                </IconButton>

              </Paper>
            }
          </Grid>
        </Grid>

        <TableContainer className={classes.tableContainer} component={Paper} >

          <Table className={classes.table} size="small" aria-label="trans list table">

            <TableHead>
                <TableRow>
                    <TableCell className={classes.colIndex} align="center">STT</TableCell>
                    <TableCell className={classes.colAddress} align="left">SĐT gửi</TableCell>
                    <TableCell className={classes.colAddress} align="left">SĐT nhận</TableCell>
                    <TableCell className={classes.colSmsStatus} align="left">Trạng thái</TableCell>
                    <TableCell className={classes.colTime} align="left">Thời Gian</TableCell>
                    <TableCell className={classes.colSmsBody} align="left">Nội dung</TableCell>
                    <TableCell align="center" className={classes.colHeader}>Thao tác</TableCell>

                  </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? smsTxList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : smsTxList
              ).map((sms, index) => (
                <TableRow key={index}>
                      <TableCell className={classes.colIndex} component="th" scope="row">{index + 1}</TableCell>
                      <TableCell className={classes.colAddress} align="left">{getDisplayPhone(sms.src)}</TableCell>
                      <TableCell className={classes.colAddress} align="left">{getDisplayPhone((sms.ben) ? sms.ben : sms.destPhone)}</TableCell>
                      <TableCell className={classes.colSmsStatus} align="left" style={{ color: getStatusColor(sms.statusId) }}>{getTxStatusText(sms.statusId)}</TableCell>
                      <TableCell className={classes.colTime} align="left">{sms.time}</TableCell>
                      <TableCell className={classes.colSmsBody} align="left">{sms.content}</TableCell>
                      <TableCell align="center" className={classes.colActions}>
                        {getActions(sms, index)}
                      </TableCell>
                    </TableRow>
              ))}
            </TableBody>
            {
              (smsTxList.length > 5) &&
              <TableFooter >
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[11, 22, 33]}
                    colSpan={9}
                    count={smsTxList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Số GĐ' },
                      native: true,
                    }}
                    labelRowsPerPage="Xem:"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            }
          </Table>
        </TableContainer>
        
        {
          showSyncBankTx &&
          <SyncSmsToBankTxDialog open={showSyncBankTx} closeCallback={closeSyncBankTx} 
            txObj={selectedTxObj}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
          />
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}



const theme = createTheme(adaptV4Theme({
  // spacing: 3,
  overrides: {
    MuiTableCell: {
      root: {  //This can be referred from Material UI API documentation.
        padding: '2px 6px',
      },
      sizeSmall: {
        padding: '2px 3px 2px 3px',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      }
    },
  },
}));

const useStyles = makeStyles(theme => ({
  root: {
      padding: 8,
  },

  alert: {
    marginBottom: 12,
  },

  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
  },

  transHead: {
    minHeight: 22,
    padding: '3px 3px',
  },

  searchBox: {
    padding: '1px 3px',
    display: 'flex',
    alignItems: 'center',
    width: 200,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  searchIcon: {
    padding: 1,
  },

  tableContainer: {
    marginTop: 8,
  },

  table: {
    marginTop: 4,
    marginBottom: 0,    
  },

  row: {
    overflow: 'hidden',
    height: 48,
    maxHeight: 48,
    minHeight: 48,
    fontSize: 11,
  },


  colIndex: {
    minWidth: 80,
    maxWidth: 120,
    textAlign: 'center',
    padding: '2px 3px',
    fontSize: 12,
  },

  colSmsStatus: {
    minWidth: 80,
    maxWidth: 80,
    textAlign: 'center',
    padding: '2px 3px',
    fontSize: 12,
  },

  colAddress: {
    minWidth: 80,
    maxWidth: 12,
    padding: '2px 3px',
    fontSize: 12,
  },

  colTime: {
    minWidth: 120,
    maxWidth: 120,
    padding: '2px 3px',
    fontSize: 12,
  },

  colSmsBody: {
    minWidth: 333,
    maxWidth: 360,
    padding: '2px 3px',
    fontSize: 12,
  },
}));

const useStylesPaging = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
