
// Account action
const Actions = {
    SIGN_IN: "SIGN_IN",
    SIGN_UP: "SIGN_UP",
    SIGN_OUT: "SIGN_OUT",
    AUTHORIZE: "AUTHORIZE",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    LOGGED_IN: "LOGGED_IN",
};

export default Actions;
