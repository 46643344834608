import React from 'react';
import NumberFormat from 'react-number-format';
import './ckpayment.css';

export default function ProductSummary(props) {

  const order = props.order;
  const product = (order.product) ? order.product : order.products[0];

  return (
    <div className="ProductSummary">

      <div className="ProductSummary-productImageContainer">

        <div className="ProductImage-container">
          <img src={product.image} alt="Sản phẩm" className="ProductImage-image" />
        </div>
      </div>

      <div className="ProductSummary-info">
        <span className="Text Text-color--gray700 Text-fontSize--16 Text-fontWeight--500 Text-lineHeight--15">
          Đơn hàng #{order.order_id}: {product.name}
        </span>
        <span className="ProductSummary-totalAmount Text Text-color--default Text-fontWeight--600 Text--tabularNumbers" id="ProductSummary-totalAmount">
          <NumberFormat value={order.order_total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} suffix={'đ'} />
        </span>
        <span className="ProductSummary-description Text Text-color--gray500 Text-fontSize--14 Text-fontWeight--500 Text-lineHeight--15" id="ProductSummary-description">
          <div className="ProductSummaryDescription ProductSummaryDescription--singleItem">
            {product.description}
          </div>
        </span>
      </div>
    </div>
  );

}