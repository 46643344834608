import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Colors from '../../ckcore/core/Colors';
import { Grid, Paper } from '@mui/material';
import { Link } from '@mui/material';


export default function StepFive(props) {
  const classes = useStyles();

  return (


    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>

        <div>
          <Typography variant="subtitle1">
            <strong>Bước 5: </strong>
            <span> Cấu hình kết nối</span>
            <Link className={classes.link} onClick={() => props.refreshCallback("DEVELOPER", 2)}>xác nhận giao dịch tự động</Link>
            <span>, </span>
            <Link className={classes.link} onClick={() => props.refreshCallback("DEVELOPER", 3)}>chuyển tiếp BĐSD</Link>
          </Typography>
          <p>
            <span>Đây là bước dành cho Lập trình viên (DEVELOPER): mời bạn xem tài liệu và ví dụ hướng dẫn kết nối tại <Link className={classes.link} href="https://github.com/chuyenkhoan" target="_blank">https://github.com/chuyenkhoan</Link>.</span>
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <p>
          <iframe
            width="480"
            height="270"
            src="https://www.youtube.com/embed/4KogT5i7Guk"
            title="Chuyenkhoan.com - Cấu hình webhooks"
            allowfullscreen
          ></iframe>
        </p>
      </Grid>
    </Grid>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFBE6',
    marginBottom: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guide: {
    paddingLeft: 40,
    paddingBottom: 20,
  },
  link: {
    cursor: 'pointer',
    color: Colors.DARK_BLUE,
    fontWeight: 'bold'
  }
}));
