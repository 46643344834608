import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { ckApiStyles } from '../ApiStyles';
import Colors from '../../../ckcore/core/Colors';
import TxStatus from '../../../ckcore/core/TxStatuses';
import Config from '../../../ckcore/core/Config';
import User from '../../../ckcore/core/User';

/**
 * Test function
 */
export const testConnectGoogleSheets = async (apiObj, callback) => {
  try {
    // load request
    var ckmsg = {
      txType: "googlesheets",
      spreadsheetId: apiObj.client_key,
      time: new Date().getTime(),
      identity: User.getIdentity(),
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ckmsg)
    };

    // fetch response
    const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/export/check?'), requestOptions);
    const response = await rawResponse.json();

    if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
      callback(response.statusId, 'Đã kiểm tra trang tính: ' + response.spreadsheetTitle);
    } else {
      callback(response.statusId, '');
    }

  } catch (error) {
    console.log("Error check google sheet api " + error);
    callback(TxStatus.ERROR, '');
  }
}

export default function WebhookGoogleSheets(props) {

  const classes = ckApiStyles();

  const [webhookObj, setWebhookObj] = useState(props.webhookObj);

  /**
   * Set change, validate, and callback
   */
  const handleChange = (name, value) => {

    // escapse #
    if (value.indexOf('#') > 11) {
      value = value.substring(0, value.indexOf('#'));
    }

    var apiObj = { ...webhookObj.api };
    apiObj[name] = value;

    // get google sheets ID as client_key
    if (name === 'endpoint') {
      let sheetIdStartIndex = value.indexOf('d/') + 2;
      var sheetIdEndIndex = value.indexOf('/', sheetIdStartIndex + 1);
      if (sheetIdEndIndex < 0) {
        sheetIdEndIndex = value.length;
      }

      if (sheetIdStartIndex > 2 && sheetIdEndIndex > sheetIdStartIndex) {
        apiObj['client_key'] = value.substring(sheetIdStartIndex, sheetIdEndIndex);
      }
    }


    setWebhookObj({ ...webhookObj, api: apiObj });
  };

  const handleCheck = (name, value) => {
    setWebhookObj({ ...webhookObj, [name]: value });
  };

  useEffect(() => {

    // callback update change
    props.handleChange(webhookObj);

  }, [webhookObj]);

  return (
    <Grid container spacing={2}>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={(webhookObj.enable)}
              onChange={e => handleCheck("enable", e.target.checked)}
              name="enable"
              color={(webhookObj.enable) ? "primary" : "success"}
            />
          }
          label={<Typography variant="h6" style={{ color: webhookObj.enable ? "primary" : "success" }}>Đăng ký gửi thông tin chuyển khoản tới Google Sheets</Typography>}
        />
      </Grid>

      <Grid item xs={12}>
        <Box>
          <strong>Bước 1:</strong> Bạn copy trang tính mẫu tại <a target='_blank' rel="noreferrer" href='https://docs.google.com/spreadsheets/d/1_LJru5YJY4y7LS0cj-lHxUd2opjGdOXBrGxTl1ulTNE/edit?usp=sharing'>Gooogle Sheets</a>.
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
        <strong>Bước 2:</strong> Sửa đổi thông tin tài khoản tương ứng với tài khoản trên dashboard.chuyenkhoan.com.
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
        <strong>Bước 3:</strong> Chọn Chia sẻ/Share --&gt; Thêm quyền Editor/Ghi cho email: <strong style={{color: Colors.DARK_BLUE}}>chuyenkhoan@chuyenkhoan.iam.gserviceaccount.com</strong>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box>
        <strong>Bước 4:</strong> Copy đường link trang tính mới và dán vào form bên dưới.
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Đường link trang tính GoogleSheets (URL)"
          placeholder='URL có dạng: https://docs.google.com/spreadsheets/d/1_LJru5YJY4y7LS0cj-lHxUd2opjGdOXBrGxTl1ulTNE/edit'
          disabled={!webhookObj.enable}
          value={(webhookObj.api.endpoint) ? webhookObj.api.endpoint : ""}
          onChange={e => handleChange('endpoint', e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
          label="Email sở hữu trang tính GoogleSheets (owner)"
          placeholder='Ví dụ chuyenkhoan.com@gmail.com'
          disabled={!webhookObj.enable}
          value={(webhookObj.api.client_id) ? webhookObj.api.client_id : ""}
          onChange={e => handleChange('client_id', e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>

      </Grid>

    </Grid>
  );
}
