import React, { useState, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import {Roles, getRoleName, getRoleStatus } from '../../ckcore/core/Roles';
import {getStatusColor, getTxTimeText} from '../../ckcore/core/TxUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';

import EditStaffRoleDialog from './EditStaffRole';
import AddPartnerDialog from './AddPartnerDialog';
import RemoveRoleDialog from './RemoveRoleDialog';


export default function StaffList(props) {
  const classes = useStyle();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const activeRole = User.getActiveRole(userObj);

  const [showCreatePartnerDialog, setShowCreatePartnerDialog] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [staffObj, setStaffObj] = useState({});
  const [showEditStaffDialog, setShowEditStaffDialog] = useState(false);
  const [showRemoveStaffDialog, setShowRemoveStaffDialog] = useState(false);
  const [loading, setLoading] = useState(false);


  const loadStaffList = async () => {
    // request get user info
    const ckc = {
      txTypeId: TxTypes.PARTNER_ADMIN_STAFF_LIST,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setStaffList(response.staffs);
        console.log("Staff list loaded.");
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
      }
      
    } catch (error) {
      console.log("Error... " + error);
    }

    setLoading(false);
  }

  const editStaffDialog = (staff) => {
    setStaffObj(staff);
    setShowEditStaffDialog(true);
  }

  const deleteStaffDialog = (staff) => {
    setStaffObj(staff);
    setShowRemoveStaffDialog(true);
  }


  const openCreatePartnerDialog = () => {
    setShowCreatePartnerDialog(true);
  }

  const closeCreatePartnerDialog = () => {
    setShowCreatePartnerDialog(false);
    props.refreshCallback();      
  }

  const openAddStaffDialog = () => {
    setStaffObj({})
    setShowEditStaffDialog(true);
  }

  const closeEditStaffDialog = () => {
    setShowEditStaffDialog(false);
    // props.refreshCallback();      
    loadStaffList();
  }

  const closeRemoveStaffDialog = () => {
    setShowRemoveStaffDialog(false);
    loadStaffList();
  }


  useEffect(() => {
    if(User.hasActivePartner(userObj)) {
      loadStaffList();
    }
  }, [userObj.partnerId]);


  // Get staff actions
  const getStaffActions = (staff) => {
    if(activeRole.roleId === Roles.ADMIN) {
      return (
        <div>
          <Tooltip title="Sửa" aria-label="Setting">
            <span>
            <IconButton size="small" onClick={() => editStaffDialog(staff)}>
              <EditOutlinedIcon/>
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Xoá" aria-label="Setting">
            <span>
            <IconButton size="small" onClick={() => deleteStaffDialog(staff)} 
                disabled={!(staff.statusId === TxStatus.CANCEL || staff.statusId === TxStatus.WAITING)}>
              <DeleteOutlinedIcon/>
            </IconButton>
            </span>
          </Tooltip>
        </div>
      );
    } else if(activeRole.roleId === Roles.MANAGER && staff.roleId === Roles.STAFF) {
      return (
        <div>
          <Tooltip title="Sửa" aria-label="Setting">
            <span>
            <IconButton size="small" onClick={() => editStaffDialog(staff)}>
              <EditOutlinedIcon/>
            </IconButton>
            </span>
          </Tooltip>
        </div>
      );
    } else {
      return <div></div>
    }

  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>

        {
          /* 
          Welcome message?
          */

        }

        {
          !User.hasActivePartner(userObj) && !Config.isStandalone() &&
          <Grid justifyContent="center" alignItems="center" container spacing={1} style={{marginBottom: 20}}>
            <Grid item xs={12}>
              <Box display="inline" alignItems="center">
                <Typography align="center" variant="h5" component="h1" style={{color: Colors.DARK_GREEN}}>
                  Chào mừng bạn đến với Chuyenkhoan.com
                </Typography>
              </Box>
              <Box display="inline" alignItems="center">
                <Typography align="center" variant="body1" component="h1" style={{padding: 20}}>
                  Mời bạn tạo tài khoản tổ chức mới, hoặc yêu cầu quản trị doanh nghiệp, tổ chức mình gửi lời mời tham gia.
                </Typography>
              </Box>
            </Grid>
            <Grid item >
              <Box display="inline" alignItems="center" style={{margin: 'auto'}}>
                <Button type="submit" variant="contained" color="primary" style={{fontWeight: 'bold'}}
                      disabled={loading}
                      onClick={() => openCreatePartnerDialog()}>TẠO TÀI KHOẢN TỔ CHỨC</Button>
              </Box>
            </Grid>
          </Grid>
        }
        
        {
          User.hasActivePartner(userObj) &&
          <div>
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item className={classes.transHead}>
              <Box display="inline" alignItems="flex-end">
                <span className={classes.title}>DANH SÁCH THÀNH VIÊN của</span>
                <span className={classes.name}>{partnerObj.name}</span>
              </Box>
            </Grid>
            <Grid item>
            </Grid>

            <Grid item>
              <Box display="inline">            
                <Button disabled={(activeRole.roleId !== Roles.ADMIN)} color="primary" size="small" className={classes.actionButton} onClick={() => openAddStaffDialog()}>Thêm thành viên</Button>
                {
                  Config.isSuperAdmin(userObj.phone) && !Config.isStandalone() &&
                  <Button color="primary" size="small" className={classes.actionButton} onClick={() => openCreatePartnerDialog()}>Thêm tổ chức</Button>
                }  
              </Box>
            </Grid>        
          </Grid>

      
          <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="modem usb table">
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.colNo}>STT</TableCell>
                    <TableCell className={classes.colStatus}>Họ tên</TableCell>
                    <TableCell className={classes.colPhone}>SĐT</TableCell>
                    <TableCell className={classes.colMessage}>Vai trò</TableCell>
                    <TableCell className={classes.colMessage}>Trạng thái</TableCell>
                    <TableCell className={classes.colExpired}>Cập nhật</TableCell>
                    <TableCell className={classes.colActions}>Thao tác</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffList.map((staff, index) => (
                    <TableRow hover key={index} padding="none" className={classes.row}>
                      <TableCell className={classes.colNo}>{index + 1}</TableCell>
                      <TableCell className={(userObj.phone === staff.phone) ? classes.activeStaff : ""} >{staff.name}</TableCell>
                      <TableCell className={(userObj.phone === staff.phone) ? classes.activeStaff : ""} >{getDisplayPhone(staff.phone)}</TableCell>
                      <TableCell className={(userObj.phone === staff.phone) ? classes.activeStaff : ""} >
                        {
                          (staff.phone === partnerObj.phone) ? "Chủ sở hữu" : getRoleName(staff.roleId)
                        }
                      </TableCell>
                      <TableCell className={classes.colMessage} style={{color: getStatusColor(staff.statusId)}}>{getRoleStatus(staff.statusId)}</TableCell>
                      <TableCell className={classes.colMessage} >{getTxTimeText(staff.updated)}</TableCell>
                      <TableCell className={classes.colActions}>{getStaffActions(staff)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>          
          </div>
        }
        

            {
              showEditStaffDialog &&
              <EditStaffRoleDialog open={showEditStaffDialog} closeCallback={closeEditStaffDialog} 
                staffObj={staffObj} 
                userObj={userObj}
                partnerObj={partnerObj}/>
            }
            {
              showRemoveStaffDialog &&
              <RemoveRoleDialog open={showRemoveStaffDialog} closeCallback={closeRemoveStaffDialog} 
                staffObj={staffObj} 
                userObj={userObj}
                partnerObj={partnerObj}/>
            }
            {
              showCreatePartnerDialog &&
              <AddPartnerDialog open={showCreatePartnerDialog} closeCallback={closeCreatePartnerDialog} 
                userObj={userObj}
                partnerObj={partnerObj}/>
            }

        </ThemeProvider>
    </StyledEngineProvider>
  ); 
}


const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.LIGHT_BLUE,
      contrastText: "#fff" //button text white instead of black
    },
    action: {
      // disabledBackground: 'set color of background here',
    },
    text: {
    }
  }
}));



const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#37517f",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  title: {
    color: Colors.DARK_BLUE,
    paddingLeft: 2,
  },
  name: {
    color: Colors.DARK_GREEN,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  toolbar_height: {
    height: "30px",
  },
  transHead: {
    minHeight: 30,
    padding: "3px 3px",
  },

  actionButton: {
    color: Colors.CK_BUTTON,
    fontWeight: 'bold',
    marginLeft: 3,
  },

  table: {
    whiteSpace: "nowrap",
    marginTop: 4,
    marginBottom: 0,
  },

  row: {
    height: 30,
    maxHeight: 36,
    fontSize: 12,
  },

  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },

  activeStaff: {
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
  },

  
}));
