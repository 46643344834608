
import { ckTableStyles } from '../../../ckcore/ui/CkStyles';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { formatAmount, getPaymentStatusColor, getPaymentStatusText, getStatusColor, getTxStatusText, getTxTimeVN } from '../../../ckcore/core/TxUtils';
import Colors from '../../../ckcore/core/Colors';

export default function MultipleOrdersMatchingTable(props) {
  const tableStyles = ckTableStyles();
  const bankTxObj = props.bankTxObj;
  const orderList = props.orderList;
  const cellBorder = { borderLeft: 1, borderColor: 'grey.300' };

  var orderIdList = [];
  var orderTotal = 0;

  orderList.map((orderObj) => {

    orderIdList.push(orderObj.txnRef);
    orderTotal = orderTotal + orderObj.amount;

  });


  return (
    <TableContainer className={tableStyles.tableContainer}>

      <Table className={tableStyles.table} size="small" aria-label="trans list table" sx={{ borderTop: 1, borderRight: 1, borderColor: 'grey.300' }}>
        <TableBody>

          <TableRow className={tableStyles.row} style={{ height: 32 }}>
            <TableCell colSpan={2} width="50%" align="center" className={tableStyles.colHeader} sx={cellBorder}> ĐƠN HÀNG </TableCell>
            <TableCell colSpan={2} width="50%" align="center" className={tableStyles.colHeader} sx={cellBorder}> GIAO DỊCH CHUYỂN KHOẢN </TableCell>
          </TableRow>


          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Mã đơn hàng:</TableCell>
            <TableCell className={tableStyles.colHeader}><strong>{orderIdList.join(" ")}</strong></TableCell>

            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Mã thanh toán:</TableCell>
            <TableCell className={tableStyles.colHeader}><strong>{bankTxObj.txId}</strong></TableCell>
          </TableRow>

          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" width="20%" className={tableStyles.colHeader} sx={cellBorder}>
              <strong>Trạng thái:</strong>
            </TableCell>
            <TableCell align="left" width="30%" className={tableStyles.colHeader}>
              
            </TableCell>

            <TableCell align="left" width="20%" className={tableStyles.colHeader} sx={cellBorder}>
              <strong>Trạng thái:</strong>
            </TableCell>
            <TableCell align="left" width="30%" className={tableStyles.colHeader}>
              <span style={{ color: getPaymentStatusColor(bankTxObj.paymentStatus) }}> {getPaymentStatusText(bankTxObj.paymentStatus)}</span>
            </TableCell>

          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Tổng cộng</TableCell>
            <TableCell className={tableStyles.colHeader}><strong>{formatAmount(orderTotal)}</strong></TableCell>

            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Số tiền nhận</TableCell>
            <TableCell className={tableStyles.colHeader}><strong>{formatAmount(bankTxObj.amount)}</strong></TableCell>
          </TableRow>

          <TableRow className={tableStyles.row} style={{ height: 32 }}>

            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Thời gian lập đơn:</TableCell>
            <TableCell className={tableStyles.colHeader}></TableCell>

            <TableCell align="left" className={tableStyles.colHeaderStrong} sx={cellBorder}>Thời gian thanh toán:</TableCell>
            <TableCell className={tableStyles.colHeader}>{getTxTimeVN(bankTxObj.created)}</TableCell>
          </TableRow>
          <TableRow className={tableStyles.row} style={{ height: 56 }}>
            <TableCell colSpan={2} align="left" className={tableStyles.colContent} sx={cellBorder}>
                         
            </TableCell>
            <TableCell colSpan={2} align="left" className={tableStyles.colContent} sx={cellBorder}>
              Nội dung chuyển khoản: <strong>{bankTxObj.content}</strong>
            </TableCell>
          </TableRow>
          {
            (orderTotal > 0 && orderTotal !== bankTxObj.amount) &&
            <TableRow className={tableStyles.row} style={{ height: 40 }}>
              <TableCell colSpan={4} align="left" className={tableStyles.colHeader} sx={cellBorder}>
                <Box style={{ color: Colors.RED }}>Lưu ý: số tiền nhận được và số tiền cần thanh toán không bằng nhau</Box>
              </TableCell>
            </TableRow>
          }

        </TableBody>
      </Table>
    </TableContainer>

  );
}