import React from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, Button, Box, TextField } from '@mui/material';
import Colors from '../../../ckcore/core/Colors';

/**
 * Get webhook card view
 */
export default function WebhookCard(props) {

  let webhook = props.webhook;
  let webhookObj = props.webhookObj;
  return (
    <Card sx={{ maxWidth: 333 }}>
      <CardActionArea onClick={() => props.actionCallback(webhook)}>
        <CardMedia
          component="img"
          height="101"
          image={"https://chuyenkhoan.com/images/partners/" + webhook + ".png"}
          alt={webhookObj.name}
        />
        <CardContent>
          <Typography variant="h6" component="div" style={{ textAlign: "left" }}>
            {webhookObj.name}
          </Typography>         
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

/**
 * Get webhook item config LABEL: VALUE INPUT
 */
export function WebhookItem(props) {
  let item = props.item;
  let apiObj = props.apiObj;

  const handleChangeValue = ()  => event =>  {
    const value = event.target.value;

    // callback to update value
  }

  return (
    <Box key={item} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
      <Box style={{ textAlign: "right", width: 300 }}>
        {apiObj[item + "_label"]}:
      </Box>
      <Box style={{ textAlign: "left", width: 480, marginRight: 16 }}>
        <TextField
          type="text"
          inputProps={{ min: 0, style: { textAlign: 'left' } }}
          fullWidth
          size="small"
          variant="outlined"
          defaultValue={apiObj[item]}
          margin="dense"
          label={apiObj[item + "_guide"]}
          onChange={handleChangeValue}
        />
      </Box>
    </Box>
  );
}
