import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Colors from '../../ckcore/core/Colors';
import { Grid, Paper } from '@mui/material';
import { Link } from '@mui/material';


export default function StepTwo(props) {
  const classes = useStyles();

  return (

    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>

        <div>
          <Typography variant="subtitle1">
            <strong>Bước 2: </strong>
            <span>Thêm tài khoản ngân hàng nhận tiền</span>
          </Typography>
          <p>
            <span>Chuyenkhoan.com hỗ trợ cả tài khoản Công ty và tài khoản Cá nhân của hơn 15 ngân hàng top đầu Việt Nam. </span>
            <span>Tổ chức của bạn có thể dùng những tài khoản sẵn có hoặc đăng ký mới để phục vụ khách hàng thanh toán.</span>
          </p>
          <p>
            <span>Chuyenkhoan.com xác nhận giao dịch dựa vào Biến động số dư (BĐSD) tài khoản ngân hàng bạn đăng ký, và gửi về qua SMS. </span>
            <span>Bạn nên lựa chọn những ngân hàng hỗ trợ gửi tin nhắn BĐSD nhanh, ổn định, và đầy đủ (như Vietcombank).</span>
          </p>
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <p>
          <iframe
            width="480"
            height="270"
            src="https://www.youtube.com/embed/4KogT5i7Guk"
            title="Chuyenkhoan.com - Thêm tài khoản ngân hàng"
            allowfullscreen
          ></iframe>
        </p>
      </Grid>
    </Grid>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#FFFBE6',
    marginBottom: 20
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guide: {
    paddingLeft: 40,
    paddingBottom: 20,
  },
  link: {
    cursor: 'pointer',
    color: Colors.DARK_BLUE,
    fontWeight: 'bold'
  }
}));
