import { adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';

import { getBankSvgIcon } from '../../ckcore/core/CkBank'
import { getBankInfo, getBankAccount } from '../../ckcore/core/CkBank';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import { isValidAccountNumber, isValidNameUppercaseAscii } from '../../ckcore/core/Validator';
import Colors from '../../ckcore/core/Colors';

import { ckTheme } from '../../ckcore/ui/CkStyles';


export default function EditBankAccountDialog(props) {

  const userObj = props.userObj;
  const bankAccounts = props.bankAccounts;
  const selectedBankObj = (props.bankObj.accountNumber) ? props.bankObj : {
    bankName: 'Vietcombank',
    accountNumber: '',
    accountName: '',
    accountBranch: '',
    notifier: '0946861101',
    verified: false,
    allowUpdateBalance: true,
    allowUpdateValue: true,
    allowDelete: false,
    updated: new Date().getTime(),
  };
  const isUpdate = (selectedBankObj.accountNumber.length > 0);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [bankObj, setBankObj] = useState(selectedBankObj);

  const handleSelect = (name, value) => {
    setBankObj({ ...bankObj, [name]: value });
    setLoading(false);
    setEditing(true);
  };

  const handleChange = name => event => {

    var val = event.target.value;

    if (name === 'accountName') {
      val = val.toUpperCase();
    } else if(name === 'accountNumber') {
      val = val.replace(/ /g, "");
    }

    setBankObj({ ...bankObj, [name]: val });
    setLoading(false);
    setEditing(true);
  };

  const handleCheck = (name, value) => {
    setBankObj({ ...bankObj, [name]: value });
    setLoading(false);
    setEditing(true);
  };


  // User click agree
  const handleEditBank = () => {

    setSuccessMessage("");

    // get bank info
    const bankInfo = getBankInfo(Config.getAvailableBanks(), bankObj.bankName);
    if (!(bankInfo)) {
      setErrorMessage("Tên ngân hàng không hợp lệ: " + bankObj.bankName);
      return;
    }

    // validate account
    if (!isValidAccountNumber(bankObj.accountNumber, bankInfo.accountRegex)) {
      setErrorMessage("Số tài khoản không hợp lệ: " + bankObj.accountNumber);
      return;
    }

    // validate name
    if (!isValidNameUppercaseAscii(bankObj.accountName)) {
      setErrorMessage("Tên tài khoản không hợp lệ (cần viết hoa không dấu)");
      return;
    }

    // check duplicated
    if (!isUpdate && getBankAccount(bankAccounts, bankObj.accountNumber)) {
      setErrorMessage("Số tài khoản đã tồn tại");
      return;
    }

    // do update
    processUpdateBank();
  };

  // Update BANK ACCOUNT LIST
  const processUpdateBank = async () => {

    // refresh message
    const cka = {
      txTypeId: TxTypes.PARTNER_ADMIN_BANK_UPDATE,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      bankAccount: bankObj,
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setErrorMessage("");
      setLoading(true);
      setEditing(false);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        if (bankObj.allowDelete) {
          setSuccessMessage("Xoá tài khoản ngân hàng thành công");

          // clean bank
          setBankObj({
            bankName: '',
            accountNumber: '',
            accountName: '',
            accountBranch: '',
            verified: false,
            allowUpdateBalance: false,
            allowUpdateValue: false,
            allowDelete: true,
            isPrimary: false,
            updated: new Date().getTime(),
          });

        } else {

          setSuccessMessage(getEditText() + " tài khoản ngân hàng thành công");
        }

        // handle close
        setTimeout(() => {
          handleClose();
        }, 1800);

        // reset partner info
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMessage(getEditText() + " tài khoản bị lỗi: " + response.msg);
      }

    } catch (error) {
      console.log("Error... " + error);
      setErrorMessage(getEditText() + " tài khoản bị lỗi, vui lòng thử lại sau");
    }

    setLoading(false);
  };

  const loadPhoneNotifiers = () => {
  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const getEditText = () => {
    if (isUpdate) {
      return "Cập nhật";
    } else {
      return "Thêm"
    }
  }

  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={ckTheme}>
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{getEditText()} tài khoản ngân hàng</DialogTitle>
          <DialogContent dividers>

            {
              (bankAccounts.length === 0) &&
              <Box sx={{p: 2, color: Colors.DARK_BLUE}}>
                <span>Mời bạn nhập ít nhất một tài khoản ngân hàng để Chuyenkhoan.com có thể bắt đầu phục vụ.</span>
              </Box>
            }

            <Box p={1}>
              {getMessageBox(errorMessage, successMessage, isUpdate ? "" : "")}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Autocomplete id="select-bank" disableClearable
                  options={Config.getAvailableBanks()}
                  getOptionLabel={(bank) => bank.name}
                  isOptionEqualToValue={(option, value) => option.name.toUpperCase() === bankObj.bankName.toUpperCase()}
                  value={{ name: bankObj.bankName }}
                  onChange={(event, bank) => handleSelect('bankName', (bank) ? bank.name : "")}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" margin="dense" fullWidth
                      label="Tên ngân hàng"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton size="small" >
                              <SvgIcon component={getBankSvgIcon(bankObj.bankName)} viewBox="0 0 48 48" />
                            </IconButton>
                          </InputAdornment>
                        )
                      }} />
                  )}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField id="ck-bank-account-number" fullWidth margin="dense" type="text" variant="outlined"
                  label="Số tài khoản"
                  value={bankObj.accountNumber}
                  onChange={handleChange('accountNumber')}
                  inputProps={{
                    maxLength: 15,
                  }}
                  disabled={isUpdate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField id="ck-bank-account-name" fullWidth margin="dense" type="text" variant="outlined"
                  label="Tên tài khoản (tiếng Việt không dấu)"
                  value={bankObj.accountName}
                  onChange={handleChange('accountName')}
                  disabled={isUpdate && bankObj.verified && !Config.isSuperAdmin(userObj.phone)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField id="ck-bank-account-notifier" fullWidth margin="dense" type="text" variant="outlined"
                  label="Số điện thoại nhận Biến động số dư"
                  value={bankObj.notifier}
                  disabled={true}
                />
              </Grid>
              {
                (props.bankObj.accountNumber) &&
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bankObj.verified}
                        onChange={e => handleCheck("verified", e.target.checked)}
                        name="verified"
                        color="primary"
                      />
                    }
                    label={bankObj.verified ? "Tài khoản đã được xác nhận" : "Tài khoản CHƯA được xác nhận"}
                    disabled={true}
                  />
                </Grid>
              }


              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bankObj.allowUpdateBalance}
                      onChange={e => handleCheck("allowUpdateBalance", e.target.checked)}
                      name="allowUpdateBalance"
                      color="primary"
                    />
                  }
                  label="Cho phép cập nhật số dư tài khoản"
                />
              </Grid>

              {
                isUpdate &&
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bankObj.allowDelete}
                        onChange={e => handleCheck("allowDelete", e.target.checked)}
                        name="allowDelete"
                        color="primary"
                      />
                    }
                    label="Yêu cầu xoá tài khoản"
                  />
                </Grid>
              }


            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>
            {
              isUpdate && bankObj.allowDelete &&
              <Button disabled={loading || !editing} onClick={handleEditBank} variant="outlined" color="primary">
                Xoá tài khoản
              </Button>
            }
            {
              isUpdate && !bankObj.allowDelete &&
              <Button disabled={loading || !editing} onClick={handleEditBank} variant="outlined" color="primary">
                Cập nhật tài khoản
              </Button>
            }
            {
              !isUpdate &&
              <Button disabled={loading || !editing} onClick={handleEditBank} variant="outlined" color="primary">
                Thêm tài khoản
              </Button>
            }
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

