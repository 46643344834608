import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import PartnerList from '../partner/PartnerList';
import StaffList from '../partner/StaffList';
import { Button, Container, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import Colors from '../../ckcore/core/Colors';
import Roles from '../../ckcore/core/Roles';
import User from '../../ckcore/core/User';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import PricingDialog from '../subscription/PricingDialog';
import TopupCkCoin from '../subscription/TopupCkCoin';


export default function Members(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const activeRole = User.getActiveRole(userObj);

  const action = props.action;
  const refreshTime = props.refreshTime;

  const [showPricingDialog, setShowPricingDialog] = useState(false);
  const requestPricingDialog = () => {
    setShowPricingDialog(true);
  }
  const closePricingDialog = () => {
    setShowPricingDialog(false);
    props.refreshCallback('MEMBERS', '');
  }

  const [showTopupCkCoinDialog, setShowTopupCkCoinDialog] = useState(false);

  const requestTopupCkCoin = () => {
    setShowTopupCkCoinDialog(true);
  }

  const closeTopupCkCoinDialog = () => {
    setShowTopupCkCoinDialog(false);
    props.refreshCallback('MEMBERS', '');
  }


  const [partnerConfig, setPartnerConfig] = useState({
    name: partnerObj.name,
    phone: " ",
    tin: " ",
    email: " ",
    tel: " ",
    website: " ",
    address: " ",
  });

  // load forward config
  const loadAppConfig = async () => {

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: "SETTING",
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {
      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setPartnerConfig({
          name: partnerObj.name,
          phone: partnerObj.phone,
          tin: response.appConfig.tin.value,
          email: response.appConfig.email.value,
          tel: response.appConfig.tel.value,
          website: response.appConfig.website.value,
          address: response.appConfig.address.value,
        });

      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // load config on startup
  useEffect(() => {

    loadAppConfig();

  }, [refreshTime]);

  useEffect(() => {
    if (action === 'upgrade') {
      requestPricingDialog();
    } else if (action === 'ckcoin') {
      requestTopupCkCoin();
    }

  }, [action]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Grid container spacing={3}>

          {
            (partnerObj.partnerId > 0) &&
            <Grid item xs={12}>
              <Box style={{ textAlign: "center", fontSize: 22, fontWeight: "bold", color: Colors.DARK_BLUE }}>{partnerObj.name}</Box>
              <Box style={{ textAlign: "center", fontSize: 16, fontWeight: "bold", paddingTop: 8 }}>Mã đối tác: {partnerObj.partnerId}</Box>
            </Grid>
          }


          {
            showPricingDialog &&
            <PricingDialog open={showPricingDialog} closeCallback={closePricingDialog}
              userObj={props.userObj}
              partnerObj={props.partnerObj}
              logoutCallback={props.logoutCallback}
              refreshCallback={props.refreshCallback} />
          }
          {
            showTopupCkCoinDialog &&
            <TopupCkCoin open={showTopupCkCoinDialog} closeCallback={closeTopupCkCoinDialog}
              userObj={props.userObj}
              partnerObj={props.partnerObj}
              logoutCallback={props.logoutCallback}
              refreshCallback={props.refreshCallback} />
          }

          {
            (partnerObj.partnerId > 0) &&
            <Grid item xs={12}>
              <Container maxWidth="md">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField autoFocus size="small" margin="dense" id="incOwner" fullWidth
                      label="SĐT Admin"
                      type="text"
                      value={getDisplayPhone(partnerObj.phone)}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField size="small" margin="dense" id="incId" fullWidth
                      label="Mã số thuế"
                      type="text"
                      value={partnerConfig.tin}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField size="small" margin="dense" id="address" fullWidth
                      label="Địa chỉ kinh doanh"
                      type="text"
                      value={partnerConfig.address}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField size="small" margin="dense" id="domain" fullWidth
                      label="Website"
                      type="text"
                      value={partnerConfig.website}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField size="small" margin="dense" id="hotline" fullWidth
                      label="Hotline"
                      type="text"
                      value={partnerConfig.tel}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField autoFocus size="small" margin="dense" id="incOwner" fullWidth
                      label="Email hỗ trợ"
                      type="text"
                      value={partnerConfig.email}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button fullWidth disabled={(activeRole.roleId !== Roles.ADMIN)} style={{ marginTop: 8, color: Colors.CK_BUTTON }} variant="outlined" onClick={() => props.refreshCallback("SETTING")}>Thay đổi</Button>
                  </Grid>

                </Grid>
              </Container>
            </Grid>
          }


          <Grid item xs={12}>

            <StaffList
              userObj={props.userObj}
              partnerObj={props.partnerObj}
              logoutCallback={props.logoutCallback}
              refreshCallback={props.refreshCallback} />
          </Grid>

          {
            !Config.isStandalone() &&
            <Grid item xs={12} style={{ paddingTop: 16 }}>

              <PartnerList
                userObj={props.userObj}
                partnerObj={props.partnerObj}
                logoutCallback={props.logoutCallback}
                refreshCallback={props.refreshCallback} />
            </Grid>
          }


          <Grid item xs={12}>

          </Grid>

        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>

  );
}



const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});
