import React, { useState, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from '@mui/material/Button';

import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import Roles, {getRoleName, getRoleStatus } from '../../ckcore/core/Roles';
import {getStatusColor, getTxTimeText} from '../../ckcore/core/TxUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import AcceptRoleDialog from './AcceptRoleDialog';

/**
 * Sort roles by ID
 */
function compare( roleA, roleB ) {
  if ( roleA.partnerId < roleB.partnerId ){
    return -1;
  }
  if ( roleA.partnerId > roleB.partnerId ){
    return 1;
  }
  return 0;
}

export default function PartnerList(props) {
  const classes = useStyle();

  const userObj = props.userObj;
  const userRoles = userObj.roles;
  userRoles.sort(compare);

  const [partners, setPartners] = useState({});
  const [loading, setLoading] = useState(false);

  const [acceptDialog, setAcceptDialog] = useState({show: false, role: {}, partner: {}});

  const requestAcceptDialog = (role, partner) => {
    setAcceptDialog({show: true, role: role, partner: partner});
  }
  const closeAcceptDialog = () => {
    setAcceptDialog({...acceptDialog, show: false});
  }

  const addNewPartner = () => {

  }

  /**
   * Load partner request list
   */
  const loadPartnerList = async () => {

    if(loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const ckc = {
      txTypeId: TxTypes.TX_USER_LIST_PARTNER,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setPartners(response.partners);


        // check has waiting role
        userRoles.map((role, index) => {
          if(role.statusId === TxStatus.WAITING) {
            let partner =  response.partners[role.partnerId.toString()];
            requestAcceptDialog(role, partner);
          }
        });

        console.log("Partner list loaded");
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Load partner list error with statuses: " + response.statusId);
      }
      
    } catch (error) {
      console.log("Error load partner list: " + error);
    }

    setLoading(false);
  }

  /**
   * User accept role
   */
  const acceptRole = async (role) => {
    if(loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const ckc = {
      txTypeId: TxTypes.TX_USER_ACCEPT_ROLE,
      role: role,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        // update partner
        setPartners(response.partners);

        // request refresh all page
        // window.location.reload();
        activeRole(role);

        // props.refreshCallback("HOME");      
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Accept role error with statuses: " + response.statusId);
      }
      
    } catch (error) {
      console.log("Error accept role: " + error);
    }

    setLoading(false);
  }

  /**
   * User ACTIVE role
   */
   const activeRole = async (role) => {
    if(loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const ckc = {
      txTypeId: TxTypes.TX_USER_ACTIVE_ROLE,
      role: role,
      partnerId: role.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "account=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        // update partner
        setPartners(response.partners);

        console.log("Set active partnerId: " + role.partnerId);
        props.refreshCallback();      
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Active role error with statuses: " + response.statusId);
      }
      
    } catch (error) {
      console.log("Error active role: " + error);
    }

    setLoading(false);
  }

  const denyRole = (role) => {
    
  }

  // Get staff actions
  const getStaffActions = (role) => {
    if(role.statusId === TxStatus.WAITING) {
      return (      
        <div>
          <Tooltip title="Đồng ý" aria-label="Agree"><span>
            <IconButton size="small" onClick={() => acceptRole(role)} style={{color: Colors.DARK_GREEN}}>
              <CheckOutlinedIcon/>
            </IconButton>
            </span></Tooltip>
          <Tooltip title="Bỏ qua" aria-label="Ignore"><span>
            <IconButton size="small" onClick={() => denyRole(role)} style={{color: Colors.RED}} >
              <ClearOutlinedIcon/>
            </IconButton>
            </span></Tooltip>
        </div>
      );
    } else if(role.statusId === TxStatus.SUCCESS && role.partnerId !== userObj.partnerId) {
      return (      
        <div>
          <Tooltip title="Chọn" aria-label="Active"><span>
            <IconButton size="small" onClick={() => activeRole(role)}>
              <CheckOutlinedIcon/>
            </IconButton>
            </span></Tooltip>
        </div>
      );
    } else if(role.statusId === TxStatus.SUCCESS && role.partnerId === userObj.partnerId) {
      return (      
        <div>
          <Tooltip title="Đang chọn" aria-label="Active"><span>
            <IconButton size="small" disabled={true} onClick={() => activeRole(role)} style={{color: Colors.DARK_GREEN}}>
              <DoneAllOutlinedIcon/>
            </IconButton>
          </span></Tooltip>
          {
            (role.roleId === Roles.ADMIN) &&
            <Tooltip title="Cài đặt" aria-label="Active"><span>
            <IconButton size="small" onClick={() => props.refreshCallback("SETTING")}>
              <SettingsOutlinedIcon/>
            </IconButton>
          </span></Tooltip>
          }
        </div>
      );

      //
    } else {
      return (
        <div></div>
      );
    }

  }

  const getPartnerName = (partnerId) => {

    let partnerObj = partners[partnerId.toString()];
    if(partnerObj) {
      return partnerObj.name;
    }
    return "";
  }

  const getPartnerWebsite = (partnerId) => {

    let partnerObj = partners[partnerId.toString()];
    if(partnerObj) {
      return partnerObj.website;
    }
    return "";
  }

  const getPartnerLogo = (partnerId) => {

    let partnerObj = partners[partnerId.toString()];
    if(partnerObj) {
      return partnerObj.logo;
    }
    return "";
  }

  useEffect(() => {
    loadPartnerList();
  }, []);


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>     

        <Grid justifyContent="space-between" alignItems="flex-end" container style={{marginTop: 20}}>
          <Grid item className={classes.transHead}>
            <Box display="inline" alignItems="flex-end">
              <span className={classes.title}>DANH SÁCH TỔ CHỨC của</span>
              <span className={classes.name}>{userObj.name}</span>
            </Box>
          </Grid>
          <Grid item>
          </Grid>

          <Grid item>
            <Box display="inline">            
              <Button color="primary" size="small" className={classes.modemButton} onClick={() => loadPartnerList({})}>Duyệt lại</Button>                   
            </Box>
            
          </Grid>        
        </Grid>

      
        <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="modem usb table">
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.colNo}>STT</TableCell>
                    <TableCell className={classes.colNo}>ID</TableCell>
                    <TableCell className={classes.colStatus}>Tên tổ chức</TableCell>
                    <TableCell className={classes.colPhone}>Website</TableCell>
                    <TableCell className={classes.colMessage}>Vai trò của bạn</TableCell>
                    <TableCell className={classes.colMessage}>Trạng thái</TableCell>
                    <TableCell className={classes.colExpired}>Cập nhật</TableCell>
                    <TableCell className={classes.colActions}>Thao tác</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userObj.roles.map((role, index) => (
                    <TableRow hover key={index} padding="none" className={classes.row}>
                      <TableCell className={classes.colNo}>{index + 1}</TableCell>
                      <TableCell className={classes.colNo}>{role.partnerId}</TableCell>
                      <TableCell className={classes.partnerName} >
                        {
                          (getPartnerLogo(role.partnerId)) &&
                          <img className={classes.partnerLogo} src={getPartnerLogo(role.partnerId)} alt={getPartnerName(role.partnerId)}/>
                        }
                        <span className={role.partnerId === userObj.partnerId ? classes.activePartner : ""}>{getPartnerName(role.partnerId)}</span>
                      </TableCell>
                      <TableCell className={classes.colPhone} >{getPartnerWebsite(role.partnerId)}</TableCell>
                      <TableCell className={role.partnerId === userObj.partnerId ? classes.activePartner : ""} >{getRoleName(role.roleId)}</TableCell>
                      <TableCell className={classes.colMessage} style={{color: getStatusColor(role.statusId)}} >{getRoleStatus(role.statusId)}</TableCell>
                      <TableCell className={classes.colMessage} >{getTxTimeText(role.updated)}</TableCell>
                      <TableCell className={classes.colActions}>{getStaffActions(role)}</TableCell>
                    </TableRow>
                  ))}

                  {
                    (userObj.roles.length === 0) &&
                    <TableRow key={1} padding="none" className={classes.row}>
                      <TableCell colSpan={7} align="center">
                        <span style={{padding: 1, color: Colors.DARK_BLUE}}>Bạn chưa có lời mời từ tổ chức nào.</span>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>

            {
              acceptDialog.show &&
              <AcceptRoleDialog open={acceptDialog.show} closeCallback={closeAcceptDialog} 
                userObj={userObj}
                role={acceptDialog.role}
                partner={acceptDialog.partner} 
                activeRole={activeRole} />
            }

        </ThemeProvider>
    </StyledEngineProvider>
  ); 
}


const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
}));



const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#37517f",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  title: {
    color: Colors.DARK_BLUE,
    paddingLeft: 2,
  },
  name: {
    color: Colors.DARK_GREEN,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  toolbar_height: {
    height: "30px",
  },
  transHead: {
    minHeight: 30,
    padding: "3px 3px",
  },

  table: {
    whiteSpace: "nowrap",
    marginTop: 4,
    marginBottom: 0,
  },

  row: {
    height: 30,
    maxHeight: 36,
    fontSize: 12,
  },


  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },
  partnerName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    padding: 8,
  },

  activePartner: {
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
  },  
  
  partnerLogo: {
    width: 18,
    height: 18,
    marginRight: 8,
  },
}));
