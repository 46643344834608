import React from 'react';
import './ckpayment.css';
import ck_logo from '../images/ck_icon_128.png';
import ValUtils from '../../ckcore/core/ValUtils';

export default function Header(props) {

  const order = props.order;
  const bankInfo = props.bankInfo;
  const partnerDomain = ValUtils.getDomain(order.return_url, true);

  return (

    <header className="Header" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
      <div className="Header-Content flex-container justify-content-space-between align-items-stretch">
        <div className="Header-business flex-item width-grow flex-container align-items-center">
          
          <a className="Link Header-businessLink Link--primary" 
              onClick={(e) => { e.preventDefault(); props.cancelOrder();}} 
              aria-label="Previous page" title={partnerDomain} target="_self">

            <div style={{ position: 'relative' }}>
              <div className="flex-container align-items-center">
                <div className="Header-backArrowContainer" style={{ opacity: 1, transform: 'none' }}>
                  <svg className="InlineSVG Icon Header-backArrow mr2 Icon--sm" focusable="false" width="12" height="12" viewBox="0 0 16 16">
                    <path d="M3.417 7H15a1 1 0 0 1 0 2H3.417l4.591 4.591a1 1 0 0 1-1.415 1.416l-6.3-6.3a1 1 0 0 1 0-1.414l6.3-6.3A1 1 0 0 1 8.008 2.41z" fillRule="evenodd"></path>
                  </svg>
                </div>
                <div className="Header-merchantLogoContainer" style={{ transform: 'none' }}>
                  <div className="Header-merchantLogoWithLabel flex-item width-grow">
                    <div
                      className="HeaderImage HeaderImage--icon flex-item width-fixed flex-container justify-content-center align-items-center width-fixed">
                      <img className="HeaderImage-imageIcon" alt={partnerDomain}
                        src={(order.partner_logo) ? order.partner_logo : ck_logo} />
                    </div>

                    <span className="Header-businessLink-label Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500">
                      Trở về</span>

                    <h1 className="Header-businessLink-name Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500 Text--truncate">
                      {order.partner_name}</h1>
                  </div>
                </div>
              </div>
            </div>
          </a>

          {
            !(bankInfo.verified) &&
            <div className="mx2 flex-item width-fixed">
            <div className="Tag Header-testTag Tag-orange">              
              <span className="Text Text-color--orange Text-fontSize--11 Text-fontWeight--700 Text-transform--uppercase">
                CHỜ XÁC NHẬN</span>
            </div>
            <div className="Tag Header-testTagMobile Tag-orange">
              <span className="Text Text-color--orange Text-fontSize--11 Text-fontWeight--700 Text-transform--uppercase">CHỜ XÁC NHẬN</span>
            </div>
          </div>
          }
          {
            (bankInfo.verified) &&
            <div className="mx2 flex-item width-fixed">
            <div className="Tag Header-testTag Tag-green">              
              <span className="Text Text-color--green Text-fontSize--11 Text-fontWeight--700 Text-transform--uppercase">
                ĐÃ XÁC NHẬN</span>
            </div>
            <div className="Tag Header-testTagMobile Tag-green">
              <span className="Text Text-color--green Text-fontSize--11 Text-fontWeight--700 Text-transform--uppercase">ĐÃ XÁC NHẬN</span>
            </div>
          </div>
          }
        </div>
      </div>
    </header>

  );

}