import makeStyles from '@mui/styles/makeStyles';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState, useEffect } from 'react';
import { Box, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';

import { ckTheme } from '../../ckcore/ui/CkStyles';

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';

import { getBankInfo, getDefaultBanks } from '../../ckcore/core/CkBank';
import CKQRGen from '../../ckcore/core/CKQRGen';
import { getMessageBox } from '../../ckcore/core/TxUtils';
import BankTxDetailsTable from './ui/BankTxDetailsTable';
import PaymentStatus from '../../payment/components/PaymentStatus';

const bankTxStatuses = [
  {statusId: -1, statusName: '---'},
  {statusId: 22, statusName: 'Đã xử lý'},
  {statusId: 21, statusName: 'Bỏ qua'}
];

export default function BankTxDetailsDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;

  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [messages, setMessages] = useState({ info: '', success: '', error: '' });



  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const [bankTxObj, setBankTxObj] = useState(props.bankTxObj);

  // check for allowing change payment status
  const canChangeStatus = () => {

    let paymentStatus = props.bankTxObj.paymentStatus;

    if(paymentStatus === PaymentStatus.PAYMENT_ORDER_NOT_FOUND 
      || paymentStatus === PaymentStatus.PAYMENT_OVER_FUND
      || paymentStatus === PaymentStatus.PAYMENT_INSUFFICIENT_FUND) {
        return true;
    }

    return false;
  }

  const handleChangeStatus = (statusId) => {
    setBankTxObj({...bankTxObj, newStatusId: statusId});
    setChanged(true);
  };

  const getMaxLength = () => {
    const maxChangeLength = 100 - 3 - bankTxObj.content.length;
    if(maxChangeLength < 50) {
      return 50;
    } else {
      return maxChangeLength;
    }
  }


  const handleChangeDescription = (desc) => {
    setBankTxObj({...bankTxObj, description: desc});
    setChanged(true);
  };

  /**
   * Update bank status and description
   */
  const updateBankTx = async () => {

    try {

      if (loading || !changed) {
        return;
      }

      // description required
      if(bankTxObj.newStatusId > -1 && bankTxObj.description.trim() === '') {
        setMessages({ ...messages, error: 'Bạn cần thêm mô tả giao dịch khi muốn thay đổi trạng thái!' });
        return;
      }

      // update request
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_BANK_TX_UPDATE,
        partnerId: userObj.partnerId,
        txId: bankTxObj.txId,
        created: bankTxObj.created,
        accountNumber: bankTxObj.ben,
        paymentStatus: bankTxObj.newStatusId,
        desc: bankTxObj.description.trim(),
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(ckmsg)
      };
      setLoading(true);
      setMessages({ ...messages, success: '', error: '' });

      // fetch response
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/dashboard/bank?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {

        console.log("Update bank tx success");
        setBankTxObj(response.bankTx);

        setChanged(false);
        setMessages({ ...messages, success: 'Cập nhật giao dịch chuyển khoản thành công' });

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR update bank tx, msg: " + response.msg);
        setMessages({ ...messages, error: 'Cập nhật giao dịch bị lỗi' });
      }
    } catch (error) {
      console.log("Error update bank tx.. " + error);
    }

    setLoading(false);

  };


  useEffect(() => {
    // reload on refresh
    // loadOrderDetails();
  }, []);


  // Add bank dialog
  return (
    <ThemeProvider theme={ckTheme}>
      <Dialog open={props.open} fullWidth={true} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Grid justifyContent="space-between" alignItems="flex-end" container>
            <Grid item>
              <span>Giao dịch ngân hàng <strong>#{bankTxObj.txId}</strong></span>
            </Grid>
            <Grid item>
              <span>
                <Button className={classes.actionButton} disabled={loading} variant="outlined" color="primary" onClick={() => {props.searchingOrder(bankTxObj)}}>KHỚP ĐƠN</Button>
              </span>
              {/*
              <span>
                <Button className={classes.actionButton} disabled={loading} variant="outlined" color="primary" onClick={() => {props.searchingMultipleOrders(bankTxObj)}}>KHỚP NHIỀU ĐƠN</Button>
              </span>
              */}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box style={{ marginBottom: 12 }}>
            {
              getMessageBox(messages.error, messages.success, messages.info)
            }
          </Box>

          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Typography component="div" variant="body1" style={{ marginBottom: 3, fontWeight: 'bold' }}>Chi tiết giao dịch ngân hàng: </Typography>
              <BankTxDetailsTable bankTxObj={bankTxObj} />
            </Grid>

            <Grid item xs={4}>
              <Typography component="div" variant="body1" style={{ marginTop: 12, fontWeight: 'bold' }}>Đổi trạng thái giao dịch: </Typography>
              <TextField id="ck-bank-account" variant="outlined" margin="normal" size="small" fullWidth
                label={"Trạng thái giao dịch"}
                select
                value={((bankTxObj.newStatusId) || bankTxObj.newStatusId === 0) ? bankTxObj.newStatusId : -1}
                InputLabelProps={{ style: { fontSize: 16 } }}
                InputProps={{ readOnly: !canChangeStatus()}}
                onChange={(e) => handleChangeStatus(e.target.value)}>

                {bankTxStatuses.map((bankTxStatus, index) => (
                  <MenuItem key={bankTxStatus.statusId} value={bankTxStatus.statusId}>
                    <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3, fontWeight: 'bold' }}>{bankTxStatus.statusName}</Box>
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
            <Grid item xs={8}>
              <Typography component="div" variant="body1" style={{ marginTop: 12, fontWeight: 'bold' }}>Thêm mô tả giao dịch: </Typography>
              <TextField id="ck-order-info" variant="outlined" margin="normal" size="small" fullWidth
                label={"Mô tả giao dịch (khoảng " + getMaxLength() + " ký tự)"} 
                value={(bankTxObj.description) ? bankTxObj.description : ''}
                InputLabelProps={{ style: { fontSize: 16 } }}
                inputProps={{
                  min: 0, maxLength: getMaxLength(), style: {
                    textAlign: 'left', fontWeight: 'bold', height: 25,
                  }
                }}
                onChange={(e) => handleChangeDescription(e.target.value)}
                helperText="Mô tả giao dịch được thêm vào phía sau nội dung chuyển khoản trong ngoặc []"
              />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">Trở lại</Button>
          <Button className={classes.actionButton} disabled={!changed} variant="outlined" color="warning" onClick={() => updateBankTx()}>CẬP NHẬT</Button>

        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}


const useStyles = makeStyles(theme => ({
  root: {

  },

  actionButton: {
    marginLeft: 8,
  },
}));
