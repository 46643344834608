import React, { useState, useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import {compareAccount, getTxTimeText} from '../../ckcore/core/TxUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { useInterval } from '../../ckcore/core/CkUtils';

export default function UserListAll(props) {
  const classes = useStyle();

  const userObj = props.userObj;

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);


  /**
   * Load account request list
   */
  const loadUserList = async () => {

    if(loading) {
      return;
    }

    setLoading(true);

    // request get user info
    const cksu = {
      txTypeId: TxTypes.ORIGIN_ADMIN_USER_REPORT,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "admin=" + JSON.stringify(cksu));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        const accountList = response.users;
        accountList.sort(compareAccount);
        setUsers(accountList);

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Load account list error with statuses: " + response.statusId);
      }
      
    } catch (error) {
      console.log("Error load account list: " + error);
    }

    setLoading(false);
  }


  /**
   * Get role count
   */
  const getRoleCount = (userObj) => {
    
    const staffs = userObj.roles;
    
    if(!(staffs)) {
      return <div className={classes.valueSmall}>NONE</div>
    }

    return (
      <div className={classes.valueSmall}>{staffs.length}</div>
    );

  }


  const getActions = (userObj) => {
    
  }

  // Use interval to refresh bank data
  useInterval(() => {
    loadUserList();
  }, 5000);

  useEffect(() => {
    loadUserList();
  }, []);


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>     

       
      
        <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="account table">
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.colNo}>ID</TableCell>
                    <TableCell className={classes.colStatus}>Name</TableCell>
                    <TableCell className={classes.colPhone}>Phone</TableCell>
                    <TableCell className={classes.colPhone}>Email</TableCell>
                    <TableCell className={classes.colPhone}>Roles</TableCell>
                    <TableCell className={classes.colPhone}>PartnerId</TableCell>
                    <TableCell className={classes.colExpired}>Updated</TableCell>
                    <TableCell className={classes.colActions}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((userObj, index) => (
                    <TableRow hover key={index} padding="none" className={classes.row}>
                      <TableCell className={classes.colNo}>{userObj.userId}</TableCell>
                      <TableCell className={classes.accountName} >{userObj.name}</TableCell>
                      <TableCell className={classes.colPhone} >{getDisplayPhone(userObj.phone)}</TableCell>
                      <TableCell className={classes.colPhone} >{userObj.email}</TableCell>
                      <TableCell className={classes.colPhone} >{getRoleCount(userObj)}</TableCell>
                      <TableCell className={classes.colPhone} >{userObj.partnerId}</TableCell>
                      <TableCell className={classes.colExpired} >{getTxTimeText(userObj.updated)}</TableCell>
                      <TableCell className={classes.colActions}>{getActions(userObj)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

        </ThemeProvider>
    </StyledEngineProvider>
  ); 
}


const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
}));



const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#37517f",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  title: {
    color: Colors.DARK_BLUE,
    paddingLeft: 2,
  },
  name: {
    color: Colors.DARK_GREEN,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  toolbar_height: {
    height: "30px",
  },
  transHead: {
    minHeight: 30,
    padding: "3px 3px",
  },

  table: {
    whiteSpace: "nowrap",
    marginTop: 5,
    marginBottom: 0,
  },

  row: {
    height: 30,
    maxHeight: 36,
    fontSize: 12,
  },


  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },
  accountName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
    padding: 8,
    fontWeight: 'bold',
    color: Colors.DARK_BLUE,
  },

  accountLogo: {
    width: 18,
    height: 18,
    marginRight: 8,
  },
  colWebsite: {
    cursor: 'pointer',
  },
  ckAppStatus: {
    textAlign: 'right',
    paddingRight: 6,
    minWidth: 99
  }
}));
