import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, AlertTitle } from '@mui/material';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Colors from '../../ckcore/core/Colors';
import { Typography } from '@mui/material';
import './ckpayment.css';
import ValUtils from '../../ckcore/core/ValUtils';


export default function PaymentCancelDialog(props) {

  const classes = useStyles();

  const order = props.order;
  const bankInfo = props.bankInfo;
  const bankObj = props.bankObj;

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const handleReturn = () => {
    props.returnCallback();
  }

  // Payment transfer bank guide dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <Dialog open={props.open} onClose={handleClose} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Xác nhận huỷ thanh toán</DialogTitle>
        <DialogContent dividers>

          <Box p={1} style={{marginBottom: 8}}>
            <Alert severity="warning">
              <AlertTitle>Giao dịch đang chờ thanh toán</AlertTitle>
              Hệ thống đang chờ bạn chuyển khoản để xác nhận giao dịch <strong>{order.order_id}</strong>.
            </Alert>
          </Box>

          <Box p={1}>
              <Typography component="div" variant="body1">Nếu bạn muốn huỷ thanh toán, vui lòng bấm Đồng ý để trở về trang <a href={order.return_url} target="_blank" rel="noreferrer">{ValUtils.getDomain(order.return_url, true)}</a>.</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Trở lại
          </Button>

          <Button onClick={handleReturn} color="warning" variant='outlined'>
            Đồng ý
          </Button>        
         
        </DialogActions>
      </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});

const useStyles = makeStyles(theme => ({
  root: {

  },

  textField: {
  },

  inputAccountNumber: {
    color: Colors.DARK_GREEN,
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputTxValue: {
    color: Colors.DARK_BLUE,
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 'bold',
  },

  inputTxContent: {
    color: Colors.RED,
    width: '100%',
    fontSize: 13,
    fontWeight: 'bold',
  },  
}));
