const CARD_VIETTEL = "vt";
const CARD_MOBIFONE = "mf";
const CARD_VINAPHONE = "vn";
const CARD_VIETNAMMOBILE = "vm";
const CARD_GMOBILE = "gm";
const CARD_UNKNOWN = "oo";

const VIETTEL_SET = [
  "086",
  "096",
  "097",
  "098",
  "032",
  "033",
  "034",
  "035",
  "036",
  "037",
  "038",
  "039",
];
const MOBI_SET = ["089", "090", "093", "070", "079", "077", "076", "078"];
const VINA_SET = ["088", "091", "094", "083", "084", "085", "081", "082"];
const VNMOBILE_SET = ["092", "056", "058"];
const GMOBILE_SET = ["099", "059"];

//***************************** VN TELCO UTIL *******************************/
function whatTelco(phone) {
  // Invalid
  if (phone.length < 5) {
    return CARD_UNKNOWN;
  }

  // Get prefix
  const prefix = phone.startsWith("0")
    ? phone.substring(0, 3)
    : "0" + phone.substring(2, 4);

  console.log("Check phone " + phone + " with prefix: " + prefix);
  if (VIETTEL_SET.includes(prefix)) {
    return CARD_VIETTEL;
  } else if (MOBI_SET.includes(prefix)) {
    return CARD_MOBIFONE;
  } else if (VINA_SET.includes(prefix)) {
    return CARD_VINAPHONE;
  } else if (VNMOBILE_SET.includes(prefix)) {
    return CARD_VIETNAMMOBILE;
  } else if (GMOBILE_SET.includes(prefix)) {
    return CARD_GMOBILE;
  }

  // not found
  return CARD_UNKNOWN;
}

// Check card type from pin and seri
function whatCard(cardPin, cardSeri) {
  const pinLen = cardPin.length;
  const seriLen = cardSeri.length;

  // Viettel
  if ((seriLen === 11 && pinLen === 13) || (seriLen === 14 && pinLen === 15)) {
    return CARD_VIETTEL;
  }

  // Mobifone
  if (seriLen === 15 && pinLen === 12) {
    return CARD_MOBIFONE;
  }

  // Vinaphone
  if (seriLen === 14 && pinLen === 14) {
    return CARD_VINAPHONE;
  }

  // not found
  return CARD_UNKNOWN;
}

// Currently support only three telcos
function isSupportTelco(telco) {
  if (
    telco === CARD_VIETTEL ||
    telco === CARD_MOBIFONE ||
    telco === CARD_VINAPHONE
  ) {
    return true;
  }
  return false;
}

// Currently support only three telcos
function getTelcoShortName(fullName) {
  if (fullName === "VIETTEL") {
    return "VTEL";
  } else if (fullName === "MOBIFONE") {
    return "MOBI";
  } else if (fullName === "VINAPHONE") {
    return "VINA";
  } else if (fullName === "VIETNAMMOBILE") {
    return "VNMB";
  } else if (fullName === "GMOBILE") {
    return "GTEL";
  }

  return fullName;
}

// Get VN display phone start with 0
function getDisplayPhone(phone) {

  if(!(phone)) {
    return "";
  }

  if (phone.startsWith("+84")) {
    return "0" + phone.substring(3);
  }

  if (phone.startsWith("84")) {
    return "0" + phone.substring(2);
  }

  return phone;
};


// Get VN formated phone start with 84
function getFormatedPhone (phone) {

  if(!(phone)) {
    return "";
  }

  if (phone.startsWith("+84")) {
    return phone.substring(1);
  }

  if (phone.startsWith("0")) {
    return "84" + phone.substring(1);
  }

  return phone;
};

// export functions
export { whatTelco, whatCard, isSupportTelco, getTelcoShortName, getDisplayPhone, getFormatedPhone };
