import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SvgIcon from '@mui/material/SvgIcon';
import Autocomplete from '@mui/material/Autocomplete';

import { sha256 } from 'js-sha256';
import { ckTheme } from '../../ckcore/ui/CkStyles';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import { isValidOrderId, isValidNameUTF8, isValidUrl, isValidAccountNumber } from '../../ckcore/core/Validator';
import Colors from '../../ckcore/core/Colors';
import ValUtils from '../../ckcore/core/ValUtils';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import { getBankInfo, getBankSvgIcon } from '../../ckcore/core/CkBank'
import { MenuItem } from '@mui/material';
import User from '../../ckcore/core/User';
import TxTypes from '../../ckcore/core/TxTypes';
import TxStatus from '../../ckcore/core/TxStatuses';
import Config from '../../ckcore/core/Config';
import ServiceTypes from '../../ckcore/core/ServiceTypes';


export default function PurchaseRequestDialog(props) {

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const bankAccount = props.bankAccounts.length > 0 ? props.bankAccounts[0] : { accountNumber: '', bankName: '' }
  const bankList = Config.getAvailableBanks();

  const currentSecondId = Math.floor(((new Date().getTime()) / 1000) % 100000);

  const purchaseObj = {

    partner_id: partnerObj.partnerId,
    service_type: ServiceTypes.PURCHASE_REQUEST,
    account_number: '',
    account_name: '',
    bank_name: '',
    order_id: '',
    order_total: 0,
    order_info: '',
    customer_name: userObj.name,
    customer_phone: userObj.phone,
    customer_id: '',
    customer_email: userObj.email,
    staff_name: '',
    staff_phone: '',
    staff_email: '',
    debit_account_number: '',
    debit_bank_name: '',
    order_description: '',
    return_url: 'https://dashboard.chuyenkhoan.com',
    product: {
      name: 'Yêu cầu chi phí',
      code: 'YCCP',
      description: 'Yêu cầu chi phí',
      image: '',
      price: 0,
      quantity: 1
    },

  };

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editing, setEditing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [orderObj, setOrderObj] = useState(purchaseObj);

  const isValidOrder = () => {

    // order total
    if (orderObj.order_total <= 0) {
      console.log("Order total invalid: " + orderObj.order_total);
      return false;
    }

    // bank account
    if (!(orderObj.account_number) || !(orderObj.bank_name)) {
      console.log("account_number invalid: " + orderObj.account_number);
      return false;
    }

    // staff
    if (!(orderObj.staff_name) || !(orderObj.staff_phone)) {
      console.log("staff_phone invalid: " + orderObj.staff_phone);
      return false;
    }

    // debit account
    if (!(orderObj.debit_account_number) || !(orderObj.debit_bank_name)) {
      console.log("debit_account_number invalid: " + orderObj.debit_account_number);
      return false;
    }

    // desc
    if (!(orderObj.product.description)) {
      console.log("Product desc invalid: " + orderObj.product.description);
      return false;
    }

    return true;
  }

  const handleChange = name => event => {
    let productObj = { ...orderObj.product };

    var val = event.target.value;
    if (name === 'order_total') {
      val = parseInt(val.replace(/\D/g, ""));

      // set price
      productObj.price = val;
    }

    if (name === 'order_description') {
      // use description as product name
      productObj.name = val;
    }

    if (name === 'account_number') {
      val = val.replace(/ /g, "");
    }

    setOrderObj({ ...orderObj, [name]: val, product: productObj });
    setLoading(false);
    setEditing(true);
  };

  const handleChangeDebitBank = name => event => {

    let accountNumber = event.target.value;
    let debitAccount = props.bankAccounts.find((acc) => acc.accountNumber === accountNumber);
    if (debitAccount) {
      setOrderObj({ ...orderObj, debit_account_number: debitAccount.accountNumber, debit_bank_name: debitAccount.bankName });
    }
  }

  const handleChangeStaff = name => event => {
    let staffPhone = event.target.value;
    let staffObj = staffList.find((staff) => staff.phone === staffPhone);
    if (staffObj) {
      setOrderObj({ ...orderObj, staff_phone: staffObj.phone, staff_email: staffObj.email, staff_name: staffObj.name });
    }
  }

  // load partner staff list (purchaser)
  const loadStaffList = async () => {
    // request get user info
    const ckc = {
      txTypeId: TxTypes.PARTNER_ADMIN_STAFF_LIST,
      partnerId: userObj.partnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      setLoading(true);

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(ckc));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        setStaffList(response.staffs);
        console.log("Staff list loaded.");
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error... " + error);
    }

    setLoading(false);
  }

  // Create purchase request
  const createPurchaseRequest = async () => {


    // get bank info
    const bankInfo = getBankInfo(Config.getAvailableBanks(), orderObj.bank_name);
    if (!(bankInfo)) {
      setErrorMessage("Tên ngân hàng không hợp lệ: " + orderObj.bank_name);
      return;
    }

    // validate account
    if (!isValidAccountNumber(orderObj.account_number, bankInfo.accountRegex)) {
      setErrorMessage("Số tài khoản không hợp lệ: " + orderObj.account_number);
      return;
    }
    if (!isValidNameUTF8(orderObj.account_name)) {
      setErrorMessage("Tên tài khoản không hợp lệ: " + orderObj.account_name);
      return;
    }

    if (!isValidNameUTF8(orderObj.product.description)) {
      setErrorMessage("Lý do yêu cầu không hợp lệ");
      return;
    }

    if (!(isValidOrder())) {
      console.log("Order invalid: " + JSON.stringify(orderObj));
      setErrorMessage("Vui lòng nhập đủ thông tin");
      return;
    }

    // create order request
    try {

      if (loading) {
        return;
      }

      setErrorMessage("");
      setSuccessMessage("");

      // load CKBox status
      var ckmsg = {
        txTypeId: TxTypes.PARTNER_ADMIN_ORDER_CREATE,
        partnerId: userObj.partnerId,
        order: orderObj,
        time: new Date().getTime(),
        identity: User.getIdentity(),
      };

      setLoading(true);

      // fetch response
      const rawResponse = await fetch(
        Config.getServerURL() + "order=" + JSON.stringify(ckmsg)
      );
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.SUCCESS || response.statusId === TxStatus.HTTP_SUCCESS) {
        setSuccessMessage("Tạo phiếu chi thành công.");

        // auto close
        setTimeout(() => {
          handleClose();
        }, 2200);

      } else if (response.statusId === TxStatus.TIMEOUT || response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session has expired!");
        props.logoutCallback();
      } else {
        console.log("ERROR create order, msg: " + response.msg);
        setErrorMessage("Lỗi tạo phiếu chi, vui lòng thử lại sau");
      }
    } catch (error) {
      console.log("Error create order .. " + error);
      setErrorMessage("Lỗi tạo phiếu chi, vui lòng thử lại sau");
    }

    setLoading(false);

  };

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  useEffect(() => {
    loadStaffList();
  }, []);

  // Add bank dialog
  return (
    <ThemeProvider theme={ckTheme}>
      <Dialog open={props.open} fullWidth maxWidth="md" onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Yêu cầu chi phí</DialogTitle>
        <DialogContent dividers>

          <Box p={1}>
            {getMessageBox(errorMessage, successMessage, "")}
          </Box>

          <Grid container spacing={2}>

            <Grid item xs={4}>
              <TextField type="text" variant="outlined" margin="dense" fullWidth
                label={"Người yêu cầu (" + userObj.email + ")"}
                value={userObj.name}
                disabled={true}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField id="ck-bank-account" variant="outlined" margin="dense" fullWidth
                label={"Người nhận yêu cầu"}
                select
                defaultValue={""}
                value={orderObj.staff_phone}
                InputProps={{ readOnly: false }}
                onChange={handleChangeStaff('')}>
                {staffList.map((staff, index) => (
                  <MenuItem key={staff.phone} value={staff.phone}>
                    <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{staff.name}</Box>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={4}>
              <TextField id="ck-credit-account" variant="outlined" margin="dense" fullWidth
                label={"Số tài khoản Quỹ chuyển tiền"}
                select
                defaultValue={""}
                value={orderObj.debit_account_number}
                InputProps={{ readOnly: false }}
                onChange={handleChangeDebitBank('')}>
                {props.bankAccounts.map((bankAccount, index) => (
                  <MenuItem key={bankAccount.accountNumber} value={bankAccount.accountNumber}>
                    <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{bankAccount.accountNumber} - {bankAccount.bankName}</Box>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField id="ck-bank-account" type="text" variant="outlined" margin="dense" fullWidth
                label="Số tài khoản nhận"
                value={orderObj.account_number}
                onChange={handleChange('account_number')}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField id="ck-bank-list" variant="outlined" margin="dense" fullWidth
                label="Ngân hàng nhận"
                select
                defaultValue={""}
                value={(orderObj.bank_name) ? orderObj.bank_name : ''}
                InputProps={{ readOnly: false }}
                onChange={handleChange('bank_name')}>
                {bankList.map((bankObj, index) => (
                  <MenuItem key={bankObj.name} value={bankObj.name}>
                    <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{bankObj.name}</Box>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField type="text" variant="outlined" margin="dense" fullWidth
                label="Tên tài khoản nhận"
                value={orderObj.account_name}
                onChange={handleChange('account_name')}
              />
            </Grid>


            <Grid item xs={4}>
              <TextField type="text" variant="outlined" margin="dense" fullWidth
                label="Số tiền (VND)"
                value={ValUtils.formatMoney(orderObj.order_total, '.')}
                onChange={handleChange('order_total')}
              />
            </Grid>

            <Grid item xs={8}>
              <TextField type="text" variant="outlined" margin="dense" fullWidth
                label="Nội dung chuyển khoản (tiếng Việt không dấu)"
                value={orderObj.order_info}
                onChange={handleChange('order_info')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField type="text" variant="outlined" margin="dense" fullWidth
                label="Lý do yêu cầu"
                value={orderObj.order_description}
                onChange={handleChange('order_description')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Trở lại
          </Button>

          <Button disabled={loading || !editing || (successMessage !== '')} onClick={() => createPurchaseRequest()} variant="outlined" color="primary">
            Tạo yêu cầu
          </Button>

        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
