import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { Alert, AlertTitle, Grid, Hidden } from '@mui/material';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';

import Colors from '../../ckcore/core/Colors';
import { Typography } from '@mui/material';
import './ckpayment.css';
import ValUtils from '../../ckcore/core/ValUtils';
import PaymentStatus from './PaymentStatus';
import { getCKBankLogo, getBankSvgIcon } from '../../ckcore/core/CkBank'

function getDomain(url, subdomain) {
  subdomain = subdomain || false;

  url = url.replace(/(https?:\/\/)?(www.)?/i, '');

  if (!subdomain) {
    url = url.split('.');

    url = url.slice(url.length - 2).join('.');
  }

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0];
  }

  return url;
}

/**
 * Warning user based on payment status
 */
export default function PaymentWarningDialog(props) {

  const classes = useStyles();

  const paymentStatus = props.paymentStatus;
  const remainTime = props.remainTime;
  const order = props.order;
  const bankInfo = props.bankInfo;
  const bankObj = props.bankObj;
  const partnerObj = props.partnerObj;

  // Customer click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Customer click to return partner site
  const handleReturn = () => {
    props.returnCallback('home');
  }

  const handleRepay = () => {
    props.returnCallback('repay');
  }

  const handleDownloadQR = () => {
    props.returnCallback('download_qr');
  }

  /**
   * Warning titles
   */
  const getWarningTitle = () => {

    if (isTimeout()) {
      return "Giao dịch quá thời gian";
    }

    switch (paymentStatus) {

      case PaymentStatus.PAYMENT_WAITING:
        return "Giao dịch chờ thanh toán";

      case PaymentStatus.PAYMENT_SUCCESS:
        return "Giao dịch thành công";

      case PaymentStatus.PAYMENT_OVER_FUND:
        return "Giao dịch chuyển thừa tiền";

      case PaymentStatus.PAYMENT_INSUFFICIENT_FUND:
        return "Giao dịch chuyển thiếu tiền";

      case PaymentStatus.PAYMENT_CANCEL:
        return "Giao dịch bị huỷ";

      case PaymentStatus.PAYMENT_TIMEOUT:
        return "Giao dịch quá thời gian";

      default:
        return "Giao dịch lỗi không xác định";
    }
  }

  const isTimeout = () => {
    if (remainTime < 1 && (paymentStatus === PaymentStatus.PAYMENT_WAITING || paymentStatus === PaymentStatus.PAYMENT_CANCEL)) {
      return true;
    }
    return false;
  }

  const isAllowDownload = () => {

    switch (paymentStatus) {
      case PaymentStatus.PAYMENT_WAITING:
      case PaymentStatus.PAYMENT_SUCCESS:
      case PaymentStatus.PAYMENT_OVER_FUND:
      case PaymentStatus.PAYMENT_INSUFFICIENT_FUND:
      case PaymentStatus.PAYMENT_CANCEL:
      case PaymentStatus.PAYMENT_TIMEOUT:
        return true;
      default:
        return false;
    }
  }

  /**
   * Warning content
   */
  const getWarningContent = () => {

    switch (paymentStatus) {

      case PaymentStatus.PAYMENT_WAITING:
        if (isTimeout()) {
          return getTimeoutMessage();
        }

        if (bankInfo.upToDate) {
          return getWaitingMessage();
        } else {
          return getCommonGuideMessage();
        }

      case PaymentStatus.PAYMENT_SUCCESS:
        return getSuccessMessage();

      case PaymentStatus.PAYMENT_OVER_FUND:
        return getOverFundMessage();

      case PaymentStatus.PAYMENT_INSUFFICIENT_FUND:
        return getInsufficientFundMessage();

      case PaymentStatus.PAYMENT_CANCEL:
      case PaymentStatus.PAYMENT_TIMEOUT:
        return getTimeoutMessage();

      default:
        if (isTimeout()) {
          return getTimeoutMessage();
        } else {
          return getPartnerSupport();
        }
    }
  }

  // Get waiting message
  const getWaitingMessage = () => {
    return (
      <div>

        <Box p={1} style={{ marginBottom: 8 }}>
          <Alert severity="info">
            <AlertTitle>Chờ chuyển khoản</AlertTitle>
            Vui lòng đợi ít nhất <strong>2 phút</strong> sau khi bạn chuyển khoản, hệ thống sẽ xác nhận giao dịch tự động.
          </Alert>
        </Box>

        {getBankTransferSupport()}

        {getPartnerSupport()}

      </div>
    );
  }

  const getCommonGuideMessage = () => {
    return (
      <div>

        <Box p={1} style={{ marginBottom: 8 }}>
          <Alert severity="warning">
            <AlertTitle>Giao dịch không tự động</AlertTitle>
            Giao dịch thanh toán của bạn sẽ không được xác nhận tự động do gián đoạn kết nối giữa tài khoản <strong>{order.account_number}</strong> của <strong>{partnerObj.name} </strong>
            và CTCP GIẢI PHÁP THANH TOÁN CHUYỂN KHOẢN.
          </Alert>
        </Box>

        {getBankTransferSupport()}

        {getPartnerSupport()}

      </div>
    );
  }

  // Success mesage
  const getSuccessMessage = () => {
    return (
      <div>

        <Box p={1} style={{ marginBottom: 8 }}>
          <Alert severity="success">
            <AlertTitle>Giao dịch thành công</AlertTitle>
            Giao dịch <strong>{order.order_id}</strong> đã được xác nhận thành công. Mời bạn trở lại với {partnerObj.name}.
          </Alert>
        </Box>

      </div>
    );
  }

  // Over fund message
  const getOverFundMessage = () => {
    return (
      <div>

        <Box p={1} style={{ marginBottom: 8 }}>
          <Alert severity="success">
            <AlertTitle>Giao dịch thành công - Thừa tiền</AlertTitle>
            Giao dịch <strong>{order.order_id}</strong> đã được xác nhận thành công, nhưng bạn chuyển thừa tiền (bạn chuyển {ValUtils.formatMoney(order.order_paid)}đ cho đơn {ValUtils.formatMoney(order.order_total)}đ).
          </Alert>
        </Box>
        <Box p={1}>
          <Typography component="div" variant="body1">Mời bạn liên hệ với {partnerObj.name} để được hoàn lại tiền thừa:</Typography>
          {getHotline()}
        </Box>
      </div>
    );
  }

  // Insufficient fund message
  const getInsufficientFundMessage = () => {
    return (
      <div>

        <Box p={1} style={{ marginBottom: 8 }}>
          <Alert severity="error">
            <AlertTitle>Giao dịch lỗi - Thiếu tiền</AlertTitle>
            Giao dịch <strong>{order.order_id}</strong> bị lỗi do bạn chuyển thiếu tiền (bạn đã chuyển {ValUtils.formatMoney(order.order_paid)}đ cho đơn {ValUtils.formatMoney(order.order_total)}đ).
          </Alert>
        </Box>
        <Box p={1}>
          <Typography component="div" variant="body1">Mời bạn liên hệ với {partnerObj.name} để chuyển bổ sung, hoặc huỷ giao dịch và hoàn lại tiền đã chuyển:</Typography>
          {getHotline()}
        </Box>
      </div>
    );
  }

  // Get timeout message
  const getTimeoutMessage = () => {
    return (
      <div>

        <Box p={1} style={{ marginBottom: 8 }}>
          <Alert severity="warning">
            <AlertTitle>Giao dịch quá thời gian</AlertTitle>
            Hệ thống đã chờ hơn <strong>10 phút</strong> nhưng chưa nhận được thông báo thanh toán cho giao dịch <strong>{order.order_id}</strong>.
          </Alert>
        </Box>

        {getPartnerSupport()}

      </div>
    );
  }

  // Show bank transfer support
  const getBankTransferSupport = () => {
    return <Box p={1}>
      <Typography component="div" variant="body1">Nếu bạn <strong>chưa quen dùng Chuyển khoản QR</strong>, mời bạn chọn biểu tượng ngân hàng bạn sẽ chuyển tiền để xem hướng dẫn:</Typography>
      <div key="support-bank" style={{ marginTop: 8 }}>
        {
          props.supportBanks.map((bank, index) => (
            <span key={index}>
              <Tooltip title={"Hướng dẫn chuyển khoản từ " + bank.name} aria-label="Bank"><span>
                <IconButton size="small" className={classes.bankIconButton} onClick={() => props.bankTransferGuide(bank)}>
                  <SvgIcon component={getBankSvgIcon(bank.name)} viewBox="0 0 48 48" />
                </IconButton></span>
              </Tooltip>
            </span>
          ))
        }
      </div>
    </Box>
  }

  // Get support from partner
  const getPartnerSupport = () => {
    return <Box p={1}>
      <Typography component="div" variant="body1">Nếu bạn <strong>đã chuyển khoản</strong> đúng nội dung mà giao dịch chưa xác nhận thành công, bạn có thể liên hệ với {partnerObj.name} để được hỗ trợ:</Typography>
      {getHotline()}
    </Box>
  }

  const getHotline = () => {
    if(partnerObj.tel) {
      return <Typography component="div" variant="subtitle1" style={{ marginTop: 16 }}>Hotline: <strong>{partnerObj.tel}</strong>, hoặc email: <strong>{partnerObj.email}</strong></Typography>;
    } else {
      return <Typography component="div" variant="subtitle1" style={{ marginTop: 16 }}>Email: <strong>{partnerObj.email}</strong></Typography>;
    }
  }

  // Payment transfer bank guide dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} onClose={handleClose} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">

          <DialogTitle id="form-dialog-title">
            <Grid justifyContent="space-between" alignItems="flex-end" container>
              <Grid item>
                {getWarningTitle()}
              </Grid>
              <Grid item>
                {
                  isAllowDownload() &&
                  <span>
                    <Button className={classes.actionButton} size="small" variant="outlined" color="primary" 
                      disabled={props.loading === true}
                      onClick={() => props.downloadCallback('png')}>TẢI ĐƠN (PNG)</Button>
                    <Button className={classes.actionButton} size="small" variant="outlined" color="primary" 
                      disabled={props.loading === true}
                      onClick={() => props.downloadCallback('pdf')}>IN ĐƠN (PDF)</Button>
                  </span>
                }
              </Grid>
            </Grid>

          </DialogTitle>


          <DialogContent dividers>

            <Box p={1} style={{ marginBottom: 8 }}>
              {getWarningContent()}
            </Box>

          </DialogContent>

          <DialogActions>


            

            {
              (paymentStatus !== PaymentStatus.PAYMENT_WAITING || remainTime < 1) &&
              <>
              <Hidden smDown>
                <Button onClick={handleReturn} color="primary" sx={{ textTransform: 'none' }}>
                  Trở về {getDomain(order.return_url, true)}
                </Button>
              </Hidden>
              </>
            }

            {
              isTimeout() &&
              <Button onClick={handleRepay} color="warning" variant='outlined'>
                Thanh toán lại
              </Button>
            }

            {
              (paymentStatus === PaymentStatus.PAYMENT_WAITING) && !isTimeout() &&
              <Button onClick={handleClose} color="primary">
                Trở lại
              </Button>
            }
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});

const useStyles = makeStyles(theme => ({
  root: {

  },

  textField: {
  },

  inputAccountNumber: {
    color: Colors.DARK_GREEN,
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },

  inputTxValue: {
    color: Colors.DARK_BLUE,
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    fontWeight: 'bold',
  },

  inputTxContent: {
    color: Colors.RED,
    width: '100%',
    fontSize: 13,
    fontWeight: 'bold',
  },

  actionButton: {
    marginRight: 16,
  }
}));
