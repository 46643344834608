import React from 'react';
import { Dialog } from '@mui/material';
import Setting from './Setting';

/**
 * Wrap setting app
 */
export default function SettingDialog(props) {
  return (
    <Dialog fullWidth maxWidth="sm" open={props.open} onClose={props.closeCallback} aria-labelledby="profile-dialog">
      <Setting appId="SETTING"
          selectedIndex={props.selectedIndex}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback} 
          refreshCallback={props.closeCallback} />
    </Dialog>
   
  );
}


