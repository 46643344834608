import { adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import { sha256 } from 'js-sha256';

import Config from '../../ckcore/core/Config';
import Colors from '../../ckcore/core/Colors';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';
import TxStatus from '../../ckcore/core/TxStatuses';
import { getMessageBox } from '../../ckcore/core/TxUtils';


export default function CreateTestOrderDialog(props) {

  const classes = useStyles();

  const partnerObj = props.partnerObj;
  const userObj = props.userObj;
  const bankTxObj = props.txObj;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  const requestRefund = () => {

  }


  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Hoàn tiền Chuyển Khoản QR</DialogTitle>
          <DialogContent dividers>

            <Box p={1} m={1}>
              { getMessageBox(errorMessage, successMessage, "Chức năng sẽ sớm ra mắt, mong bạn thử lại sau.")}
            </Box>

            <Grid container spacing={2}>

              <Grid item xs={12}>
                <TextField type="text" variant="outlined" margin="dense" className={classes.textfieldAccNumberNew} fullWidth
                  label="Mã đơn"
                  value={bankTxObj.txnRef}
                  disabled={true}
                />
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            <Button disabled={loading || true} onClick={() => requestRefund()} color="primary">
              Hoàn tiền
            </Button>

          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
}));

const useStyles = makeStyles(theme => ({
  root: {

  },
}));
