import makeStyles from '@mui/styles/makeStyles';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import moment from 'moment';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Colors from '../../ckcore/core/Colors';
import TxStatus from '../../ckcore/core/TxStatuses';
import Config from '../../ckcore/core/Config';
import User from '../../ckcore/core/User';



export default function ExportAccountingDialog(props) {

  const classes = useStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const bankTxList = props.bankTxList;

  var fromDate = moment().startOf('month').format('YYYY-MM-DD');
  var toDate = moment().endOf('month').format('YYYY-MM-DD');
  if((bankTxList) && bankTxList.length > 0) {
    // toDate = moment(bankTxList[0].created).format('DD/MM/YYYY');
    // fromDate = moment(bankTxList[bankTxList.length-1].created).format('DD/MM/YYYY');
  }
  console.log("From date: " + fromDate + " to date: " + toDate);

  const exportObjTemplate = {
    partner_id: partnerObj.partnerId,
    partner_name: partnerObj.name,
    bank_accounts: props.bankAccounts,
    from_date: fromDate,
    to_date: toDate,
    bank_tx_list: bankTxList,
    file_template: 'amis_accounting_ban_hang.xlsx',
    export_url: 'https://dev.chuyenkhoan.com/export',
  };


  const [downloadUrl, setDownloadUrl] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [exportObj, setExportObj] = useState(exportObjTemplate);
  const handleChange = name => event => {
    var val = event.target.value;
    setExportObj({ ...exportObj, [name]: val });
    setLoading(false);
    setEditing(true);
  };

  /**
   * Request export service
   */
   const requestExportService = async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(exportObj)
    };   

    // Call server
    try {

      setSuccessMessage("");
      setErrorMessage("");


      setLoading(true);

      const rawResponse = await fetch(exportObjTemplate.export_url, requestOptions);
      const response = await rawResponse.json();

      if (response.download_url) {
        setSuccessMessage("Gửi yêu cầu xuất báo cáo thành công. Mời bạn bấm tải về.");
        setDownloadUrl(response.download_url);
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMessage("Gửi yêu cầu xuất báo cáo bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMessage("Gửi yêu cầu xuất báo cáo bị lỗi, vui lòng thử lại sau.")
      console.log("Error update API config " + error);
    }

    setLoading(false);

  }

  /**
   * Download accouting file
   */
  const downloadAccoutingFile = () => {
    props.closeCallback();
    window.open(downloadUrl, '_blank').focus();
  }

  // User click close
  const handleClose = () => {
    props.closeCallback();
  };

  // Add bank dialog
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Xuất báo cáo kế toán</DialogTitle>
          <DialogContent dividers>

            <Box p={1}>

              {
                loading && 
                <Typography variant="body1" style={{color: Colors.ORANGE, textAlign: "center"}}>Đang kết nối..</Typography>
              }

              {
                !loading &&
                getMessageBox(errorMessage, successMessage, "Test xuất báo cáo kế toán cho " + partnerObj.name)
              }
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField select type="text" variant="outlined" margin="dense" className={classes.textField} fullWidth
                  label="Tên file mẫu"
                  value={exportObj.file_template}
                  onChange={handleChange('file_template')}
                >
                  <MenuItem key="file_template" value="amis_accounting_ban_hang.xlsx">
                    amis_accounting_ban_hang.xlsx
                  </MenuItem>
                  <MenuItem key="file_template" value="amis_accounting_thu.xlsx">
                    amis_accounting_thu.xlsx
                  </MenuItem>
                  <MenuItem key="file_template" value="amis_accounting_chi.xlsx">
                    amis_accounting_chi.xlsx
                  </MenuItem>
                </TextField>
              </Grid>


              <Grid item xs={6}>
                <TextField
                        label="Từ ngày: "
                        type="date"
                        variant="outlined" margin="dense"
                        defaultValue={fromDate}
                        className={classes.textField} fullWidth
                        onChange={handleChange('from_date')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                        label="Đến ngày: "
                        type="date"
                        variant="outlined" margin="dense"
                        defaultValue={toDate}
                        className={classes.textField}
                        fullWidth
                        onChange={handleChange('to_date')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                />
              </Grid>

            </Grid>
            <Box p={1}>
            </Box>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} color="primary">
              Trở lại
            </Button>

            {
              (downloadUrl) ? (
                <Button onClick={() => downloadAccoutingFile()} color="primary">
                  Tải về
                </Button>
              ): (
                <Button disabled={loading} onClick={() => requestExportService()} color="primary">
                  Gửi yêu cầu
                </Button>
              )
            }


          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}


const theme = createTheme({
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.DARK_GREEN,
      contrastText: "#fff" //button text white instead of black
    },
    text: {
      disabled: Colors.DARK_BLUE
    }
  },
});

const useStyles = makeStyles(theme => ({
  root: {

  },
}));
