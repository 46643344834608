import React from 'react';
import Grid from '@mui/material/Grid';
import ProductList from '../product/ProductList';

export default function Product(props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
       <ProductList 
          userObj={props.userObj}
          partnerObj={props.partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
      </Grid>
    </Grid>
  );
} 
