import React from 'react';
// drawer menu
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Hidden from '@mui/material/Hidden';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';

const drawerWidth = 230;

export default function CkDrawer(props) {

  const classes = useStyles();

  const ckApps = props.ckApps;
  const selectedAppId = props.selectedAppId;
  const isOpenDrawer = false;


  /*
    Hidden note:

        innerWidth  |xs      sm       md       lg       xl
                    |--------|--------|--------|--------|-------->
        width       |   xs   |   sm   |   md   |   lg   |   xl

        smUp        |   show | hide
        mdDown      |                     hide | show
  */

  const getAppItem = (app, internal) => {

    // devider
    if ((app.name === 'DIVIDER')) {
      return <Divider key={app.appId} style={{ marginTop: 8, marginBottom: 8 }} />
    }

    // not show in drawer
    if (!(app.default)) {
      return <div key={app.appId} />
    }

    // only for internal use
    if ((app.internal) && !(internal)) {
      return <div key={app.appId} />
    }

    if (app.appId === selectedAppId) {
      return <ListItem button key={app.appId} onClick={() => props.showApp(app.appId, 0)} className={classes.selectedAction}>

        <ListItemIcon className={classes.selectedActionIcon}>
          {app.icon}
        </ListItemIcon>
        <ListItemText style={{marginLeft: -12}}><strong>{app.name}</strong></ListItemText>

      </ListItem>
    } else {
      return (
        <ListItem button key={app.appId} onClick={() => props.showApp(app.appId, 0)} className={classes.action}>

          <ListItemIcon>
            {app.icon}
          </ListItemIcon>

          <ListItemText style={{marginLeft: -12}}>{app.name}</ListItemText>

        </ListItem>
      );

    }
  }


  const getAppItemInternal = (app) => {

    if (!(app.internal)) {
      return <div key={app.appId} />
    }

    return getAppItem(app, true);
  }

  // RETURN VIEW
  return (
    <Drawer variant="permanent"
      className={clsx(classes.drawer, classes.drawerOpen)}
      classes={{ paper: classes.drawerOpen }}>
      <Divider />

      <div>
        <CssBaseline />
        <Toolbar className={classes.appBarSmall} style={{ minHeight: 48 }} />
        <List>
          {
            ckApps.map((app) => (getAppItem(app)))
          }
          {
            Config.isInternal(props.userObj) &&
            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
          }
          {
            Config.isInternal(props.userObj) &&
            ckApps.map((app) => (getAppItemInternal(app)))
          }
        </List>
      </div>

    </Drawer>
  );
}

const useStyles = makeStyles((homeTheme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {

    width: drawerWidth,
    transition: homeTheme.transitions.create("width", {
      easing: homeTheme.transitions.easing.sharp,
      duration: homeTheme.transitions.duration.enteringScreen,
    }),
    backgroundColor: Colors.BG_WHITE,
    overflowX: "hidden",
  },
  drawerClose: {
    transition: homeTheme.transitions.create("width", {
      easing: homeTheme.transitions.easing.sharp,
      duration: homeTheme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: homeTheme.spacing(9) + 1,
    [homeTheme.breakpoints.up("sm")]: {
      width: homeTheme.spacing(9) + 1,
    },
    backgroundColor: Colors.BG_WHITE,
  },

  // action toolbar
  action: {
    marginLeft: homeTheme.spacing(0.5),
    cursor: "pointer",
  },

  selectedAction: {
    marginLeft: homeTheme.spacing(0.5),
    cursor: "pointer",
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    backgroundColor: '#D1E2FF',
    
    borderRadius: 5,
  },

  selectedActionIcon: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
  },
}));