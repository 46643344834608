/* eslint-disable array-callback-return */
/* eslint-disable no-extend-native */

import { ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Hidden from "@mui/material/Hidden";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


import { timeOffsetString } from '../../ckcore/core/CkUtils';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import TxTypes from '../../ckcore/core/TxTypes';
import User from '../../ckcore/core/User';

import PartnerSendSms from './PartnerSendSms';
import { Button } from '@mui/material';
import { getDisplayPhone } from '../../ckcore/core/Telcos';
import ValUtils from '../../ckcore/core/ValUtils';
import Roles from '../../ckcore/core/Roles';
import PreorderCKBox from './PreorderCKBox';
import RegisterCKSimDialog from './RegisterCKSim';

export default function SimList(props) {

  const classes = useStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const activeRole = User.getActiveRole(userObj);
  const simList = props.simList;
  const [loading, setLoading] = useState(false);

  // console.log("Show simList: " + simList.length + " parnterId: " + userObj.partnerId);

  // const [loading, setLoading] = useState(false);
  const [selectedSim, setSelectedSim] = useState({phone: ''});
  const [showSendSmsDialog, setShowSendSmsDialog] = useState(false);
  const [showPreoderCKBoxDialog, setShowPreoderCKBoxDialog] = useState(false);
  const [showRegisterCKSimDialog, setShowRegisterCKSimDialog] = useState(false);

  // Open send sms or USSD dialog
  const openSendSmsDialog = (sim) => {
    setSelectedSim(sim);
    setShowSendSmsDialog(true);
  }

  // Close send dialog
  const closeSendSmsDialog = () => {
    setShowSendSmsDialog(false);
  }

  const requestPreoderCKBox = () => {
    setShowPreoderCKBoxDialog(true);
  }

  const closePreoderCKBoxDialog = () => {
    setShowPreoderCKBoxDialog(false);
    props.refreshCallback();
  }

  const requestCKSIM = (simObj) => {
    setShowRegisterCKSimDialog(true);
    setSelectedSim(simObj);

  }
  const closeRegisterCKSimDialog = () => {
    setShowRegisterCKSimDialog(false);
    props.refreshCallback();
  }


  // Check sim balance
  const balanceCheck = () => {

  }

  /**
   * Download CKApp by OS
   * @param {} os 
   * @returns 
   */
  const downloadCkApp = async (os) => {

    if (loading) {
      return;
    }
    setLoading(true);

    // download ckapp message
    const cka = {
      txTypeId: TxTypes.TX_USER_DOWNLOAD_LINK,
      partnerId: userObj.partnerId,
      os: os,
      file: 'ckapp',
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "partner=" + JSON.stringify(cka));
      const response = await rawResponse.json();
      if (response.statusId === TxStatus.SUCCESS) {

        let downloadURL = response.url;
        console.log("Got download link: " + downloadURL);

        // open it
        window.open(downloadURL);
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request download error with statuses: " + response.statusId);
      }

    } catch (error) {
      console.log("Error... " + error);
    }

    setLoading(false);
  }

  const getCkCoinStatus = (ckCoin) => {

    var message = "Mỗi thông báo biến động số dư của tài khoản ngân hàng được tính là một lượt chuyển khoản.";

    if (ckCoin >= 100) {


      return <Tooltip title={message} aria-label="CKCoin">
        <span className={classes.ckCoinHigh}>Số lượt CK còn lại: {ckCoin}</span>
      </Tooltip>;
    } else if (ckCoin > 0) {

      message = message + " Số CKCoin của " + partnerObj.name + " đang ở mức thấp, vui lòng mua thêm để giao dịch không bị gián đoạn."

      return <Tooltip title={message} aria-label="CKCoin">
        <span className={classes.ckCoinLow}>Số lượt CK còn lại: {ckCoin}</span>
      </Tooltip>;
    } else {
      message = message + " Số CKCoin của " + partnerObj.name + " đã hết, vui lòng mua thêm để CKBox hoạt động trở lại."

      return <Tooltip title={message} aria-label="CKCoin">
        <span className={classes.ckCoinZero}>Số lượt CK còn lại: {ckCoin}</span>
      </Tooltip>
    }
  }


  const getSimStatusColor = (simStatus, timestamp) => {

    // fix timestamp
    if (new Date().getTime() - timestamp > 30000) {
      return Colors.YELLOW;
    }

    if (simStatus === "READY") {
      return 'green';
    } else if (simStatus === "READING" || simStatus === "WRITING" || simStatus === "TRANSFERING" || simStatus === "RECHARGING") {
      return Colors.DARK_BLUE;
    } else if (simStatus === "PROCESSING" || simStatus === "TRANSFER_WAITING" || simStatus === "RECHARG_WAITING") {
      return Colors.YELLOW;
    } else if (simStatus === "WAITING") {
      return Colors.YELLOW;
    } else if (simStatus === "NOTFOUND") {
      return 'rgba(0, 0, 0, 0.66)';
    } else if (simStatus === "DISABLE") {
      return 'rgba(0, 0, 0, 0.26)';
    } else if (simStatus === "ERROR" || simStatus === "RECHARGE_ERROR" || simStatus === "TRANSFER_ERROR") {
      return Colors.RED;
    } else {
      return Colors.RED;
    }
  }

  const getUpdateTime = (sim) => {

    return (
      <div className={classes.valueSmall}>{timeOffsetString(sim.timestamp / 1000, new Date().getTime() / 1000)}</div>
    );
  }

  const getModemId = (sim) => {

    if (sim.partnerId > 0 && sim.partnerId !== userObj.partnerId) {
      return "CKSIM";
    }

    if (sim.modemId.includes("CKBOX")) {
      return sim.modemId;
    } else {
      return sim.imei;
    }
  }

  const getSimActions = (sim) => {

    if (sim.partnerId > 0 && sim.partnerId !== userObj.partnerId) {
      return <div>
        <Tooltip title="Cài đặt" aria-label="Setting">
          <span>
            <IconButton size="small" className={classes.smsButton} onClick={() => requestCKSIM(sim)}>
              <SettingsOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    }

    return (<div>
      <Tooltip title="Cài đặt" aria-label="Setting">
        <span>
          <IconButton size="small" className={classes.smsButton} onClick={() => balanceCheck(sim)}>
            <SettingsOutlinedIcon />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Gửi SMS" aria-label="Send sms">
        <IconButton size="small" className={classes.smsButton} onClick={() => openSendSmsDialog(sim)}>
          <SendOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>);
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>

        <Grid justifyContent="space-between" alignItems="flex-end" container>
          <Grid item className={classes.transHead}>
            {
              (partnerObj.delegate) &&
              <Box display="inline" alignItems="flex-end">
                <span className={classes.title} style={{ cursor: "pointer" }} onClick={() => props.refreshCallback("CKBOX")}>TRẠNG THÁI SIM DELEGATE</span>
              </Box>
            }
            {
              !(partnerObj.delegate) &&
              <Box display="inline" alignItems="flex-end">
                <span className={classes.title} style={{ cursor: "pointer" }} onClick={() => props.refreshCallback("CKBOX")}>TRẠNG THÁI SIM</span>
                {(activeRole.roleId === Roles.ADMIN) && !Config.isStandalone() &&
                  <Box display="inline" >
                    {/* <Button color="primary" size="small" className={classes.modemButton} onClick={() => requestPreoderCKBox()}>[+CKBOX]</Button> */}                    
                    <Button color="primary" size="small" className={classes.modemButton} onClick={() => requestCKSIM()}>[+CKSIM]</Button>
                  </Box>}
              </Box>
            }

          </Grid>

          <Grid item>
           

          </Grid>
        </Grid>
        {
          (simList.length > 0) ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="modem usb table">
                <TableHead>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.colNo}>STT</TableCell>
                    <TableCell className={classes.colPhone}>SĐT</TableCell>
                    <TableCell className={classes.colStatus}>Trạng thái</TableCell>
                    <Hidden mdDown>
                      <TableCell className={classes.colExpired}>Số dư</TableCell>
                      <TableCell className={classes.colExpired}>Hạn dùng</TableCell>
                      <TableCell className={classes.colExpired}>Mã thiết bị</TableCell>
                    </Hidden>
                    <TableCell className={classes.colExpired}>Cập nhật</TableCell>
                    <TableCell className={classes.colActions}>Thao tác</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {simList.map((sim, index) => (
                    (sim) &&
                    <TableRow hover key={index} padding="none" className={classes.row}>
                      <TableCell className={classes.colNo}>{index + 1}</TableCell>
                      <TableCell className={classes.colPhone} style={{ color: getSimStatusColor(sim.state, sim.timestamp) }}>{getDisplayPhone(sim.phone)}</TableCell>
                      <TableCell className={classes.colStatus} style={{ color: getSimStatusColor(sim.state, sim.timestamp) }}>{sim.status}</TableCell>
                      <Hidden mdDown>
                        <TableCell className={classes.colBalance}>{(sim.balance === 0) ? '' : ValUtils.formatMoney(sim.balance) + 'đ'}</TableCell>
                        <TableCell className={classes.colExpired}>{sim.expired}</TableCell>
                        <TableCell className={classes.colExpired}>{getModemId(sim)}</TableCell>
                      </Hidden>
                      <TableCell className={classes.colTime} style={{ color: getSimStatusColor(sim.state, sim.timestamp) }}>{getUpdateTime(sim)}</TableCell>
                      <TableCell className={classes.colActions}>{getSimActions(sim)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) :
            (
              (activeRole.roleId === Roles.ADMIN) && !Config.isStandalone() &&
              <Box className={classes.guideBox}>
                <Box display="inline" >
                  <Button color="primary" variant="contained" className={classes.downloadButton} onClick={() => requestCKSIM()}>ĐĂNG KÝ CKSIM</Button>
                </Box>
                {/*
                  <Button color="primary" variant="contained" className={classes.downloadButton} onClick={() => requestPreoderCKBox()}>ĐẶT MUA CKBOX</Button>
                  Config.isSuperAdmin(userObj.phone) &&
                  <Box display="inline" >
                    <Button color="primary" variant="contained" className={classes.downloadButton} onClick={() => downloadCkApp('windows')}>Tải CKApp Windows</Button>
                    <Button color="primary" variant="contained" className={classes.downloadButton} onClick={() => downloadCkApp('linux')}>Tải CKApp Linux</Button>
                  </Box>
                */}
              </Box>
            )
        }

        {
          showSendSmsDialog &&
          <PartnerSendSms open={showSendSmsDialog} closeCallback={closeSendSmsDialog}
            sim={selectedSim}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            logoutCallback={props.logoutCallback}
            refreshCallback={props.refreshCallback} />
        }
       
        {
          showPreoderCKBoxDialog &&
          <PreorderCKBox open={showPreoderCKBoxDialog} closeCallback={closePreoderCKBoxDialog}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            logoutCallback={props.logoutCallback}
            refreshCallback={props.refreshCallback} />
        }

        {
          showRegisterCKSimDialog &&
          <RegisterCKSimDialog open={showRegisterCKSimDialog} closeCallback={closeRegisterCKSimDialog}
            userObj={props.userObj}
            partnerObj={props.partnerObj}
            simObj={selectedSim}
            logoutCallback={props.logoutCallback}
            refreshCallback={props.refreshCallback} />
        }

      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiTableCell: {
      root: {
        //This can be referred from Material UI API documentation.
        padding: "2px 6px",
      },
      sizeSmall: {
        padding: "2px 3px 2px 3px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: 18,
        height: 18,
      },
    },
  },
  palette: {
    primary: {
      main: Colors.BG_BLUE,
    },
    secondary: {
      main: Colors.LIGHT_BLUE,
      contrastText: "#fff" //button text white instead of black
    }
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    textAlign: 'left',
  },
  title: {
    color: Colors.DARK_BLUE,
    fontWeight: 'bold',
    paddingLeft: 2,
    marginBottom: 6,
  },

  transHead: {
    minHeight: 30,
    padding: '3px 3px',
  },

  guideBox: {
    textAlign: "center",
    margin: "auto",
    padding: 16,
  },
  guideText: {
    textAlign: "center",
    margin: "auto",
    marginBottom: 20,
  },

  ckCoinHigh: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.DARK_GREEN
  },
  ckCoinLow: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.ORANGE
  },
  ckCoinZero: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.RED
  },

  downloadButton: {
    margin: 'auto',
    marginLeft: '20px',
    fontWeight: 'bold'
  },

  modemButton: {
    padding: '3px 8px',
    marginLeft: '8px',
    color: '#1976d2',
    fontWeight: 'bold'
  },

  table: {
    marginTop: 4,
    marginBottom: 0,
    whiteSpace: "nowrap",
  },

  row: {
    height: 30,
    maxHeight: 36,
    fontSize: 12,
  },

  colNo: {
    minWidth: 30,
    textAlign: "center",
    padding: "2px 3px",
  },

  colPhone: {
    minWidth: 80,
    padding: '2px 6px',
    paddingLeft: 16,
  },

  colStatus: {
    minWidth: 110,
    padding: '2px 6px',
    fontWeight: 'bold',
  },

  colBalance: {
    minWidth: 50,
    padding: '2px 12px',
  },

  colExpired: {
    minWidth: 80,
    padding: '2px 12px',
  },

  colTime: {
    minWidth: 110,
    padding: '2px 12px',
  },


  colMessage: {
    minWidth: 222,
    maxWidth: 363,
    overflow: 'hidden',
    padding: '2px 6px',
  },

  colActions: {
    minWidth: 111,
    maxWidth: 111,
    padding: '3px 3px',
    textAlign: 'center',
  },
  valueIn: {
    color: 'green',
    textAlign: 'right',
  },
  valueOut: {
    color: 'orange',
    textAlign: 'right',
  },
  valueBold: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  valueEmpty: {
    color: Colors.DARK_BLUE,
    textAlign: 'center',
    fontSize: 12,
  },

  valueSuccess: {
    color: Colors.DARK_GREEN,
    textAlign: 'center',
    fontSize: 12,
  },
  valueError: {
    color: Colors.RED,
    textAlign: 'center',
    fontSize: 12,
  },

  valueSmall: {
    fontSize: 12,
  },

  smsButton: {
    fontSize: 10,
    padding: '0px 6px',
  },

}));
