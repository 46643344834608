import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


//  change local color
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { isValidUrl, isValidRegex } from '../../ckcore/core//Validator';

import { getMessageBox } from '../../ckcore/core//TxUtils';
import Config from '../../ckcore/core//Config';
import TxStatus from '../../ckcore/core//TxStatuses';
import User from '../../ckcore/core//User';
import Colors from '../../ckcore/core/Colors';
import ConfirmApiTestDialog from './ConfirmApiTestDialog';

/**
 * API for automatic confirm order
 */
export default function ConfirmApiConfig(props) {

  const classes = useStyle();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const appId = props.appId;

  const [apiConfig, setApiConfig] = useState({});
  const [bankAccounts, setBankAccounts] = useState([]);
  const [apiChanged, setApiChanged] = useState(false);
  const [changedValue, setChangedValue] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAllParams, setShowAllParams] = useState(false);
  const [showEndpointAccounts, setShowEndpointAccounts] = useState(false);
  const [showFilterRules, setShowFilterRules] = useState(false);
  const [showTestDialog, setShowTestDialog] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");


  const closeTestDialog = () => {
    setShowTestDialog(false);
  }

  const handleChangeKey = (field) => event => {
    apiConfig[field]["key"] = event.target.value;
    setApiChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  const handleChangeValue = (field) => event => {
    apiConfig[field]["value"] = event.target.value;
    setApiChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  const handleChangeEndpointKey = (field, index) => event => {
    const url = event.target.value;
    apiConfig[field]["value"][index]["url"] = url;
    setApiChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  const handleChangeEndpointName = (field, index) => event => {
    const url = event.target.value;
    apiConfig[field]["value"][index]["name"] = url;
    setApiChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  const handleChangeRule = (field, name, index) => event => {
    const val = event.target.value;
    apiConfig[field]["value"][index][name] = val;
    setApiChanged(true);

    // fake to refresh
    setChangedValue(!changedValue);
  }

  // Validate all key
  const validateKeys = () => {

    var allValid = true;

    apiConfig.fields.map((field) => {
      var fieldObj = apiConfig[field];
      apiConfig[field]["error"] = false;

      // Validate end point and key
      if (field === 'endpoint') {
        if (fieldObj["key"] !== '' && !isValidUrl(fieldObj["key"])) {
          apiConfig[field]["error"] = true;
          allValid = false;
          console.log("Invalid endpoint: " + fieldObj["key"]);
        }
      } else if (field === 'endpointAccounts') {
        fieldObj.value.map((account) => {
          if (account["url"] !== '' && !isValidUrl(account["url"])) {
            apiConfig[field]["error"] = true;
            allValid = false;
            console.log("Invalid endpoint: " + account["url"] + " of account: " + account.name);
          }
        });
      } else if (field === 'endpointServices') {
        fieldObj.value.map((service) => {
          if (service["url"] !== '' && (!isValidUrl(service["url"]) && !isValidUrl(service["name"]))) {
            apiConfig[field]["error"] = true;
            allValid = false;
            console.log("Invalid endpoint: " + service["url"] + " of account: " + service.name);
          }
        });
      } else if (field === 'filterRules') {
        fieldObj.value.map((ruleObj) => {
          if (ruleObj["name"] !== '' && (!isValidRegex(ruleObj["name"]))) {
            apiConfig[field]["error"] = true;
            allValid = false;
            console.log("Invalid filter rule: " + ruleObj["name"]);
          }
        });
      } else {
        apiConfig[field]["error"] = false;
      }

      // validate value
      if ((fieldObj["value"])) {
        if (!isValidRegex(fieldObj["value"])) {
          apiConfig[field]["regexError"] = true;
          allValid = false;
        } else {
          apiConfig[field]["regexError"] = false;
        }
      }


    });

    setChangedValue(!changedValue);

    return allValid;
  }


  const submitForm = (event) => {

    event.preventDefault();
    event.stopPropagation();

    setSuccessMsg("");
    setErrorMsg("");

    if (validateKeys()) {
      // do update API config
      processUpdateApiConfig();
    } else {
      setErrorMsg("Giá trị không hợp lệ, vui lòng kiểm tra lại");
    }

  }


  // load confirm API config
  const loadAppConfig = async () => {

    setSuccessMsg("");
    setErrorMsg("");

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setApiConfig(response.appConfig);

        if (response.bankAccounts)
          setBankAccounts(response.bankAccounts);

        // check config endpoint by account
        response.appConfig.endpointAccounts.value.map(account => {
          if (account.url) {
            setShowEndpointAccounts(true);
          }
        });

      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // Update API config
  const processUpdateApiConfig = async () => {

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      appId: appId,
      appConfig: apiConfig,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cka)
    };

    // Call server
    try {

      // const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const rawResponse = await fetch(Config.getServerURL().replace('/dashboard?', '/dashboard/cfg?'), requestOptions);
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        console.log("update done");

        setApiChanged(false);
        setSuccessMsg("Cập nhật API thành công");
      } else if (response.statusId === TxStatus.HTTP_UNAUTHORIZED) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setErrorMsg("Cập nhật API bị lỗi, vui lòng thử lại sau.")
      }

    } catch (error) {
      setErrorMsg("Cập nhật API bị lỗi, vui lòng thử lại sau.")
      console.log("Error update API config " + error);
    }
  }

  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);


  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = apiConfig[field];


    if (!showAllParams) {
      if ((field === "endpoint" || field === "secretKey" || field === "orderId" || field === "customerId")
        || ((field === "endpointAccounts" || field === "endpointServices") && showEndpointAccounts)
        || (field === 'filterRules' && showFilterRules)) {
        // show as default
      } else {
        return <div key={field} />
      }
    }

    return (

      <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
        <Box className={classes.fieldNameStyle}>
          {fieldObj["name"]}:
        </Box>
        <Box className={classes.fieldKeyStyle}>
          {
            getFieldConfigKey(field)
          }
        </Box>
        <Box className={classes.fieldValueStyle}>
          {
            getFieldConfigValue(field)
          }
        </Box>
      </Box>
    );
  }

  /**
   * Get field config key
   */
  const getFieldConfigKey = (field) => {

    const fieldObj = apiConfig[field];
    const isError = (apiConfig[field]["error"]);

    // Endpoint POST or Get
    if (field === "endpoint") {
      return <TextField
        key={field}
        error={isError}
        type="text"
        className={classes.textFieldKey}
        inputProps={{ min: 0, autoComplete: 'new-password', style: { textAlign: 'left' } }}
        InputProps={{ autoComplete: 'new-password' }}
        fullWidth
        size="small"
        variant="outlined"
        value={fieldObj["key"]}
        margin="dense"
        label={fieldObj["guide"]}
        onChange={handleChangeKey(field)}
      />
    } else if (field === "secretKey") {

      return (
        <TextField
          key={field}
          error={isError}
          className={classes.textFieldKey}
          fullWidth
          size="small"
          margin="dense"
          variant="outlined"
          inputProps={{ autoComplete: 'off' }}
          autoComplete='off'
          value={fieldObj["key"]}
          label={(fieldObj["value"] === "secret_key") ? fieldObj["guide"] : "Mật khẩu"}
          onChange={handleChangeKey(field)}
          type={showPassword ? "text" : "password"}
          InputProps={{
            min: 0,
            style: { textAlign: 'left' },
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                  size="large">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={(fieldObj["disabled"])}
        />
      );
    } else if (field === "endpointAccounts") {

      return <div style={{ marginTop: 20 }}>
        {
          fieldObj.value.map((account, index) => (
            <TextField
              key={field + index}
              error={isError}
              type="text"
              className={classes.textFieldKey}
              inputProps={{ min: 0, autoComplete: 'new-password', style: { textAlign: 'left' } }}
              InputProps={{ autoComplete: 'new-password' }}
              fullWidth
              size="small"
              variant="outlined"
              value={account.url}
              margin="dense"
              label={account.label}
              onChange={handleChangeEndpointKey(field, index)}
            />
          ))
        }  </div>
    } else if (field === "endpointServices") {

      return <div style={{ marginTop: 20 }}>
        {
          fieldObj.value.map((service, index) => (
            <TextField
              error={isError}
              key={field + "services" + index}
              type="text"
              className={classes.textFieldKey}
              inputProps={{ min: 0, autoComplete: 'new-password', style: { textAlign: 'left' } }}
              InputProps={{ autoComplete: 'new-password' }}
              fullWidth
              size="small"
              variant="outlined"
              value={service.url}
              margin="dense"
              label={service.label}
              onChange={handleChangeEndpointKey(field, index)}
            />
          ))
        }  </div>
    } else if (field === "filterRules") {

      return <div style={{ marginTop: 20 }}>
        {
          fieldObj.value.map((ruleObj, index) => (
            <TextField
              error={isError}
              key={field + "rule-name" + index}
              type="text"
              className={classes.textFieldKey}
              inputProps={{ min: 0, autoComplete: 'new-password', style: { textAlign: 'left' } }}
              InputProps={{ autoComplete: 'new-password' }}
              fullWidth
              size="small"
              variant="outlined"
              value={ruleObj.name}
              margin="dense"
              label={ruleObj.label}
              onChange={handleChangeRule(field, 'name', index)}
            />
          ))
        }  </div>
    }

    return <TextField
      key={field}
      error={isError}
      type="text"
      className={classes.textFieldKey}
      inputProps={{ min: 0, autoComplete: 'new-password', style: { textAlign: 'right' } }}
      InputProps={{ autoComplete: 'new-password' }}
      fullWidth
      size="small"
      variant="outlined"
      value={fieldObj["key"]}
      margin="dense"
      label={fieldObj["guide"]}
      onChange={handleChangeKey(field)}
      disabled={(fieldObj["disabled"])}
    />
  }

  /**
   * Get field config value
   */
  const getFieldConfigValue = (field) => {
    const fieldObj = apiConfig[field];
    const isRegexError = (apiConfig[field]["regexError"]);

    // Endpoint POST or Get
    if (field === "endpoint") {
      return <TextField
        select
        type="text"
        className={classes.textFieldValue}
        inputProps={{ min: 0, autoComplete: 'off', style: { textAlign: 'left' } }}
        InputProps={{ autoComplete: 'off' }}
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        label={fieldObj["exLabel"]}
        onChange={handleChangeValue(field)}
        value={fieldObj["value"]}>
        <MenuItem key="post" value="POST">
          POST
        </MenuItem>
        <MenuItem key="get" value="GET">
          GET
        </MenuItem>
      </TextField>
    } else if (field === "secretKey") {
      return <TextField
        select
        type="text"
        className={classes.textFieldValue}
        inputProps={{ min: 0, autoComplete: 'off', style: { textAlign: 'left' } }}
        InputProps={{ autoComplete: 'off' }}
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        label={fieldObj["exLabel"]}
        onChange={handleChangeValue(field)}
        value={fieldObj["value"]}
        disabled={true}>
        <MenuItem key="secret_key" value="secret_key">
          KHOÁ BÍ MẬT
        </MenuItem>
        <MenuItem key="password" value="password">
          MẬT KHẨU
        </MenuItem>
      </TextField>
    } else if (field === "endpointAccounts") {
      return <div style={{ marginTop: 20 }}>
        {
          fieldObj.value.map((account, index) => (
            <TextField
              type="text"
              className={classes.textFieldValue}
              inputProps={{ autoComplete: 'new-password' }}
              InputProps={{ autoComplete: 'new-password' }}
              fullWidth
              size="small"
              margin="dense"
              variant="outlined"
              value={account.name}
              label={"Số tài khoản"}
              disabled
            />
          ))
        }  </div>
    } else if (field === "endpointServices") {
      return <div style={{ marginTop: 20 }}>
        {
          fieldObj.value.map((service, index) => (
            <TextField
              error={isRegexError}
              type="text"
              className={classes.textFieldKey}
              inputProps={{ min: 0, autoComplete: 'new-password', style: { textAlign: 'left' } }}
              InputProps={{ autoComplete: 'new-password' }}
              fullWidth
              size="small"
              variant="outlined"
              value={service.name}
              margin="dense"
              label={service.exLabel}
              onChange={handleChangeEndpointName(field, index)}
            />
          ))
        }  </div>
    } else if (field === "filterRules") {
      return <div style={{ marginTop: 20 }}>
        {
          fieldObj.value.map((filterObj, index) => (
            <TextField id="ck-filter-rule"  variant="outlined" size="small" margin="dense" fullWidth
              key={field + "rule" + index}
              label={filterObj.exLabel}
              select
              value={filterObj.rule}
              onChange={handleChangeRule(field, 'rule', index)}>
              {fieldObj.rules.map((ruleObj, index) => (
                <MenuItem key={ruleObj.name} value={ruleObj.value}>
                  <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{ruleObj.name}</Box>
                </MenuItem>
              ))}
            </TextField>
          ))
        }  </div>
    }

    if ((fieldObj["value"]) || (fieldObj["value"] === '')) {
      return <TextField
        error={isRegexError}
        type="text"
        className={classes.textFieldValue}
        inputProps={{ autoComplete: 'new-password' }}
        InputProps={{ autoComplete: 'new-password' }}
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        value={fieldObj["value"]}
        label={fieldObj["exLabel"]}
        /* disabled={(fieldObj["disabled"])} */
        onChange={handleChangeValue(field)}
      />

    } else if ((fieldObj["exValue"])) {
      return <TextField
        type="text"
        className={classes.textFieldValue}
        inputProps={{ autoComplete: 'new-password' }}
        InputProps={{ autoComplete: 'new-password' }}
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        value={fieldObj["exValue"]}
        label={fieldObj["exLabel"]}
        disabled
      />
    }
  }

  return (
    <Container className={classes.paper}>

      <h3 className={classes.title}>KẾT NỐI XÁC NHẬN GIAO DỊCH TỰ ĐỘNG</h3>

      <Box p={1} m={1}>
        {
          getMessageBox(errorMsg, successMsg, "Mời bạn cập nhật URL nhận thông báo chuyển khoản thành công")
        }
        <Box className={classes.guide}>
          Tài liệu hướng dẫn kết nối: <Link href="https://github.com/chuyenkhoan" target="_blank" rel="noreferrer">https://github.com/chuyenkhoan</Link>
        </Box>

        <Box className={classes.checkedShowAll}>
          <FormControlLabel
            control={
              <Checkbox color="primary"
                checked={showEndpointAccounts}
                onChange={() => setShowEndpointAccounts(!showEndpointAccounts)}
              />
            }
            label="Endpoint theo dịch vụ hoặc STK"
          />
          <FormControlLabel
            style={{ paddingLeft: 16 }}
            control={
              <Checkbox color="primary"
                checked={showFilterRules}
                onChange={() => setShowFilterRules(!showFilterRules)}
              />
            }
            label="Lọc nội dung chuyển khoản"
          />
          <FormControlLabel
            style={{ paddingLeft: 16 }}
            control={
              <Checkbox color="primary"
                checked={showAllParams}
                onChange={() => setShowAllParams(!showAllParams)}
              />
            }
            label="Hiện tất cả tham số"
          />

        </Box>
      </Box>

      <form className={classes.box_form} onSubmit={submitForm} autoComplete="off">
        {
          (apiConfig.fields) &&
          apiConfig.fields.map((field, index) => (
            getFieldConfig(field)
          ))
        }
        <div className={classes.grow}></div>

        <Divider />

        <Button className={classes.btnAdd} variant="contained" color="primary"
          type="submit" disabled={!apiChanged}>
          Cập nhật
        </Button>

        <Button className={classes.btnAdd} variant="contained" color="primary"
          onClick={() => setShowTestDialog(true)}
          disabled={!(apiConfig.endpoint) || !isValidUrl(apiConfig.endpoint.key) || bankAccounts.length < 1}>
          Test gọi API
        </Button>
      </form>
      {showTestDialog &&
        <ConfirmApiTestDialog open={showTestDialog} closeCallback={closeTestDialog}
          appId={appId}
          apiConfig={apiConfig}
          bankAccounts={bankAccounts}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
        />
      }
    </Container>
  );
}

const useStyle = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },

  guide: {
    padding: 8,
  },

  fieldsBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },

  fieldNameStyle: {
    width: 138,
    textAlign: "right",
  },

  fieldKeyStyle: {
    width: 380,
    marginLeft: 8,
    marginRight: 8,
  },

  fieldValueStyle: {
    width: 190,
  },

  fieldFullStyle: {
    width: 570,
    marginLeft: 8,
    marginRight: 8,
  },


  textFieldKey: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldValue: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldOption: {
  },

  grow: {
    flexFlow: 1,
    minHeight: "15px",
  },

  btnAdd: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  },
}));
