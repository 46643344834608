import React from 'react';
import { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
//  change local color
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import { isValidDomain, isValidIpAddress, isValidNameUppercaseAscii } from '../../ckcore/core/Validator';

import { getMessageBox } from '../../ckcore/core/TxUtils';
import Colors from '../../ckcore/core/Colors';
import Config from '../../ckcore/core/Config';
import TxStatus from '../../ckcore/core/TxStatuses';
import User from '../../ckcore/core/User';

import CkqrConfigTestDialog from './CkqrConfigTestDialog';
import { MenuItem } from '@mui/material';

/**
 * Configuration keys for generate CK QR
 */
export default function CkqrConfig(props) {
  const classes = useStyles();

  const userObj = props.userObj;
  // const partnerObj = props.partnerObj;
  const activePartnerId = userObj.partnerId;
  const appId = props.appId;

  const [ckqrConfig, setCkqrConfig] = useState({});

  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testBankAccounts, setTestBankAcounts] = useState({});
  const [paymentLink, setPaymentLink] = useState("");

  const [messages, setMessages] = useState({error: '', success: '', info: '', changed: false, showPassword: false});

  const toogleShowPassword = () => {
    setMessages({ ...messages, showPassword: !messages.showPassword });
  }

  const handleChange = (field, name) => event => {
    var config = { ...ckqrConfig };
    config[field][name] = event.target.value;
    setCkqrConfig(config);
    setMessages({ ...messages, changed: true });
  }

  const closeTestDialog = () => {
    setShowTestDialog(false);
  }

  
  // Validate all key
  const validateKeys = () => {

    var allValid = true;

    ckqrConfig.fields.map((field) => {

      ckqrConfig[field]["error"] = false;

      // Validate domain
      if (field === "paymentKey") {
        const domains = ckqrConfig[field]["domain"].split(",");
        domains.map((domain) => {
          if ((domain) && !isValidDomain(domain.trim())) {
            ckqrConfig[field]["error"] = true;
            allValid = false;

            console.log("Domain invalid: " + domain);
          }
        });
      } else if (field === "serverKey") {

        const remoteIps = ckqrConfig[field]["domain"].split(",");
        remoteIps.map((remoteIp) => {
          if ((remoteIp) && !isValidIpAddress(remoteIp.trim())) {
            ckqrConfig[field]["error"] = true;
            allValid = false;
            console.log("IP address invalid: " + remoteIp);
          }
        });
      } else if (field === "cmdCode") {
        const commandCode = ckqrConfig[field]["value"];
        if (isValidNameUppercaseAscii(commandCode) && commandCode.length <= 5) {
          
        } else {
          ckqrConfig[field]["error"] = true;
          allValid = false;
          console.log("Order code prefix invalid " + commandCode);
        }
      }
    });

    return allValid;
  }


  const submitForm = (event) => {

    event.preventDefault();
    event.stopPropagation();

    setMessages({ ...messages, error: '', success: '' });

    if (validateKeys()) {
      // do update API config
      processUpateCkqrConfig();
    } else {
      setMessages({ ...messages, error: 'Dữ liệu nhập không hợp lệ, vui lòng kiểm tra lại', success: '' });
    }

  }

  // load confirm API config
  const loadAppConfig = async () => {

    setMessages({ ...messages, error: '', success: '' });

    // refresh message
    const ckmsg = {
      txType: "loadAppConfig",
      appId: appId,
      partnerId: activePartnerId,
      timestamp: new Date().getTime(),
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(ckmsg));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS) {
        setCkqrConfig(response.appConfig);
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
      } else {
        console.log("Request load app config error with status: " + response.statusId);
      }

    } catch (error) {
      console.log("Error load app config " + error);
    }
  }

  // load config on startup
  useEffect(() => {

    // load secure config
    loadAppConfig();

  }, []);


  // Update API config
  const processUpateCkqrConfig = async () => {

    // refresh message
    const cka = {
      txType: "updateAppConfig",
      timestamp: new Date().getTime(),
      partnerId: activePartnerId,
      appId: appId,
      appConfig: ckqrConfig,
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS || response.statusId === TxStatus.SUCCESS) {

        setMessages({ ...messages, error: '', success: 'Cập nhật cấu hình thành công', changed: false });
      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        console.log("Request refresh error with statuses: " + response.statusId);
        setMessages({ ...messages, error: 'Cập nhật API bị lỗi, vui lòng thử lại sau.', success: '' });
      }

    } catch (error) {
      setMessages({ ...messages, error: 'Cập nhật API bị lỗi, vui lòng thử lại sau.', success: '' });
      console.log("Error update API config " + error);
    }
  }

  /**
   * Request test CKQR
   */
  const requestTestCkqr = async () => {

    // refresh message
    const cka = {
      txType: "testAppConfig",
      timestamp: new Date().getTime(),
      partnerId: activePartnerId,
      appId: appId,
      appConfig: ckqrConfig,
      identity: User.getIdentity(),
    };

    // Call server
    try {

      const rawResponse = await fetch(Config.getServerURL() + "cfg=" + JSON.stringify(cka));
      const response = await rawResponse.json();

      if (response.statusId === TxStatus.HTTP_SUCCESS || response.statusId === TxStatus.SUCCESS) {

        const bankAccounts = response.bankAccounts;
        if ((bankAccounts) && bankAccounts.length > 0) {
          // get first bank account
          setTestBankAcounts(bankAccounts);
          // get payment link
          setPaymentLink(response.paymentLink);

          // show test dialog
          setShowTestDialog(true);
        } else {
          setMessages({ ...messages, error: 'Bạn chưa có tài khoản ngân hàng để nhận tiền.', success: '' });
        }

      } else if (response.statusId === TxStatus.TIMEOUT) {
        console.log("User session timeout. Need to login again");
        props.logoutCallback();
      } else {
        setMessages({ ...messages, error: 'Kiểm tra API KEYS bị lỗi, vui lòng thử lại sau.', success: '' });
        console.log("Request refresh error with statuses: " + response.statusId);
      }

    } catch (error) {
      setMessages({ ...messages, error: 'Kiểm tra API KEYS bị lỗi, vui lòng thử lại sau.', success: '' });
      console.log("Error update API config " + error);
    }
  }


  /**
   * Get field config
   */
  const getFieldConfig = (field) => {

    const fieldObj = ckqrConfig[field];

    return (
      <Box key={field} display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="center" alignItems="center" alignContent="stretch">
        <Box className={classes.fieldNameStyle}>
          {fieldObj["name"]}:
        </Box>
        <Box className={classes.fieldValueStyle}>
          {
            getFieldConfigKey(field)
          }
        </Box>
        <Box className={classes.fieldValueStyle}>
          {
            getFieldConfigDomain(field)
          }
        </Box>
      </Box>
    );
  }

  /**
   * Get field config key
   */
  const getFieldConfigKey = (field) => {

    const fieldObj = ckqrConfig[field];

    // Endpoint POST or Get
    if (field === "serverKey") {

      return (
        <TextField
          className={classes.textFieldKey}
          fullWidth
          size="small"
          margin="dense"
          variant="outlined"
          defaultValue={fieldObj["value"]}
          label={fieldObj["label"]}
          onChange={handleChange(field, "value")}
          type={messages.showPassword ? "text" : "password"}
          InputProps={{
            autoComplete: 'new-password',
            min: 0,
            style: { textAlign: 'left' },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => toogleShowPassword()}
                  onMouseDown={() => toogleShowPassword()}
                  size="large">
                  {messages.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          disabled={true}
        />
      );
    } else if(field === 'template') {
      return (
        <TextField id="ck-order-code-form-list" variant="outlined" size="small" margin="dense" fullWidth
          label={fieldObj.label}
          select
          value={fieldObj.value}
          onChange={handleChange(field, 'value')}>
          {fieldObj.templates.map((templateObj, index) => (
            <MenuItem key={templateObj.name} value={templateObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{templateObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      );
    } else {
      return <TextField
        className={classes.textFieldKey}
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        inputProps={{ autoComplete: 'new-password' }}
        defaultValue={fieldObj["value"]}
        label={fieldObj["label"]}
        onChange={handleChange(field, "value")}
        type={"text"}
        disabled={(field !== 'cmdCode')}
        error={(field === 'cmdCode') && (ckqrConfig[field]["error"])}
      />
    }
  }

  /**
   * Get field config domain
   */
  const getFieldConfigDomain = (field) => {
    const fieldObj = ckqrConfig[field];

    if(field === 'cmdCode') {
      return (
        <TextField id="ck-order-code-form-list" variant="outlined" size="small" margin="dense" fullWidth
          label={fieldObj.guide}
          select
          value={fieldObj.code}
          onChange={handleChange(field, 'code')}>
          {fieldObj.codes.map((codeObj, index) => (
            <MenuItem key={codeObj.name} value={codeObj.value}>
              <Box style={{ color: Colors.DARK_BLUE, paddingTop: 3 }}>{codeObj.name}</Box>
            </MenuItem>
          ))}
        </TextField>
      );
    } else if(field === 'template') {
      return <></>;
    } else {
      const isError = (ckqrConfig[field]["error"]);

      return <TextField
        error={isError}
        type="text"
        className={classes.textFieldValue}
        inputProps={{ autoComplete: 'new-password' }}
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        value={fieldObj["domain"]}
        label={fieldObj["guide"]}
        disabled={(fieldObj["disabled"])}
        onChange={handleChange(field, "domain")}
      />
    }
    
  }

  
  return (
    <Container className={classes.paper}>

      <h3 className={classes.title}>TẠO QRCODE CHUYỂN KHOẢN THANH TOÁN 24/7</h3>

      <h3 className={classes.title}>Mã đối tác: {activePartnerId}</h3>
      <Box p={1} m={1}>
        {
          getMessageBox(messages.error, messages.success, "Cập nhật domain website và địa chỉ IP được phép tạo QR chuyển khoản")
        }
        <Box className={classes.guide}>
          Tài liệu hướng dẫn kết nối: <Link href="https://github.com/chuyenkhoan" target="_blank" rel="noreferrer">https://github.com/chuyenkhoan</Link>
        </Box>
      </Box>

      <form className={classes.box_form} onSubmit={submitForm}>
        {
          (ckqrConfig.fields) &&
          ckqrConfig.fields.map((field, index) => (
            getFieldConfig(field)
          ))
        }
        <div className={classes.grow}></div>

        <Divider />

        <Button className={classes.btnAdd} variant="contained" color="primary" type="submit" disabled={!messages.changed}>
          Cập nhật
        </Button>
        <Button className={classes.btnAdd} variant="contained" color="primary" onClick={() => requestTestCkqr()}>
          Test tạo CKQR
        </Button>
      </form>
      {showTestDialog &&
        <CkqrConfigTestDialog open={showTestDialog} closeCallback={closeTestDialog}
          bankAccounts={testBankAccounts}
          paymentKey={ckqrConfig.paymentKey.value}
          testKey={ckqrConfig.testKey.value}
          paymentLink={paymentLink}
          userObj={props.userObj}
          partnerObj={props.partnerObj}
        />
      }
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({

  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },

  title: {
    color: "#37517f",
    marginTop: 20,
  },


  guide: {
    padding: 8,
  },

  fieldNameStyle: {
    width: 150,
    textAlign: "right",
  },

  fieldKeyStyle: {
    width: 320,
    marginLeft: 8,
    marginRight: 8,
  },

  fieldValueStyle: {
    width: 360,
    marginLeft: 8,
    marginRight: 8,
  },

  textFieldKey: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: Colors.DARK_BLUE
    }
  },

  textFieldValue: {
  },

  textFieldOption: {
  },

  grow: {
    flexFlow: 1,
    minHeight: "15px",
  },

  btnAdd: {
    margin: 8,
    marginTop: "15px",
    transition: "all, .15s, ease-in-out",
    "&:hover": {
    },
  }
}));
