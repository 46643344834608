import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SettingsIcon from '@mui/icons-material/Settings';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';

import PartnerHome from './PartnerHome';
import Members from './Members';
import Customer from './Customer';
import Product from './Product';
import Report from './Report';
import Profile from './Profile';
import Setting from './Setting';
import ChangePassword from '../../ckcore/account/ChangePassword';
import CkApiTabs from '../api/CkApiTabs';
import CkDrawer from './CkDrawer';
import User from '../../ckcore/core/User';
import Roles from '../../ckcore/core/Roles';
import Colors from '../../ckcore/core/Colors';
import PartnerBank from './PartnerBank';
import PartnerOrder from './PartnerOrder';
import PartnerCKApp from './PartnerCKApp';
import RootTabs from '../root/RootTabs';
import Config from '../../ckcore/core/Config';
import PopasTabs from '../popas/PopasTabs';
import Guideline from '../help/Guideline';
import CkDrawerResponsive from './CkDrawerResponsive';
import useMediaQuery from '@mui/material/useMediaQuery';


export default function CkApps(props) {

  const classes = useStyles();

  const userObj = props.userObj;
  const partnerObj = props.partnerObj;

  const selectedAppId = props.selectedAppId;
  const action = (props.action) ? props.action : '';

  const activeRole = User.getActiveRole(userObj);
  const largeScreen = useMediaQuery('(min-width:960px)');

  const showCustomersAndProducts = () => {
    if((userObj.partnerId > 0) && !(partnerObj.transparency) &&
      (partnerObj.subscription) && (partnerObj.subscription.package === 'large')) {
      return true;
    }
    return false;
  }

  const showCKApps = () => {
    if((userObj.partnerId > 0)) {
      if (typeof partnerObj.sims !== 'undefined' && partnerObj.sims.length > 0) {
        return true;
      }
    }
    return false;
  }


  const ckAppList = [
    {
      appId: "HOME", default: (userObj.partnerId > 0), name: "Trang chủ", icon: <HomeOutlinedIcon />, app:
        <PartnerHome appId="HOME"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "BANKS", default: (userObj.partnerId > 0), name: "Ngân hàng", icon: <AccountBalanceOutlinedIcon />, app:
        <PartnerBank appId="BANKS"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "ORDERS", default: (userObj.partnerId > 0 && !(partnerObj.transparency)), name: "Đơn hàng", icon: <ReceiptOutlinedIcon />, app:
        <PartnerOrder appId="ORDERS"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "CUSTOMERS", default: showCustomersAndProducts(), name: "Danh bạ", icon: <RecentActorsOutlinedIcon />, app:
        <Customer appId="CUSTOMERS"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "PRODUCTS", default: showCustomersAndProducts(), name: "Sản phẩm", icon: <AppsOutlinedIcon />, app:
        <Product appId="PRODUCTS"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "REPORT", default: false, name: "Báo cáo", icon: <AssessmentOutlinedIcon />, app:
        <Report appId="REPORT" logoutCallback={props.logoutCallback} refreshCallback={props.refreshCallback} />
    },
    {
      appId: "DIVIDER1", default: false, name: "DIVIDER", icon: <div />, app: <div />
    },
    {
      appId: "CKBOX", default: showCKApps(), name: "CKApp", icon: <DeveloperBoardIcon />, app:
        <PartnerCKApp appId="CKBOX"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "DEVELOPER", default: (userObj.partnerId > 0) && (activeRole.roleId === Roles.ADMIN), name: "Tích hợp", icon: <CodeOutlinedIcon />, app:
        <CkApiTabs appId="DEVELOPER"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "SETTING", default: false, name: "Cài đặt", icon: <SettingsIcon />, app:
        <Setting appId="SETTING"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "PROFILE", default: false, name: "Thông tin", icon: <AccountCircleOutlinedIcon />, app:
        <Profile appId="PROFILE"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "PASSWORD", default: false, name: "Đổi mật khẩu", icon: <AccountCircleOutlinedIcon />, app:
        <ChangePassword appId="PASSWORD"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "MEMBERS", default: true, name: "Cài đặt tổ chức", icon: <SettingsOutlinedIcon />, app:
        <Members appId="MEMBERS"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          refreshTime={props.refreshTime}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "DIVIDER2", default: false, name: "DIVIDER", icon: <div />, app: <div />
    },
    {
      appId: "GUIDELINE", default: true, name: "Hướng dẫn", icon: <InfoOutlinedIcon />, app:
        <Guideline appId="GUIDELINE"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          logoutCallback={props.logoutCallback}
          refreshCallback={props.refreshCallback} />
    },
    {
      appId: "SUPERADMIN", default: (userObj.partnerId > 0) && Config.isSuperAdmin(userObj.phone), internal: true, name: "Super Admin", icon: <SupervisorAccountOutlinedIcon />, app:
        <RootTabs appId="SUPERADMIN"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          logoutCallback={props.logoutCallback} refreshCallback={props.refreshCallback} />
    },
    {
      appId: "POPAS", default: (userObj.partnerId > 0) && Config.isSuperAdmin(userObj.phone), internal: true, name: "Potential Partners", icon: <GroupWorkIcon />, app:
        <PopasTabs appId="POPAS"
          action={action}
          userObj={userObj}
          partnerObj={partnerObj}
          logoutCallback={props.logoutCallback} refreshCallback={props.refreshCallback} />
    }
  ];

  // get selected APP
  let selectedApp = ckAppList.find((app) => app.appId === selectedAppId);


  // RETURN VIEW
  return (
    <React.Fragment>

      <CkDrawerResponsive
        ckApps={ckAppList}
        selectedAppId={selectedAppId}
        showApp={props.showApp}
        isOpenDrawer={largeScreen ? true : false}
        userObj={userObj}
        partnerObj={partnerObj} />

      <main className={classes.content}>
        <div className={classes.mainContent}>
          <div className={classes.page}>

            <div className={classes.mainPage}>

              <div className={classes.toolbar} style={{ minHeight: 48 }} />

              <div className={classes.toolbarHeight} />

              {
                selectedApp.app
              }

            </div>
          </div>
        </div>
      </main>

    </React.Fragment>
  );
}

const useStyles = makeStyles((homeTheme) => ({
  content: {
    display: "flex",
    flexGrow: 1,
    padding: homeTheme.spacing(2),
    backgroundColor: Colors.CK_BG,
  },
  mainContent: {
    display: "flex",
    flexGrow: 1,
    padding: homeTheme.spacing(0),
    backgroundColor: Colors.CK_BG,
  },
  page: {
    display: "flex",
    flexGrow: 2,
    justifyContent: "center",
    // height: "100%",
  },
  mainPage: {
    marginRight: "1%",
    [homeTheme.breakpoints.down('md')]: {
      maxWidth: "600px",
      minWidth: "400px",
      width: "100%",
      paddingLeft: 8,
      paddingRight: 8,
    },
    [homeTheme.breakpoints.down('lg')]: {
      maxWidth: "938px",
      width: "100%",
      paddingLeft: 12,
      paddingRight: 12,
    },
    [homeTheme.breakpoints.up("md")]: {
      width: "100%",
      paddingLeft: 12,
      paddingRight: 12,
    },
    [homeTheme.breakpoints.up("lg")]: {
      width: "100%",
      maxWidth: "1260px",
      paddingLeft: 20,
      paddingRight: 20,
    },
    height: "100%",
    minHeight: 48,
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: homeTheme.spacing(0, 1),
    // necessary for content to be below app bar
    ...homeTheme.mixins.toolbar,
  },

  toolbarHeight: {
    height: "8px",
  },
}));